import React, { useContext, useEffect, useState } from 'react'

import Loader from '../common/loader/Loader'
import LockScreen from '../common/lockScreen/LockScreen'
import GifPreloader from '../games/fruits/helpers/gifGenerators/GifPreloader'
import { Context as AuthContext } from '../../context/AuthContext'
import { Context as NavContext } from '../../context/NavContext'
import { Context as UserContext } from '../../context/UserContext'
import { Context as GameContext } from '../../context/GameContext'
import { Context as SocketContext } from '../../context/SocketContext'
import { Context as PayoutContext } from '../../context/PayoutContext'
import { Context as PromoPrizeWheelContext } from '../../context/PromoPrizeWheelContext'
import { Context as BaitContext } from '../../context/BaitContext'
import './gamesMenu.scss'

const GamesMenu = () => {
  const [loading, setLoading] = useState(true)
  const [username, setUsername] = useState('')

  const { signOut } = useContext(AuthContext)

  const {
    state: { lock, reload },
    setSocketDataFromClient,
    setLock,
    setReload,
  } = useContext(SocketContext)

  const { setVisibleComponent } = useContext(NavContext)

  const {
    state: { siteData },
    fetchSiteData,
    setPromoPrizeWheelShow,
    setPromoPrizeWheelUsed,
  } = useContext(PromoPrizeWheelContext)

  const {
    state: { clientMostRecentBuyIn },
    fetchSiteFloorCredits,
    fetchSiteBankValue,
    fetchClientProgresiveData,
    fetchSiteClients,
    fetchClientMostRecentBuyIn,
  } = useContext(PayoutContext)

  const {
    state: { loading: userContextLoading, user, error },
    fetchUser,
  } = useContext(UserContext)

  const {
    state: {
      latestGamePlayData,
      freeSpinsTotal,
      gameCredits,
      userSetCreditCount,
    },
    setGameCredits,
    setNumberOfLines,
    setFreeSpinsTotal,
    fetchLatestGamePlayData,
    fetchJackpotData,
    resetAllGameState,
    setNewGameOpenBalance,
    setUserSetCreditCount,
  } = useContext(GameContext)

  const {
    state: { siteBaitData },
    fetchSiteBait,
  } = useContext(BaitContext)

  useEffect(() => {
    if (!siteData || userContextLoading) {
      setLoading(true)
    }
    if (siteData && !userContextLoading) {
      setLoading(false)
    }
  }, [siteData, userContextLoading])

  useEffect(() => {
    fetchUser()
    fetchLatestGamePlayData()
    resetAllGameState()
  }, [])

  useEffect(() => {
    if (!lock || lock) {
      fetchUser()
    }
  }, [lock])

  useEffect(() => {
    if (user) {
      if (userSetCreditCount === 0) {
        setGameCredits(user.credits)
        setUserSetCreditCount(1)
      }
    }
  }, [user, userSetCreditCount])

  useEffect(() => {
    if (reload) {
      if (user) {
        setGameCredits(user.credits)
        setReload(false)
      }
    }
  }, [reload, user])

  useEffect(() => {
    if (user) {
      fetchJackpotData({ siteNumber: user.siteNumber })
      fetchSiteBait({ siteNumber: user.siteNumber })
      fetchClientProgresiveData()
      fetchSiteFloorCredits({ siteNumber: user.siteNumber })
      fetchSiteClients({ siteNumber: user.siteNumber })
      fetchSiteBankValue({ siteNumber: user.siteNumber })
      fetchClientMostRecentBuyIn({
        siteNumber: user.siteNumber,
        username: user.username,
      })
      fetchSiteData({ siteNumber: user.siteNumber })
    }
  }, [user])

  useEffect(() => {
    if (siteData) {
      const { promoPrizeWheelActive } = siteData
      setPromoPrizeWheelShow(promoPrizeWheelActive)
    }
  }, [siteData])

  useEffect(() => {
    if (error === 'no user') setVisibleComponent('login')
  }, [error])

  useEffect(() => {
    if (user) {
      const { username } = user
      if (username) {
        setUsername(username)
      }
    }
  }, [user])

  useEffect(() => {
    if (clientMostRecentBuyIn) {
      setNewGameOpenBalance(clientMostRecentBuyIn)
    }
  }, [clientMostRecentBuyIn])

  const trigerCashOut = () => {
    setSocketDataFromClient({
      subject: 'cashOut',
      clientUsername: user.username,
      cashOut: true,
    })
    setLock(true)
  }

  // AUTO START IF USER HAS FREE SPINS AND SYSTEM HAS RESET...!
  useEffect(() => {
    if (user && latestGamePlayData.length > 0) {
      const { numberOfLines, nameOfGame } = latestGamePlayData[0]
      const { freeSpins } = user
      if (freeSpins > 0) {
        setFreeSpinsTotal(freeSpins)
        setNumberOfLines(numberOfLines)
        setPromoPrizeWheelShow(false)
        setPromoPrizeWheelUsed(true)
        if (nameOfGame.length > 0) {
          setVisibleComponent(nameOfGame)
        } else {
          setVisibleComponent('gamesMenu')
        }
      }
    }
  }, [latestGamePlayData, user])

  const renderContent = () => {
    if (loading) return <Loader />
    if (lock) return <LockScreen />
    if (!user || user.length < 1) return null
    return (
      <div className="gamesMenuBed">
        <div className="gamesMenuHeaderBarBed">
          <div className="gamesMenuHeaderBarCredits">
            Credits:{' '}
            {!gameCredits
              ? null
              : gameCredits
                  .toString()
                  .replace(/.(?=(...........)*..$)/g, '$&,')}
            {gameCredits < 1 ? null : (
              <div
                className="gamesMenuHeaderBarLogout"
                onClick={() => trigerCashOut()}
              >
                Cash out
              </div>
            )}
          </div>
          <div className="gamesMenuHeaderBarLogout">{username.slice(6)}</div>
          <div className="gamesMenuHeaderBarLogout" onClick={() => signOut()}>
            Log out
          </div>
        </div>
        <div className="gamesMenuCenterBed">
          <div className="gamesMenuHeaderBed">
            <div className="gamesMenuHeading">Games Menu</div>
          </div>
          <div className="gamesMenuList">
            <div
              className="gamesMenuItemsBed"
              onClick={
                gameCredits === null || gameCredits === 0
                  ? null
                  : () => setVisibleComponent('fruits')
              }
            >
              <div className="gamesMenuItem">
                <div className="gameMenuButtonGameName">Fruitakia</div>
              </div>
            </div>
            {/* <div className="gamesMenuItemsBed">
              <div
                className="gamesMenuItem"
                onClick={
                  gameCredits === null || gameCredits === 0
                    ? null
                    : () => setVisibleComponent('faces')
                }
              >
                <div className="gamesMenuText">Faces</div>
              </div>
            </div> */}
            {/* <div className="gamesMenuItemsBed">
              <div
                className="gamesMenuItem"
                onClick={
                  gameCredits === null || gameCredits === 0
                    ? null
                    : () => setVisibleComponent('test')
                }
              >
                <div className="gamesMenuText">Test</div>
              </div>
            </div> */}
          </div>
          <div className="gamesMenuInsertCoinBed">
            <div className="gamesMenuInsertCoinText">
              {gameCredits === null || gameCredits === 0
                ? 'insert coin'
                : 'select game'}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <GifPreloader />
      <div className="gamesMenuBackGroundBed">
        <ul className="gamesMenuBackGroundShapes">
          {renderContent()}
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
    </>
  )
}

export default GamesMenu
