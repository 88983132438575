import React, { useState, useContext } from 'react'
import ReactDOM from 'react-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTimesCircle,
  faTimes,
  faArrowLeftLong,
} from '@fortawesome/free-solid-svg-icons'
import useSound from 'use-sound'

import { Context as GameContext } from '../../../../context/GameContext'
import pressButton from '../../../../assets/sounds/press_but.mp3'
import './fruitsPaytable.css'

const FruitsPaytableModal = () => {
  const [modalShow, setModalShow] = useState(false)

  const [playPressButtonSound] = useSound(pressButton)

  const {
    state: { betValue },
  } = useContext(GameContext)

  if (!modalShow)
    return (
      <div className="fruitsPaytableButtonBed">
        <div
          className="fruitsPaytableButton"
          onClick={() => {
            playPressButtonSound()
            setModalShow(true)
          }}
        >
          Paytable
        </div>
      </div>
    )

  const renderSymbolPaytableCenterLeftRight = ({
    symbol,
    value3,
    value4,
    value5,
  }) => {
    let formattedValue3Lines = (value3 / 100).toFixed(2)
    let formattedValue4Lines = (value4 / 100).toFixed(2)
    let formattedValue5Lines = (value5 / 100).toFixed(2)
    return (
      <div className="fruitsSymbolPaytableCenterBed">
        <div className="fruitsSymbolPaytableCenterLeft">{symbol}</div>
        <div className="fruitsSymbolPaytableCenterCenter">
          <div>
            <FontAwesomeIcon
              className="fruitsSymbolPaytablePoint"
              icon={faTimes}
            />
            5
          </div>
          <div>
            <FontAwesomeIcon
              className="fruitsSymbolPaytablePoint"
              icon={faTimes}
            />
            4
          </div>
          <div>
            <FontAwesomeIcon
              className="fruitsSymbolPaytablePoint"
              icon={faTimes}
            />
            3
          </div>
        </div>
        <div className="fruitsSymbolPaytableCenterRight">
          <div>{formattedValue5Lines}</div>
          <div>{formattedValue4Lines}</div>
          <div>{formattedValue3Lines}</div>
        </div>
      </div>
    )
  }

  const renderSymbolPaytableCenterCenter = () => {
    return (
      <>
        <div className="fruitsSymbolPaytableCenterTop">
          <div className="fruitsSymbolPaytableCenterTopHeading">Wild Card</div>
          <div className="fruitsSymbolPaytableCenterTopSymbolBed">
            <div className="fruitsSymbolPaytableCenterTopSymbolLeft">🍀</div>
            <div className="fruitsSymbolPaytableCenterTopSymbolCenter">
              <div className="fruitsSymbolPaytableCenterCenter">
                <FontAwesomeIcon
                  icon={faArrowLeftLong}
                  onClick={() => setModalShow(false)}
                />
              </div>
            </div>
            <div className="fruitsSymbolPaytableCenterTopSymbolRight">
              Substitute for any symbol
            </div>
          </div>
        </div>
        <div className="fruitsSymbolPaytableCenterBottom">
          <div className="fruitsSymbolPaytableCenterBottomHeading">
            <div className="fruitsSymbolPaytableCenterBottomHeadingLeft">+</div>
            <div className="fruitsSymbolPaytableCenterBottomHeadingRight"></div>
          </div>
          <div className="fruitsSymbolPaytableCenterBottomSymbolBed">
            <div className="fruitsSymbolPaytableCenterBottomSymbolLeft">🔔</div>
            <div className="fruitsSymbolPaytableCenterBottomSymbolRight">
              <div className="fruitsSymbolPaytableCenterBottomSymbolRightLeft">
                =
              </div>
              <div className="fruitsSymbolPaytableCenterBottomSymbolRightRight">
                10 x FREE SPINS!
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  const renderSymbolPaytableBottom = ({
    className,
    symbol1,
    symbol2,
    symbol3,
    value5,
    value4,
    value3,
  }) => {
    let formattedValue5Lines = (value5 / 100).toFixed(2)
    let formattedValue4Lines = (value4 / 100).toFixed(2)
    let formattedValue3Lines = (value3 / 100).toFixed(2)
    return (
      <div className={className}>
        <div className="fruitsSymbolPaytableBottomLeft">
          <div className="fruitsSymbolPaytableBottomSymbolBed">
            <div>{symbol1}</div>
            <div>{symbol2}</div>
            {!symbol3 ? null : <div>{symbol3}</div>}
          </div>
        </div>
        <div className="fruitsSymbolPaytableBottomCenter">
          <div>
            <FontAwesomeIcon
              className="fruitsSymbolPaytablePoint"
              icon={faTimes}
            />
            5
          </div>
          <div>
            <FontAwesomeIcon
              className="fruitsSymbolPaytablePoint"
              icon={faTimes}
            />
            4
          </div>
          <div>
            <FontAwesomeIcon
              className="fruitsSymbolPaytablePoint"
              icon={faTimes}
            />
            3
          </div>
        </div>
        <div className="fruitsSymbolPaytableLeftRight">
          <div>{formattedValue5Lines}</div>
          <div>{formattedValue4Lines}</div>
          <div>{formattedValue3Lines}</div>
        </div>
      </div>
    )
  }

  const renderContent = () => {
    return (
      <div className="fruitsPaytableBed">
        <div className="fruitPaytableHeader">
          <div className="fruitPaytableHeaderNameBed">
            <div className="fruitPaytableHeaderNameContainer">
              <div className="fruitPaytableHeaderNameText">Lucky Fruits</div>
            </div>
          </div>
          <div className="fruitPaytableCloseButtonBed">
            <FontAwesomeIcon
              className="fruitPaytableCloseButton"
              icon={faTimesCircle}
              onClick={() => setModalShow(false)}
            />
          </div>
        </div>
        <div className="fruitPaytableCenter">
          <div className="fruitPaytableCenterLeft">
            {renderSymbolPaytableCenterLeftRight({
              symbol: '🍓',
              value3: betValue * 100,
              value4: betValue * 1000,
              value5: betValue * 5000,
            })}
            {renderSymbolPaytableCenterLeftRight({
              symbol: '🍇',
              value3: betValue * 30,
              value4: betValue * 100,
              value5: betValue * 750,
            })}
          </div>
          <div className="fruitPaytableCenterCenter">
            {renderSymbolPaytableCenterCenter()}
          </div>
          <div className="fruitPaytableCenterRight">
            {renderSymbolPaytableCenterLeftRight({
              symbol: '🍒',
              value3: betValue * 40,
              value4: betValue * 400,
              value5: betValue * 2000,
            })}
            {renderSymbolPaytableCenterLeftRight({
              symbol: '🍌',
              value3: betValue * 30,
              value4: betValue * 100,
              value5: betValue * 750,
            })}
          </div>
        </div>
        <div className="fruitPaytableFooter">
          {renderSymbolPaytableBottom({
            className: 'fruitsSymbolPaytableBottomBedLeft',
            symbol1: '🍉',
            symbol2: '🥑',
            value5: betValue * 150,
            value4: betValue * 40,
            value3: betValue * 5,
          })}
          {renderSymbolPaytableBottom({
            className: 'fruitsSymbolPaytableBottomBedRight',
            symbol1: '🍊',
            symbol2: '🍋',
            symbol3: '🍈',
            value5: betValue * 100,
            value4: betValue * 25,
            value3: betValue * 5,
          })}
        </div>
      </div>
    )
  }

  return ReactDOM.createPortal(
    <div className="ui dimmer active">{renderContent()}</div>,
    document.querySelector('#modal')
  )
}

export default FruitsPaytableModal
