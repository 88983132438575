// desctiption: not balanced, high paying symbols are most frequent excluding wildcard and scatter
import { useContext, useEffect, useState } from 'react'

import { Context as GameContext } from '../../../../../context/GameContext'
import { Context as PayoutContext } from '../../../../../context/PayoutContext'

const CondenseJackpotNumber = () => {
  const [condensedNumber1Done, setCondensedNumber1Done] = useState(false)
  const [condensedNumber2Done, setCondensedNumber2Done] = useState(false)
  const [condensedNumber3Done, setCondensedNumber3Done] = useState(false)
  const [condensedNumber4Done, setCondensedNumber4Done] = useState(false)
  const [condensedNumber5Done, setCondensedNumber5Done] = useState(false)

  const {
    state: {
      randomNumberB1,
      randomNumberB2,
      randomNumberB3,
      randomNumberB4,
      randomNumberB5,
      reelSpinning5,
    },
    setCondensedNumberB1,
    setCondensedNumberB2,
    setCondensedNumberB3,
    setCondensedNumberB4,
    setCondensedNumberB5,
    setCondenseRandomNumberBDone,
  } = useContext(GameContext)

  const {
    state: { payoutOverLimitStartReplay },
  } = useContext(PayoutContext)

  useEffect(() => {
    condensedRandomNumberB1()
    condensedRandomNumberB2()
    condensedRandomNumberB3()
    condensedRandomNumberB4()
    condensedRandomNumberB5()
  }, [
    randomNumberB1,
    randomNumberB2,
    randomNumberB3,
    randomNumberB4,
    randomNumberB5,
  ])

  useEffect(() => {
    if (
      condensedNumber1Done &&
      condensedNumber2Done &&
      condensedNumber3Done &&
      condensedNumber4Done &&
      condensedNumber5Done
    ) {
      setCondenseRandomNumberBDone(true)
    }
  }, [
    condensedNumber1Done,
    condensedNumber2Done,
    condensedNumber3Done,
    condensedNumber4Done,
    condensedNumber5Done,
  ])

  useEffect(() => {
    if (!reelSpinning5 || payoutOverLimitStartReplay) {
      setCondenseRandomNumberBDone(false)
      setCondensedNumber1Done(false)
      setCondensedNumber2Done(false)
      setCondensedNumber3Done(false)
      setCondensedNumber4Done(false)
      setCondensedNumber5Done(false)
    }
  }, [reelSpinning5, payoutOverLimitStartReplay])

  const condensedRandomNumberB1 = () => {
    switch (true) {
      case randomNumberB1 > 0 && randomNumberB1 < 100:
        setCondensedNumberB1(12)
        setCondensedNumber1Done(true)
        break
      default:
        break
    }
  }

  const condensedRandomNumberB2 = () => {
    switch (true) {
      case randomNumberB2 > 0 && randomNumberB2 < 100:
        setCondensedNumberB2(12)
        setCondensedNumber2Done(true)
        break
      default:
        break
    }
  }

  const condensedRandomNumberB3 = () => {
    switch (true) {
      case randomNumberB3 > 0 && randomNumberB3 < 100:
        setCondensedNumberB3(12)
        setCondensedNumber3Done(true)
        break
      default:
        break
    }
  }

  const condensedRandomNumberB4 = () => {
    switch (true) {
      case randomNumberB4 > 0 && randomNumberB4 < 100:
        setCondensedNumberB4(12)
        setCondensedNumber4Done(true)
        break
      default:
        break
    }
  }

  const condensedRandomNumberB5 = () => {
    switch (true) {
      case randomNumberB5 > 0 && randomNumberB5 < 100:
        setCondensedNumberB5(12)
        setCondensedNumber5Done(true)
        break
      default:
        break
    }
  }

  return null
}

export default CondenseJackpotNumber
