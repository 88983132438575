import createDataContext from './createDataContext'
import ngrokApi from '../api/ngrokApi'

// Reducer
const PayoutContext = (state, action) => {
  switch (action.type) {
    case 'FETCH_SITE_TRANSACTION_HISTORY':
      return { ...state, siteTransactionHistory: action.payload }
    case 'FETCH_SITE_FLOOR_CREDITS':
      return { ...state, siteFloorCredits: action.payload }
    case 'FETCH_SITE_BANK_VALUE':
      return { ...state, siteBankValue: action.payload }
    case 'UPDATE_PROGRESIVE_JACKPOT_BALANCE_REMAINING':
      return { ...state, progresiveJackpotBalanceRemaining: action.payload }
    case 'FETCH_SITE_CLIENTS':
      return { ...state, siteClients: action.payload }
    case 'FETCH_CLIENT_PROGRESIVE_DATA':
      return { ...state, clientProgresiveData: action.payload, loading: false }
    case 'UPDATE_CLIENT_PROGRESIVE':
      return { ...state, clientProgresiveData: action.payload }
    case 'SET_PAYOUT_UNDER_LIMIT_CHECK_DONE':
      return { ...state, payoutUnderLimitCheckDone: action.payload }
    case 'SET_PAYOUT_OVER_LIMIT_START_REPLAY':
      return { ...state, payoutOverLimitStartReplay: action.payload }
    case 'SET_CONDENCER_COUNTER':
      return { ...state, condenserCounter: action.payload }
    case 'SET_WIN_RESERVE_BALANCE':
      return { ...state, winReserveBalance: action.payload }
    case 'SET_TRIGGER_STRATEGY_SELECTOR':
      return { ...state, triggerStrategySelector: action.payload }
    case 'SET_PROGRESIVE_JACKPOT_RELEASE':
      return { ...state, progresiveJackpot1Release: action.payload }
    case 'SET_CLIENT_PROGRESIVE_1':
      return { ...state, clientProgresive1: action.payload }
    case 'SET_CLIENT_PROGRESIVE_1_RELEASE':
      return { ...state, clientProgresive1Release: action.payload }
    case 'SET_CLIENT_PROGRESIVE_1_BALANCE':
      return { ...state, clientProgresive1Balance: action.payload }
    case 'SET_CLIENT_PROGRESIVE_2':
      return { ...state, clientProgresive2: action.payload }
    case 'SET_CLIENT_PROGRESIVE_2_RELEASE':
      return { ...state, clientProgresive2Release: action.payload }
    case 'SET_CLIENT_PROGRESIVE_2_BALANCE':
      return { ...state, clientProgresive2Balance: action.payload }
    case 'SET_CLIENT_PROGRESIVE_3':
      return { ...state, clientProgresive3: action.payload }
    case 'SET_CLIENT_PROGRESIVE_3_RELEASE':
      return { ...state, clientProgresive3Release: action.payload }
    case 'SET_CLIENT_PROGRESIVE_3_BALANCE':
      return { ...state, clientProgresive3Balance: action.payload }
    case 'SET_ADD_CREDIT_AGERAGE':
      return { ...state, addCreditAverage: action.payload }
    case 'SET_JACKPOT_WON':
      return { ...state, jackpotWon: action.payload }
    case 'SET_TRIGGER_JACKPOT_OVERLAY':
      return { ...state, triggerJackpotOverlay: action.payload }
    case 'TRIGGER_CONDENSE_PRE_JACKPOT_NUMBER':
      return { ...state, triggerCondensePreJackpotNumber: action.payload }
    case 'RESET_JACKPOT_STATE':
      return { ...state, resetJackpotState: action.payload }
    case 'SET_JACKPOT_OVERLAY_VALUE':
      return { ...state, jackpotOverlayValue: action.payload }
    case 'CLAIM_JACKPOT':
      return { ...state, jackpotClaimed: action.payload }
    case 'RESET_JACKPOT_CLAIMED':
      return { ...state, jackpotClaimed: action.payload }
    case 'PAUSE_JACKPOT_RELEASE_DB':
      return { ...state, pauseJackpotReleaseDB: action.payload }
    case 'FETCH_CLIENT_MOST_RECENT_BUY_IN':
      return { ...state, clientMostRecentBuyIn: action.payload, loading: false }
    case 'SET_NEW_GAME_OPENING_BALANCE_COUNT':
      return { ...state, newGameOpeningBalanceCount: action.payload }
    case 'SET_UPDATE_CREDIT_COUNTER':
      return { ...state, updateCreditCounter: action.payload }
    default:
      return state
  }
}

// Actions
const fetchSiteTransactionHistory = (dispatch) => async (siteNumber) => {
  try {
    const response = await ngrokApi.post(
      '/payout/site-transaction-history',
      siteNumber
    )
    dispatch({
      type: 'FETCH_SITE_TRANSACTION_HISTORY',
      payload: response.data,
    })
    return
  } catch (error) {
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const fetchSiteFloorCredits = (dispatch) => async (siteNumber) => {
  try {
    const response = await ngrokApi.post(
      '/payout/site-floor-credits',
      siteNumber
    )
    dispatch({
      type: 'FETCH_SITE_FLOOR_CREDITS',
      payload: response.data,
    })
    return
  } catch (error) {
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const fetchSiteBankValue = (dispatch) => async (siteNumber) => {
  try {
    const response = await ngrokApi.post('/payout/site-bank-value', siteNumber)
    dispatch({
      type: 'FETCH_SITE_BANK_VALUE',
      payload: response.data,
    })
    return
  } catch (error) {
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const fetchSiteClients = (dispatch) => async (siteNumber) => {
  try {
    const response = await ngrokApi.post(
      '/payout/fetch-site-clients',
      siteNumber
    )
    dispatch({
      type: 'FETCH_SITE_CLIENTS',
      payload: response.data,
    })
    return
  } catch (error) {
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const updateProgresiveJackpotBalanceRemaining = (dispatch) => async (data) => {
  try {
    const response = await ngrokApi.post(
      '/payout/progresive-jackpot-balance-remaining',
      data
    )
    dispatch({
      type: 'UPDATE_PROGRESIVE_JACKPOT_BALANCE_REMAINING',
      payload: response.data,
    })
    return
  } catch (error) {
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const fetchClientProgresiveData = (dispatch) => async () => {
  dispatch({ type: 'LOADING', payload: true })
  try {
    const response = await ngrokApi.post('/client-progresive/fetch')
    dispatch({ type: 'FETCH_CLIENT_PROGRESIVE_DATA', payload: response.data })
  } catch (error) {
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const updateClientProgresive = (dispatch) => async (data) => {
  try {
    const response = await ngrokApi.post('/client-progresive/update', data)
    dispatch({ type: 'UPDATE_CLIENT_PROGRESIVE', payload: response.data })
  } catch (error) {
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const setClientProgresive1BalanceRemaining = (dispatch) => async (data) => {
  try {
    await ngrokApi.post('/payout/client-progresive-1-balance-remaining', data)
    return
  } catch (error) {
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const setClientProgresive2BalanceRemaining = (dispatch) => async (data) => {
  try {
    await ngrokApi.post('/payout/client-progresive-2-balance-remaining', data)
    return
  } catch (error) {
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const setClientProgresive3BalanceRemaining = (dispatch) => async (data) => {
  try {
    await ngrokApi.post('/payout/client-progresive-3-balance-remaining', data)
    return
  } catch (error) {
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const setClientProgresiveReleaseDB = (dispatch) => async (data) => {
  try {
    await ngrokApi.post('/client-progresive/client-progresive-release-db', data)
  } catch (error) {
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const setProgresiveReleaseDB = (dispatch) => async (data) => {
  try {
    await ngrokApi.post('/payout/progresive-release-db', data)
  } catch (error) {
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const setPayoutUnderLimitCheckDone = (dispatch) => (value) => {
  dispatch({ type: 'SET_PAYOUT_UNDER_LIMIT_CHECK_DONE', payload: value })
}

const setPayoutOverLimitStartReplay = (dispatch) => (value) => {
  dispatch({ type: 'SET_PAYOUT_OVER_LIMIT_START_REPLAY', payload: value })
}

const setCondenserCounter = (dispatch) => (value) => {
  dispatch({ type: 'SET_CONDENCER_COUNTER', payload: value })
}

const setWinReserveBalance = (dispatch) => (value) => {
  dispatch({ type: 'SET_WIN_RESERVE_BALANCE', payload: value })
}

const setTriggerStrategySelector = (dispatch) => (value) => {
  dispatch({ type: 'SET_TRIGGER_STRATEGY_SELECTOR', payload: value })
}

const setProgresiveJackpot1Release = (dispatch) => (value) => {
  dispatch({ type: 'SET_PROGRESIVE_JACKPOT_RELEASE', payload: value })
}

const setClientProgresive1 = (dispatch) => (value) => {
  dispatch({ type: 'SET_CLIENT_PROGRESIVE_1', payload: value })
}

const setClientProgresive1Release = (dispatch) => (value) => {
  dispatch({ type: 'SET_CLIENT_PROGRESIVE_1_RELEASE', payload: value })
}

const setClientProgresive1Balance = (dispatch) => (value) => {
  dispatch({ type: 'SET_CLIENT_PROGRESIVE_1_BALANCE', payload: value })
}

const setClientProgresive2 = (dispatch) => (value) => {
  dispatch({ type: 'SET_CLIENT_PROGRESIVE_2', payload: value })
}

const setClientProgresive2Release = (dispatch) => (value) => {
  dispatch({ type: 'SET_CLIENT_PROGRESIVE_2_RELEASE', payload: value })
}

const setClientProgresive2Balance = (dispatch) => (value) => {
  dispatch({ type: 'SET_CLIENT_PROGRESIVE_2_BALANCE', payload: value })
}

const setClientProgresive3 = (dispatch) => (value) => {
  dispatch({ type: 'SET_CLIENT_PROGRESIVE_3', payload: value })
}

const setClientProgresive3Release = (dispatch) => (value) => {
  dispatch({ type: 'SET_CLIENT_PROGRESIVE_3_RELEASE', payload: value })
}

const setClientProgresive3Balance = (dispatch) => (value) => {
  dispatch({ type: 'SET_CLIENT_PROGRESIVE_3_BALANCE', payload: value })
}

const setAddCreditAverage = (dispatch) => (value) => {
  dispatch({ type: 'SET_ADD_CREDIT_AGERAGE', payload: value })
}

const setJackpotReleaseDB = (dispatch) => async (data) => {
  try {
    await ngrokApi.post('/payout/jackpot-release-db', data)
  } catch (error) {
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const setJackpotWon = (dispatch) => (value) => {
  dispatch({ type: 'SET_JACKPOT_WON', payload: value })
}

const setTriggerJackpotOverlay = (dispatch) => (value) => {
  dispatch({ type: 'SET_TRIGGER_JACKPOT_OVERLAY', payload: value })
}

const setTriggerCondensePreJackpotNumber = (dispatch) => (value) => {
  dispatch({ type: 'TRIGGER_CONDENSE_PRE_JACKPOT_NUMBER', payload: value })
}

const setResetJackpotState = (dispatch) => (value) => {
  dispatch({ type: 'RESET_JACKPOT_STATE', payload: value })
}

const setJackpotOverlayValue = (dispatch) => (value) => {
  dispatch({ type: 'SET_JACKPOT_OVERLAY_VALUE', payload: value })
}

const claimJackpot = (dispatch) => async (data) => {
  try {
    const response = await ngrokApi.post('/payout/claim-jackpot', data)
    dispatch({ type: 'CLAIM_JACKPOT', payload: response.data })
  } catch (error) {
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const resetJackpotClaimed = (dispatch) => (value) => {
  dispatch({ type: 'RESET_JACKPOT_CLAIMED', payload: value })
}

const setPauseJackpotReleaseDB = (dispatch) => (value) => {
  dispatch({ type: 'PAUSE_JACKPOT_RELEASE_DB', payload: value })
}

const fetchClientMostRecentBuyIn = (dispatch) => async (data) => {
  dispatch({ type: 'LOADING', payload: true })
  try {
    const response = await ngrokApi.post(
      '/payout/fetch-client-most-recent-buy-in',
      data
    )
    dispatch({
      type: 'FETCH_CLIENT_MOST_RECENT_BUY_IN',
      payload: response.data,
    })
  } catch (error) {
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const setNewGameOpeningBalanceCount = (dispatch) => (value) => {
  dispatch({ type: 'SET_NEW_GAME_OPENING_BALANCE_COUNT', payload: value })
}

const setUpdateCreditCounter = (dispatch) => (value) => {
  dispatch({ type: 'SET_UPDATE_CREDIT_COUNTER', payload: value })
}

export const { Context, Provider } = createDataContext(
  PayoutContext,
  {
    fetchSiteTransactionHistory,
    fetchSiteFloorCredits,
    fetchSiteClients,
    fetchSiteBankValue,
    setPayoutUnderLimitCheckDone,
    setPayoutOverLimitStartReplay,
    setCondenserCounter,
    setWinReserveBalance,
    setTriggerStrategySelector,
    updateProgresiveJackpotBalanceRemaining,
    fetchClientProgresiveData,
    updateClientProgresive,
    setProgresiveJackpot1Release,
    setClientProgresive1,
    setClientProgresive1Release,
    setClientProgresive1Balance,
    setClientProgresive1BalanceRemaining,
    setClientProgresive2BalanceRemaining,
    setClientProgresive3BalanceRemaining,
    setClientProgresiveReleaseDB,
    setProgresiveReleaseDB,
    setClientProgresive2,
    setClientProgresive2Release,
    setClientProgresive2Balance,
    setClientProgresive3,
    setClientProgresive3Release,
    setClientProgresive3Balance,
    setAddCreditAverage,
    setJackpotReleaseDB,
    setJackpotWon,
    setTriggerJackpotOverlay,
    setTriggerCondensePreJackpotNumber,
    setResetJackpotState,
    setJackpotOverlayValue,
    claimJackpot,
    resetJackpotClaimed,
    setPauseJackpotReleaseDB,
    fetchClientMostRecentBuyIn,
    setNewGameOpeningBalanceCount,
    setUpdateCreditCounter,
  },
  // Initial state
  {
    siteTransactionHistory: null,
    siteFloorCredits: null,
    siteBankValue: null,
    siteClients: null,
    payoutUnderLimitCheckDone: false,
    payoutOverLimitStartReplay: false,
    condenserCounter: 0,
    winReserveBalance: 0,
    triggerStrategySelector: false,
    progresiveJackpot1Release: false,
    clientProgresiveData: null,
    clientProgresive1: 0,
    clientProgresive1Release: false,
    clientProgresive1Balance: 0,
    clientProgresive2: 0,
    clientProgresive2Release: false,
    clientProgresive2Balance: 0,
    clientProgresive3: 0,
    clientProgresive3Release: false,
    clientProgresive3Balance: 0,
    addCreditAverage: 0,
    jackpotWon: false,
    triggerJackpotOverlay: false,
    triggerCondensePreJackpotNumber: false,
    resetJackpotState: false,
    jackpotOverlayValue: 0,
    jackpotClaimed: false,
    pauseJackpotReleaseDB: false,
    clientMostRecentBuyIn: 0,
    newGameOpeningBalanceCount: 0,
    updateCreditCounter: 0,
    progresiveJackpotBalanceRemaining: null,
  }
)
