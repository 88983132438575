import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'

function FullscreenButton() {
  const [isFullscreen, setIsFullscreen] = useState(
    document.fullscreenElement ||
      document.mozFullScreenElement ||
      document.webkitFullscreenElement ||
      document.msFullscreenElement
  )

  const toggleFullscreen = () => {
    if (!isFullscreen) {
      // Request fullscreen for different browsers
      const element = document.documentElement
      if (element.requestFullscreen) {
        element.requestFullscreen()
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen()
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen()
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen()
      }
    } else {
      // Exit fullscreen for different browsers
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      }
    }
    setIsFullscreen(!isFullscreen)
  }

  const renderContent = () => {
    if (isMobile) return <div>mobile rendering not allowed</div>
    return (
      <div>
        {!isFullscreen ? (
          <button onClick={toggleFullscreen}>Enter Fullscreen</button>
        ) : (
          <button onClick={toggleFullscreen}>Exit Fullscreen</button>
        )}
      </div>
    )
  }

  return renderContent()
}

export default FullscreenButton
