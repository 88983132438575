// desctiption: not balanced, high paying symbols are most frequent excluding wildcard and scatter
import { useContext, useEffect, useState } from 'react'

import { Context as GameContext } from '../../../../../context/GameContext'
import { Context as PayoutContext } from '../../../../../context/PayoutContext'

const CondenseRandomNumberC3 = () => {
  const [condensedNumber1Done, setCondensedNumber1Done] = useState(false)
  const [condensedNumber2Done, setCondensedNumber2Done] = useState(false)
  const [condensedNumber3Done, setCondensedNumber3Done] = useState(false)
  const [condensedNumber4Done, setCondensedNumber4Done] = useState(false)
  const [condensedNumber5Done, setCondensedNumber5Done] = useState(false)

  const {
    state: {
      randomNumberC1,
      randomNumberC2,
      randomNumberC3,
      randomNumberC4,
      randomNumberC5,
      reelSpinning5,
      freeSpinPlayOn,
    },
    setCondensedNumberC1,
    setCondensedNumberC2,
    setCondensedNumberC3,
    setCondensedNumberC4,
    setCondensedNumberC5,
    setCondenseRandomNumberCDone,
  } = useContext(GameContext)

  const {
    state: {
      payoutOverLimitStartReplay,
      triggerCondensePreJackpotNumber,
      jackpotWon,
    },
  } = useContext(PayoutContext)

  useEffect(() => {
    condensedRandomNumberC1()
    condensedRandomNumberC2()
    condensedRandomNumberC3()
    condensedRandomNumberC4()
    condensedRandomNumberC5()
  }, [
    randomNumberC1,
    randomNumberC2,
    randomNumberC3,
    randomNumberC4,
    randomNumberC5,
  ])

  useEffect(() => {
    if (
      condensedNumber1Done &&
      condensedNumber2Done &&
      condensedNumber3Done &&
      condensedNumber4Done &&
      condensedNumber5Done
    ) {
      setCondenseRandomNumberCDone(true)
    }
  }, [
    condensedNumber1Done,
    condensedNumber2Done,
    condensedNumber3Done,
    condensedNumber4Done,
    condensedNumber5Done,
  ])

  useEffect(() => {
    if (!reelSpinning5 || payoutOverLimitStartReplay) {
      setCondenseRandomNumberCDone(false)
      setCondensedNumber1Done(false)
      setCondensedNumber2Done(false)
      setCondensedNumber3Done(false)
      setCondensedNumber4Done(false)
      setCondensedNumber5Done(false)
    }
  }, [reelSpinning5, payoutOverLimitStartReplay])

  const condensedRandomNumberC1 = () => {
    switch (true) {
      // 4
      case randomNumberC1 > 0 && randomNumberC1 < 5:
        setCondensedNumberC1(1)
        setCondensedNumber1Done(true)
        break
      // 4
      case randomNumberC1 > 4 && randomNumberC1 < 9:
        setCondensedNumberC1(2)
        setCondensedNumber1Done(true)
        break
      // 4
      case randomNumberC1 > 8 && randomNumberC1 < 13:
        setCondensedNumberC1(3)
        setCondensedNumber1Done(true)
        break
      // 14
      case randomNumberC1 > 12 && randomNumberC1 < 27:
        setCondensedNumberC1(4)
        setCondensedNumber1Done(true)
        break
      // 14
      case randomNumberC1 > 26 && randomNumberC1 < 41:
        setCondensedNumberC1(5)
        setCondensedNumber1Done(true)
        break
      // 14
      case randomNumberC1 > 40 && randomNumberC1 < 55:
        setCondensedNumberC1(6)
        setCondensedNumber1Done(true)
        break
      // 14
      case randomNumberC1 > 54 && randomNumberC1 < 69:
        setCondensedNumberC1(7)
        setCondensedNumber1Done(true)
        break
      // 14
      case randomNumberC1 > 68 && randomNumberC1 < 83:
        setCondensedNumberC1(8)
        setCondensedNumber1Done(true)
        break
      // 14
      case randomNumberC1 > 82 && randomNumberC1 < 97:
        setCondensedNumberC1(9)
        setCondensedNumber1Done(true)
        break
      // 2
      case randomNumberC1 > 96 && randomNumberC1 < 99:
        setCondensedNumberC1(
          triggerCondensePreJackpotNumber || jackpotWon || freeSpinPlayOn
            ? 1
            : 10
        )
        setCondensedNumber1Done(true)
        break
      // 1
      case randomNumberC1 > 98 && randomNumberC1 < 100:
        setCondensedNumberC1(
          triggerCondensePreJackpotNumber || jackpotWon || freeSpinPlayOn
            ? 2
            : 11
        )
        setCondensedNumber1Done(true)
        break
      default:
        break
    }
  }

  const condensedRandomNumberC2 = () => {
    switch (true) {
      // 4
      case randomNumberC2 > 0 && randomNumberC2 < 5:
        setCondensedNumberC2(1)
        setCondensedNumber2Done(true)
        break
      // 4
      case randomNumberC2 > 4 && randomNumberC2 < 9:
        setCondensedNumberC2(2)
        setCondensedNumber2Done(true)
        break
      // 4
      case randomNumberC2 > 8 && randomNumberC2 < 13:
        setCondensedNumberC2(3)
        setCondensedNumber2Done(true)
        break
      // 14
      case randomNumberC2 > 12 && randomNumberC2 < 27:
        setCondensedNumberC2(4)
        setCondensedNumber2Done(true)
        break
      // 14
      case randomNumberC2 > 26 && randomNumberC2 < 41:
        setCondensedNumberC2(5)
        setCondensedNumber2Done(true)
        break
      // 14
      case randomNumberC2 > 40 && randomNumberC2 < 55:
        setCondensedNumberC2(6)
        setCondensedNumber2Done(true)
        break
      // 14
      case randomNumberC2 > 54 && randomNumberC2 < 69:
        setCondensedNumberC2(7)
        setCondensedNumber2Done(true)
        break
      // 14
      case randomNumberC2 > 68 && randomNumberC2 < 83:
        setCondensedNumberC2(8)
        setCondensedNumber2Done(true)
        break
      // 14
      case randomNumberC2 > 82 && randomNumberC2 < 97:
        setCondensedNumberC2(9)
        setCondensedNumber2Done(true)
        break
      // 2
      case randomNumberC2 > 96 && randomNumberC2 < 99:
        setCondensedNumberC2(
          triggerCondensePreJackpotNumber || jackpotWon || freeSpinPlayOn
            ? 1
            : 10
        )
        setCondensedNumber2Done(true)
        break
      // 1
      case randomNumberC2 > 98 && randomNumberC2 < 100:
        setCondensedNumberC2(
          triggerCondensePreJackpotNumber || jackpotWon || freeSpinPlayOn
            ? 2
            : 11
        )
        setCondensedNumber2Done(true)
        break
      default:
        break
    }
  }

  const condensedRandomNumberC3 = () => {
    switch (true) {
      // 4
      case randomNumberC3 > 0 && randomNumberC3 < 5:
        setCondensedNumberC3(1)
        setCondensedNumber3Done(true)
        break
      // 4
      case randomNumberC3 > 4 && randomNumberC3 < 9:
        setCondensedNumberC3(2)
        setCondensedNumber3Done(true)
        break
      // 4
      case randomNumberC3 > 8 && randomNumberC3 < 13:
        setCondensedNumberC3(3)
        setCondensedNumber3Done(true)
        break
      // 14
      case randomNumberC3 > 12 && randomNumberC3 < 27:
        setCondensedNumberC3(4)
        setCondensedNumber3Done(true)
        break
      // 14
      case randomNumberC3 > 26 && randomNumberC3 < 41:
        setCondensedNumberC3(5)
        setCondensedNumber3Done(true)
        break
      // 14
      case randomNumberC3 > 40 && randomNumberC3 < 55:
        setCondensedNumberC3(6)
        setCondensedNumber3Done(true)
        break
      // 14
      case randomNumberC3 > 54 && randomNumberC3 < 69:
        setCondensedNumberC3(7)
        setCondensedNumber3Done(true)
        break
      // 14
      case randomNumberC3 > 68 && randomNumberC3 < 83:
        setCondensedNumberC3(8)
        setCondensedNumber3Done(true)
        break
      // 14
      case randomNumberC3 > 82 && randomNumberC3 < 97:
        setCondensedNumberC3(9)
        setCondensedNumber3Done(true)
        break
      // 2
      case randomNumberC3 > 96 && randomNumberC3 < 99:
        setCondensedNumberC3(
          triggerCondensePreJackpotNumber || jackpotWon || freeSpinPlayOn
            ? 1
            : 10
        )
        setCondensedNumber3Done(true)
        break
      // 1
      case randomNumberC3 > 98 && randomNumberC3 < 100:
        setCondensedNumberC3(
          triggerCondensePreJackpotNumber || jackpotWon || freeSpinPlayOn
            ? 2
            : 11
        )
        setCondensedNumber3Done(true)
        break
      default:
        break
    }
  }

  const condensedRandomNumberC4 = () => {
    switch (true) {
      // 4
      case randomNumberC4 > 0 && randomNumberC4 < 5:
        setCondensedNumberC4(1)
        setCondensedNumber4Done(true)
        break
      // 4
      case randomNumberC4 > 4 && randomNumberC4 < 9:
        setCondensedNumberC4(2)
        setCondensedNumber4Done(true)
        break
      // 4
      case randomNumberC4 > 8 && randomNumberC4 < 13:
        setCondensedNumberC4(3)
        setCondensedNumber4Done(true)
        break
      // 14
      case randomNumberC4 > 12 && randomNumberC4 < 27:
        setCondensedNumberC4(4)
        setCondensedNumber4Done(true)
        break
      // 14
      case randomNumberC4 > 26 && randomNumberC4 < 41:
        setCondensedNumberC4(5)
        setCondensedNumber4Done(true)
        break
      // 14
      case randomNumberC4 > 40 && randomNumberC4 < 55:
        setCondensedNumberC4(6)
        setCondensedNumber4Done(true)
        break
      // 14
      case randomNumberC4 > 54 && randomNumberC4 < 69:
        setCondensedNumberC4(7)
        setCondensedNumber4Done(true)
        break
      // 14
      case randomNumberC4 > 68 && randomNumberC4 < 83:
        setCondensedNumberC4(8)
        setCondensedNumber4Done(true)
        break
      // 14
      case randomNumberC4 > 82 && randomNumberC4 < 97:
        setCondensedNumberC4(9)
        setCondensedNumber4Done(true)
        break
      // 2
      case randomNumberC4 > 96 && randomNumberC4 < 99:
        setCondensedNumberC4(
          triggerCondensePreJackpotNumber || jackpotWon || freeSpinPlayOn
            ? 1
            : 10
        )
        setCondensedNumber4Done(true)
        break
      // 1
      case randomNumberC4 > 98 && randomNumberC4 < 100:
        setCondensedNumberC4(
          triggerCondensePreJackpotNumber || jackpotWon || freeSpinPlayOn
            ? 2
            : 11
        )
        setCondensedNumber4Done(true)
        break
      default:
        break
    }
  }

  const condensedRandomNumberC5 = () => {
    switch (true) {
      // 4
      case randomNumberC5 > 0 && randomNumberC5 < 5:
        setCondensedNumberC5(1)
        setCondensedNumber5Done(true)
        break
      // 4
      case randomNumberC5 > 4 && randomNumberC5 < 9:
        setCondensedNumberC5(2)
        setCondensedNumber5Done(true)
        break
      // 4
      case randomNumberC5 > 8 && randomNumberC5 < 13:
        setCondensedNumberC5(3)
        setCondensedNumber5Done(true)
        break
      // 14
      case randomNumberC5 > 12 && randomNumberC5 < 27:
        setCondensedNumberC5(4)
        setCondensedNumber5Done(true)
        break
      // 14
      case randomNumberC5 > 26 && randomNumberC5 < 41:
        setCondensedNumberC5(5)
        setCondensedNumber5Done(true)
        break
      // 14
      case randomNumberC5 > 40 && randomNumberC5 < 55:
        setCondensedNumberC5(6)
        setCondensedNumber5Done(true)
        break
      // 14
      case randomNumberC5 > 54 && randomNumberC5 < 69:
        setCondensedNumberC5(7)
        setCondensedNumber5Done(true)
        break
      // 14
      case randomNumberC5 > 68 && randomNumberC5 < 83:
        setCondensedNumberC5(8)
        setCondensedNumber5Done(true)
        break
      // 14
      case randomNumberC5 > 82 && randomNumberC5 < 97:
        setCondensedNumberC5(9)
        setCondensedNumber5Done(true)
        break
      // 2
      case randomNumberC5 > 96 && randomNumberC5 < 99:
        setCondensedNumberC5(
          triggerCondensePreJackpotNumber || jackpotWon || freeSpinPlayOn
            ? 1
            : 10
        )
        setCondensedNumber5Done(true)
        break
      // 1
      case randomNumberC5 > 98 && randomNumberC5 < 100:
        setCondensedNumberC5(
          triggerCondensePreJackpotNumber || jackpotWon || freeSpinPlayOn
            ? 2
            : 11
        )
        setCondensedNumber5Done(true)
        break
      default:
        break
    }
  }

  return null
}

export default CondenseRandomNumberC3
