import React, { useState, useEffect, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faPlay } from '@fortawesome/free-solid-svg-icons'
import { TbCircle1, TbCircle2, TbCircle3 } from 'react-icons/tb'
import useSound from 'use-sound'

import { Context as NavContext } from '../../../context/NavContext'
import { Context as UserContext } from '../../../context/UserContext'
import { Context as GameContext } from '../../../context/GameContext'
import { Context as PayoutContext } from '../../../context/PayoutContext'
import { Context as SocketContext } from '../../../context/SocketContext'
import { Context as PromoPrizeWheelContext } from '../../../context/PromoPrizeWheelContext'
import { fruitsSpinningArray1, fruitsArray } from './fruitsArray'

import LockScreen from '../../common/lockScreen/LockScreen'
import RandomNumbers from './helpers/condensers/RandomNubers'
import CondenserSeldector from './helpers/condensers/CondenserSelector'
import CalculateRowAWin from './helpers/CalculateRowAWin'
import CalculateRowBWin from './helpers/CalculateRowBWin'
import CalculateRowCWin from './helpers/CalculateRowCWin'
import CalculateResult from './helpers/CalculateResult'
import AssignWinClass from './helpers/AssignWinClass'
import FreeSpinPlayer from './helpers/FreeSpinPlayer'
import PayoutInterceptor from './helpers/PayoutInterceptor'
import FruitsPaytable from './paytable/FruitsPaytable'
import LinesOverlay from './linesOverlay/LinesOverlay'
import ScatterWinOverlay from './scatterWinOverlay/ScatterWinOverlay'
import JackpotOverlay from './jackpotOverlay/JackpotOverlay'
import NetworkErrorOverlay from './networkErrorOverlay/NetworkErrorOverlay'
import GifSelector from './helpers/gifGenerators/GifSelector'
import PromoPrizeWheel from '../promoPrizeWheel/PromoPrizeWheel'
import startReel from '../../../assets/sounds/start_reel.mp3'
import stopReel from '../../../assets/sounds/reel_stop.mp3'
import reels from '../../../assets/sounds/reels.mp3'
import win from '../../../assets/sounds/win.mp3'
import pressButton from '../../../assets/sounds/press_but.mp3'
import './fruits.scss'

const Fruits = () => {
  const [autoPlay, setAutoPlay] = useState(false)
  const [reelSpinning3, setReelSpinning3] = useState(false)
  const [reelSpinning4, setReelSpinning4] = useState(false)
  const [spinningSymbolCounter, setSpinningSymbolCounter] = useState(0)
  const [spinningSymbolObj, setSpinningSymbolObj] = useState()
  const [spinningSymbol, setSpinningSymbol] = useState()
  const [bannerText, setBannerText] = useState('good luck 🍀')
  const [autoPlayTrigerReady, setAutoPlayTrigerReady] = useState(false)
  const [triggerReplay, setTriggerReplay] = useState(false)
  const [celebrateWin, setCelebrateWin] = useState(false)
  const [networkErrorTimeout, setNetworkErrorTimeout] = useState(false)
  const [runNetworkChecker, setRunNetworkChecker] = useState(false)
  const [maxedOut, setMaxedOut] = useState(false)

  const { setVisibleComponent } = useContext(NavContext)

  const [playStartReelSound] = useSound(startReel)
  const [playStopReelSound] = useSound(stopReel)
  const [playReelsSound, { stop }] = useSound(reels)
  const [playWinSound] = useSound(win)
  const [playPressButtonSound] = useSound(pressButton)

  const {
    state: {
      gameCredits,
      betValue,
      betTotal,
      numberOfLines,
      jackpotData,
      spinCount,
      condensedNumberA1,
      condensedNumberA2,
      condensedNumberA3,
      condensedNumberA4,
      condensedNumberA5,
      condensedNumberB1,
      condensedNumberB2,
      condensedNumberB3,
      condensedNumberB4,
      condensedNumberB5,
      condensedNumberC1,
      condensedNumberC2,
      condensedNumberC3,
      condensedNumberC4,
      condensedNumberC5,
      allRandomNumbersDone,
      symbolLandedA1,
      symbolLandedA2,
      symbolLandedA3,
      symbolLandedA4,
      symbolLandedA5,
      symbolLandedB1,
      symbolLandedB2,
      symbolLandedB3,
      symbolLandedB4,
      symbolLandedB5,
      symbolLandedC1,
      symbolLandedC2,
      symbolLandedC3,
      symbolLandedC4,
      symbolLandedC5,
      reelSpinning1,
      reelSpinning2,
      reelSpinning5,
      scatterWinOverlayShow,
      freeSpinPlayOn,
      freeSpinsTotal,
      prize,
      symbolWinClassRowA1,
      symbolWinClassRowA2,
      symbolWinClassRowA3,
      symbolWinClassRowA4,
      symbolWinClassRowA5,
      symbolWinClassRowB1,
      symbolWinClassRowB2,
      symbolWinClassRowB3,
      symbolWinClassRowB4,
      symbolWinClassRowB5,
      symbolWinClassRowC1,
      symbolWinClassRowC2,
      symbolWinClassRowC3,
      symbolWinClassRowC4,
      symbolWinClassRowC5,
      testSwitch,
      bannerGifContent,
    },
    setNameOfGame,
    setGameCredits,
    setBetTotal,
    setNumberOfLines,
    setSpinCount,
    updateJackpot,
    setRandomNumberA1,
    setRandomNumberA2,
    setRandomNumberA3,
    setRandomNumberA4,
    setRandomNumberA5,
    setRandomNumberB1,
    setRandomNumberB2,
    setRandomNumberB3,
    setRandomNumberB4,
    setRandomNumberB5,
    setRandomNumberC1,
    setRandomNumberC2,
    setRandomNumberC3,
    setRandomNumberC4,
    setRandomNumberC5,
    setCondensedNumberA1,
    setCondensedNumberA2,
    setCondensedNumberA3,
    setCondensedNumberA4,
    setCondensedNumberA5,
    setCondensedNumberB1,
    setCondensedNumberB2,
    setCondensedNumberB3,
    setCondensedNumberB4,
    setCondensedNumberB5,
    setCondensedNumberC1,
    setCondensedNumberC2,
    setCondensedNumberC3,
    setCondensedNumberC4,
    setCondensedNumberC5,
    setAllRandomNumbersDone,
    setSymbolLandedA1,
    setSymbolLandedA2,
    setSymbolLandedA3,
    setSymbolLandedA4,
    setSymbolLandedA5,
    setSymbolLandedB1,
    setSymbolLandedB2,
    setSymbolLandedB3,
    setSymbolLandedB4,
    setSymbolLandedB5,
    setSymbolLandedC1,
    setSymbolLandedC2,
    setSymbolLandedC3,
    setSymbolLandedC4,
    setSymbolLandedC5,
    setLinesOverlayShow,
    setReelSpinning1,
    setReelSpinning2,
    setReelSpinning5,
    setRowAResult,
    setRowBResult,
    setRowCResult,
    setRowAWinArray,
    setRowBWinArray,
    setRowCWinArray,
    setWinningSymbolRowA,
    setWinningSymbolRowB,
    setWinningSymbolRowC,
    setRowAPrizeArray,
    setRowBPrizeArray,
    setRowCPrizeArray,
    setBetValue,
    setFreeSpinsTotal,
    setPrize,
    setWildCardQuantityRowA,
    setWildCardQuantityRowB,
    setWildCardQuantityRowC,
    setScatterQuantityRowA,
    setScatterQuantityRowB,
    setScatterQuantityRowC,
    setRowAPrize,
    setRowBPrize,
    setRowCPrize,
    setSymbolWinClassRowA1,
    setSymbolWinClassRowA2,
    setSymbolWinClassRowA3,
    setSymbolWinClassRowA4,
    setSymbolWinClassRowA5,
    setSymbolWinClassRowB1,
    setSymbolWinClassRowB2,
    setSymbolWinClassRowB3,
    setSymbolWinClassRowB4,
    setSymbolWinClassRowB5,
    setSymbolWinClassRowC1,
    setSymbolWinClassRowC2,
    setSymbolWinClassRowC3,
    setSymbolWinClassRowC4,
    setSymbolWinClassRowC5,
    checkUserLock,
    setSymbol1WildCardWinA,
    setSymbol2WildCardWinA,
    setSymbol3WildCardWinA,
    setSymbol4WildCardWinA,
    setSymbol5WildCardWinA,
    setSymbol6WildCardWinA,
    setSymbol7WildCardWinA,
    setSymbol8WildCardWinA,
    setSymbol9WildCardWinA,
    setSymbol1WildCardWinB,
    setSymbol2WildCardWinB,
    setSymbol3WildCardWinB,
    setSymbol4WildCardWinB,
    setSymbol5WildCardWinB,
    setSymbol6WildCardWinB,
    setSymbol7WildCardWinB,
    setSymbol8WildCardWinB,
    setSymbol9WildCardWinB,
    setSymbol1WildCardWinC,
    setSymbol2WildCardWinC,
    setSymbol3WildCardWinC,
    setSymbol4WildCardWinC,
    setSymbol5WildCardWinC,
    setSymbol6WildCardWinC,
    setSymbol7WildCardWinC,
    setSymbol8WildCardWinC,
    setSymbol9WildCardWinC,
    setRowA,
    setRowB,
    setRowC,
    setNetworkErrorOverlayShow,
    updateUserFreeSpins,
  } = useContext(GameContext)

  const {
    state: {
      siteTransactionHistory,
      payoutUnderLimitCheckDone,
      payoutOverLimitStartReplay,
      condenserCounter,
      progresiveJackpot1Release,
      clientProgresive1Release,
      clientProgresive2Release,
      clientProgresive3Release,
      jackpotWon,
      triggerJackpotOverlay,
      jackpotRelease,
    },
    fetchSiteTransactionHistory,
    fetchSiteFloorCredits,
    fetchSiteBankValue,
    setPayoutOverLimitStartReplay,
    setCondenserCounter,
    setTriggerStrategySelector,
    updateClientProgresive,
    setAddCreditAverage,
    setTriggerJackpotOverlay,
    setUpdateCreditCounter,
  } = useContext(PayoutContext)

  const {
    state: { user, userCreditsBalanceLoading, userCreditsDB },
    setUserCreditBalance,
  } = useContext(UserContext)

  const {
    state: { lock },
  } = useContext(SocketContext)

  const {
    state: { promoPrizeWheelShow, promoWheelUsed },
  } = useContext(PromoPrizeWheelContext)

  useEffect(() => {
    setSpinCount(0)
    setCondenserCounter(0)
    setNameOfGame('fruits')
  }, [])

  useEffect(() => {
    let num = gameCredits < 0 ? 0 : gameCredits
    setUserCreditBalance({ creditBalance: num })
  }, [gameCredits])

  useEffect(() => {
    if (!userCreditsBalanceLoading && !reelSpinning5) {
      if (userCreditsDB !== gameCredits) {
        setUserCreditBalance({ creditBalance: gameCredits })
      }
    }
  }, [userCreditsBalanceLoading, reelSpinning5, userCreditsDB, gameCredits])

  useEffect(() => {
    if (runNetworkChecker) {
      const run = setInterval(() => {
        setNetworkErrorTimeout(true)
      }, 5000)
      return () => {
        clearInterval(run)
      }
    }
  }, [runNetworkChecker])

  useEffect(() => {
    if (networkErrorTimeout) {
      setRunNetworkChecker(false)
      setNetworkErrorTimeout(false)
      setNetworkErrorOverlayShow(true)
    }
  }, [networkErrorTimeout])

  useEffect(() => {
    if (siteTransactionHistory) {
      let addCreditsValuesArray = siteTransactionHistory
        .filter((item) => item.type === 'addCredit')
        .map((item) => item.value)
      let sum = addCreditsValuesArray.reduce((acc, val) => acc + val, 0)
      let avg = sum / addCreditsValuesArray.length
      let average = Math.floor(avg)
      setAddCreditAverage(average)
    }
  }, [siteTransactionHistory])

  useEffect(() => {
    setBetTotal(betValue * numberOfLines)
  }, [betValue, numberOfLines])

  useEffect(() => {
    if (payoutUnderLimitCheckDone) {
      const runFreeSpinsPlaySubtract = setInterval(() => {
        freeSpinsPlaySubtract()
      }, 30)
      const stopReels1 = setInterval(() => {
        setReelSpinning1(false)
        playStopReelSound()
      }, 1000)
      const stopReels2 = setInterval(() => {
        setReelSpinning2(false)
        playStopReelSound()
      }, 2000)
      const stopReels3 = setInterval(() => {
        setReelSpinning3(false)
        playStopReelSound()
      }, 3000)
      const stopReels4 = setInterval(() => {
        setReelSpinning4(false)
        playStopReelSound()
        setRunNetworkChecker(false)
      }, 4000)
      const stopReels5 = setInterval(() => {
        setReelSpinning5(false)
        playStopReelSound()
        setSpinningSymbolCounter(0)
        setAutoPlayTrigerReady(false)
      }, 5000)
      return () => {
        clearInterval(runFreeSpinsPlaySubtract)
        clearInterval(stopReels1)
        clearInterval(stopReels2)
        clearInterval(stopReels3)
        clearInterval(stopReels4)
        clearInterval(stopReels5)
      }
    }
  }, [payoutUnderLimitCheckDone])

  useEffect(() => {
    if (!testSwitch) {
      setAutoPlay(false)
    }
  }, [testSwitch])

  useEffect(() => {
    if (freeSpinPlayOn && !reelSpinning5 && !scatterWinOverlayShow) {
      const runAutoPlay = setTimeout(() => {
        setAutoPlayTrigerReady(true)
      }, 2000)
      return () => {
        clearTimeout(runAutoPlay)
      }
    }
  }, [freeSpinPlayOn, reelSpinning5, scatterWinOverlayShow])

  useEffect(() => {
    if (autoPlay && !reelSpinning5 && !scatterWinOverlayShow) {
      const runAutoPlay = setTimeout(() => {
        setAutoPlayTrigerReady(true)
      }, 2000)
      return () => {
        clearTimeout(runAutoPlay)
      }
    }
  }, [autoPlay, reelSpinning5, scatterWinOverlayShow])

  useEffect(() => {
    if (autoPlayTrigerReady) {
      spinStart()
    }
  }, [autoPlayTrigerReady])

  useEffect(() => {
    if (payoutOverLimitStartReplay) {
      resetData()
      setPayoutOverLimitStartReplay(false)
      setTriggerReplay(true)
    }
  }, [payoutOverLimitStartReplay])

  useEffect(() => {
    if (triggerReplay) {
      spinStartReplay()
      setTriggerReplay(false)
    }
  }, [triggerReplay])

  useEffect(() => {
    if (reelSpinning5) {
      const interval = setInterval(() => {
        setSpinningSymbolCounter(
          (spinningSymbolCounter) => spinningSymbolCounter + 1
        )
      }, 15)
      return () => {
        clearInterval(interval)
      }
    }
  }, [reelSpinning5])

  useEffect(() => {
    if (reelSpinning5) playReelsSound()
    if (!reelSpinning5) stop()
  }, [reelSpinning5])

  useEffect(() => {
    setSpinningSymbolObj(
      fruitsSpinningArray1.filter((sym) => {
        return sym.id === spinningSymbolCounter
      })
    )
  }, [spinningSymbolCounter])

  useEffect(() => {
    if (spinningSymbolObj) {
      if (spinningSymbolObj.length > 0)
        setSpinningSymbol(spinningSymbolObj[0].symbol)
    }
  }, [spinningSymbolObj])

  useEffect(() => {
    if (allRandomNumbersDone && condensedNumberA1 > 0) {
      let symbolA1 = fruitsArray.filter((fruit) => {
        return fruit.id === condensedNumberA1
      })
      setSymbolLandedA1(symbolA1[0].symbol)
      let symbolB1 = fruitsArray.filter((fruit) => {
        return fruit.id === condensedNumberB1
      })
      setSymbolLandedB1(symbolB1[0].symbol)
      let symbolC1 = fruitsArray.filter((fruit) => {
        return fruit.id === condensedNumberC1
      })
      setSymbolLandedC1(symbolC1[0].symbol)
      let symbolA2 = fruitsArray.filter((fruit) => {
        return fruit.id === condensedNumberA2
      })
      setSymbolLandedA2(symbolA2[0].symbol)
      let symbolB2 = fruitsArray.filter((fruit) => {
        return fruit.id === condensedNumberB2
      })
      setSymbolLandedB2(symbolB2[0].symbol)
      let symbolC2 = fruitsArray.filter((fruit) => {
        return fruit.id === condensedNumberC2
      })
      setSymbolLandedC2(symbolC2[0].symbol)
      let symbolA3 = fruitsArray.filter((fruit) => {
        return fruit.id === condensedNumberA3
      })
      setSymbolLandedA3(symbolA3[0].symbol)
      let symbolB3 = fruitsArray.filter((fruit) => {
        return fruit.id === condensedNumberB3
      })
      setSymbolLandedB3(symbolB3[0].symbol)
      let symbolC3 = fruitsArray.filter((fruit) => {
        return fruit.id === condensedNumberC3
      })
      setSymbolLandedC3(symbolC3[0].symbol)
      let symbolA4 = fruitsArray.filter((fruit) => {
        return fruit.id === condensedNumberA4
      })
      setSymbolLandedA4(symbolA4[0].symbol)
      let symbolB4 = fruitsArray.filter((fruit) => {
        return fruit.id === condensedNumberB4
      })
      setSymbolLandedB4(symbolB4[0].symbol)
      let symbolC4 = fruitsArray.filter((fruit) => {
        return fruit.id === condensedNumberC4
      })
      setSymbolLandedC4(symbolC4[0].symbol)
      let symbolA5 = fruitsArray.filter((fruit) => {
        return fruit.id === condensedNumberA5
      })
      setSymbolLandedA5(symbolA5[0].symbol)
      let symbolB5 = fruitsArray.filter((fruit) => {
        return fruit.id === condensedNumberB5
      })
      setSymbolLandedB5(symbolB5[0].symbol)
      let symbolC5 = fruitsArray.filter((fruit) => {
        return fruit.id === condensedNumberC5
      })
      setSymbolLandedC5(symbolC5[0].symbol)
    }
    // eslint-disable-next-line
  }, [allRandomNumbersDone, condensedNumberA1])

  useEffect(() => {
    if (gameCredits < betTotal) {
      setAutoPlay(false)
    }
  }, [gameCredits, betTotal])

  useEffect(() => {
    let formattedNumber = (prize / 100).toFixed(2)
    if (!freeSpinPlayOn && prize > 0 && !reelSpinning5) {
      playWinSound()
      setBannerText(`${formattedNumber} credits won 🤑`)
    }
    if (scatterWinOverlayShow) {
      playWinSound()
    }
    if (freeSpinPlayOn && prize < 1) {
      setBannerText(`${freeSpinsTotal} spins remaining 😎`)
    }
    if (freeSpinPlayOn && prize > 0 && !reelSpinning5) {
      playWinSound()
      setBannerText(`${formattedNumber} credits won 🤑`)
    }
  }, [
    prize,
    reelSpinning5,
    freeSpinPlayOn,
    freeSpinsTotal,
    scatterWinOverlayShow,
  ])

  useEffect(() => {
    if (prize > 0 && !reelSpinning5) {
      setCelebrateWin(true)
    }
  }, [prize, reelSpinning5])

  useEffect(() => {
    if (triggerJackpotOverlay) {
      setAutoPlay(false)
    }
  }, [triggerJackpotOverlay])

  useEffect(() => {
    updateUserFreeSpins({ freeSpins: freeSpinsTotal })
  }, [freeSpinsTotal])

  useEffect(() => {
    if (betTotal > gameCredits) {
      setBetValue(50)
    }
  }, [betTotal, gameCredits])

  const condenserCounterTrigger = () => {
    switch (condenserCounter) {
      case 0:
        setCondenserCounter(1)
        break
      case 1:
        setCondenserCounter(2)
        break
      case 2:
        setCondenserCounter(3)
        break
      case 3:
        setCondenserCounter(4)
        break
      case 4:
        setCondenserCounter(0)
        break
      default:
        break
    }
  }

  const randomNumber = () => {
    if (jackpotWon) {
      setTriggerJackpotOverlay(true)
    }
    setRandomNumberA1(Math.floor(Math.random() * (100 - 1) + 1))
    setRandomNumberB1(Math.floor(Math.random() * (100 - 1) + 1))
    setRandomNumberC1(Math.floor(Math.random() * (100 - 1) + 1))
    setRandomNumberA2(Math.floor(Math.random() * (100 - 1) + 1))
    setRandomNumberB2(Math.floor(Math.random() * (100 - 1) + 1))
    setRandomNumberC2(Math.floor(Math.random() * (100 - 1) + 1))
    setRandomNumberA3(Math.floor(Math.random() * (100 - 1) + 1))
    setRandomNumberB3(Math.floor(Math.random() * (100 - 1) + 1))
    setRandomNumberC3(Math.floor(Math.random() * (100 - 1) + 1))
    setRandomNumberA4(Math.floor(Math.random() * (100 - 1) + 1))
    setRandomNumberB4(Math.floor(Math.random() * (100 - 1) + 1))
    setRandomNumberC4(Math.floor(Math.random() * (100 - 1) + 1))
    setRandomNumberA5(Math.floor(Math.random() * (100 - 1) + 1))
    setRandomNumberB5(Math.floor(Math.random() * (100 - 1) + 1))
    setRandomNumberC5(Math.floor(Math.random() * (100 - 1) + 1))
    setAllRandomNumbersDone(true)
  }

  const freeSpinsPlaySubtract = () => {
    if (freeSpinsTotal > 0) {
      setFreeSpinsTotal(freeSpinsTotal - 1)
    }
  }

  const renderSymbol = (placeholder, symbolLanded, reelSpinning) => {
    if (!symbolLanded && !reelSpinning) return <>{placeholder}</>
    if (reelSpinning) return spinningSymbol
    return symbolLanded
  }

  const reel1 = () => {
    return (
      <div className="reelsCenterBed">
        <div className="reelsCenterContainer">
          <div className={!reelSpinning1 ? 'reelStatic' : 'reelSpinning'}>
            <div
              className={
                !symbolWinClassRowA1 ? 'reelSymbolBed' : 'reelSymbolBedWinRowA'
              }
            >
              <div
                className={
                  reelSpinning1
                    ? 'reelSymbolContainerSpin'
                    : 'reelSymbolContainerStop'
                }
              >
                <div className="reelSymbol">
                  {renderSymbol('🍓', symbolLandedA1, reelSpinning1)}
                </div>
              </div>
            </div>
            <div
              className={
                !symbolWinClassRowB1 ? 'reelSymbolBed' : 'reelSymbolBedWinRowB'
              }
            >
              <div
                className={
                  reelSpinning1
                    ? 'reelSymbolContainerSpin'
                    : 'reelSymbolContainerStop'
                }
              >
                <div className="reelSymbol">
                  {renderSymbol('🍇', symbolLandedB1, reelSpinning1)}
                </div>
              </div>
            </div>
            <div
              className={
                !symbolWinClassRowC1 ? 'reelSymbolBed' : 'reelSymbolBedWinRowC'
              }
            >
              <div
                className={
                  reelSpinning1
                    ? 'reelSymbolContainerSpin'
                    : 'reelSymbolContainerStop'
                }
              >
                <div className="reelSymbol">
                  {renderSymbol('🍒', symbolLandedC1, reelSpinning1)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const reel2 = () => {
    return (
      <div className="reelsCenterBed">
        <div className="reelsCenterContainer">
          <div className={!reelSpinning2 ? 'reelStatic' : 'reelSpinning'}>
            <div
              className={
                !symbolWinClassRowA2 ? 'reelSymbolBed' : 'reelSymbolBedWinRowA'
              }
            >
              <div
                className={
                  reelSpinning2
                    ? 'reelSymbolContainerSpin'
                    : 'reelSymbolContainerStop'
                }
              >
                <div className="reelSymbol">
                  {renderSymbol('🍌', symbolLandedA2, reelSpinning2)}
                </div>
              </div>
            </div>
            <div
              className={
                !symbolWinClassRowB2 ? 'reelSymbolBed' : 'reelSymbolBedWinRowB'
              }
            >
              <div
                className={
                  reelSpinning2
                    ? 'reelSymbolContainerSpin'
                    : 'reelSymbolContainerStop'
                }
              >
                <div className="reelSymbol">
                  {renderSymbol('🍉', symbolLandedB2, reelSpinning2)}
                </div>
              </div>
            </div>
            <div
              className={
                !symbolWinClassRowC2 ? 'reelSymbolBed' : 'reelSymbolBedWinRowC'
              }
            >
              <div
                className={
                  reelSpinning2
                    ? 'reelSymbolContainerSpin'
                    : 'reelSymbolContainerStop'
                }
              >
                <div className="reelSymbol">
                  {renderSymbol('🥑', symbolLandedC2, reelSpinning2)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const reel3 = () => {
    return (
      <div className="reelsCenterBed">
        <div className="reelsCenterContainer">
          <div className={!reelSpinning3 ? 'reelStatic' : 'reelSpinning'}>
            <div
              className={
                !symbolWinClassRowA3 ? 'reelSymbolBed' : 'reelSymbolBedWinRowA'
              }
            >
              <div
                className={
                  reelSpinning3
                    ? 'reelSymbolContainerSpin'
                    : 'reelSymbolContainerStop'
                }
              >
                <div className="reelSymbol">
                  {renderSymbol('🍊', symbolLandedA3, reelSpinning3)}
                </div>
              </div>
            </div>
            <div
              className={
                !symbolWinClassRowB3 ? 'reelSymbolBed' : 'reelSymbolBedWinRowB'
              }
            >
              <div
                className={
                  reelSpinning3
                    ? 'reelSymbolContainerSpin'
                    : 'reelSymbolContainerStop'
                }
              >
                <div className="reelSymbol">
                  {renderSymbol('🍋', symbolLandedB3, reelSpinning3)}
                </div>
              </div>
            </div>
            <div
              className={
                !symbolWinClassRowC3 ? 'reelSymbolBed' : 'reelSymbolBedWinRowC'
              }
            >
              <div
                className={
                  reelSpinning3
                    ? 'reelSymbolContainerSpin'
                    : 'reelSymbolContainerStop'
                }
              >
                <div className="reelSymbol">
                  {renderSymbol('🍈', symbolLandedC3, reelSpinning3)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const reel4 = () => {
    return (
      <div className="reelsCenterBed">
        <div className="reelsCenterContainer">
          <div className={!reelSpinning4 ? 'reelStatic' : 'reelSpinning'}>
            <div
              className={
                !symbolWinClassRowA4 ? 'reelSymbolBed' : 'reelSymbolBedWinRowA'
              }
            >
              <div
                className={
                  reelSpinning4
                    ? 'reelSymbolContainerSpin'
                    : 'reelSymbolContainerStop'
                }
              >
                <div className="reelSymbol">
                  {renderSymbol('🍇', symbolLandedA4, reelSpinning4)}
                </div>
              </div>
            </div>
            <div
              className={
                !symbolWinClassRowB4 ? 'reelSymbolBed' : 'reelSymbolBedWinRowB'
              }
            >
              <div
                className={
                  reelSpinning4
                    ? 'reelSymbolContainerSpin'
                    : 'reelSymbolContainerStop'
                }
              >
                <div className="reelSymbol">
                  {renderSymbol('🍀', symbolLandedB4, reelSpinning4)}
                </div>
              </div>
            </div>
            <div
              className={
                !symbolWinClassRowC4 ? 'reelSymbolBed' : 'reelSymbolBedWinRowC'
              }
            >
              <div
                className={
                  reelSpinning4
                    ? 'reelSymbolContainerSpin'
                    : 'reelSymbolContainerStop'
                }
              >
                <div className="reelSymbol">
                  {renderSymbol('🔔', symbolLandedC4, reelSpinning4)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const reel5 = () => {
    return (
      <div className="reelsCenterBed">
        <div className="reelsCenterContainer">
          <div className={!reelSpinning5 ? 'reelStatic' : 'reelSpinning'}>
            <div
              className={
                !symbolWinClassRowA5 ? 'reelSymbolBed' : 'reelSymbolBedWinRowA'
              }
            >
              <div
                className={
                  reelSpinning5
                    ? 'reelSymbolContainerSpin'
                    : 'reelSymbolContainerStop'
                }
              >
                <div className="reelSymbol">
                  {renderSymbol('🥑', symbolLandedA5, reelSpinning5)}
                </div>
              </div>
            </div>
            <div
              className={
                !symbolWinClassRowB5 ? 'reelSymbolBed' : 'reelSymbolBedWinRowB'
              }
            >
              <div
                className={
                  reelSpinning5
                    ? 'reelSymbolContainerSpin'
                    : 'reelSymbolContainerStop'
                }
              >
                <div className="reelSymbol">
                  {renderSymbol('🍌', symbolLandedB5, reelSpinning5)}
                </div>
              </div>
            </div>
            <div
              className={
                !symbolWinClassRowC5 ? 'reelSymbolBed' : 'reelSymbolBedWinRowC'
              }
            >
              <div
                className={
                  reelSpinning5
                    ? 'reelSymbolContainerSpin'
                    : 'reelSymbolContainerStop'
                }
              >
                <div className="reelSymbol">
                  {renderSymbol('🍒', symbolLandedC5, reelSpinning5)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const resetData = () => {
    setBannerText('good luck 🍀')
    setCondensedNumberA1(0)
    setCondensedNumberA2(0)
    setCondensedNumberA3(0)
    setCondensedNumberA4(0)
    setCondensedNumberA5(0)
    setCondensedNumberB1(0)
    setCondensedNumberB2(0)
    setCondensedNumberB3(0)
    setCondensedNumberB4(0)
    setCondensedNumberB5(0)
    setCondensedNumberC1(0)
    setCondensedNumberC2(0)
    setCondensedNumberC3(0)
    setCondensedNumberC4(0)
    setCondensedNumberC5(0)
    setRowA([])
    setRowB([])
    setRowC([])
    setRowAResult([])
    setRowBResult([])
    setRowCResult([])
    setRowAWinArray([])
    setRowBWinArray([])
    setRowCWinArray([])
    setWinningSymbolRowA([])
    setWinningSymbolRowB([])
    setWinningSymbolRowC([])
    setRowAPrizeArray([])
    setRowBPrizeArray([])
    setRowCPrizeArray([])
    setPrize(0)
    setWildCardQuantityRowA(0)
    setWildCardQuantityRowB(0)
    setWildCardQuantityRowC(0)
    setScatterQuantityRowA(0)
    setScatterQuantityRowB(0)
    setScatterQuantityRowC(0)
    setRowAPrize(0)
    setRowBPrize(0)
    setRowCPrize(0)
    setSymbolWinClassRowA1(false)
    setSymbolWinClassRowA2(false)
    setSymbolWinClassRowA3(false)
    setSymbolWinClassRowA4(false)
    setSymbolWinClassRowA5(false)
    setSymbolWinClassRowB1(false)
    setSymbolWinClassRowB2(false)
    setSymbolWinClassRowB3(false)
    setSymbolWinClassRowB4(false)
    setSymbolWinClassRowB5(false)
    setSymbolWinClassRowC1(false)
    setSymbolWinClassRowC2(false)
    setSymbolWinClassRowC3(false)
    setSymbolWinClassRowC4(false)
    setSymbolWinClassRowC5(false)
    setLinesOverlayShow(false)
    setSymbol1WildCardWinA(false)
    setSymbol2WildCardWinA(false)
    setSymbol3WildCardWinA(false)
    setSymbol4WildCardWinA(false)
    setSymbol5WildCardWinA(false)
    setSymbol6WildCardWinA(false)
    setSymbol7WildCardWinA(false)
    setSymbol8WildCardWinA(false)
    setSymbol9WildCardWinA(false)
    setSymbol1WildCardWinB(false)
    setSymbol2WildCardWinB(false)
    setSymbol3WildCardWinB(false)
    setSymbol4WildCardWinB(false)
    setSymbol5WildCardWinB(false)
    setSymbol6WildCardWinB(false)
    setSymbol7WildCardWinB(false)
    setSymbol8WildCardWinB(false)
    setSymbol9WildCardWinB(false)
    setSymbol1WildCardWinC(false)
    setSymbol2WildCardWinC(false)
    setSymbol3WildCardWinC(false)
    setSymbol4WildCardWinC(false)
    setSymbol5WildCardWinC(false)
    setSymbol6WildCardWinC(false)
    setSymbol7WildCardWinC(false)
    setSymbol8WildCardWinC(false)
    setSymbol9WildCardWinC(false)
  }

  const spinStart = () => {
    if (reelSpinning5) return null
    if (gameCredits < betValue) {
      setBannerText('insufficient credits')
      return
    }
    setUpdateCreditCounter(0)
    setTriggerStrategySelector(true)
    condenserCounterTrigger()
    checkUserLock({ id: user._id })
    fetchSiteTransactionHistory({ siteNumber: user.siteNumber })
    fetchSiteFloorCredits({ siteNumber: user.siteNumber })
    fetchSiteBankValue({ siteNumber: user.siteNumber })
    resetData()
    randomNumber()
    playStartReelSound()
    if (!freeSpinPlayOn) {
      subtractBetValueFromCredits()
    }
    setSpinCount(spinCount + 1)
    setReelSpinning1(true)
    setReelSpinning2(true)
    setReelSpinning3(true)
    setReelSpinning4(true)
    setReelSpinning5(true)
    setCelebrateWin(false)
    setRunNetworkChecker(true)
  }

  const spinStartReplay = () => {
    resetData()
    randomNumber()
  }

  const closeGame = () => {
    if (reelSpinning5 || freeSpinPlayOn) return null
    setVisibleComponent('gamesMenu')
  }

  const betAmountSelector = () => {
    if (reelSpinning5) return null
    if (maxedOut) {
      setBetValue(50)
      setMaxedOut(false)
      return
    }
    if (betValue !== 2000) {
      setBetValue(betValue + 50)
    } else {
      setBetValue(50)
    }
  }

  const subtractBetValueFromCredits = () => {
    if (betTotal > gameCredits) {
      setBannerText('insufficientCredits')
      return
    }
    let creditBalance = 0
    let jackpotContribution = 0
    let progresiveJackpot1Contribution = 0
    let clientProgresive1Contribution = 0
    let clientProgresive2Contribution = 0
    let clientProgresive3Contribution = 0
    creditBalance = gameCredits - betTotal
    jackpotContribution = (20 / 100) * betTotal
    progresiveJackpot1Contribution = (20 / 100) * betTotal
    clientProgresive1Contribution = (14 / 100) * betTotal
    clientProgresive2Contribution = (13 / 100) * betTotal
    clientProgresive3Contribution = (13 / 100) * betTotal
    if (
      !freeSpinPlayOn &&
      !progresiveJackpot1Release &&
      !clientProgresive1Release &&
      !clientProgresive2Release &&
      !clientProgresive3Release &&
      !jackpotRelease
    ) {
      updateJackpot({
        jackpotContribution: jackpotContribution,
        progresiveJackpot1Contribution: progresiveJackpot1Contribution,
        siteNumber: user.siteNumber,
      })
      updateClientProgresive({
        clientProgresive1Contribution: clientProgresive1Contribution,
        clientProgresive2Contribution: clientProgresive2Contribution,
        clientProgresive3Contribution: clientProgresive3Contribution,
      })
    }
    setGameCredits(creditBalance)
    creditBalance = 0
  }

  const setLines = () => {
    if (reelSpinning5) return null
    resetData()
    if (maxedOut) {
      setNumberOfLines(1)
      setBetValue(50)
      setMaxedOut(false)
    }
    if (numberOfLines === 3) {
      setNumberOfLines(1)
      setLinesOverlayShow(true)
    } else {
      setNumberOfLines(numberOfLines + 1)
      setLinesOverlayShow(true)
    }
  }

  const startAutoPlay = () => {
    spinStart()
    setAutoPlay(!autoPlay)
    setLinesOverlayShow(false)
  }

  const renderBlock = (num, showAnimated, showStill) => {
    if (numberOfLines > num && reelSpinning5) {
      return showAnimated
    }
    if (numberOfLines > num && !reelSpinning5) {
      return showStill
    }
    return showStill
  }

  const maxBet = () => {
    if (reelSpinning5) return null
    if (gameCredits < 6000) {
      let betNum = gameCredits / 3
      let betNumRound = Math.ceil(betNum / 10) * 10
      let num = betNumRound * 3 > gameCredits ? gameCredits / 3 : betNumRound
      setMaxedOut(true)
      setBetValue(num)
      setNumberOfLines(3)
      return
    }
    setBetValue(2000)
    setNumberOfLines(3)
    return
  }

  const renderFreeSpinPlaceholder = () => {
    return (
      <div className="freeSpinPlaceholderBed">
        <div className="freeSpinPlaceholderContainer">
          <div className="freeSpinPlaceholderAmountBed">
            <div className="freeSpinPlaceholderAmountContainer">
              <div className="freeSpinPlaceholderAmountText">
                {freeSpinsTotal}
              </div>
            </div>
          </div>
          <div className="freeSpinPlaceholderButtonBed">
            <div className="freeSpinPlaceholderButtonContainer">
              <div className="freeSpinPlaceholderText">
                FREE
                <br />
                SPINS
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderContent = () => {
    let formattedNumber = (prize / 100).toFixed(2)
    let formattedJackpot = !jackpotData
      ? ''
      : (jackpotData.jackpot / 100).toFixed(2)
    let formattedCredits = (gameCredits / 100).toFixed(2)
    if (!user) return null
    if (lock) return <LockScreen />
    if (
      promoPrizeWheelShow &&
      user.promoWheelEntries > 0 &&
      !promoWheelUsed &&
      gameCredits > 1950
    ) {
      return <PromoPrizeWheel />
    }
    return (
      <div className="slotBed">
        <div className="slotHeaderBed">
          <div className="slotHeaderLeftBed">
            <div className="creditsBed">
              <div className="creditsHeading">CREDITS</div>
              <div className="creditsAmount">{formattedCredits}</div>
            </div>
            <div className="paytableBed">
              {!celebrateWin ? (
                <FruitsPaytable />
              ) : (
                <div className="amountWonBed">
                  <div className="amountWonContainer">
                    {formattedNumber}
                    <br /> Won
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="slotNameBed">
            <div className="slotNameContainer">
              <div className="slotNameText">Fruitakia</div>
            </div>
          </div>
          <div className="jackpotBed">
            <div className="jackpotContainer">
              <div className="jackpotHeading">JACKPOT</div>
              <div className="jackpotAmount">{formattedJackpot}</div>
            </div>
            <div className="fruitsExitButtonBed">
              <FontAwesomeIcon
                icon={faTimesCircle}
                className="fruitsExitButton"
                onClick={() => closeGame()}
              />
            </div>
          </div>
        </div>
        <div className="reelsBed">
          <div className="reelsBorderLeftBed">
            <div
              className={renderBlock(
                1,
                'reelsBorderLeftRightBlock1Animate',
                'reelsBorderLeftRightBlock1'
              )}
            >
              <div className="reelsBorderLeftRightBlockText">
                <TbCircle2 className="reelsBorderLeftRightBlockIcon" />
              </div>
            </div>
            <div
              className={renderBlock(
                0,
                'reelsBorderLeftRightBlock2Animate',
                'reelsBorderLeftRightBlock2'
              )}
            >
              <div className="reelsBorderLeftRightBlockText">
                <TbCircle1 className="reelsBorderLeftRightBlockIcon" />
              </div>
            </div>
            <div
              className={renderBlock(
                2,
                'reelsBorderLeftRightBlock3Animate',
                'reelsBorderLeftRightBlock3'
              )}
            >
              <div className="reelsBorderLeftRightBlockText">
                <TbCircle3 className="reelsBorderLeftRightBlockIcon" />
              </div>
            </div>
          </div>
          {reel1()}
          {reel2()}
          {reel3()}
          {reel4()}
          {reel5()}
          <div className="reelsBorderRightBed">
            <div
              className={renderBlock(
                1,
                'reelsBorderLeftRightBlock1Animate',
                'reelsBorderLeftRightBlock1'
              )}
            >
              <div className="reelsBorderLeftRightBlockText">
                <TbCircle2 className="reelsBorderLeftRightBlockIcon" />
              </div>
            </div>
            <div
              className={renderBlock(
                0,
                'reelsBorderLeftRightBlock2Animate',
                'reelsBorderLeftRightBlock2'
              )}
            >
              <div className="reelsBorderLeftRightBlockText">
                <TbCircle1 className="reelsBorderLeftRightBlockIcon" />
              </div>
            </div>
            <div
              className={renderBlock(
                2,
                'reelsBorderLeftRightBlock3Animate',
                'reelsBorderLeftRightBlock3'
              )}
            >
              <div className="reelsBorderLeftRightBlockText">
                <TbCircle3 className="reelsBorderLeftRightBlockIcon" />
              </div>
            </div>
          </div>
        </div>
        <div className="footerBed">
          {!freeSpinPlayOn ? (
            <div className="betSizeBed">
              <div className="betSizeContainer">
                <div className="betSizeAmountBed">
                  <div className="betSizeAmountContainer">
                    <div className="betSizeAmountText">
                      {maxedOut
                        ? 'ALL IN'
                        : betValue
                            .toString()
                            .replace(/.(?=(...........)*..$)/g, '$&.')}
                    </div>
                  </div>
                </div>
                <div
                  className="betSizeButtonBed"
                  onClick={() => {
                    playPressButtonSound()
                    betAmountSelector()
                  }}
                >
                  <div className="betSizeButtonContainer">
                    <div className="betSizeButtonText">BET</div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            renderFreeSpinPlaceholder()
          )}
          {!freeSpinPlayOn ? (
            <div
              className="betSizeBed"
              onClick={() => {
                playPressButtonSound()
                setLines()
              }}
            >
              <div className="betSizeContainer">
                <div className="betSizeAmountBed">
                  <div className="betSizeAmountContainer">
                    <div className="betSizeAmountText">{numberOfLines}</div>
                  </div>
                </div>
                <div className="betSizeButtonBed">
                  <div className="betSizeButtonContainer">
                    <div className="betSizeButtonText">Lines</div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            renderFreeSpinPlaceholder()
          )}
          {!freeSpinPlayOn ? (
            <div className="betTotalBed">
              <div className="betTotalContainer">
                <div className="betTotalAmountBed">
                  <div className="betTotalAmountContainer">
                    <div className="betTotalAmountText">
                      {betTotal
                        .toString()
                        .replace(/.(?=(...........)*..$)/g, '$&.')}
                    </div>
                  </div>
                </div>
                <div className="betTotalButtonBed">
                  <div className="betTotalButtonContainer">
                    <div className="betTotalText">
                      BET
                      <br />
                      TOTAL
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            renderFreeSpinPlaceholder()
          )}
          {!freeSpinPlayOn ? (
            <div
              className="maxBetButtonBed"
              onClick={() => {
                playPressButtonSound()
                maxBet()
              }}
            >
              <div className="maxBetButtonContainer">
                <div className="maxBetButton">
                  <div className="maxBetButtonText">
                    MAX <br /> BET
                  </div>
                </div>
              </div>
            </div>
          ) : (
            renderFreeSpinPlaceholder()
          )}
          <div className="resultBed">
            <div className="resultContainer">
              <div className="resultTextBed">
                <GifSelector />
                {bannerGifContent === '' ? bannerText : null}
              </div>
            </div>
          </div>
          {!freeSpinPlayOn ? (
            <div
              className="autoSpinButtonBed"
              onClick={
                gameCredits < betTotal
                  ? null
                  : () => {
                      playPressButtonSound()
                      startAutoPlay()
                    }
              }
            >
              <div className="autoSpinButtonContainer">
                <div
                  className={
                    autoPlay ? 'autoSpinButtonStop' : 'autoSpinButtonStart'
                  }
                >
                  <div className="autoSpinButtonText">
                    {autoPlay ? (
                      <>
                        STOP <br /> PLAY
                      </>
                    ) : (
                      <>
                        AUTO <br /> PLAY
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            renderFreeSpinPlaceholder()
          )}
          {!freeSpinPlayOn ? (
            <div
              className="spinButtonBed"
              onClick={
                gameCredits < betTotal
                  ? null
                  : () => {
                      playPressButtonSound()
                      spinStart()
                    }
              }
            >
              <div className="spinButtonContainer">
                <div className="spinButton">
                  <div className="spinButtonText">
                    <FontAwesomeIcon icon={faPlay} /> <br /> PLAY
                  </div>
                </div>
              </div>
            </div>
          ) : (
            renderFreeSpinPlaceholder()
          )}
        </div>
      </div>
    )
  }

  return (
    <>
      <RandomNumbers />
      <CondenserSeldector />
      <CalculateRowAWin />
      <CalculateRowBWin />
      <CalculateRowCWin />
      <CalculateResult />
      <AssignWinClass />
      <FreeSpinPlayer />
      <PayoutInterceptor />
      <LinesOverlay />
      <ScatterWinOverlay />
      <JackpotOverlay />
      <NetworkErrorOverlay />
      {renderContent()}
    </>
  )
}

export default Fruits
