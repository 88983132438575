import React, { useState, useContext, useEffect } from 'react'

import { Context as GameContext } from '../../../../../context/GameContext'
import gif001 from '../../../../../assets/images/gifs/win/winGif001.gif'
import gif002 from '../../../../../assets/images/gifs/win/winGif002.gif'
import gif003 from '../../../../../assets/images/gifs/win/winGif003.gif'
import gif004 from '../../../../../assets/images/gifs/win/winGif004.gif'
import gif005 from '../../../../../assets/images/gifs/win/winGif005.gif'
import gif006 from '../../../../../assets/images/gifs/win/winGif006.gif'
import gif007 from '../../../../../assets/images/gifs/win/winGif007.gif'
import gif008 from '../../../../../assets/images/gifs/win/winGif008.gif'
import gif009 from '../../../../../assets/images/gifs/win/winGif009.gif'
import gif010 from '../../../../../assets/images/gifs/win/winGif010.gif'
import gif011 from '../../../../../assets/images/gifs/win/winGif011.gif'
import gif012 from '../../../../../assets/images/gifs/win/winGif012.gif'
import gif013 from '../../../../../assets/images/gifs/win/winGif013.gif'
import gif014 from '../../../../../assets/images/gifs/win/winGif014.gif'
import gif015 from '../../../../../assets/images/gifs/win/winGif015.gif'
import gif016 from '../../../../../assets/images/gifs/win/winGif016.gif'
import gif017 from '../../../../../assets/images/gifs/win/winGif017.gif'
import gif018 from '../../../../../assets/images/gifs/win/winGif018.gif'
import gif019 from '../../../../../assets/images/gifs/win/winGif019.gif'
import gif020 from '../../../../../assets/images/gifs/win/winGif020.gif'
import gif021 from '../../../../../assets/images/gifs/win/winGif021.gif'
import gif022 from '../../../../../assets/images/gifs/win/winGif022.gif'
import gif023 from '../../../../../assets/images/gifs/win/winGif023.gif'
import gif024 from '../../../../../assets/images/gifs/win/winGif024.gif'
import gif025 from '../../../../../assets/images/gifs/win/winGif025.gif'
import gif026 from '../../../../../assets/images/gifs/win/winGif026.gif'
import gif027 from '../../../../../assets/images/gifs/win/winGif027.gif'
import gif028 from '../../../../../assets/images/gifs/win/winGif028.gif'
import gif029 from '../../../../../assets/images/gifs/win/winGif029.gif'
import gif030 from '../../../../../assets/images/gifs/win/winGif030.gif'
import gif031 from '../../../../../assets/images/gifs/win/winGif031.gif'
import gif032 from '../../../../../assets/images/gifs/win/winGif032.gif'
import gif033 from '../../../../../assets/images/gifs/win/winGif033.gif'
import gif034 from '../../../../../assets/images/gifs/win/winGif034.gif'
import gif035 from '../../../../../assets/images/gifs/win/winGif035.gif'
import gif036 from '../../../../../assets/images/gifs/win/winGif036.gif'
import gif037 from '../../../../../assets/images/gifs/win/winGif037.gif'
import gif038 from '../../../../../assets/images/gifs/win/winGif038.gif'
import gif039 from '../../../../../assets/images/gifs/win/winGif039.gif'
import gif040 from '../../../../../assets/images/gifs/win/winGif040.gif'
import gif041 from '../../../../../assets/images/gifs/win/winGif041.gif'
import gif042 from '../../../../../assets/images/gifs/win/winGif042.gif'
import gif043 from '../../../../../assets/images/gifs/win/winGif043.gif'
import gif044 from '../../../../../assets/images/gifs/win/winGif044.gif'
import gif045 from '../../../../../assets/images/gifs/win/winGif045.gif'
import gif046 from '../../../../../assets/images/gifs/win/winGif046.gif'
import gif047 from '../../../../../assets/images/gifs/win/winGif047.gif'
import gif048 from '../../../../../assets/images/gifs/win/winGif048.gif'
import gif049 from '../../../../../assets/images/gifs/win/winGif049.gif'
import gif050 from '../../../../../assets/images/gifs/win/winGif050.gif'
import gif051 from '../../../../../assets/images/gifs/win/winGif051.gif'
import gif052 from '../../../../../assets/images/gifs/win/winGif052.gif'
import gif053 from '../../../../../assets/images/gifs/win/winGif053.gif'
import gif054 from '../../../../../assets/images/gifs/win/winGif054.gif'
import gif055 from '../../../../../assets/images/gifs/win/winGif055.gif'
import gif056 from '../../../../../assets/images/gifs/win/winGif056.gif'
import gif057 from '../../../../../assets/images/gifs/win/winGif057.gif'
import gif058 from '../../../../../assets/images/gifs/win/winGif058.gif'
import gif059 from '../../../../../assets/images/gifs/win/winGif059.gif'
import gif060 from '../../../../../assets/images/gifs/win/winGif060.gif'
import gif061 from '../../../../../assets/images/gifs/win/winGif061.gif'
import gif062 from '../../../../../assets/images/gifs/win/winGif062.gif'
import gif063 from '../../../../../assets/images/gifs/win/winGif063.gif'
import gif064 from '../../../../../assets/images/gifs/win/winGif064.gif'
import gif065 from '../../../../../assets/images/gifs/win/winGif065.gif'
import gif066 from '../../../../../assets/images/gifs/win/winGif066.gif'
import gif067 from '../../../../../assets/images/gifs/win/winGif067.gif'
import gif068 from '../../../../../assets/images/gifs/win/winGif068.gif'
import gif069 from '../../../../../assets/images/gifs/win/winGif069.gif'
import gif070 from '../../../../../assets/images/gifs/win/winGif070.gif'
import gif071 from '../../../../../assets/images/gifs/win/winGif071.gif'
import gif072 from '../../../../../assets/images/gifs/win/winGif072.gif'
import gif073 from '../../../../../assets/images/gifs/win/winGif073.gif'
import gif074 from '../../../../../assets/images/gifs/win/winGif074.gif'
import gif075 from '../../../../../assets/images/gifs/win/winGif075.gif'
import gif076 from '../../../../../assets/images/gifs/win/winGif076.gif'
import gif077 from '../../../../../assets/images/gifs/win/winGif077.gif'
import gif078 from '../../../../../assets/images/gifs/win/winGif078.gif'
import gif079 from '../../../../../assets/images/gifs/win/winGif079.gif'
import gif080 from '../../../../../assets/images/gifs/win/winGif080.gif'
import gif081 from '../../../../../assets/images/gifs/win/winGif081.gif'
import gif082 from '../../../../../assets/images/gifs/win/winGif082.gif'
import gif083 from '../../../../../assets/images/gifs/win/winGif083.gif'
import gif084 from '../../../../../assets/images/gifs/win/winGif084.gif'
import gif085 from '../../../../../assets/images/gifs/win/winGif085.gif'
import gif086 from '../../../../../assets/images/gifs/win/winGif086.gif'

const GifCelebrateGenerator = () => {
  const [randomNumber, setRandomNumber] = useState(0)

  const {
    state: { prize },
  } = useContext(GameContext)

  useEffect(() => {
    if (prize > 0) {
      setRandomNumber(Math.floor(Math.random() * (85 - 1) + 1))
    }
  }, [prize])

  const renderGif = () => {
    switch (randomNumber) {
      case 1:
        return <img src={gif001} alt="celebrateGif" className="bannerGif" />
      case 2:
        return <img src={gif002} alt="celebrateGif" className="bannerGif" />
      case 3:
        return <img src={gif003} alt="celebrateGif" className="bannerGif" />
      case 4:
        return <img src={gif004} alt="celebrateGif" className="bannerGif" />
      case 5:
        return <img src={gif005} alt="celebrateGif" className="bannerGif" />
      case 6:
        return <img src={gif006} alt="celebrateGif" className="bannerGif" />
      case 7:
        return <img src={gif007} alt="celebrateGif" className="bannerGif" />
      case 8:
        return <img src={gif008} alt="celebrateGif" className="bannerGif" />
      case 9:
        return <img src={gif009} alt="celebrateGif" className="bannerGif" />
      case 10:
        return <img src={gif010} alt="celebrateGif" className="bannerGif" />
      case 11:
        return <img src={gif011} alt="celebrateGif" className="bannerGif" />
      case 12:
        return <img src={gif012} alt="celebrateGif" className="bannerGif" />
      case 13:
        return <img src={gif013} alt="celebrateGif" className="bannerGif" />
      case 14:
        return <img src={gif014} alt="celebrateGif" className="bannerGif" />
      case 15:
        return <img src={gif015} alt="celebrateGif" className="bannerGif" />
      case 16:
        return <img src={gif016} alt="celebrateGif" className="bannerGif" />
      case 17:
        return <img src={gif017} alt="celebrateGif" className="bannerGif" />
      case 18:
        return <img src={gif018} alt="celebrateGif" className="bannerGif" />
      case 19:
        return <img src={gif019} alt="celebrateGif" className="bannerGif" />
      case 20:
        return <img src={gif020} alt="celebrateGif" className="bannerGif" />
      case 21:
        return <img src={gif021} alt="celebrateGif" className="bannerGif" />
      case 22:
        return <img src={gif022} alt="celebrateGif" className="bannerGif" />
      case 23:
        return <img src={gif023} alt="celebrateGif" className="bannerGif" />
      case 24:
        return <img src={gif024} alt="celebrateGif" className="bannerGif" />
      case 25:
        return <img src={gif025} alt="celebrateGif" className="bannerGif" />
      case 26:
        return <img src={gif026} alt="celebrateGif" className="bannerGif" />
      case 27:
        return <img src={gif027} alt="celebrateGif" className="bannerGif" />
      case 28:
        return <img src={gif028} alt="celebrateGif" className="bannerGif" />
      case 29:
        return <img src={gif029} alt="celebrateGif" className="bannerGif" />
      case 30:
        return <img src={gif030} alt="celebrateGif" className="bannerGif" />
      case 31:
        return <img src={gif031} alt="celebrateGif" className="bannerGif" />
      case 32:
        return <img src={gif032} alt="celebrateGif" className="bannerGif" />
      case 33:
        return <img src={gif033} alt="celebrateGif" className="bannerGif" />
      case 34:
        return <img src={gif034} alt="celebrateGif" className="bannerGif" />
      case 35:
        return <img src={gif035} alt="celebrateGif" className="bannerGif" />
      case 36:
        return <img src={gif036} alt="celebrateGif" className="bannerGif" />
      case 37:
        return <img src={gif037} alt="celebrateGif" className="bannerGif" />
      case 38:
        return <img src={gif038} alt="celebrateGif" className="bannerGif" />
      case 39:
        return <img src={gif039} alt="celebrateGif" className="bannerGif" />
      case 40:
        return <img src={gif040} alt="celebrateGif" className="bannerGif" />
      case 41:
        return <img src={gif041} alt="celebrateGif" className="bannerGif" />
      case 42:
        return <img src={gif042} alt="celebrateGif" className="bannerGif" />
      case 43:
        return <img src={gif043} alt="celebrateGif" className="bannerGif" />
      case 44:
        return <img src={gif044} alt="celebrateGif" className="bannerGif" />
      case 45:
        return <img src={gif045} alt="celebrateGif" className="bannerGif" />
      case 46:
        return <img src={gif046} alt="celebrateGif" className="bannerGif" />
      case 47:
        return <img src={gif047} alt="celebrateGif" className="bannerGif" />
      case 48:
        return <img src={gif048} alt="celebrateGif" className="bannerGif" />
      case 49:
        return <img src={gif049} alt="celebrateGif" className="bannerGif" />
      case 50:
        return <img src={gif050} alt="celebrateGif" className="bannerGif" />
      case 51:
        return <img src={gif051} alt="celebrateGif" className="bannerGif" />
      case 52:
        return <img src={gif052} alt="celebrateGif" className="bannerGif" />
      case 53:
        return <img src={gif053} alt="celebrateGif" className="bannerGif" />
      case 54:
        return <img src={gif054} alt="celebrateGif" className="bannerGif" />
      case 55:
        return <img src={gif055} alt="celebrateGif" className="bannerGif" />
      case 56:
        return <img src={gif056} alt="celebrateGif" className="bannerGif" />
      case 57:
        return <img src={gif057} alt="celebrateGif" className="bannerGif" />
      case 58:
        return <img src={gif058} alt="celebrateGif" className="bannerGif" />
      case 59:
        return <img src={gif059} alt="celebrateGif" className="bannerGif" />
      case 60:
        return <img src={gif060} alt="celebrateGif" className="bannerGif" />
      case 61:
        return <img src={gif061} alt="celebrateGif" className="bannerGif" />
      case 62:
        return <img src={gif062} alt="celebrateGif" className="bannerGif" />
      case 63:
        return <img src={gif063} alt="celebrateGif" className="bannerGif" />
      case 64:
        return <img src={gif064} alt="celebrateGif" className="bannerGif" />
      case 65:
        return <img src={gif065} alt="celebrateGif" className="bannerGif" />
      case 66:
        return <img src={gif066} alt="celebrateGif" className="bannerGif" />
      case 67:
        return <img src={gif067} alt="celebrateGif" className="bannerGif" />
      case 68:
        return <img src={gif068} alt="celebrateGif" className="bannerGif" />
      case 69:
        return <img src={gif069} alt="celebrateGif" className="bannerGif" />
      case 70:
        return <img src={gif070} alt="celebrateGif" className="bannerGif" />
      case 71:
        return <img src={gif071} alt="celebrateGif" className="bannerGif" />
      case 72:
        return <img src={gif072} alt="celebrateGif" className="bannerGif" />
      case 73:
        return <img src={gif073} alt="celebrateGif" className="bannerGif" />
      case 74:
        return <img src={gif074} alt="celebrateGif" className="bannerGif" />
      case 75:
        return <img src={gif075} alt="celebrateGif" className="bannerGif" />
      case 76:
        return <img src={gif076} alt="celebrateGif" className="bannerGif" />
      case 77:
        return <img src={gif077} alt="celebrateGif" className="bannerGif" />
      case 78:
        return <img src={gif078} alt="celebrateGif" className="bannerGif" />
      case 79:
        return <img src={gif079} alt="celebrateGif" className="bannerGif" />
      case 80:
        return <img src={gif080} alt="celebrateGif" className="bannerGif" />
      case 81:
        return <img src={gif081} alt="celebrateGif" className="bannerGif" />
      case 82:
        return <img src={gif082} alt="celebrateGif" className="bannerGif" />
      case 83:
        return <img src={gif083} alt="celebrateGif" className="bannerGif" />
      case 84:
        return <img src={gif084} alt="celebrateGif" className="bannerGif" />
      case 85:
        return <img src={gif085} alt="celebrateGif" className="bannerGif" />
      case 86:
        return <img src={gif086} alt="celebrateGif" className="bannerGif" />
      default:
        break
    }
  }

  return renderGif()
}

export default GifCelebrateGenerator
