import React from 'react'
import { Provider as AuthProvider } from '../context/AuthContext'
import { Provider as UserProvider } from '../context/UserContext'
import { Provider as NavProvider } from '../context/NavContext'
import { Provider as SocketProvider } from '../context/SocketContext'
import { Provider as ModalProvider } from '../context/ModalContext'
import { Provider as GameProvider } from '../context/GameContext'
import { Provider as PayoutProvider } from '../context/PayoutContext'
import { Provider as PromoPrizeWheelContext } from '../context/PromoPrizeWheelContext'
import { Provider as BaitProvider } from '../context/BaitContext'

import AppScreens from './AppScreens'
import './app.css'

const App = () => {
  return (
    <div className="appBed">
      <AuthProvider>
        <UserProvider>
          <NavProvider>
            <SocketProvider>
              <ModalProvider>
                <GameProvider>
                  <PayoutProvider>
                    <PromoPrizeWheelContext>
                      <BaitProvider>
                        <AppScreens />
                      </BaitProvider>
                    </PromoPrizeWheelContext>
                  </PayoutProvider>
                </GameProvider>
              </ModalProvider>
            </SocketProvider>
          </NavProvider>
        </UserProvider>
      </AuthProvider>
    </div>
  )
}

export default App
