import { useState, useContext, useEffect } from 'react'

import { Context as PayoutContext } from '../../../../../context/PayoutContext'

const ClientProgresive1 = () => {
  const [clientProgresive1StartValue, setClientProgresive1StartValue] =
    useState(0)

  const [clientProgresive1BalanceCounter, setClientProgresive1BalanceCounter] =
    useState(0)

  const {
    state: {
      clientProgresiveData,
      clientProgresive1,
      clientProgresive1Release,
      clientProgresive1Balance,
      addCreditAverage,
    },
    updateClientProgresive,
    setClientProgresive1,
    setClientProgresive1Release,
    setClientProgresive1Balance,
    setClientProgresiveReleaseDB,
  } = useContext(PayoutContext)

  useEffect(() => {
    if (clientProgresiveData) {
      const { clientProgresive1Release, clientProgresive1 } =
        clientProgresiveData
      setClientProgresive1Release(clientProgresive1Release)
      setClientProgresive1(clientProgresive1)
      let triggerValue = addCreditAverage * 40
      if (triggerValue > 0) {
        if (clientProgresive1 > triggerValue) {
          setClientProgresive1Release(true)
          setClientProgresiveReleaseDB({ clientProgresive1Release: true })
        }
      }
    }
  }, [clientProgresiveData, addCreditAverage])

  useEffect(() => {
    if (clientProgresive1Release && clientProgresive1BalanceCounter === 0) {
      setClientProgresive1StartValue(clientProgresive1)
      setClientProgresive1Balance(clientProgresive1)
      setClientProgresive1Release(true)
      setClientProgresive1BalanceCounter(1)
    }
  }, [
    clientProgresive1Release,
    clientProgresive1,
    clientProgresive1BalanceCounter,
  ])

  useEffect(() => {
    let clientProgresive1BalancePercentage =
      (clientProgresive1Balance / clientProgresive1StartValue) * 100
    if (clientProgresive1BalancePercentage < 20) {
      setClientProgresive1Release(false)
      setClientProgresiveReleaseDB({ clientProgresive1Release: false })
      updateClientProgresive({
        clientProgresive1PayoutEnd: true,
      })
      setClientProgresive1Balance(0)
      setClientProgresive1BalanceCounter(0)
    }
  }, [clientProgresive1Balance, clientProgresive1StartValue])

  return null
}

export default ClientProgresive1
