import React, { useState, useContext, useEffect } from 'react'

import { Context as GameContext } from '../../../../../context/GameContext'
import GifCelebrateGenerator from './GifCelebrateGenerator'
import GifSoSadGenerator from './GifSoSadGenerator'
import GifGoodLuckGenerator from './GifGoodLuckGenerator'

const GifSelector = () => {
  const [soSadRandomNumber, setSoSadRandomNumber] = useState(0)
  const [goodLuckRandomNumber, setGoodLuckRandomNumber] = useState(0)

  const {
    state: { bannerGifContent, prize, reelSpinning5, spinCount },
    setBannerGifContent,
  } = useContext(GameContext)

  useEffect(() => {
    if (bannerGifContent !== '') {
      const runTimer = setInterval(() => {
        setBannerGifContent('')
        setSoSadRandomNumber(0)
        setGoodLuckRandomNumber(0)
      }, 5000)
      return () => {
        clearInterval(runTimer)
      }
    }
  }, [bannerGifContent])

  useEffect(() => {
    if (prize > 0 && !reelSpinning5) {
      setBannerGifContent('celebrate')
    }
  }, [prize, reelSpinning5])

  useEffect(() => {
    if (!reelSpinning5 && prize === 0) {
      setSoSadRandomNumber(Math.floor(Math.random() * (100 - 1) + 1))
    }
  }, [reelSpinning5, prize])

  useEffect(() => {
    if (soSadRandomNumber > 70 && spinCount > 0) {
      setBannerGifContent('soSad')
    }
  }, [soSadRandomNumber, spinCount])

  useEffect(() => {
    if (reelSpinning5 && bannerGifContent === '') {
      setGoodLuckRandomNumber(Math.floor(Math.random() * (100 - 1) + 1))
    }
  }, [reelSpinning5, bannerGifContent])

  useEffect(() => {
    if (goodLuckRandomNumber > 70 && spinCount > 0) {
      setBannerGifContent('goodLuck')
    }
  }, [goodLuckRandomNumber, spinCount])

  const renderContent = () => {
    switch (bannerGifContent) {
      case 'celebrate':
        return <GifCelebrateGenerator />
      case 'soSad':
        return <GifSoSadGenerator />
      case 'goodLuck':
        return <GifGoodLuckGenerator />
      case '':
        return null
      default:
        break
    }
  }

  return renderContent()
}

export default GifSelector
