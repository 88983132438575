// desctiption: not balanced, high paying symbols are most frequent excluding wildcard and scatter
import { useContext, useEffect, useState } from 'react'

import { Context as GameContext } from '../../../../../context/GameContext'
import { Context as PayoutContext } from '../../../../../context/PayoutContext'

const CondenseRandomNumberA3 = () => {
  const [condensedNumber1Done, setCondensedNumber1Done] = useState(false)
  const [condensedNumber2Done, setCondensedNumber2Done] = useState(false)
  const [condensedNumber3Done, setCondensedNumber3Done] = useState(false)
  const [condensedNumber4Done, setCondensedNumber4Done] = useState(false)
  const [condensedNumber5Done, setCondensedNumber5Done] = useState(false)

  const {
    state: {
      randomNumberA1,
      randomNumberA2,
      randomNumberA3,
      randomNumberA4,
      randomNumberA5,
      reelSpinning5,
      freeSpinPlayOn,
    },
    setCondensedNumberA1,
    setCondensedNumberA2,
    setCondensedNumberA3,
    setCondensedNumberA4,
    setCondensedNumberA5,
    setCondenseRandomNumberADone,
  } = useContext(GameContext)

  const {
    state: {
      payoutOverLimitStartReplay,
      triggerCondensePreJackpotNumber,
      jackpotWon,
    },
  } = useContext(PayoutContext)

  useEffect(() => {
    condensedRandomNumberA1()
    condensedRandomNumberA2()
    condensedRandomNumberA3()
    condensedRandomNumberA4()
    condensedRandomNumberA5()
  }, [
    randomNumberA1,
    randomNumberA2,
    randomNumberA3,
    randomNumberA4,
    randomNumberA5,
  ])

  useEffect(() => {
    if (
      condensedNumber1Done &&
      condensedNumber2Done &&
      condensedNumber3Done &&
      condensedNumber4Done &&
      condensedNumber5Done
    ) {
      setCondenseRandomNumberADone(true)
    }
  }, [
    condensedNumber1Done,
    condensedNumber2Done,
    condensedNumber3Done,
    condensedNumber4Done,
    condensedNumber5Done,
  ])

  useEffect(() => {
    if (!reelSpinning5 || payoutOverLimitStartReplay) {
      setCondenseRandomNumberADone(false)
      setCondensedNumber1Done(false)
      setCondensedNumber2Done(false)
      setCondensedNumber3Done(false)
      setCondensedNumber4Done(false)
      setCondensedNumber5Done(false)
    }
  }, [reelSpinning5, payoutOverLimitStartReplay])

  const condensedRandomNumberA1 = () => {
    switch (true) {
      // 4
      case randomNumberA1 > 0 && randomNumberA1 < 5:
        setCondensedNumberA1(1)
        setCondensedNumber1Done(true)
        break
      // 4
      case randomNumberA1 > 4 && randomNumberA1 < 9:
        setCondensedNumberA1(2)
        setCondensedNumber1Done(true)
        break
      // 4
      case randomNumberA1 > 8 && randomNumberA1 < 13:
        setCondensedNumberA1(3)
        setCondensedNumber1Done(true)
        break
      // 14
      case randomNumberA1 > 12 && randomNumberA1 < 27:
        setCondensedNumberA1(4)
        setCondensedNumber1Done(true)
        break
      // 14
      case randomNumberA1 > 26 && randomNumberA1 < 41:
        setCondensedNumberA1(5)
        setCondensedNumber1Done(true)
        break
      // 14
      case randomNumberA1 > 40 && randomNumberA1 < 55:
        setCondensedNumberA1(6)
        setCondensedNumber1Done(true)
        break
      // 14
      case randomNumberA1 > 54 && randomNumberA1 < 69:
        setCondensedNumberA1(7)
        setCondensedNumber1Done(true)
        break
      // 14
      case randomNumberA1 > 68 && randomNumberA1 < 83:
        setCondensedNumberA1(8)
        setCondensedNumber1Done(true)
        break
      // 14
      case randomNumberA1 > 82 && randomNumberA1 < 97:
        setCondensedNumberA1(9)
        setCondensedNumber1Done(true)
        break
      // 2
      case randomNumberA1 > 96 && randomNumberA1 < 99:
        setCondensedNumberA1(
          triggerCondensePreJackpotNumber || jackpotWon || freeSpinPlayOn
            ? 1
            : 10
        )
        setCondensedNumber1Done(true)
        break
      // 1
      case randomNumberA1 > 98 && randomNumberA1 < 100:
        setCondensedNumberA1(
          triggerCondensePreJackpotNumber || jackpotWon || freeSpinPlayOn
            ? 2
            : 11
        )
        setCondensedNumber1Done(true)
        break
      default:
        break
    }
  }

  const condensedRandomNumberA2 = () => {
    switch (true) {
      // 4
      case randomNumberA2 > 0 && randomNumberA2 < 5:
        setCondensedNumberA2(1)
        setCondensedNumber2Done(true)
        break
      // 4
      case randomNumberA2 > 4 && randomNumberA2 < 9:
        setCondensedNumberA2(2)
        setCondensedNumber2Done(true)
        break
      // 4
      case randomNumberA2 > 8 && randomNumberA2 < 13:
        setCondensedNumberA2(3)
        setCondensedNumber2Done(true)
        break
      // 14
      case randomNumberA2 > 12 && randomNumberA2 < 27:
        setCondensedNumberA2(4)
        setCondensedNumber2Done(true)
        break
      // 14
      case randomNumberA2 > 26 && randomNumberA2 < 41:
        setCondensedNumberA2(5)
        setCondensedNumber2Done(true)
        break
      // 14
      case randomNumberA2 > 40 && randomNumberA2 < 55:
        setCondensedNumberA2(6)
        setCondensedNumber2Done(true)
        break
      // 14
      case randomNumberA2 > 54 && randomNumberA2 < 69:
        setCondensedNumberA2(7)
        setCondensedNumber2Done(true)
        break
      // 14
      case randomNumberA2 > 68 && randomNumberA2 < 83:
        setCondensedNumberA2(8)
        setCondensedNumber2Done(true)
        break
      // 14
      case randomNumberA2 > 82 && randomNumberA2 < 97:
        setCondensedNumberA2(9)
        setCondensedNumber2Done(true)
        break
      // 2
      case randomNumberA2 > 96 && randomNumberA2 < 99:
        setCondensedNumberA2(
          triggerCondensePreJackpotNumber || jackpotWon || freeSpinPlayOn
            ? 1
            : 10
        )
        setCondensedNumber2Done(true)
        break
      // 1
      case randomNumberA2 > 98 && randomNumberA2 < 100:
        setCondensedNumberA2(
          triggerCondensePreJackpotNumber || jackpotWon || freeSpinPlayOn
            ? 2
            : 11
        )
        setCondensedNumber2Done(true)
        break
      default:
        break
    }
  }

  const condensedRandomNumberA3 = () => {
    switch (true) {
      // 4
      case randomNumberA3 > 0 && randomNumberA3 < 5:
        setCondensedNumberA3(1)
        setCondensedNumber3Done(true)
        break
      // 4
      case randomNumberA3 > 4 && randomNumberA3 < 9:
        setCondensedNumberA3(2)
        setCondensedNumber3Done(true)
        break
      // 4
      case randomNumberA3 > 8 && randomNumberA3 < 13:
        setCondensedNumberA3(3)
        setCondensedNumber3Done(true)
        break
      // 14
      case randomNumberA3 > 12 && randomNumberA3 < 27:
        setCondensedNumberA3(4)
        setCondensedNumber3Done(true)
        break
      // 14
      case randomNumberA3 > 26 && randomNumberA3 < 41:
        setCondensedNumberA3(5)
        setCondensedNumber3Done(true)
        break
      // 14
      case randomNumberA3 > 40 && randomNumberA3 < 55:
        setCondensedNumberA3(6)
        setCondensedNumber3Done(true)
        break
      // 14
      case randomNumberA3 > 54 && randomNumberA3 < 69:
        setCondensedNumberA3(7)
        setCondensedNumber3Done(true)
        break
      // 14
      case randomNumberA3 > 68 && randomNumberA3 < 83:
        setCondensedNumberA3(8)
        setCondensedNumber3Done(true)
        break
      // 14
      case randomNumberA3 > 82 && randomNumberA3 < 97:
        setCondensedNumberA3(9)
        setCondensedNumber3Done(true)
        break
      // 2
      case randomNumberA3 > 96 && randomNumberA3 < 99:
        setCondensedNumberA3(
          triggerCondensePreJackpotNumber || jackpotWon || freeSpinPlayOn
            ? 1
            : 10
        )
        setCondensedNumber3Done(true)
        break
      // 1
      case randomNumberA3 > 98 && randomNumberA3 < 100:
        setCondensedNumberA3(
          triggerCondensePreJackpotNumber || jackpotWon || freeSpinPlayOn
            ? 2
            : 11
        )
        setCondensedNumber3Done(true)
        break
      default:
        break
    }
  }

  const condensedRandomNumberA4 = () => {
    switch (true) {
      // 4
      case randomNumberA4 > 0 && randomNumberA4 < 5:
        setCondensedNumberA4(1)
        setCondensedNumber4Done(true)
        break
      // 4
      case randomNumberA4 > 4 && randomNumberA4 < 9:
        setCondensedNumberA4(2)
        setCondensedNumber4Done(true)
        break
      // 4
      case randomNumberA4 > 8 && randomNumberA4 < 13:
        setCondensedNumberA4(3)
        setCondensedNumber4Done(true)
        break
      // 14
      case randomNumberA4 > 12 && randomNumberA4 < 27:
        setCondensedNumberA4(4)
        setCondensedNumber4Done(true)
        break
      // 14
      case randomNumberA4 > 26 && randomNumberA4 < 41:
        setCondensedNumberA4(5)
        setCondensedNumber4Done(true)
        break
      // 14
      case randomNumberA4 > 40 && randomNumberA4 < 55:
        setCondensedNumberA4(6)
        setCondensedNumber4Done(true)
        break
      // 14
      case randomNumberA4 > 54 && randomNumberA4 < 69:
        setCondensedNumberA4(7)
        setCondensedNumber4Done(true)
        break
      // 14
      case randomNumberA4 > 68 && randomNumberA4 < 83:
        setCondensedNumberA4(8)
        setCondensedNumber4Done(true)
        break
      // 14
      case randomNumberA4 > 82 && randomNumberA4 < 97:
        setCondensedNumberA4(9)
        setCondensedNumber4Done(true)
        break
      // 2
      case randomNumberA4 > 96 && randomNumberA4 < 99:
        setCondensedNumberA4(
          triggerCondensePreJackpotNumber || jackpotWon || freeSpinPlayOn
            ? 1
            : 10
        )
        setCondensedNumber4Done(true)
        break
      // 1
      case randomNumberA4 > 98 && randomNumberA4 < 100:
        setCondensedNumberA4(
          triggerCondensePreJackpotNumber || jackpotWon || freeSpinPlayOn
            ? 2
            : 1
        )
        setCondensedNumber4Done(true)
        break
      default:
        break
    }
  }

  const condensedRandomNumberA5 = () => {
    switch (true) {
      // 4
      case randomNumberA5 > 0 && randomNumberA5 < 5:
        setCondensedNumberA5(1)
        setCondensedNumber5Done(true)
        break
      // 4
      case randomNumberA5 > 4 && randomNumberA5 < 9:
        setCondensedNumberA5(2)
        setCondensedNumber5Done(true)
        break
      // 4
      case randomNumberA5 > 8 && randomNumberA5 < 13:
        setCondensedNumberA5(3)
        setCondensedNumber5Done(true)
        break
      // 14
      case randomNumberA5 > 12 && randomNumberA5 < 27:
        setCondensedNumberA5(4)
        setCondensedNumber5Done(true)
        break
      // 14
      case randomNumberA5 > 26 && randomNumberA5 < 41:
        setCondensedNumberA5(5)
        setCondensedNumber5Done(true)
        break
      // 14
      case randomNumberA5 > 40 && randomNumberA5 < 55:
        setCondensedNumberA5(6)
        setCondensedNumber5Done(true)
        break
      // 14
      case randomNumberA5 > 54 && randomNumberA5 < 69:
        setCondensedNumberA5(7)
        setCondensedNumber5Done(true)
        break
      // 14
      case randomNumberA5 > 68 && randomNumberA5 < 83:
        setCondensedNumberA5(8)
        setCondensedNumber5Done(true)
        break
      // 14
      case randomNumberA5 > 82 && randomNumberA5 < 97:
        setCondensedNumberA5(9)
        setCondensedNumber5Done(true)
        break
      // 2
      case randomNumberA5 > 96 && randomNumberA5 < 99:
        setCondensedNumberA5(
          triggerCondensePreJackpotNumber || jackpotWon || freeSpinPlayOn
            ? 1
            : 10
        )
        setCondensedNumber5Done(true)
        break
      // 1
      case randomNumberA5 > 98 && randomNumberA5 < 100:
        setCondensedNumberA5(
          triggerCondensePreJackpotNumber || jackpotWon || freeSpinPlayOn
            ? 2
            : 11
        )
        setCondensedNumber5Done(true)
        break
      default:
        break
    }
  }

  return null
}

export default CondenseRandomNumberA3
