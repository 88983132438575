import React, { useContext } from 'react'

import { Context as GameContext } from '../../../../context/GameContext'
import './scatterWinOverlay.css'

const ScatterWinOverlay = () => {
  const {
    state: { scatterWinOverlayShow },
  } = useContext(GameContext)

  const renderAnimation = () => {
    return (
      <div className="scatterWinOverlayAnimationBed">
        <div className="scatterWinOverlayAnimationBellBed">
          <div className="scatterWinOverlayBell">🔔</div>
        </div>
        <div className="scatterWinOverlayTextBed">
          <div className="scatterWinOverlayTextContainer">
            <div className="scatterWinOverlayText">10 x free spins!</div>
          </div>
        </div>
        <div className="scatterWinOverlayAnimationCloverBed">
          <div className="scatterWinOverlayClover">🍀</div>
        </div>
      </div>
    )
  }

  const renderContent = () => {
    if (!scatterWinOverlayShow) return null
    return (
      <div className="scatterWinOverlayBed">
        <div className="scatterWinOverlayTop"></div>
        <div className="scatterWinOverlayMiddle">
          <div className="scatterWinOverlayMiddleTop">
            <div className="scatterWinOverlayTopLineHidden">
              <div className="scatterWinOverlayBlinkDots">
                <span id="dot1" className="blink">
                  .
                </span>
                <span id="dot2" className="blink">
                  .
                </span>
                <span id="dot3" className="blink">
                  .
                </span>
                <span id="dot1" className="blink">
                  .
                </span>
                <span id="dot2" className="blink">
                  .
                </span>
                <span id="dot3" className="blink">
                  .
                </span>
                <span id="dot1" className="blink">
                  .
                </span>
                <span id="dot2" className="blink">
                  .
                </span>
                <span id="dot3" className="blink">
                  .
                </span>
                <span id="dot1" className="blink">
                  .
                </span>
                <span id="dot2" className="blink">
                  .
                </span>
                <span id="dot3" className="blink">
                  .
                </span>
                <span id="dot1" className="blink">
                  .
                </span>
                <span id="dot2" className="blink">
                  .
                </span>
                <span id="dot3" className="blink">
                  .
                </span>
                <span id="dot1" className="blink">
                  .
                </span>
                <span id="dot2" className="blink">
                  .
                </span>
                <span id="dot3" className="blink">
                  .
                </span>
              </div>
            </div>
          </div>
          <div className="scatterWinOverlayMiddleCenter">
            <div className="scatterWinOverlayMiddleLineHidden">
              {renderAnimation()}
            </div>
          </div>
          <div className="scatterWinOverlayMiddleBottom">
            <div className="scatterWinOverlayBottomLineHidden">
              <div className="scatterWinOverlayBlinkDots">
                <span id="dot1" className="blink">
                  .
                </span>
                <span id="dot2" className="blink">
                  .
                </span>
                <span id="dot3" className="blink">
                  .
                </span>
                <span id="dot1" className="blink">
                  .
                </span>
                <span id="dot2" className="blink">
                  .
                </span>
                <span id="dot3" className="blink">
                  .
                </span>
                <span id="dot1" className="blink">
                  .
                </span>
                <span id="dot2" className="blink">
                  .
                </span>
                <span id="dot3" className="blink">
                  .
                </span>
                <span id="dot1" className="blink">
                  .
                </span>
                <span id="dot2" className="blink">
                  .
                </span>
                <span id="dot3" className="blink">
                  .
                </span>
                <span id="dot1" className="blink">
                  .
                </span>
                <span id="dot2" className="blink">
                  .
                </span>
                <span id="dot3" className="blink">
                  .
                </span>
                <span id="dot1" className="blink">
                  .
                </span>
                <span id="dot2" className="blink">
                  .
                </span>
                <span id="dot3" className="blink">
                  .
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="scatterWinOverlayBottom"></div>
      </div>
    )
  }

  return renderContent()
}

export default ScatterWinOverlay
