import { useState, useContext, useEffect } from 'react'

import { Context as UserContext } from '../../../../../context/UserContext'
import { Context as PayoutContext } from '../../../../../context/PayoutContext'
import { Context as BaitContext } from '../../../../../context/BaitContext'

const SiteBait = () => {
  const [siteBaitStartValue, setSiteBaitStartValue] = useState(0)

  const [siteBaitBalanceCounter, setSiteBaitBalanceCounter] = useState(0)

  const [siteBait, setSiteBait] = useState(0)

  const {
    state: {
      siteBaitData,
      siteBaitBalance,
      siteBaitRelease,
      siteBaitBalanceRemaining,
    },
    updateSiteBait,
    setSiteBaitReleaseBalance,
    setSiteBaitBalance,
    setSiteBaitRelease,
    setSiteBaitReleaseDB,
  } = useContext(BaitContext)

  const {
    state: { user },
  } = useContext(UserContext)

  const {
    state: { addCreditAverage },
  } = useContext(PayoutContext)

  // useEffect(() => {
  //   if (siteBaitBalanceRemaining) {
  //     setSiteBaitBalance(siteBaitBalanceRemaining)
  //   }
  // }, [siteBaitBalanceRemaining, siteBaitBalance])

  useEffect(() => {
    if (siteBaitData) {
      const { siteBaitRelease, siteBaitValue } = siteBaitData
      setSiteBaitRelease(siteBaitRelease)
      setSiteBait(siteBaitValue)
      if (siteBaitRelease) {
        setSiteBaitReleaseDB({ siteBaitRelease: true })
      }
    }
  }, [siteBaitData, addCreditAverage])

  useEffect(() => {
    if (siteBaitRelease && siteBaitBalanceCounter === 0) {
      setSiteBaitStartValue(siteBait)
      setSiteBaitBalance(siteBait)
      setSiteBaitReleaseBalance(true)
      setSiteBaitBalanceCounter(1)
    }
  }, [siteBaitRelease, siteBait, siteBaitBalanceCounter])

  useEffect(() => {
    let siteBaitBalancePercentage = (siteBaitBalance / siteBaitStartValue) * 100
    console.log(`siteBaitBalancePercentage`, siteBaitBalancePercentage)
    if (
      siteBaitBalancePercentage < 20 ||
      siteBaitBalancePercentage === Infinity
    ) {
      console.log(`END SITE BAIT`)
      setSiteBaitRelease(false)
      setSiteBaitReleaseBalance(false)
      updateSiteBait({
        siteBaitPayoutEnd: true,
        siteNumber: user.siteNumber,
      })
      setSiteBaitBalance(0)
      setSiteBaitBalanceCounter(0)
    }
  }, [siteBaitBalance, siteBaitStartValue])

  return null
}

export default SiteBait
