import createDataContext from './createDataContext'

// Reducer
const NavContext = (state, action) => {
  switch (action.type) {
    case 'SET_VISIBLE_COMPONENT':
      return { ...state, visibleComponent: action.payload }
    default:
      return state
  }
}

// Actions
const setVisibleComponent = (dispatch) => async (value) => {
  dispatch({ type: 'SET_VISIBLE_COMPONENT', payload: value })
}

export const { Context, Provider } = createDataContext(
  NavContext,
  {
    setVisibleComponent,
  },
  // Initial state
  {
    visibleComponent: null,
  }
)
