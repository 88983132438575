import axios from 'axios'

const instance = axios.create({
  baseURL: 'https://react-lucky-server-e88da0a4b9f6.herokuapp.com',
})

instance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  (err) => {
    return Promise.reject(err)
  }
)

export default instance
