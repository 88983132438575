import createDataContext from './createDataContext'
import ngrokApi from '../api/ngrokApi'

// Reducer
const PromoPrizeWheelContext = (state, action) => {
  switch (action.type) {
    case 'LOADING':
      return { ...state, loading: true }
    case 'SET_PROMO_PRIZE_WHEEL_SHOW':
      return { ...state, promoPrizeWheelShow: action.payload }
    case 'FETCH_SITE_DATA':
      return { ...state, siteData: action.payload, loading: false }
    case 'SET_PROMO_PRIZE_WHEEL_USED':
      return { ...state, promoWheelUsed: action.payload }
    default:
      return state
  }
}

// Actions
const setPromoPrizeWheelShow = (dispatch) => async (value) => {
  dispatch({ type: 'SET_PROMO_PRIZE_WHEEL_SHOW', payload: value })
}

const fetchSiteData = (dispatch) => async (data) => {
  dispatch({ type: 'LOADING', payload: true })
  try {
    const response = await ngrokApi.post('/site/fetch-site-data', data)
    dispatch({ type: 'FETCH_SITE_DATA', payload: response.data })
    return
  } catch (error) {
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const setPromoPrizeWheelUsed = (dispatch) => async (value) => {
  dispatch({ type: 'SET_PROMO_PRIZE_WHEEL_USED', payload: value })
}

const decuctPromoWheelEntry = (dispatch) => async (data) => {
  dispatch({ type: 'LOADING', payload: true })
  try {
    await ngrokApi.post('/play/decuct-promo-wheel-entry', data)
    return
  } catch (error) {
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

export const { Context, Provider } = createDataContext(
  PromoPrizeWheelContext,
  {
    setPromoPrizeWheelShow,
    fetchSiteData,
    setPromoPrizeWheelUsed,
    decuctPromoWheelEntry,
  },
  // Initial state
  {
    loading: false,
    promoPrizeWheelShow: false,
    siteData: null,
    promoWheelUsed: false,
  }
)
