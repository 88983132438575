import { useContext, useEffect, useState } from 'react'

import { Context as GameContext } from '../../../../context/GameContext'
import { Context as UserContext } from '../../../../context/UserContext'
import { Context as PayoutContext } from '../../../../context/PayoutContext'
import { Context as BaitContext } from '../../../../context/BaitContext'

import Jackpot from './progresivesAndJackpot/Jackpot'
import ProgresiveJackpot1 from './progresivesAndJackpot/ProgresiveJackpot1'
import ClientProgresive1 from './progresivesAndJackpot/ClientProgresive1'
import ClientProgresive2 from './progresivesAndJackpot/ClientProgresive2'
import ClientProgresive3 from './progresivesAndJackpot/ClientProgresive3'
import SiteBait from './bait/SiteBait'

const CalculateResult = () => {
  const [checkDone, setCheckDone] = useState(false)
  const [rowADone, setRowADone] = useState(false)
  const [rowBDone, setRowBDone] = useState(false)
  const [rowCDone, setRowCDone] = useState(false)
  const [winReserveBalanceCount, setWinReserveBalanceCount] = useState(0)
  const [winReserveBalanceStartValue, setWinReserveBalanceStartValue] =
    useState(0)
  const [
    winReserveBalanceStartValueCount,
    setWinReserveBalanceStartValueCount,
  ] = useState(0)
  const [winReserveBalancePercentage, setWinReserveBalancePercentage] =
    useState(0)
  const [
    winReserveBalancePercentageCount,
    setWinReserveBalancePercentageCount,
  ] = useState(0)
  const [gameCreditPercentage, setGameCreditPercentage] = useState(0)
  const [gameCreditPercentageCount, setGameCreditPercentageCount] = useState(0)
  const [betTotalVsPrizePercentage, setBetTotalVsPrizePercentage] = useState(0)
  const [clientProgresive1PayoutCounter, setClientProgresive1PayoutCounter] =
    useState(0)
  const [clientProgresive2PayoutCounter, setClientProgresive2PayoutCounter] =
    useState(0)
  const [clientProgresive3PayoutCounter, setClientProgresive3PayoutCounter] =
    useState(0)
  const [progresiveJackpotPayoutCounter, setProgresiveJackpotPayoutCounter] =
    useState(0)
  const [siteBaitPayoutCounter, setSiteBaitPayoutCounter] = useState(0)

  const {
    state: {
      nameOfGame,
      numberOfLines,
      betValue,
      newGameOpenBalance,
      condensedNumberA1,
      condensedNumberA2,
      condensedNumberA3,
      condensedNumberA4,
      condensedNumberA5,
      condensedNumberB1,
      condensedNumberB2,
      condensedNumberB3,
      condensedNumberB4,
      condensedNumberB5,
      condensedNumberC1,
      condensedNumberC2,
      condensedNumberC3,
      condensedNumberC4,
      condensedNumberC5,
      condenseRandomNumberADone,
      condenseRandomNumberBDone,
      condenseRandomNumberCDone,
      rowAResult,
      rowBResult,
      rowCResult,
      freeSpinsTotal,
      reelSpinning4,
      reelSpinning5,
      rowA,
      rowB,
      rowC,
      rowAPrizeArray,
      rowBPrizeArray,
      rowCPrizeArray,
      rowAPrize,
      rowBPrize,
      rowCPrize,
      prize,
      gameCredits,
      prizeWonDoneRowA,
      prizeWonDoneRowB,
      prizeWonDoneRowC,
      noPrizeDoneRowA,
      noPrizeDoneRowB,
      noPrizeDoneRowC,
      progresiveJackpot1ReleaseBalance,
      progresiveJackpot1Balance,
      setReelSpinning5,
    },
    setRowA,
    setRowB,
    setRowC,
    setRowAResult,
    setRowBResult,
    setRowCResult,
    setRowAPrize,
    setRowBPrize,
    setRowCPrize,
    setPrize,
    setGameCredits,
    recordGamePlay,
    setProgresiveJackpot1Balance,
  } = useContext(GameContext)

  const {
    state: { user },
  } = useContext(UserContext)

  const {
    state: {
      payoutOverLimitStartReplay,
      siteFloorCredits,
      siteBankValue,
      winReserveBalance,
      clientProgresive1Release,
      clientProgresive1Balance,
      clientProgresive2Release,
      clientProgresive2Balance,
      clientProgresive3Release,
      clientProgresive3Balance,
      newGameOpeningBalanceCount,
      updateCreditCounter,
    },
    setPayoutUnderLimitCheckDone,
    setPayoutOverLimitStartReplay,
    setWinReserveBalance,
    updateProgresiveJackpotBalanceRemaining,
    setClientProgresive1BalanceRemaining,
    setClientProgresive2BalanceRemaining,
    setClientProgresive3BalanceRemaining,
    setClientProgresive1Balance,
    setClientProgresive2Balance,
    setClientProgresive3Balance,
    setNewGameOpeningBalanceCount,
    setUpdateCreditCounter,
  } = useContext(PayoutContext)

  const {
    state: { siteBaitReleaseBalance, siteBaitBalance },
    updateSiteBaitBalanceRemaining,
    setSiteBaitBalance,
  } = useContext(BaitContext)

  useEffect(() => {
    if (!setReelSpinning5) {
      setSiteBaitPayoutCounter(0)
    }
  }, [setReelSpinning5])

  useEffect(() => {
    if (newGameOpeningBalanceCount < 1) {
      if (newGameOpenBalance > 0) {
        let num1 = newGameOpenBalance * 0.5 // Percentage set here
        let num2 = winReserveBalance
        let sum = num1 + num2
        setWinReserveBalance(sum)
        setNewGameOpeningBalanceCount(1)
      }
    }
  }, [newGameOpeningBalanceCount, newGameOpenBalance])

  useEffect(() => {
    if (
      winReserveBalance > 0 &&
      winReserveBalanceStartValue > 0 &&
      gameCredits > 0 &&
      newGameOpenBalance > 0
    ) {
      if (winReserveBalancePercentageCount < 1) {
        setWinReserveBalancePercentage(
          (winReserveBalance / winReserveBalanceStartValue) * 100
        )
        setWinReserveBalancePercentageCount(1)
      }
      if (gameCreditPercentageCount < 1) {
        setGameCreditPercentage((gameCredits / newGameOpenBalance) * 100)
        setGameCreditPercentageCount(1)
      }
    }
  }, [
    winReserveBalancePercentageCount,
    gameCreditPercentageCount,
    winReserveBalance,
    winReserveBalanceStartValue,
    gameCredits,
    newGameOpenBalance,
  ])

  useEffect(() => {
    if (winReserveBalance > 0 && winReserveBalanceStartValueCount < 1) {
      setWinReserveBalanceStartValue(winReserveBalance)
      setWinReserveBalanceStartValueCount(1)
    }
  }, [winReserveBalance, winReserveBalanceStartValueCount])

  useEffect(() => {
    if (
      condenseRandomNumberADone &&
      condenseRandomNumberBDone &&
      condenseRandomNumberCDone
    ) {
      setRowA([
        condensedNumberA1,
        condensedNumberA2,
        condensedNumberA3,
        condensedNumberA4,
        condensedNumberA5,
      ])
      setRowADone(true)
      setRowB([
        condensedNumberB1,
        condensedNumberB2,
        condensedNumberB3,
        condensedNumberB4,
        condensedNumberB5,
      ])
      setRowBDone(true)
      setRowC([
        condensedNumberC1,
        condensedNumberC2,
        condensedNumberC3,
        condensedNumberC4,
        condensedNumberC5,
      ])
      setRowCDone(true)
    }
    // eslint-disable-next-line
  }, [
    condenseRandomNumberADone,
    condenseRandomNumberBDone,
    condenseRandomNumberCDone,
  ])

  useEffect(() => {
    if (rowADone && rowBDone && rowADone) {
      let countsA = {}
      let countsB = {}
      let countsC = {}
      rowA.forEach((x) => {
        countsA[x] = (countsA[x] || 0) + 1
      })
      rowB.forEach((x) => {
        countsB[x] = (countsB[x] || 0) + 1
      })
      rowC.forEach((x) => {
        countsC[x] = (countsC[x] || 0) + 1
      })
      let countsSymbolA = Object.keys(countsA)
      let countsQantityA = Object.values(countsA)
      let countsSymbolB = Object.keys(countsB)
      let countsQantityB = Object.values(countsB)
      let countsSymbolC = Object.keys(countsC)
      let countsQantityC = Object.values(countsC)
      if (countsSymbolA.length > 0) {
        setRowAResult([
          { symbol: countsSymbolA[0], quantity: countsQantityA[0] },
          { symbol: countsSymbolA[1], quantity: countsQantityA[1] },
          { symbol: countsSymbolA[2], quantity: countsQantityA[2] },
          { symbol: countsSymbolA[3], quantity: countsQantityA[3] },
          { symbol: countsSymbolA[4], quantity: countsQantityA[4] },
        ])
      }
      if (countsSymbolB.length > 0) {
        setRowBResult([
          { symbol: countsSymbolB[0], quantity: countsQantityB[0] },
          { symbol: countsSymbolB[1], quantity: countsQantityB[1] },
          { symbol: countsSymbolB[2], quantity: countsQantityB[2] },
          { symbol: countsSymbolB[3], quantity: countsQantityB[3] },
          { symbol: countsSymbolB[4], quantity: countsQantityB[4] },
        ])
      }
      if (countsSymbolC.length > 0) {
        setRowCResult([
          { symbol: countsSymbolC[0], quantity: countsQantityC[0] },
          { symbol: countsSymbolC[1], quantity: countsQantityC[1] },
          { symbol: countsSymbolC[2], quantity: countsQantityC[2] },
          { symbol: countsSymbolC[3], quantity: countsQantityC[3] },
          { symbol: countsSymbolC[4], quantity: countsQantityC[4] },
        ])
      }
    }
    // eslint-disable-next-line
  }, [rowADone, rowBDone, rowCDone])

  useEffect(() => {
    if (prizeWonDoneRowA && numberOfLines > 1) {
      let prizesAdded = 0
      rowAPrizeArray.map((prize) => {
        prizesAdded = prizesAdded + prize.prizeValue
      })
      setRowAPrize(prizesAdded)
    }
  }, [prizeWonDoneRowA, rowAPrizeArray, numberOfLines])

  useEffect(() => {
    if (prizeWonDoneRowB) {
      let prizesAdded = 0
      rowBPrizeArray.map((prize) => {
        prizesAdded = prizesAdded + prize.prizeValue
      })
      setRowBPrize(prizesAdded)
    }
  }, [prizeWonDoneRowB, rowBPrizeArray])

  useEffect(() => {
    if (prizeWonDoneRowC && numberOfLines === 3) {
      let prizesAdded = 0
      rowCPrizeArray.map((prize) => {
        prizesAdded = prizesAdded + prize.prizeValue
      })
      setRowCPrize(prizesAdded)
    }
  }, [prizeWonDoneRowC, rowCPrizeArray, numberOfLines])

  useEffect(() => {
    if (noPrizeDoneRowA && noPrizeDoneRowB && noPrizeDoneRowC) {
      setPayoutUnderLimitCheckDone(true)
    }
  }, [noPrizeDoneRowA, noPrizeDoneRowB, noPrizeDoneRowC])

  useEffect(() => {
    let betTotal = betValue * numberOfLines
    let selected = ''
    if (siteBaitReleaseBalance) {
      selected = 'siteBaitReleaseBalance'
    }
    if (progresiveJackpot1ReleaseBalance) {
      selected = 'progresiveJackpot1ReleaseBalance'
    }
    if (clientProgresive1Release) {
      selected = 'clientProgresive1Release'
    }
    if (clientProgresive2Release) {
      selected = 'clientProgresive2Release'
    }
    if (clientProgresive3Release) {
      selected = 'clientProgresive3Release'
    }
    if (
      !siteBaitReleaseBalance &&
      !progresiveJackpot1ReleaseBalance &&
      !clientProgresive1Release &&
      !clientProgresive2Release &&
      !clientProgresive3Release
    ) {
      selected = 'noProgresive'
    }
    console.log(`selected`, selected)
    switch (selected) {
      case 'siteBaitReleaseBalance':
        if (prizeWonDoneRowA || prizeWonDoneRowB || prizeWonDoneRowC) {
          let sum = 0
          if (rowAPrize > 0) sum = sum + rowAPrize
          if (rowBPrize > 0) sum = sum + rowBPrize
          if (rowCPrize > 0) sum = sum + rowCPrize
          setBetTotalVsPrizePercentage((sum / betTotal) * 100)
          if (sum > 0) {
            if (sum > siteBaitBalance) {
              setPayoutOverLimitStartReplay(true)
              return
            }
            console.log('sum', sum)
            console.log('siteBaitBalance', siteBaitBalance)
            console.log('siteBaitPayoutCounter', siteBaitPayoutCounter)
            if (sum < siteBaitBalance && siteBaitPayoutCounter === 0) {
              console.log(`hello world...1`)
              let siteBaitBalanceSum = 0
              siteBaitBalanceSum = siteBaitBalance - sum
              siteBaitBalanceSum = Math.floor(siteBaitBalanceSum)
              setPrize(sum)
              updateSiteBaitBalanceRemaining({
                siteNumber: user.siteNumber,
                amountToDeduct: sum,
              })
              setSiteBaitBalance(siteBaitBalanceSum)
              setPayoutUnderLimitCheckDone(true)
              setSiteBaitPayoutCounter(1)
              console.log('hello worls..2')
            }
          }
          setCheckDone(true)
        }
        break
      case 'progresiveJackpot1ReleaseBalance':
        if (prizeWonDoneRowA || prizeWonDoneRowB || prizeWonDoneRowC) {
          let sum = 0
          if (rowAPrize > 0) sum = sum + rowAPrize
          if (rowBPrize > 0) sum = sum + rowBPrize
          if (rowCPrize > 0) sum = sum + rowCPrize
          setBetTotalVsPrizePercentage((sum / betTotal) * 100)
          if (sum > 0) {
            if (sum > progresiveJackpot1Balance) {
              setPayoutOverLimitStartReplay(true)
              return
            }
            if (
              sum < progresiveJackpot1Balance &&
              progresiveJackpotPayoutCounter === 0
            ) {
              let progresiveJackpot1BalanceSum = 0
              progresiveJackpot1BalanceSum = progresiveJackpot1Balance - sum
              progresiveJackpot1BalanceSum = Math.floor(
                progresiveJackpot1BalanceSum
              )
              setPrize(sum)
              updateProgresiveJackpotBalanceRemaining({
                siteNumber: user.siteNumber,
                amountToDeduct: sum,
              })
              setProgresiveJackpot1Balance(progresiveJackpot1BalanceSum)
              setPayoutUnderLimitCheckDone(true)
              setProgresiveJackpotPayoutCounter(1)
            }
          }
          setCheckDone(true)
        }
        break
      case 'clientProgresive1Release':
        if (prizeWonDoneRowA || prizeWonDoneRowB || prizeWonDoneRowC) {
          let sum = 0
          if (rowAPrize > 0) sum = sum + rowAPrize
          if (rowBPrize > 0) sum = sum + rowBPrize
          if (rowCPrize > 0) sum = sum + rowCPrize
          setBetTotalVsPrizePercentage((sum / betTotal) * 100)
          if (sum > 0) {
            if (sum > clientProgresive1Balance) {
              setPayoutOverLimitStartReplay(true)
              return
            }
            if (
              sum < clientProgresive1Balance &&
              clientProgresive1PayoutCounter === 0
            ) {
              let clientProgresive1BalanceSum = 0
              clientProgresive1BalanceSum = clientProgresive1Balance - sum
              clientProgresive1BalanceSum = Math.floor(
                clientProgresive1BalanceSum
              )
              setPrize(sum)
              setClientProgresive1BalanceRemaining({
                clientProgresive1: clientProgresive1BalanceSum,
              })
              setClientProgresive1Balance(clientProgresive1BalanceSum)
              setPayoutUnderLimitCheckDone(true)
              setClientProgresive1PayoutCounter(1)
            }
          }
          setCheckDone(true)
        }
        break
      case 'clientProgresive2Release':
        if (prizeWonDoneRowA || prizeWonDoneRowB || prizeWonDoneRowC) {
          let sum = 0
          if (rowAPrize > 0) sum = sum + rowAPrize
          if (rowBPrize > 0) sum = sum + rowBPrize
          if (rowCPrize > 0) sum = sum + rowCPrize
          setBetTotalVsPrizePercentage((sum / betTotal) * 100)
          if (sum > 0) {
            if (sum > clientProgresive2Balance) {
              setPayoutOverLimitStartReplay(true)
            }
            if (
              sum < clientProgresive2Balance &&
              clientProgresive2PayoutCounter === 0
            ) {
              let clientProgresive2BalanceSum = 0
              clientProgresive2BalanceSum = clientProgresive2Balance - sum
              clientProgresive2BalanceSum = Math.floor(
                clientProgresive2BalanceSum
              )
              setPrize(sum)
              setClientProgresive2BalanceRemaining({
                clientProgresive2: clientProgresive2BalanceSum,
              })
              setClientProgresive2Balance(clientProgresive2BalanceSum)
              setPayoutUnderLimitCheckDone(true)
              setClientProgresive2PayoutCounter(1)
            }
          }
          setCheckDone(true)
        }
        break
      case 'clientProgresive3Release':
        if (prizeWonDoneRowA || prizeWonDoneRowB || prizeWonDoneRowC) {
          let sum = 0
          if (rowAPrize > 0) sum = sum + rowAPrize
          if (rowBPrize > 0) sum = sum + rowBPrize
          if (rowCPrize > 0) sum = sum + rowCPrize
          setBetTotalVsPrizePercentage((sum / betTotal) * 100)
          if (sum > 0) {
            if (sum > clientProgresive3Balance) {
              setPayoutOverLimitStartReplay(true)
              return
            }
            if (
              sum < clientProgresive3Balance &&
              clientProgresive3PayoutCounter === 0
            ) {
              let clientProgresive3BalanceSum = 0
              clientProgresive3BalanceSum = clientProgresive3Balance - sum
              clientProgresive3BalanceSum = Math.floor(
                clientProgresive3BalanceSum
              )
              setPrize(sum)
              setClientProgresive3BalanceRemaining({
                clientProgresive3: clientProgresive3BalanceSum,
              })
              setClientProgresive3Balance(clientProgresive3BalanceSum)
              setPayoutUnderLimitCheckDone(true)
              setClientProgresive3PayoutCounter(1)
            }
          }
          setCheckDone(true)
        }
        break
      case 'noProgresive':
        if (prizeWonDoneRowA || prizeWonDoneRowB || prizeWonDoneRowC) {
          let sum = 0
          if (rowAPrize > 0) sum = sum + rowAPrize
          if (rowBPrize > 0) sum = sum + rowBPrize
          if (rowCPrize > 0) sum = sum + rowCPrize
          setBetTotalVsPrizePercentage((sum / betTotal) * 100)
          switch (gameCreditPercentage > 50) {
            case true:
              if (winReserveBalancePercentage > 70) {
                if (betTotalVsPrizePercentage > 500) {
                  setPayoutOverLimitStartReplay(true)
                  return
                }
                if (sum > 0) {
                  if (sum >= winReserveBalance) {
                    setPayoutOverLimitStartReplay(true)
                    return
                  }
                  if (sum < winReserveBalance) {
                    setPrize(sum)
                    setPayoutUnderLimitCheckDone(true)
                    return
                  }
                }
                setCheckDone(true)
              }
              if (
                winReserveBalancePercentage < 71 &&
                winReserveBalancePercentage > 40
              ) {
                if (betTotalVsPrizePercentage > 750) {
                  setPayoutOverLimitStartReplay(true)
                  return
                }
                if (sum > 0) {
                  if (sum >= winReserveBalance) {
                    setPayoutOverLimitStartReplay(true)
                    return
                  }
                  if (sum < winReserveBalance) {
                    setPrize(sum)
                    setPayoutUnderLimitCheckDone(true)
                    return
                  }
                }
                setCheckDone(true)
              }
              if (
                winReserveBalancePercentage < 41 &&
                winReserveBalancePercentage > 20
              ) {
                if (betTotalVsPrizePercentage > 1000) {
                  setPayoutOverLimitStartReplay(true)
                  return
                }
                if (sum > 0) {
                  if (sum >= winReserveBalance) {
                    setPayoutOverLimitStartReplay(true)
                    return
                  }
                  if (sum < winReserveBalance) {
                    setPrize(sum)
                    setPayoutUnderLimitCheckDone(true)
                    return
                  }
                }
                setCheckDone(true)
              }
              if (
                winReserveBalancePercentage < 21 &&
                winReserveBalancePercentage > 0
              ) {
                if (betTotalVsPrizePercentage > 2000) {
                  setPayoutOverLimitStartReplay(true)
                  return
                }
                if (sum > 0) {
                  if (sum >= winReserveBalance) {
                    setPayoutOverLimitStartReplay(true)
                    return
                  }
                  if (sum < winReserveBalance) {
                    setPrize(sum)
                    setPayoutUnderLimitCheckDone(true)
                    return
                  }
                }
                setCheckDone(true)
              }
              break
            case false:
              if (winReserveBalancePercentage > 70) {
                if (betTotalVsPrizePercentage > 3000) {
                  setPayoutOverLimitStartReplay(true)
                  return
                }
                if (sum > 0) {
                  if (sum >= winReserveBalance) {
                    setPayoutOverLimitStartReplay(true)
                    return
                  }
                  if (sum < winReserveBalance) {
                    setPrize(sum)
                    setPayoutUnderLimitCheckDone(true)
                    return
                  }
                }
                setCheckDone(true)
              }
              if (
                winReserveBalancePercentage < 71 &&
                winReserveBalancePercentage > 40
              ) {
                if (betTotalVsPrizePercentage > 4000) {
                  setPayoutOverLimitStartReplay(true)
                  return
                }
                if (sum > 0) {
                  if (sum >= winReserveBalance) {
                    setPayoutOverLimitStartReplay(true)
                    return
                  }
                  if (sum < winReserveBalance) {
                    setPrize(sum)
                    setPayoutUnderLimitCheckDone(true)
                    return
                  }
                }
                setCheckDone(true)
              }
              if (
                winReserveBalancePercentage < 41 &&
                winReserveBalancePercentage > 20
              ) {
                if (betTotalVsPrizePercentage > 5000) {
                  setPayoutOverLimitStartReplay(true)
                  return
                }
                if (sum > 0) {
                  if (sum >= winReserveBalance) {
                    setPayoutOverLimitStartReplay(true)
                    return
                  }
                  if (sum < winReserveBalance) {
                    setPrize(sum)
                    setPayoutUnderLimitCheckDone(true)
                    return
                  }
                }
                setCheckDone(true)
              }
              if (
                winReserveBalancePercentage < 21 &&
                winReserveBalancePercentage > 0
              ) {
                if (betTotalVsPrizePercentage > 6000) {
                  setPayoutOverLimitStartReplay(true)
                  return
                }
                if (sum > 0) {
                  if (sum >= winReserveBalance) {
                    setPayoutOverLimitStartReplay(true)
                    return
                  }
                  if (sum < winReserveBalance) {
                    setPrize(sum)
                    setPayoutUnderLimitCheckDone(true)
                    return
                  }
                }
                setCheckDone(true)
              }
            default:
              break
          }
        }
      default:
        break
    }
  }, [
    siteBaitBalance,
    progresiveJackpot1Balance,
    clientProgresive1Release,
    clientProgresive1Balance,
    clientProgresive2Release,
    clientProgresive2Balance,
    clientProgresive3Release,
    clientProgresive3Balance,
    siteBaitReleaseBalance,
    progresiveJackpot1ReleaseBalance,
    winReserveBalance,
    prizeWonDoneRowA,
    prizeWonDoneRowB,
    prizeWonDoneRowC,
    rowAPrize,
    rowBPrize,
    rowCPrize,
    gameCreditPercentage,
    winReserveBalancePercentage,
    betTotalVsPrizePercentage,
    siteBaitPayoutCounter,
    progresiveJackpotPayoutCounter,
    clientProgresive1PayoutCounter,
    clientProgresive2PayoutCounter,
    clientProgresive3PayoutCounter,
  ])

  useEffect(() => {
    if (updateCreditCounter < 1) {
      if (prize > 0 && !reelSpinning5) {
        let sum = 0
        sum = gameCredits + prize
        setGameCredits(sum)
        setUpdateCreditCounter(1)
        sum = 0
      }
    }
  }, [updateCreditCounter, prize, reelSpinning5, gameCredits])

  useEffect(() => {
    if (
      prize > 0 &&
      !reelSpinning5 &&
      !siteBaitReleaseBalance &&
      !progresiveJackpot1ReleaseBalance &&
      !clientProgresive1Release &&
      !clientProgresive2Release &&
      winReserveBalanceCount < 1
    ) {
      setWinReserveBalance(winReserveBalance - prize)
      setWinReserveBalanceCount(1)
    }
  }, [
    winReserveBalance,
    winReserveBalanceCount,
    prize,
    reelSpinning5,
    siteBaitReleaseBalance,
    progresiveJackpot1ReleaseBalance,
    clientProgresive1Release,
    clientProgresive2Release,
  ])

  useEffect(() => {
    if (!reelSpinning4 && checkDone) {
      recordGamePlay({
        siteNumber: user.siteNumber,
        nameOfGame,
        numberOfLines,
        betValue,
        betTotal: betValue * numberOfLines,
        rowAPrize,
        rowBPrize,
        rowCPrize,
        prize,
        rowAResult,
        rowBResult,
        rowCResult,
        freeSpinsTotal,
      })
    }
  }, [
    user,
    nameOfGame,
    numberOfLines,
    betValue,
    rowAPrize,
    rowBPrize,
    rowCPrize,
    prize,
    reelSpinning4,
    checkDone,
    rowAResult,
    rowBResult,
    rowCResult,
    freeSpinsTotal,
  ])

  useEffect(() => {
    if (prize > 0) {
      recordGamePlay({
        siteNumber: user.siteNumber,
        nameOfGame,
        numberOfLines,
        betValue,
        betTotal: betValue * numberOfLines,
        rowAPrize,
        rowBPrize,
        rowCPrize,
        prize,
        rowAResult,
        rowBResult,
        rowCResult,
        freeSpinsTotal,
      })
    }
  }, [
    user,
    nameOfGame,
    numberOfLines,
    betValue,
    rowAPrize,
    rowBPrize,
    rowCPrize,
    prize,
    reelSpinning4,
    checkDone,
    rowAResult,
    rowBResult,
    rowCResult,
    freeSpinsTotal,
  ])

  useEffect(() => {
    if (payoutOverLimitStartReplay || !reelSpinning5) {
      setRowA([])
      setRowB([])
      setRowC([])
      setRowAResult([])
      setRowBResult([])
      setRowCResult([])
      setRowADone(false)
      setRowBDone(false)
      setRowCDone(false)
      setPayoutOverLimitStartReplay(false)
      setPayoutUnderLimitCheckDone(false)
      setSiteBaitPayoutCounter(0)
      setProgresiveJackpotPayoutCounter(0)
      setClientProgresive1PayoutCounter(0)
      setClientProgresive2PayoutCounter(0)
      setClientProgresive3PayoutCounter(0)
      setWinReserveBalanceCount(0)
      setWinReserveBalancePercentageCount(0)
      setGameCreditPercentageCount(0)
    }
  }, [payoutOverLimitStartReplay, reelSpinning5])

  return (
    <>
      <Jackpot />
      <ProgresiveJackpot1 />
      <ClientProgresive1 />
      <ClientProgresive2 />
      <ClientProgresive3 />
      <SiteBait />
    </>
  )
}

export default CalculateResult

// Note: check if user start credits effects the paylout balance after winning a big jackpot
