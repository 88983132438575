import { useState, useContext, useEffect } from 'react'

import { Context as PayoutContext } from '../../../../../context/PayoutContext'

const ClientProgresive2 = () => {
  const [clientProgresive2StartValue, setClientProgresive2StartValue] =
    useState(0)

  const [clientProgresive2BalanceCounter, setClientProgresive2BalanceCounter] =
    useState(0)

  const {
    state: {
      clientProgresiveData,
      clientProgresive2,
      clientProgresive2Release,
      clientProgresive2Balance,
      addCreditAverage,
    },
    updateClientProgresive,
    setClientProgresive2,
    setClientProgresive2Release,
    setClientProgresive2Balance,
    setClientProgresiveReleaseDB,
  } = useContext(PayoutContext)

  useEffect(() => {
    if (clientProgresiveData) {
      const { clientProgresive2Release, clientProgresive2 } =
        clientProgresiveData
      setClientProgresive2Release(clientProgresive2Release)
      setClientProgresive2(clientProgresive2)
      let triggerValue = addCreditAverage * 20
      if (triggerValue > 0) {
        if (clientProgresive2 > triggerValue) {
          setClientProgresive2Release(true)
          setClientProgresiveReleaseDB({ clientProgresive2Release: true })
        }
      }
    }
  }, [clientProgresiveData, addCreditAverage])

  useEffect(() => {
    if (clientProgresive2Release && clientProgresive2BalanceCounter === 0) {
      setClientProgresive2StartValue(clientProgresive2)
      setClientProgresive2Balance(clientProgresive2)
      setClientProgresive2Release(true)
      setClientProgresive2BalanceCounter(1)
    }
  }, [
    clientProgresive2Release,
    clientProgresive2,
    clientProgresive2BalanceCounter,
  ])

  useEffect(() => {
    let clientProgresive2BalancePercentage =
      (clientProgresive2Balance / clientProgresive2StartValue) * 100
    if (clientProgresive2BalancePercentage < 20) {
      setClientProgresive2Release(false)
      setClientProgresiveReleaseDB({ clientProgresive2Release: false })
      updateClientProgresive({
        clientProgresive2PayoutEnd: true,
      })
      setClientProgresive2Balance(0)
      setClientProgresive2BalanceCounter(0)
    }
  }, [clientProgresive2Balance, clientProgresive2StartValue])

  return null
}

export default ClientProgresive2
