import React, { useState, useContext, useEffect } from 'react'

import { Context as GameContext } from '../../../../../context/GameContext'
import gif001 from '../../../../../assets/images/gifs/soSad/soSadGif001.gif'
import gif002 from '../../../../../assets/images/gifs/soSad/soSadGif002.gif'
import gif003 from '../../../../../assets/images/gifs/soSad/soSadGif003.gif'
import gif004 from '../../../../../assets/images/gifs/soSad/soSadGif004.gif'
import gif005 from '../../../../../assets/images/gifs/soSad/soSadGif005.gif'
import gif006 from '../../../../../assets/images/gifs/soSad/soSadGif006.gif'
import gif007 from '../../../../../assets/images/gifs/soSad/soSadGif007.gif'
import gif008 from '../../../../../assets/images/gifs/soSad/soSadGif008.gif'
import gif009 from '../../../../../assets/images/gifs/soSad/soSadGif009.gif'
import gif010 from '../../../../../assets/images/gifs/soSad/soSadGif010.gif'
import gif011 from '../../../../../assets/images/gifs/soSad/soSadGif011.gif'
import gif012 from '../../../../../assets/images/gifs/soSad/soSadGif012.gif'
import gif013 from '../../../../../assets/images/gifs/soSad/soSadGif013.gif'
import gif014 from '../../../../../assets/images/gifs/soSad/soSadGif014.gif'
import gif015 from '../../../../../assets/images/gifs/soSad/soSadGif015.gif'
import gif016 from '../../../../../assets/images/gifs/soSad/soSadGif016.gif'
import gif017 from '../../../../../assets/images/gifs/soSad/soSadGif017.gif'
import gif018 from '../../../../../assets/images/gifs/soSad/soSadGif018.gif'
import gif019 from '../../../../../assets/images/gifs/soSad/soSadGif019.gif'
import gif020 from '../../../../../assets/images/gifs/soSad/soSadGif020.gif'
import gif021 from '../../../../../assets/images/gifs/soSad/soSadGif021.gif'
import gif022 from '../../../../../assets/images/gifs/soSad/soSadGif022.gif'
import gif023 from '../../../../../assets/images/gifs/soSad/soSadGif023.gif'
import gif024 from '../../../../../assets/images/gifs/soSad/soSadGif024.gif'
import gif025 from '../../../../../assets/images/gifs/soSad/soSadGif025.gif'
import gif026 from '../../../../../assets/images/gifs/soSad/soSadGif026.gif'
import gif027 from '../../../../../assets/images/gifs/soSad/soSadGif027.gif'
import gif028 from '../../../../../assets/images/gifs/soSad/soSadGif028.gif'
import gif029 from '../../../../../assets/images/gifs/soSad/soSadGif029.gif'
import gif030 from '../../../../../assets/images/gifs/soSad/soSadGif030.gif'
import gif031 from '../../../../../assets/images/gifs/soSad/soSadGif031.gif'
import gif032 from '../../../../../assets/images/gifs/soSad/soSadGif032.gif'
import gif033 from '../../../../../assets/images/gifs/soSad/soSadGif033.gif'
import gif034 from '../../../../../assets/images/gifs/soSad/soSadGif034.gif'
import gif035 from '../../../../../assets/images/gifs/soSad/soSadGif035.gif'
import gif036 from '../../../../../assets/images/gifs/soSad/soSadGif036.gif'
import gif037 from '../../../../../assets/images/gifs/soSad/soSadGif037.gif'
import gif038 from '../../../../../assets/images/gifs/soSad/soSadGif038.gif'
import gif039 from '../../../../../assets/images/gifs/soSad/soSadGif039.gif'
import gif040 from '../../../../../assets/images/gifs/soSad/soSadGif040.gif'
import gif041 from '../../../../../assets/images/gifs/soSad/soSadGif041.gif'
import gif042 from '../../../../../assets/images/gifs/soSad/soSadGif042.gif'
import gif043 from '../../../../../assets/images/gifs/soSad/soSadGif043.gif'
import gif044 from '../../../../../assets/images/gifs/soSad/soSadGif044.gif'
import gif045 from '../../../../../assets/images/gifs/soSad/soSadGif045.gif'
import gif046 from '../../../../../assets/images/gifs/soSad/soSadGif046.gif'
import gif047 from '../../../../../assets/images/gifs/soSad/soSadGif047.gif'
import gif048 from '../../../../../assets/images/gifs/soSad/soSadGif048.gif'
import gif049 from '../../../../../assets/images/gifs/soSad/soSadGif049.gif'
import gif050 from '../../../../../assets/images/gifs/soSad/soSadGif050.gif'
import gif051 from '../../../../../assets/images/gifs/soSad/soSadGif051.gif'
import gif052 from '../../../../../assets/images/gifs/soSad/soSadGif052.gif'
import gif053 from '../../../../../assets/images/gifs/soSad/soSadGif053.gif'
import gif054 from '../../../../../assets/images/gifs/soSad/soSadGif054.gif'
import gif055 from '../../../../../assets/images/gifs/soSad/soSadGif055.gif'
import gif056 from '../../../../../assets/images/gifs/soSad/soSadGif056.gif'
import gif057 from '../../../../../assets/images/gifs/soSad/soSadGif057.gif'
import gif058 from '../../../../../assets/images/gifs/soSad/soSadGif058.gif'
import gif059 from '../../../../../assets/images/gifs/soSad/soSadGif059.gif'
import gif060 from '../../../../../assets/images/gifs/soSad/soSadGif060.gif'
import gif061 from '../../../../../assets/images/gifs/soSad/soSadGif061.gif'
import gif062 from '../../../../../assets/images/gifs/soSad/soSadGif062.gif'
import gif063 from '../../../../../assets/images/gifs/soSad/soSadGif063.gif'
import gif064 from '../../../../../assets/images/gifs/soSad/soSadGif064.gif'
import gif065 from '../../../../../assets/images/gifs/soSad/soSadGif065.gif'
import gif066 from '../../../../../assets/images/gifs/soSad/soSadGif066.gif'
import gif067 from '../../../../../assets/images/gifs/soSad/soSadGif067.gif'
import gif068 from '../../../../../assets/images/gifs/soSad/soSadGif068.gif'
import gif069 from '../../../../../assets/images/gifs/soSad/soSadGif069.gif'
import gif070 from '../../../../../assets/images/gifs/soSad/soSadGif070.gif'
import gif071 from '../../../../../assets/images/gifs/soSad/soSadGif071.gif'
import gif072 from '../../../../../assets/images/gifs/soSad/soSadGif072.gif'
import gif073 from '../../../../../assets/images/gifs/soSad/soSadGif073.gif'
import gif074 from '../../../../../assets/images/gifs/soSad/soSadGif074.gif'
import gif075 from '../../../../../assets/images/gifs/soSad/soSadGif075.gif'
import gif076 from '../../../../../assets/images/gifs/soSad/soSadGif076.gif'
import gif077 from '../../../../../assets/images/gifs/soSad/soSadGif077.gif'
import gif078 from '../../../../../assets/images/gifs/soSad/soSadGif078.gif'
import gif079 from '../../../../../assets/images/gifs/soSad/soSadGif079.gif'
import gif080 from '../../../../../assets/images/gifs/soSad/soSadGif080.gif'
import gif081 from '../../../../../assets/images/gifs/soSad/soSadGif081.gif'
import gif082 from '../../../../../assets/images/gifs/soSad/soSadGif082.gif'
import gif083 from '../../../../../assets/images/gifs/soSad/soSadGif083.gif'
import gif084 from '../../../../../assets/images/gifs/soSad/soSadGif084.gif'
import gif085 from '../../../../../assets/images/gifs/soSad/soSadGif085.gif'
import gif086 from '../../../../../assets/images/gifs/soSad/soSadGif086.gif'
import gif087 from '../../../../../assets/images/gifs/soSad/soSadGif087.gif'
import gif088 from '../../../../../assets/images/gifs/soSad/soSadGif088.gif'
import gif089 from '../../../../../assets/images/gifs/soSad/soSadGif089.gif'
import gif090 from '../../../../../assets/images/gifs/soSad/soSadGif090.gif'
import gif091 from '../../../../../assets/images/gifs/soSad/soSadGif090.gif'
import gif092 from '../../../../../assets/images/gifs/soSad/soSadGif090.gif'
import gif093 from '../../../../../assets/images/gifs/soSad/soSadGif090.gif'
import gif094 from '../../../../../assets/images/gifs/soSad/soSadGif090.gif'
import gif095 from '../../../../../assets/images/gifs/soSad/soSadGif090.gif'
import gif096 from '../../../../../assets/images/gifs/soSad/soSadGif090.gif'
import gif097 from '../../../../../assets/images/gifs/soSad/soSadGif090.gif'
import gif098 from '../../../../../assets/images/gifs/soSad/soSadGif090.gif'
import gif099 from '../../../../../assets/images/gifs/soSad/soSadGif090.gif'
import gif100 from '../../../../../assets/images/gifs/soSad/soSadGif100.gif'
import gif101 from '../../../../../assets/images/gifs/soSad/soSadGif101.gif'
import gif102 from '../../../../../assets/images/gifs/soSad/soSadGif102.gif'
import gif103 from '../../../../../assets/images/gifs/soSad/soSadGif103.gif'
import gif104 from '../../../../../assets/images/gifs/soSad/soSadGif104.gif'
import gif105 from '../../../../../assets/images/gifs/soSad/soSadGif105.gif'
import gif106 from '../../../../../assets/images/gifs/soSad/soSadGif106.gif'
import gif107 from '../../../../../assets/images/gifs/soSad/soSadGif107.gif'
import gif108 from '../../../../../assets/images/gifs/soSad/soSadGif108.gif'
import gif109 from '../../../../../assets/images/gifs/soSad/soSadGif109.gif'

const GifSoSadGenerator = () => {
  const [randomImage, setRandomImage] = useState(0)

  const {
    state: { reelSpinning5 },
  } = useContext(GameContext)

  useEffect(() => {
    if (!reelSpinning5) {
      setRandomImage(Math.floor(Math.random() * (108 - 1) + 1))
    }
  }, [reelSpinning5])

  const renderGif = () => {
    switch (randomImage) {
      case 1:
        return <img src={gif001} alt="soSadGif" className="bannerGif" />
      case 2:
        return <img src={gif002} alt="soSadGif" className="bannerGif" />
      case 3:
        return <img src={gif003} alt="soSadGif" className="bannerGif" />
      case 4:
        return <img src={gif004} alt="soSadGif" className="bannerGif" />
      case 5:
        return <img src={gif005} alt="soSadGif" className="bannerGif" />
      case 6:
        return <img src={gif006} alt="soSadGif" className="bannerGif" />
      case 7:
        return <img src={gif007} alt="soSadGif" className="bannerGif" />
      case 8:
        return <img src={gif008} alt="soSadGif" className="bannerGif" />
      case 9:
        return <img src={gif009} alt="soSadGif" className="bannerGif" />
      case 10:
        return <img src={gif010} alt="soSadGif" className="bannerGif" />
      case 11:
        return <img src={gif011} alt="soSadGif" className="bannerGif" />
      case 12:
        return <img src={gif012} alt="soSadGif" className="bannerGif" />
      case 13:
        return <img src={gif013} alt="soSadGif" className="bannerGif" />
      case 14:
        return <img src={gif014} alt="soSadGif" className="bannerGif" />
      case 15:
        return <img src={gif015} alt="soSadGif" className="bannerGif" />
      case 16:
        return <img src={gif016} alt="soSadGif" className="bannerGif" />
      case 17:
        return <img src={gif017} alt="soSadGif" className="bannerGif" />
      case 18:
        return <img src={gif018} alt="soSadGif" className="bannerGif" />
      case 19:
        return <img src={gif019} alt="soSadGif" className="bannerGif" />
      case 20:
        return <img src={gif020} alt="soSadGif" className="bannerGif" />
      case 21:
        return <img src={gif021} alt="soSadGif" className="bannerGif" />
      case 22:
        return <img src={gif022} alt="soSadGif" className="bannerGif" />
      case 23:
        return <img src={gif023} alt="soSadGif" className="bannerGif" />
      case 24:
        return <img src={gif024} alt="soSadGif" className="bannerGif" />
      case 25:
        return <img src={gif025} alt="soSadGif" className="bannerGif" />
      case 26:
        return <img src={gif026} alt="soSadGif" className="bannerGif" />
      case 27:
        return <img src={gif027} alt="soSadGif" className="bannerGif" />
      case 28:
        return <img src={gif028} alt="soSadGif" className="bannerGif" />
      case 29:
        return <img src={gif029} alt="soSadGif" className="bannerGif" />
      case 30:
        return <img src={gif030} alt="soSadGif" className="bannerGif" />
      case 31:
        return <img src={gif031} alt="soSadGif" className="bannerGif" />
      case 32:
        return <img src={gif032} alt="soSadGif" className="bannerGif" />
      case 33:
        return <img src={gif033} alt="soSadGif" className="bannerGif" />
      case 34:
        return <img src={gif034} alt="soSadGif" className="bannerGif" />
      case 35:
        return <img src={gif035} alt="soSadGif" className="bannerGif" />
      case 36:
        return <img src={gif036} alt="soSadGif" className="bannerGif" />
      case 37:
        return <img src={gif037} alt="soSadGif" className="bannerGif" />
      case 38:
        return <img src={gif038} alt="soSadGif" className="bannerGif" />
      case 39:
        return <img src={gif039} alt="soSadGif" className="bannerGif" />
      case 40:
        return <img src={gif040} alt="soSadGif" className="bannerGif" />
      case 41:
        return <img src={gif041} alt="soSadGif" className="bannerGif" />
      case 42:
        return <img src={gif042} alt="soSadGif" className="bannerGif" />
      case 43:
        return <img src={gif043} alt="soSadGif" className="bannerGif" />
      case 44:
        return <img src={gif044} alt="soSadGif" className="bannerGif" />
      case 45:
        return <img src={gif045} alt="soSadGif" className="bannerGif" />
      case 46:
        return <img src={gif046} alt="soSadGif" className="bannerGif" />
      case 47:
        return <img src={gif047} alt="soSadGif" className="bannerGif" />
      case 48:
        return <img src={gif048} alt="soSadGif" className="bannerGif" />
      case 49:
        return <img src={gif049} alt="soSadGif" className="bannerGif" />
      case 50:
        return <img src={gif050} alt="soSadGif" className="bannerGif" />
      case 51:
        return <img src={gif051} alt="soSadGif" className="bannerGif" />
      case 52:
        return <img src={gif052} alt="soSadGif" className="bannerGif" />
      case 53:
        return <img src={gif053} alt="soSadGif" className="bannerGif" />
      case 54:
        return <img src={gif054} alt="soSadGif" className="bannerGif" />
      case 55:
        return <img src={gif055} alt="soSadGif" className="bannerGif" />
      case 56:
        return <img src={gif056} alt="soSadGif" className="bannerGif" />
      case 57:
        return <img src={gif057} alt="soSadGif" className="bannerGif" />
      case 58:
        return <img src={gif058} alt="soSadGif" className="bannerGif" />
      case 59:
        return <img src={gif059} alt="soSadGif" className="bannerGif" />
      case 60:
        return <img src={gif060} alt="soSadGif" className="bannerGif" />
      case 61:
        return <img src={gif061} alt="soSadGif" className="bannerGif" />
      case 62:
        return <img src={gif062} alt="soSadGif" className="bannerGif" />
      case 63:
        return <img src={gif063} alt="soSadGif" className="bannerGif" />
      case 64:
        return <img src={gif064} alt="soSadGif" className="bannerGif" />
      case 65:
        return <img src={gif065} alt="soSadGif" className="bannerGif" />
      case 66:
        return <img src={gif066} alt="soSadGif" className="bannerGif" />
      case 67:
        return <img src={gif067} alt="soSadGif" className="bannerGif" />
      case 68:
        return <img src={gif068} alt="soSadGif" className="bannerGif" />
      case 69:
        return <img src={gif069} alt="soSadGif" className="bannerGif" />
      case 70:
        return <img src={gif070} alt="soSadGif" className="bannerGif" />
      case 71:
        return <img src={gif071} alt="soSadGif" className="bannerGif" />
      case 72:
        return <img src={gif072} alt="soSadGif" className="bannerGif" />
      case 73:
        return <img src={gif073} alt="soSadGif" className="bannerGif" />
      case 74:
        return <img src={gif074} alt="soSadGif" className="bannerGif" />
      case 75:
        return <img src={gif075} alt="soSadGif" className="bannerGif" />
      case 76:
        return <img src={gif076} alt="soSadGif" className="bannerGif" />
      case 77:
        return <img src={gif077} alt="soSadGif" className="bannerGif" />
      case 78:
        return <img src={gif078} alt="soSadGif" className="bannerGif" />
      case 79:
        return <img src={gif079} alt="soSadGif" className="bannerGif" />
      case 80:
        return <img src={gif080} alt="soSadGif" className="bannerGif" />
      case 81:
        return <img src={gif081} alt="soSadGif" className="bannerGif" />
      case 82:
        return <img src={gif082} alt="soSadGif" className="bannerGif" />
      case 83:
        return <img src={gif083} alt="soSadGif" className="bannerGif" />
      case 84:
        return <img src={gif084} alt="soSadGif" className="bannerGif" />
      case 85:
        return <img src={gif085} alt="soSadGif" className="bannerGif" />
      case 86:
        return <img src={gif086} alt="soSadGif" className="bannerGif" />
      case 87:
        return <img src={gif087} alt="soSadGif" className="bannerGif" />
      case 88:
        return <img src={gif088} alt="soSadGif" className="bannerGif" />
      case 89:
        return <img src={gif089} alt="soSadGif" className="bannerGif" />
      case 90:
        return <img src={gif090} alt="soSadGif" className="bannerGif" />
      case 91:
        return <img src={gif091} alt="soSadGif" className="bannerGif" />
      case 92:
        return <img src={gif092} alt="soSadGif" className="bannerGif" />
      case 93:
        return <img src={gif093} alt="soSadGif" className="bannerGif" />
      case 94:
        return <img src={gif094} alt="soSadGif" className="bannerGif" />
      case 95:
        return <img src={gif095} alt="soSadGif" className="bannerGif" />
      case 96:
        return <img src={gif096} alt="soSadGif" className="bannerGif" />
      case 97:
        return <img src={gif097} alt="soSadGif" className="bannerGif" />
      case 98:
        return <img src={gif098} alt="soSadGif" className="bannerGif" />
      case 99:
        return <img src={gif099} alt="soSadGif" className="bannerGif" />
      case 100:
        return <img src={gif100} alt="soSadGif" className="bannerGif" />
      case 101:
        return <img src={gif101} alt="soSadGif" className="bannerGif" />
      case 102:
        return <img src={gif102} alt="soSadGif" className="bannerGif" />
      case 103:
        return <img src={gif103} alt="soSadGif" className="bannerGif" />
      case 104:
        return <img src={gif104} alt="soSadGif" className="bannerGif" />
      case 105:
        return <img src={gif105} alt="soSadGif" className="bannerGif" />
      case 106:
        return <img src={gif106} alt="soSadGif" className="bannerGif" />
      case 107:
        return <img src={gif107} alt="soSadGif" className="bannerGif" />
      case 108:
        return <img src={gif108} alt="soSadGif" className="bannerGif" />
      case 109:
        return <img src={gif109} alt="soSadGif" className="bannerGif" />
      default:
        break
    }
  }

  return renderGif()
}

export default GifSoSadGenerator
