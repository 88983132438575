import { useContext, useEffect } from 'react'

import { Context as GameContext } from '../../../../context/GameContext'

const FreeSpinPlayer = () => {
  const {
    state: {
      scatterWinOverlayShow,
      rowAFreeSpins,
      rowBFreeSpins,
      rowCFreeSpins,
      freeSpinsTotal,
    },
    setScatterWinOverlayShow,
    setRowAFreeSpins,
    setRowBFreeSpins,
    setRowCFreeSpins,
    setFreeSpinsTotal,
    setFreeSpinsPlayOn,
  } = useContext(GameContext)

  useEffect(() => {
    if (scatterWinOverlayShow) {
      setTimeout(() => {
        setScatterWinOverlayShow(false)
      }, 7000)
    }
  }, [scatterWinOverlayShow])

  useEffect(() => {
    if (rowAFreeSpins > 0) {
      setFreeSpinsTotal(freeSpinsTotal + rowAFreeSpins)
      setRowAFreeSpins(0)
    }
    if (rowBFreeSpins > 0) {
      setFreeSpinsTotal(freeSpinsTotal + rowBFreeSpins)
      setRowBFreeSpins(0)
    }
    if (rowCFreeSpins > 0) {
      setFreeSpinsTotal(freeSpinsTotal + rowCFreeSpins)
      setRowCFreeSpins(0)
    }
  }, [rowAFreeSpins, rowBFreeSpins, rowCFreeSpins])

  useEffect(() => {
    if (freeSpinsTotal > 0) {
      setFreeSpinsPlayOn(true)
    }
  }, [freeSpinsTotal])

  useEffect(() => {
    if (freeSpinsTotal < 1) {
      setFreeSpinsPlayOn(false)
    }
  }, [freeSpinsTotal])

  return null
}

export default FreeSpinPlayer
