import { useContext, useEffect, useState } from 'react'

import socket from '../../../api/socketIo'
import { Context as SocketContext } from '../../../context/SocketContext'
import { Context as AuthContext } from '../../../context/AuthContext'
import { Context as UserContext } from '../../../context/UserContext'
import { Context as PayoutContext } from '../../../context/PayoutContext'
import { Context as GameContext } from '../../../context/GameContext'
import { Context as NavContext } from '../../../context/NavContext'
import { Context as PromoPrizeWheelContext } from '../../../context/PromoPrizeWheelContext'
import { Context as BaitContext } from '../../../context/BaitContext'

const Socket = () => {
  const [socketSetupDone, setSocketSetupDone] = useState(false)

  const {
    state: {
      socketChanel,
      socketDataFromClient,
      socketDataFromCashier,
      socketDataFromCreator,
      jackpotTriggerValue,
      progresiveTriggerValue,
    },
    setLock,
    setSocketChanel,
    setSocketDataFromClient,
    setSocketDataFromCashier,
    setSocketDataFromCreator,
    setReload,
  } = useContext(SocketContext)

  const { signOut } = useContext(AuthContext)

  const {
    state: { user },
  } = useContext(UserContext)

  const {
    setJackpotWon,
    setResetJackpotState,
    setPauseJackpotReleaseDB,
    setNewGameOpeningBalanceCount,
    fetchClientMostRecentBuyIn,
  } = useContext(PayoutContext)

  const {
    state: { gameCredits },
    fetchJackpotData,
    setGameCredits,
  } = useContext(GameContext)

  const { setVisibleComponent } = useContext(NavContext)

  const { setPromoPrizeWheelUsed } = useContext(PromoPrizeWheelContext)

  useEffect(() => {
    if (user) {
      const { siteNumber } = user
      setSocketChanel(siteNumber)
    }
  }, [user])

  useEffect(() => {
    if (socketChanel) {
      socket.emit('join_chanel', socketChanel)
      setSocketSetupDone(true)
    }
  }, [socketChanel])

  useEffect(() => {
    if (socketDataFromClient) {
      socket.emit('send_data', { socketDataFromClient, socketChanel })
      setSocketDataFromClient(null)
    }
  }, [socketDataFromClient])

  useEffect(() => {
    if (socketSetupDone) {
      socket.on('receive_data', (data) => {
        if (data) {
          setSocketDataFromClient({
            socketDataReceivedVerified: true,
          })
          if (data.socketDataFromCashier) {
            setSocketDataFromCashier(data.socketDataFromCashier)
          }
          if (data.socketDataFromCreator) {
            setSocketDataFromCreator(data.socketDataFromCreator)
          }
        }
      })
    }
  }, [socketSetupDone, socket])

  // Remember to null socketDataFromCashier
  useEffect(() => {
    if (socketDataFromCashier) {
      const { subject } = socketDataFromCashier
      switch (subject) {
        case 'lock':
          if (socketDataFromCashier.clientUsername === user.username) {
            setLock(socketDataFromCashier.lock)
            setSocketDataFromCashier(null)
          }
          break
        case 'jackpotWinner':
          if (socketDataFromCashier.jackpotWinnerSelected) {
            setPauseJackpotReleaseDB(true)
            if (socketDataFromCashier.jackpotWinnerUsername === user.username) {
              setJackpotWon(true)
              setSocketDataFromCashier(null)
            }
          }
          break
        case 'resetJackpotState':
          if (socketDataFromCashier.resetJackpotState) {
            setResetJackpotState(true)
            setSocketDataFromCashier(null)
          }
          break
        case 'reFetchJackpotData':
          if (socketDataFromCashier.reFetchJackpotData) {
            fetchJackpotData({ siteNumber: user.siteNumber })
            setSocketDataFromCashier(null)
          }
          break
        case 'addCredit':
          if (socketDataFromCashier.addCredit) {
            if (socketDataFromCashier.clientUsername === user.username) {
              setLock(socketDataFromCashier.lock)
              setSocketDataFromCashier(null)
            }
          }
          break
        case 'addCreditDone':
          if (socketDataFromCashier.addCreditDone) {
            if (socketDataFromCashier.clientUsername === user.username) {
              setGameCredits(socketDataFromCashier.gameCredit)
              fetchClientMostRecentBuyIn({
                siteNumber: user.siteNumber,
                username: user.username,
              })
              setNewGameOpeningBalanceCount(0)
              setVisibleComponent('gamesMenu')
              setLock(false)
              setSocketDataFromCashier(null)
              setPromoPrizeWheelUsed(false)
            }
          }
          break
        case 'cashOut':
          if (socketDataFromCashier.cashOut) {
            if (socketDataFromCashier.clientUsername === user.username) {
              setVisibleComponent('gamesMenu')
              setLock(socketDataFromCashier.lock)
              setSocketDataFromCashier(null)
            }
          }
          break
        case 'cashOutDone':
          if (socketDataFromCashier.cashOutDone) {
            if (socketDataFromCashier.clientUsername === user.username) {
              setGameCredits(0)
              setLock(socketDataFromCashier.lock)
              setSocketDataFromCashier(null)
            }
          }
          break
        case 'reload':
          if (socketDataFromCashier.reload) {
            if (socketDataFromCashier.clientUsername === user.username) {
              setVisibleComponent('gamesMenu')
              setReload(true)
              setSocketDataFromCashier(null)
            }
          }
          break
        case 'siteSignedOutSuccessfully':
          if (socketDataFromCashier.siteSignedOutSuccessfully) {
            signOut()
            setSocketDataFromCashier(null)
          }
          break
        case 'cashierSignedClientOut':
          if (socketDataFromCashier.cashierSignedClientOut) {
            if (socketDataFromCashier.clientUsername === user.username) {
              signOut()
              setSocketDataFromCashier(null)
            }
          }
          break
        default:
          break
      }
    }
  }, [socketDataFromCashier, user])

  useEffect(() => {
    if (socketDataFromCreator) {
      const { subject } = socketDataFromCreator
      switch (subject) {
        case 'fetchBaitTriggerData':
          if (socketDataFromCreator.fetchBaitTriggerData) {
            setSocketDataFromClient({
              subject: 'baitTiriggerData',
              baitTiriggerData: {
                jackpotTriggerValue: jackpotTriggerValue,
                progresiveTriggerValue: progresiveTriggerValue,
                username: user.username,
                gameCredits: gameCredits,
              },
            })
            setSocketDataFromCreator(null)
          }
          break
        case 'triggerClientFetchBait':
          if (socketDataFromCreator.triggerClientFetchBait) {
            setSocketDataFromCreator(null)
          }
          break
        default:
          break
      }
    }
  }, [socketDataFromCreator])

  return null
}

export default Socket
