import React, { useState, useContext, useEffect, useRef } from 'react'
import useSound from 'use-sound'

import { Context as PromoPrizeWheelContext } from '../../../context/PromoPrizeWheelContext'
import { Context as GameContext } from '../../../context/GameContext'
import { Context as UserContext } from '../../../context/UserContext'

import startReel from '../../../assets/sounds/start_reel.mp3'
import win from '../../../assets/sounds/win.mp3'
import './promoPrizeWheel.css'

const PromoPrizeWheel = () => {
  const [result, setResult] = useState(0)

  const {
    setPromoPrizeWheelShow,
    setPromoPrizeWheelUsed,
    decuctPromoWheelEntry,
  } = useContext(PromoPrizeWheelContext)

  const { setFreeSpinsTotal, setNumberOfLines } = useContext(GameContext)

  const {
    state: { user },
  } = useContext(UserContext)

  const [playStartReelSound] = useSound(startReel)
  const [playWinSound] = useSound(win)

  const sectors = [
    { color: '#f82', label: 'X 2', value: 2 },
    { color: '#0bf', label: 'X 4', value: 4 },
    { color: '#fb0', label: 'X 6', value: 6 },
    { color: '#0fb', label: 'X 8', value: 8 },
    { color: '#b0f', label: 'X 10', value: 10 },
    { color: '#f0b', label: 'X 15', value: 15 },
    { color: '#bf0', label: 'X 20', value: 20 },
  ]

  useEffect(() => {
    if (result > 0) {
      playWinSound()
      decuctPromoWheelEntry({ clientId: user._id })
      const run = setInterval(() => {
        setNumberOfLines(3)
        setFreeSpinsTotal(result)
        setResult(0)
        setPromoPrizeWheelShow(false)
        setPromoPrizeWheelUsed(true)
      }, 4000)
      return () => {
        clearInterval(run)
      }
    }
  }, [result])

  useEffect(() => {
    if (result === 0) {
      sectors.forEach(drawSector)
      rotate()
      engine()
      spinEl.current.addEventListener('click', handleSpinClick)
      return () => {
        spinEl.current.removeEventListener('click', handleSpinClick)
      }
    }
  }, [result])

  const rand = (m, M) => Math.random() * (M - m) + m
  const tot = sectors.length
  const spinEl = useRef(null)
  const canvasRef = useRef(null)
  const dia = 300
  const rad = dia / 2
  const PI = Math.PI
  const TAU = 2 * PI
  const arc = TAU / sectors.length

  const friction = 0.991
  let angVel = 0
  let ang = 0

  const getIndex = () => Math.floor(tot - (ang / TAU) * tot) % tot

  const drawSector = (sector, i) => {
    const ctx = canvasRef.current.getContext('2d')
    const ang = arc * i
    ctx.save()
    // COLOR
    ctx.beginPath()
    ctx.fillStyle = sector.color
    ctx.moveTo(rad, rad)
    ctx.arc(rad, rad, rad, ang, ang + arc)
    ctx.lineTo(rad, rad)
    ctx.fill()
    // TEXT
    ctx.translate(rad, rad)
    ctx.rotate(ang + arc / 2)
    ctx.textAlign = 'right'
    ctx.fillStyle = '#fff'
    ctx.font = 'bold 30px Exo'
    ctx.fillText(sector.label, rad - 10, 10)
    ctx.restore()
  }

  const rotate = () => {
    const sector = sectors[getIndex()]
    const ctx = canvasRef.current.getContext('2d')
    ctx.canvas.style.transform = `rotate(${ang - PI / 2}rad)`
    spinEl.current.textContent = !angVel ? 'SPIN' : sector.label
    spinEl.current.style.background = sector.color

    if (!angVel && ang !== 0) {
      setResult(sector.value)
    }
  }

  const frame = () => {
    if (!angVel) return
    angVel *= friction
    if (angVel < 0.002) angVel = 0
    ang += angVel
    ang %= TAU
    rotate()
  }

  const handleSpinClick = () => {
    if (!angVel) {
      angVel = rand(0.25, 0.45)
      setResult(0) // Reset the result when "SPIN" button is clicked
    }
  }

  const engine = () => {
    frame()
    requestAnimationFrame(engine)
  }

  const renderHeader = () => {
    if (result > 0) {
      return (
        <div>
          <div className="promoPrizeWheelResultHeaderText">
            {result} FREE SPINS WON!
          </div>
        </div>
      )
    }
    return (
      <div>
        <div className="promoPrizeWheelHeaderText">PROMO PRIZE WHEEL</div>
        <div className="promoPrizeWheelSubHeader">
          tap 'SPIN' to win free spins!
        </div>
      </div>
    )
  }

  const renderContent = () => {
    return (
      <>
        <div className="promoPrizeWheelHeaderBed">{renderHeader()}</div>
        <div className="promoPrizeWheelBed">
          <div className="promoPrizeWheelContainer">
            <div>
              <div className="promoPrizeWheel">
                <canvas
                  className="wheel"
                  width="300"
                  height="300"
                  ref={canvasRef}
                ></canvas>
                <div
                  className="spin"
                  ref={spinEl}
                  onClick={() => playStartReelSound()}
                >
                  {result || 'SPIN'}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <div className="gamesMenuBackGroundBed">
      <ul className="gamesMenuBackGroundShapes">
        {renderContent()}
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
  )
}

export default PromoPrizeWheel
