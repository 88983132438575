import React, { useContext, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'

import Socket from './common/socket/Socket'
import Loader from './common/loader/Loader'
import LockScreen from './common/lockScreen/LockScreen'
import Login from './auth/login/Login'
import Fruits from './games/fruits/Fruits'
import Faces from './games/faces/Faces'
import Test from './games/test/Test'
import GamesMenu from './gamesMenu/GamesMenu'
import { Context as AuthContext } from '../context/AuthContext'
import { Context as UserContext } from '../context/UserContext'
import { Context as NavContext } from '../context/NavContext'

const AppScreens = () => {
  const [lock, setLock] = useState(false)
  const [isFullscreen, setIsFullscreen] = useState(
    document.fullscreenElement ||
      document.mozFullScreenElement ||
      document.webkitFullscreenElement ||
      document.msFullscreenElement
  )

  const {
    state: { loading, panic, success },
    setPanic,
    signOut,
  } = useContext(AuthContext)

  const {
    state: { user },
  } = useContext(UserContext)

  const {
    state: { visibleComponent },
    setVisibleComponent,
  } = useContext(NavContext)

  useEffect(() => {
    localStorage.getItem('token')
  }, [])

  useEffect(() => {
    const fullscreenChangeHandler = () => {
      setIsFullscreen(
        document.fullscreenElement ||
          document.mozFullScreenElement ||
          document.webkitFullscreenElement ||
          document.msFullscreenElement
      )
    }

    document.addEventListener('fullscreenchange', fullscreenChangeHandler)
    document.addEventListener('mozfullscreenchange', fullscreenChangeHandler)
    document.addEventListener('webkitfullscreenchange', fullscreenChangeHandler)
    document.addEventListener('msfullscreenchange', fullscreenChangeHandler)

    return () => {
      document.removeEventListener('fullscreenchange', fullscreenChangeHandler)
      document.removeEventListener(
        'mozfullscreenchange',
        fullscreenChangeHandler
      )
      document.removeEventListener(
        'webkitfullscreenchange',
        fullscreenChangeHandler
      )
      document.removeEventListener(
        'msfullscreenchange',
        fullscreenChangeHandler
      )
    }
  }, [])

  const toggleFullscreen = () => {
    if (!isFullscreen) {
      // Request fullscreen for different browsers
      const element = document.documentElement
      if (element.requestFullscreen) {
        element.requestFullscreen()
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen()
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen()
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen()
      }
    } else {
      // Exit fullscreen for different browsers
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      }
    }
    setIsFullscreen(!isFullscreen)
  }

  useEffect(() => {
    if (localStorage.getItem('token')) {
      setVisibleComponent('gamesMenu')
    } else {
      setVisibleComponent('login')
    }
  }, [localStorage.getItem('token')])

  useEffect(() => {
    if (user) {
      const { client, signedIn } = user
      if (!client) {
        localStorage.removeItem('token')
        setVisibleComponent('login')
      }
      if (!signedIn) {
        localStorage.removeItem('token')
        setVisibleComponent('login')
      }
    }
  }, [user])

  useEffect(() => {
    if (lock) setVisibleComponent('gamesMenu')
  }, [lock])

  useEffect(() => {
    if (panic) {
      signOut()
    }
  }, [panic])

  useEffect(() => {
    if (success === 'signed out successfully') {
      localStorage.removeItem('token')
      setVisibleComponent('login')
    }
  }, [success])

  const componentSelector = () => {
    switch (visibleComponent) {
      case 'fruits':
        return <Fruits />
      case 'faces':
        return <Faces />
      case 'test':
        return <Test />
      case 'gamesMenu':
        return <GamesMenu />
      default:
        return <Login />
    }
  }

  const renderContent = () => {
    if (loading) return <Loader />
    if (lock) return <LockScreen />
    if (isMobile) {
      return (
        <div className="enterFullScreenButtonBed">
          <div className="enterFullScreenButtonContainer">
            <div className="mobileRestrictedMessage">
              mobile rendering not allowed
            </div>
          </div>
        </div>
      )
    }
    if (!isFullscreen) {
      return (
        <div className="enterFullScreenButtonBed">
          <div className="enterFullScreenButtonContainer">
            <button
              className="enterFullScreenButton"
              onClick={toggleFullscreen}
            >
              Click here
            </button>
          </div>
        </div>
      )
    }
    return (
      <>
        <Socket />
        {componentSelector()}
      </>
    )
  }

  return renderContent()
}

export default AppScreens
