import { useContext, useEffect, useState } from 'react'
import uuid from 'uuid/v4'

import { Context as GameContext } from '../../../../context/GameContext'
import { Context as PayoutContext } from '../../../../context/PayoutContext'

const CalculateRowBWin = () => {
  const [checkForWildCardDone, setCheckForWildCardDone] = useState(false)
  const [winArray, setWinArray] = useState([])
  const [winSymbolArray, setWinSymbolArray] = useState([])
  const [prizeArray, setPrizeArray] = useState([])
  const [appendWildCardDone, setAppendWildCardDone] = useState(false)
  const [assignPrizeDone, setAssignPrizeDone] = useState(false)
  const [prizeArrayDone, setPrizeArrayDone] = useState(false)

  const {
    state: {
      betValue,
      numberOfLines,
      rowBResult,
      reelSpinning5,
      wildCardQuantityRowB,
      scatterQuantityRowB,
      rowBFreeSpins,
      symbol1WildCardWinB,
      symbol2WildCardWinB,
      symbol3WildCardWinB,
      symbol4WildCardWinB,
      symbol5WildCardWinB,
      symbol6WildCardWinB,
      symbol7WildCardWinB,
      symbol8WildCardWinB,
      symbol9WildCardWinB,
    },
    setRowBWinArray,
    setRowBPrizeArray,
    setWildCardQuantityRowB,
    setScatterQuantityRowB,
    setScatterWinOverlayShow,
    setRowBFreeSpins,
    setWinningSymbolRowB,
    setScatterAndWildCardCheckDoneRowB,
    setSymbol1WildCardWinB,
    setSymbol2WildCardWinB,
    setSymbol3WildCardWinB,
    setSymbol4WildCardWinB,
    setSymbol5WildCardWinB,
    setSymbol6WildCardWinB,
    setSymbol7WildCardWinB,
    setSymbol8WildCardWinB,
    setSymbol9WildCardWinB,
    setPrizeWonDoneRowB,
    setNoPrizeDoneRowB,
  } = useContext(GameContext)

  const {
    state: { payoutOverLimitStartReplay },
  } = useContext(PayoutContext)

  useEffect(() => {
    if (rowBResult.length > 0) {
      checkForWildCardAndScatter()
    }
  }, [rowBResult])

  useEffect(() => {
    if (
      numberOfLines > 0 &&
      wildCardQuantityRowB > 0 &&
      scatterQuantityRowB > 0
    ) {
      setScatterAndWildCardCheckDoneRowB(true)
      setTimeout(() => {
        setScatterWinOverlayShow(true)
        setRowBFreeSpins(rowBFreeSpins + 10)
      }, 4800)
    }
  }, [numberOfLines, wildCardQuantityRowB, scatterQuantityRowB])

  useEffect(() => {
    if (wildCardQuantityRowB > 0 && checkForWildCardDone) {
      appendWildCard()
    }
    if (checkForWildCardDone && wildCardQuantityRowB === 0) {
      assignPrize()
    }
  }, [wildCardQuantityRowB, checkForWildCardDone])

  useEffect(() => {
    if (appendWildCardDone || assignPrizeDone) {
      if (winArray.length < 1) {
        setNoPrizeDoneRowB(true)
      }
      if (winArray.length > 0) {
        winArray.map((win, index) => {
          if (index === winArray.length - 1) {
            setPrizeArrayDone(true)
            setPrizeWonDoneRowB(true)
          }
          let prize = win.betMutiple * betValue
          setPrizeArray((prizeArray) => [...prizeArray, { prizeValue: prize }])
        })
      }
    }
  }, [appendWildCardDone, assignPrizeDone, winArray])

  useEffect(() => {
    if (numberOfLines > 0 && (appendWildCardDone || assignPrizeDone)) {
      setWinningSymbolRowB(winSymbolArray)
    }
  }, [numberOfLines, appendWildCardDone, assignPrizeDone])

  useEffect(() => {
    if (prizeArrayDone) {
      setRowBPrizeArray(prizeArray)
      setRowBWinArray(prizeArray)
    }
  }, [prizeArrayDone])

  useEffect(() => {
    if (payoutOverLimitStartReplay || !reelSpinning5) {
      setWinArray([])
      setWinSymbolArray([])
      setPrizeArray([])
      setCheckForWildCardDone(false)
      setAppendWildCardDone(false)
      setAssignPrizeDone(false)
      setPrizeArrayDone(false)
      setPrizeWonDoneRowB(false)
      setNoPrizeDoneRowB(false)
    }
  }, [payoutOverLimitStartReplay, reelSpinning5])

  const checkForWildCardAndScatter = () => {
    rowBResult.map((result, index) => {
      if (index === rowBResult.length - 1) {
        setCheckForWildCardDone(true)
      }
      const { symbol, quantity } = result
      if (symbol === '10') {
        setWildCardQuantityRowB(quantity)
      }
      if (symbol === '11') {
        setScatterQuantityRowB(quantity)
      }
    })
  }

  const appendWildCard = () => {
    if (rowBResult.length > 0) {
      let arraySorted = rowBResult.sort((x, y) => {
        return y.symbol - x.symbol
      })
      let wildCardAssigned
      if (wildCardQuantityRowB > 0) wildCardAssigned = false
      arraySorted.map((result, index) => {
        if (index === arraySorted.length - 1) {
          setAppendWildCardDone(true)
        }
        const { symbol, quantity } = result
        // Case 🍀 total = 5
        if (symbol === '10' && quantity === 5) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5500, key: uuid() },
          ])
          setSymbol1WildCardWinB(true)
          return
        }
        // 1st
        // Case 🍓 win + 🍀 total = 5
        if (
          symbol === '9' &&
          quantity === 4 &&
          wildCardQuantityRowB === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍓', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5000, key: uuid() },
          ])
          setSymbol1WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '9' &&
          quantity === 3 &&
          wildCardQuantityRowB === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍓', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5000, key: uuid() },
          ])
          setSymbol1WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '9' &&
          quantity === 2 &&
          wildCardQuantityRowB === 3 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍓', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5000, key: uuid() },
          ])
          setSymbol1WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '9' &&
          quantity === 1 &&
          wildCardQuantityRowB === 4 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍓', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5000, key: uuid() },
          ])
          setSymbol1WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        // 2nd
        // Case 🍒 win + 🍀 total = 5
        if (
          symbol === '8' &&
          quantity === 4 &&
          wildCardQuantityRowB === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍒', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 750, key: uuid() },
          ])
          setSymbol3WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '8' &&
          quantity === 3 &&
          wildCardQuantityRowB === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍒', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 750, key: uuid() },
          ])
          setSymbol3WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '8' &&
          quantity === 2 &&
          wildCardQuantityRowB === 3 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍒', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 750, key: uuid() },
          ])
          setSymbol3WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '8' &&
          quantity === 1 &&
          wildCardQuantityRowB === 4 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍒', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 750, key: uuid() },
          ])
          setSymbol3WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        // 3rd
        // Case 🍓 win + 🍀 total = 4
        if (
          symbol === '9' &&
          quantity === 3 &&
          wildCardQuantityRowB === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍓', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 1000, key: uuid() },
          ])
          setSymbol1WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '9' &&
          quantity === 2 &&
          wildCardQuantityRowB === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍓', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 1000, key: uuid() },
          ])
          setSymbol1WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '9' &&
          quantity === 1 &&
          wildCardQuantityRowB === 3 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍓', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 1000, key: uuid() },
          ])
          setSymbol1WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        // 4th
        // Case 🍇 win + 🍀 total = 5
        if (
          symbol === '7' &&
          quantity === 4 &&
          wildCardQuantityRowB === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍇', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 750, key: uuid() },
          ])
          setSymbol2WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '7' &&
          quantity === 3 &&
          wildCardQuantityRowB === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍇', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 750, key: uuid() },
          ])
          setSymbol2WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '7' &&
          quantity === 2 &&
          wildCardQuantityRowB === 3 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍇', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 750, key: uuid() },
          ])
          setSymbol2WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '7' &&
          quantity === 1 &&
          wildCardQuantityRowB === 4 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍇', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 750, key: uuid() },
          ])
          setSymbol2WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        // 5th
        // Case 🍌 win + 🍀 total = 5
        if (
          symbol === '6' &&
          quantity === 4 &&
          wildCardQuantityRowB === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍌', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 750, key: uuid() },
          ])
          setSymbol4WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '6' &&
          quantity === 3 &&
          wildCardQuantityRowB === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍌', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 750, key: uuid() },
          ])
          setSymbol4WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '6' &&
          quantity === 2 &&
          wildCardQuantityRowB === 3 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍌', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 750, key: uuid() },
          ])
          setSymbol4WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '6' &&
          quantity === 1 &&
          wildCardQuantityRowB === 4 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍌', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 750, key: uuid() },
          ])
          setSymbol4WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        // 6th
        // Case 🍒 win + 🍀 total = 4
        if (
          symbol === '8' &&
          quantity === 3 &&
          wildCardQuantityRowB === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍒', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 400, key: uuid() },
          ])
          setSymbol3WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '8' &&
          quantity === 2 &&
          wildCardQuantityRowB === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍒', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 400, key: uuid() },
          ])
          setSymbol3WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '8' &&
          quantity === 1 &&
          wildCardQuantityRowB === 3 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍒', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 400, key: uuid() },
          ])
          setSymbol3WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        // 7th
        // Case 🍉 win + 🍀 total = 5
        if (
          symbol === '5' &&
          quantity === 4 &&
          wildCardQuantityRowB === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍉', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol5WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '5' &&
          quantity === 3 &&
          wildCardQuantityRowB === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍉', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol5WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '5' &&
          quantity === 2 &&
          wildCardQuantityRowB === 3 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍉', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol5WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '5' &&
          quantity === 1 &&
          wildCardQuantityRowB === 4 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍉', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol5WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        // 8th
        // Case 🥑 win + 🍀 total = 5
        if (
          symbol === '4' &&
          quantity === 4 &&
          wildCardQuantityRowB === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🥑', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol6WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '4' &&
          quantity === 3 &&
          wildCardQuantityRowB === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🥑', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol6WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '4' &&
          quantity === 2 &&
          wildCardQuantityRowB === 3 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🥑', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol6WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '4' &&
          quantity === 1 &&
          wildCardQuantityRowB === 4 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🥑', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol6WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        // 9th
        // Case 🍓 win + 🍀 total = 3
        if (
          symbol === '9' &&
          quantity === 2 &&
          wildCardQuantityRowB === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍓', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol1WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '9' &&
          quantity === 1 &&
          wildCardQuantityRowB === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍓', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol1WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        // 10th
        // Case 🍇 win + 🍀 total = 4
        if (
          symbol === '7' &&
          quantity === 3 &&
          wildCardQuantityRowB === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍇', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol2WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '7' &&
          quantity === 2 &&
          wildCardQuantityRowB === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍇', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol2WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '7' &&
          quantity === 1 &&
          wildCardQuantityRowB === 3 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍇', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol2WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        // 11th
        // Case 🍌 win + 🍀 total = 4
        if (
          symbol === '6' &&
          quantity === 3 &&
          wildCardQuantityRowB === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍌', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol4WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '6' &&
          quantity === 2 &&
          wildCardQuantityRowB === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍌', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol4WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '6' &&
          quantity === 1 &&
          wildCardQuantityRowB === 3 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍌', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol4WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        // 12th
        // Case 🍊 win + 🍀 total = 5
        if (
          symbol === '3' &&
          quantity === 4 &&
          wildCardQuantityRowB === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍊', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol7WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '3' &&
          quantity === 3 &&
          wildCardQuantityRowB === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍊', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol7WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '3' &&
          quantity === 2 &&
          wildCardQuantityRowB === 3 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍊', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol7WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '3' &&
          quantity === 1 &&
          wildCardQuantityRowB === 4 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍊', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol7WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        // 13th
        // Case 🍋 win + 🍀 total = 5
        if (
          symbol === '2' &&
          quantity === 4 &&
          wildCardQuantityRowB === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍋', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol8WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '2' &&
          quantity === 3 &&
          wildCardQuantityRowB === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍋', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol8WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '2' &&
          quantity === 2 &&
          wildCardQuantityRowB === 3 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍋', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol8WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '2' &&
          quantity === 1 &&
          wildCardQuantityRowB === 4 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍋', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol8WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        // 14th
        // Case 🍈 win + 🍀 total = 5
        if (
          symbol === '1' &&
          quantity === 4 &&
          wildCardQuantityRowB === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍈', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol9WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '1' &&
          quantity === 3 &&
          wildCardQuantityRowB === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍈', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol9WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '1' &&
          quantity === 2 &&
          wildCardQuantityRowB === 3 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍈', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol9WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '1' &&
          quantity === 1 &&
          wildCardQuantityRowB === 4 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍈', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol9WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        // 15th
        // Case 🍒 win + 🍀 total = 3
        if (
          symbol === '8' &&
          quantity === 2 &&
          wildCardQuantityRowB === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍒', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 40, key: uuid() },
          ])
          setSymbol3WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '8' &&
          quantity === 1 &&
          wildCardQuantityRowB === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍒', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 40, key: uuid() },
          ])
          setSymbol3WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        // 16th
        // Case 🍉 win + 🍀 total = 4
        if (
          symbol === '5' &&
          quantity === 3 &&
          wildCardQuantityRowB === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍉', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 40, key: uuid() },
          ])
          setSymbol5WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '5' &&
          quantity === 2 &&
          wildCardQuantityRowB === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍉', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 40, key: uuid() },
          ])
          setSymbol5WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '5' &&
          quantity === 1 &&
          wildCardQuantityRowB === 3 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍉', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 40, key: uuid() },
          ])
          setSymbol5WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        // 17th
        // Case 🥑 win + 🍀 total = 4
        if (
          symbol === '4' &&
          quantity === 3 &&
          wildCardQuantityRowB === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🥑', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 40, key: uuid() },
          ])
          setSymbol6WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '4' &&
          quantity === 2 &&
          wildCardQuantityRowB === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🥑', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 40, key: uuid() },
          ])
          setSymbol6WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '4' &&
          quantity === 1 &&
          wildCardQuantityRowB === 3 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🥑', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 40, key: uuid() },
          ])
          setSymbol6WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        // 18th
        // Case 🍇 win + 🍀 total = 3
        if (
          symbol === '7' &&
          quantity === 2 &&
          wildCardQuantityRowB === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍇', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 30, key: uuid() },
          ])
          setSymbol2WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '7' &&
          quantity === 1 &&
          wildCardQuantityRowB === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍇', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 30, key: uuid() },
          ])
          setSymbol2WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        // 19th
        // Case 🍌 win + 🍀 total = 3
        if (
          symbol === '6' &&
          quantity === 2 &&
          wildCardQuantityRowB === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍌', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 30, key: uuid() },
          ])
          setSymbol4WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '6' &&
          quantity === 1 &&
          wildCardQuantityRowB === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍌', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 30, key: uuid() },
          ])
          setSymbol4WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        // 20th
        // Case 🍊 win + 🍀 total = 4
        if (
          symbol === '3' &&
          quantity === 3 &&
          wildCardQuantityRowB === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍊', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 25, key: uuid() },
          ])
          setSymbol7WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '3' &&
          quantity === 2 &&
          wildCardQuantityRowB === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍊', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 25, key: uuid() },
          ])
          setSymbol7WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '3' &&
          quantity === 1 &&
          wildCardQuantityRowB === 3 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍊', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 25, key: uuid() },
          ])
          setSymbol7WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        // 21st
        // Case 🍋 win + 🍀 total = 4
        if (
          symbol === '2' &&
          quantity === 3 &&
          wildCardQuantityRowB === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍋', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 25, key: uuid() },
          ])
          setSymbol8WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '2' &&
          quantity === 2 &&
          wildCardQuantityRowB === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍋', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 25, key: uuid() },
          ])
          setSymbol8WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '2' &&
          quantity === 1 &&
          wildCardQuantityRowB === 3 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍋', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 25, key: uuid() },
          ])
          setSymbol8WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        // 22nd
        // Case 🍈 win + 🍀 total = 4
        if (
          symbol === '1' &&
          quantity === 3 &&
          wildCardQuantityRowB === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍈', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 25, key: uuid() },
          ])
          setSymbol9WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '1' &&
          quantity === 2 &&
          wildCardQuantityRowB === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍈', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 25, key: uuid() },
          ])
          setSymbol9WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '1' &&
          quantity === 1 &&
          wildCardQuantityRowB === 3 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍈', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 25, key: uuid() },
          ])
          setSymbol9WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        // 23rd
        // Case 🍉 win + 🍀 total = 3
        if (
          symbol === '5' &&
          quantity === 2 &&
          wildCardQuantityRowB === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍉', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5, key: uuid() },
          ])
          setSymbol5WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '5' &&
          quantity === 1 &&
          wildCardQuantityRowB === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍉', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5, key: uuid() },
          ])
          setSymbol5WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        // 24th
        // Case 🥑 win + 🍀 total = 3
        if (
          symbol === '4' &&
          quantity === 2 &&
          wildCardQuantityRowB === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🥑', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5, key: uuid() },
          ])
          setSymbol6WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '4' &&
          quantity === 1 &&
          wildCardQuantityRowB === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🥑', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5, key: uuid() },
          ])
          setSymbol6WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        // 25th
        // Case 🍊 win + 🍀 total = 3
        if (
          symbol === '3' &&
          quantity === 2 &&
          wildCardQuantityRowB === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍊', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5, key: uuid() },
          ])
          setSymbol7WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '3' &&
          quantity === 1 &&
          wildCardQuantityRowB === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍊', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5, key: uuid() },
          ])
          setSymbol7WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        // 26th
        // Case 🍋 win + 🍀 total = 3
        if (
          symbol === '2' &&
          quantity === 2 &&
          wildCardQuantityRowB === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍋', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5, key: uuid() },
          ])
          setSymbol8WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '2' &&
          quantity === 1 &&
          wildCardQuantityRowB === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍋', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5, key: uuid() },
          ])
          setSymbol8WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        // 27th
        // Case 🍈 win + 🍀 total = 3
        if (
          symbol === '1' &&
          quantity === 2 &&
          wildCardQuantityRowB === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍈', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5, key: uuid() },
          ])
          setSymbol9WildCardWinB(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '1' &&
          quantity === 1 &&
          wildCardQuantityRowB === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowB(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍈', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5, key: uuid() },
          ])
          setSymbol9WildCardWinB(true)
          wildCardAssigned = true
          return
        }
      })
    }
  }

  const assignPrize = () => {
    let arraySorted = rowBResult.sort((x, y) => {
      return y.symbol - x.symbol
    })
    arraySorted.map((result, index) => {
      if (index === arraySorted.length - 1) {
        setAssignPrizeDone(true)
      }
      const { symbol, quantity } = result
      if (!symbol1WildCardWinB) {
        if (symbol === '9' && quantity === 3) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍓', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          return
        }
        if (symbol === '9' && quantity === 4) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍓', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 1000, key: uuid() },
          ])
          return
        }
        if (symbol === '9' && quantity === 5) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍓', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5000, key: uuid() },
          ])
          return
        }
      }
      if (!symbol2WildCardWinB) {
        if (symbol === '7' && quantity === 3) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍇', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 30, key: uuid() },
          ])
          return
        }
        if (symbol === '7' && quantity === 4) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍇', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          return
        }
        if (symbol === '7' && quantity === 5) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍇', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 750, key: uuid() },
          ])
          return
        }
      }
      if (!symbol3WildCardWinB) {
        if (symbol === '8' && quantity === 3) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍒', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 40, key: uuid() },
          ])
          return
        }
        if (symbol === '8' && quantity === 4) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍒', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 400, key: uuid() },
          ])
          return
        }
        if (symbol === '8' && quantity === 5) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍒', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 2000, key: uuid() },
          ])
          return
        }
      }
      if (!symbol4WildCardWinB) {
        if (symbol === '6' && quantity === 3) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍌', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 30, key: uuid() },
          ])
          return
        }
        if (symbol === '6' && quantity === 4) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍌', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          return
        }
        if (symbol === '6' && quantity === 5) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍌', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 750, key: uuid() },
          ])
          return
        }
      }
      if (!symbol5WildCardWinB) {
        if (symbol === '5' && quantity === 3) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍉', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5, key: uuid() },
          ])
          return
        }
        if (symbol === '5' && quantity === 4) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍉', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 40, key: uuid() },
          ])
          return
        }
        if (symbol === '5' && quantity === 5) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍉', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          return
        }
      }
      if (!symbol6WildCardWinB) {
        if (symbol === '4' && quantity === 3) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🥑', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5, key: uuid() },
          ])
          return
        }
        if (symbol === '4' && quantity === 4) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🥑', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 40, key: uuid() },
          ])
          return
        }
        if (symbol === '4' && quantity === 5) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🥑', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          return
        }
      }
      if (!symbol7WildCardWinB) {
        if (symbol === '3' && quantity === 3) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍊', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5, key: uuid() },
          ])
          return
        }
        if (symbol === '3' && quantity === 4) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍊', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 25, key: uuid() },
          ])
          return
        }
        if (symbol === '3' && quantity === 5) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍊', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          return
        }
      }
      if (!symbol8WildCardWinB) {
        if (symbol === '2' && quantity === 3) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍋', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5, key: uuid() },
          ])
          return
        }
        if (symbol === '2' && quantity === 4) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍋', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 25, key: uuid() },
          ])
          return
        }
        if (symbol === '2' && quantity === 5) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍋', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          return
        }
      }
      if (!symbol9WildCardWinB) {
        if (symbol === '1' && quantity === 3) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍈', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5, key: uuid() },
          ])
          return
        }
        if (symbol === '1' && quantity === 4) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍈', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 25, key: uuid() },
          ])
          return
        }
        if (symbol === '1' && quantity === 5) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍈', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          return
        }
      }
    })
  }

  return null
}

export default CalculateRowBWin
