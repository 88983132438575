import React from 'react'

import './loader.css'

const Loader = () => {
  return (
    <div className="loaderBed">
      <div className="loaderContainer">
        <div>
          <div className="ui huge active inverted inline loader" />
          <div className="loaderText">Loading...</div>
        </div>
      </div>
    </div>
  )
}

export default Loader
