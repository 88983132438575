import React from 'react'
import { FcLock } from 'react-icons/fc'

import './lockScreen.css'

const LockScreen = ({ text }) => {
  return (
    <div className="lockScreenBed">
      <div className="lockScreenContainer">
        <div className="lockScreenIconBed">
          <FcLock className="lockScreenIcon" />
          <div className="lockScreenText">{text}</div>
        </div>
      </div>
    </div>
  )
}

export default LockScreen
