import React, { useContext, useState, useEffect } from 'react'
import _ from 'lodash'

import { Context as PayoutContext } from '../../../../context/PayoutContext'
import { Context as GameContext } from '../../../../context/GameContext'

const PayoutInterceptor = () => {
  const [payoutPercentage, setPayoutPercentage] = useState(0)
  const [payoutPercentageLimit, setPayoutPercentageLimit] = useState(90)
  const [transactionHistoryTenSorted, setTransactionHistoryTenSorted] =
    useState([])
  const [floorCreditAndBankValue, setFloorCreditAndBankValue] = useState()

  const {
    state: { siteTransactionHistory, siteFloorCredits, siteBankValue },
  } = useContext(PayoutContext)

  const {
    state: { prize },
  } = useContext(GameContext)

  useEffect(() => {
    setFloorCreditAndBankValue(siteFloorCredits + siteBankValue)
  }, [siteFloorCredits, siteBankValue])

  useEffect(() => {
    setPayoutPercentage((prize / floorCreditAndBankValue) * 100)
  }, [floorCreditAndBankValue, prize])

  // Still to decide on cycle
  useEffect(() => {
    if (siteTransactionHistory) {
      let array = _.orderBy(
        siteTransactionHistory,
        [(item) => item.created],
        ['desc']
      )
      let arrayTen = array.splice(0, 10)
      setTransactionHistoryTenSorted(arrayTen)
    }
  }, [siteTransactionHistory])

  return null
}

export default PayoutInterceptor
