import React, { useState, useContext, useEffect } from 'react'
import useSound from 'use-sound'

import { Context as GameContext } from '../../../../context/GameContext'
import { Context as PayoutContext } from '../../../../context/PayoutContext'
import { Context as SocketContext } from '../../../../context/SocketContext'
import { Context as UserContext } from '../../../../context/UserContext'
import reelBonus from '../../../../assets/sounds/reel_bonus.mp3'
import './jackpotOverlay.css'

const JackpotOverlay = () => {
  const [claimJackpotCount, setClaimJackpotCount] = useState(0)

  const [playReelBonusSound] = useSound(reelBonus)

  const {
    state: { jackpotOverlayShow, jackpotData, reelSpinning5, gameCredits },
    setJackpotOverlayShow,
    clearJackpot,
  } = useContext(GameContext)

  const {
    state: {
      triggerJackpotOverlay,
      jackpotWon,
      jackpotOverlayValue,
      jackpotClaimed,
    },
    setJackpotOverlayValue,
    claimJackpot,
  } = useContext(PayoutContext)

  const { setSocketDataFromClient } = useContext(SocketContext)

  const {
    state: { user },
  } = useContext(UserContext)

  useEffect(() => {
    if (jackpotWon) {
      setJackpotOverlayValue(jackpotData.jackpot)
    }
  }, [jackpotWon])

  useEffect(() => {
    if (
      triggerJackpotOverlay &&
      !reelSpinning5 &&
      !jackpotClaimed &&
      claimJackpotCount < 1
    ) {
      setTimeout(() => {
        setJackpotOverlayShow(true)
      }, 250)
      let sum = gameCredits + jackpotData.jackpot
      claimJackpot({ creditBalance: sum })
      setClaimJackpotCount(1)
    }
  }, [
    triggerJackpotOverlay,
    reelSpinning5,
    jackpotData,
    gameCredits,
    jackpotClaimed,
    claimJackpotCount,
  ])

  useEffect(() => {
    if (jackpotClaimed) {
      clearJackpot({ siteNumber: user.siteNumber })
      setSocketDataFromClient({
        subject: 'jackpotGiven',
        jackpotGiven: true,
        winningClientUsername: user.username,
      })
      setClaimJackpotCount(0)
    }
  }, [jackpotClaimed])

  const renderAnimation = () => {
    return (
      <div className="jackpotOverlayAnimationBed">
        <div className="jackpotOverlayTextBed">
          <div className="jackpotOverlayTextContainer">
            <div className="jackpotOverlayText">JACKPOT !</div>
          </div>
          <div className="jackpotOverlayTextContainer">
            <div className="jackpotOverlayText">
              R
              {jackpotOverlayValue
                .toString()
                .replace(/.(?=(...........)*..$)/g, '$&.')}
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderContent = () => {
    if (!jackpotOverlayShow) return null
    return (
      <div className="jackpotOverlayBed">
        {playReelBonusSound()}
        <div className="jackpotOverlayTop"></div>
        <div className="jackpotOverlayMiddle">
          <div className="jackpotOverlayMiddleTop">
            <div className="jackpotOverlayTopLineHidden">
              <div className="jackpotOverlayBlinkDots">
                <span id="dot1" className="blink">
                  .
                </span>
                <span id="dot2" className="blink">
                  .
                </span>
                <span id="dot3" className="blink">
                  .
                </span>
                <span id="dot1" className="blink">
                  .
                </span>
                <span id="dot2" className="blink">
                  .
                </span>
                <span id="dot3" className="blink">
                  .
                </span>
                <span id="dot1" className="blink">
                  .
                </span>
                <span id="dot2" className="blink">
                  .
                </span>
                <span id="dot3" className="blink">
                  .
                </span>
                <span id="dot1" className="blink">
                  .
                </span>
                <span id="dot2" className="blink">
                  .
                </span>
                <span id="dot3" className="blink">
                  .
                </span>
                <span id="dot1" className="blink">
                  .
                </span>
                <span id="dot2" className="blink">
                  .
                </span>
                <span id="dot3" className="blink">
                  .
                </span>
                <span id="dot1" className="blink">
                  .
                </span>
                <span id="dot2" className="blink">
                  .
                </span>
                <span id="dot3" className="blink">
                  .
                </span>
              </div>
            </div>
          </div>
          <div className="jackpotOverlayMiddleCenter">
            <div className="jackpotOverlayMiddleLineHidden">
              {renderAnimation()}
            </div>
          </div>
          <div className="jackpotOverlayMiddleBottom">
            <div className="jackpotOverlayBottomLineHidden">
              <div className="jackpotOverlayBlinkDots">
                <span id="dot1" className="blink">
                  .
                </span>
                <span id="dot2" className="blink">
                  .
                </span>
                <span id="dot3" className="blink">
                  .
                </span>
                <span id="dot1" className="blink">
                  .
                </span>
                <span id="dot2" className="blink">
                  .
                </span>
                <span id="dot3" className="blink">
                  .
                </span>
                <span id="dot1" className="blink">
                  .
                </span>
                <span id="dot2" className="blink">
                  .
                </span>
                <span id="dot3" className="blink">
                  .
                </span>
                <span id="dot1" className="blink">
                  .
                </span>
                <span id="dot2" className="blink">
                  .
                </span>
                <span id="dot3" className="blink">
                  .
                </span>
                <span id="dot1" className="blink">
                  .
                </span>
                <span id="dot2" className="blink">
                  .
                </span>
                <span id="dot3" className="blink">
                  .
                </span>
                <span id="dot1" className="blink">
                  .
                </span>
                <span id="dot2" className="blink">
                  .
                </span>
                <span id="dot3" className="blink">
                  .
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="jackpotOverlayBottom"></div>
      </div>
    )
  }

  return renderContent()
}

export default JackpotOverlay
