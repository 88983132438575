export const fruitsArray = [
  { id: 1, symbol: '🍈' },
  { id: 2, symbol: '🍋' },
  { id: 3, symbol: '🍊' },
  { id: 4, symbol: '🥑' },
  { id: 5, symbol: '🍉' },
  { id: 6, symbol: '🍌' },
  { id: 7, symbol: '🍇' },
  { id: 8, symbol: '🍒' },
  { id: 9, symbol: '🍓' },
  { id: 10, symbol: '🍀' },
  { id: 11, symbol: '🔔' },
  { id: 12, symbol: ' 🍀' },
]

// export const fruitsArray = [
//   { id: 1, symbol: '🍓' },
//   { id: 2, symbol: '🍇' },
//   { id: 3, symbol: '🍒' },
//   { id: 4, symbol: '🍌' },
//   { id: 5, symbol: '🍉' },
//   { id: 6, symbol: '🥑' },
//   { id: 7, symbol: '🍊' },
//   { id: 8, symbol: '🍋' },
//   { id: 9, symbol: '🍈' },
//   { id: 10, symbol: '🍀' },
//   { id: 11, symbol: '🔔' },
// ]

export const fruitsSpinningArray1 = [
  { id: 1, symbol: '🍇' },
  { id: 2, symbol: '🍉' },
  { id: 3, symbol: '🍒' },
  { id: 4, symbol: '🍓' },
  { id: 5, symbol: '🍈' },
  { id: 6, symbol: '🍊' },
  { id: 7, symbol: '🍋' },
  { id: 8, symbol: '🍌' },
  { id: 9, symbol: '🍍' },
  { id: 10, symbol: '🥭' },
  { id: 11, symbol: '🍎' },
  { id: 12, symbol: '🍏' },
  { id: 13, symbol: '🍐' },
  { id: 14, symbol: '🍑' },
  { id: 15, symbol: '🥝' },
  { id: 16, symbol: '🍅' },
  { id: 17, symbol: '🥥' },
  { id: 18, symbol: '🥑' },
  { id: 19, symbol: '🍇' },
  { id: 20, symbol: '🍉' },
  { id: 21, symbol: '🍒' },
  { id: 22, symbol: '🍓' },
  { id: 23, symbol: '🍈' },
  { id: 24, symbol: '🍊' },
  { id: 25, symbol: '🍋' },
  { id: 26, symbol: '🍌' },
  { id: 27, symbol: '🍍' },
  { id: 28, symbol: '🥭' },
  { id: 29, symbol: '🍎' },
  { id: 30, symbol: '🍏' },
  { id: 31, symbol: '🍐' },
  { id: 32, symbol: '🍑' },
  { id: 33, symbol: '🥝' },
  { id: 34, symbol: '🍅' },
  { id: 35, symbol: '🥥' },
  { id: 36, symbol: '🥑' },
  { id: 37, symbol: '🍇' },
  { id: 38, symbol: '🍉' },
  { id: 39, symbol: '🍒' },
  { id: 40, symbol: '🍓' },
  { id: 41, symbol: '🍈' },
  { id: 42, symbol: '🍊' },
  { id: 43, symbol: '🍋' },
  { id: 44, symbol: '🍌' },
  { id: 45, symbol: '🍍' },
  { id: 46, symbol: '🥭' },
  { id: 47, symbol: '🍎' },
  { id: 48, symbol: '🍏' },
  { id: 49, symbol: '🍐' },
  { id: 50, symbol: '🍑' },
  { id: 51, symbol: '🥝' },
  { id: 52, symbol: '🍅' },
  { id: 53, symbol: '🥥' },
  { id: 54, symbol: '🥑' },
  { id: 55, symbol: '🍇' },
  { id: 56, symbol: '🍉' },
  { id: 57, symbol: '🍒' },
  { id: 58, symbol: '🍓' },
  { id: 59, symbol: '🍈' },
  { id: 60, symbol: '🍊' },
  { id: 61, symbol: '🍋' },
  { id: 62, symbol: '🍌' },
  { id: 63, symbol: '🍍' },
  { id: 64, symbol: '🥭' },
  { id: 65, symbol: '🍎' },
  { id: 66, symbol: '🍏' },
  { id: 67, symbol: '🍐' },
  { id: 68, symbol: '🍑' },
  { id: 69, symbol: '🥝' },
  { id: 70, symbol: '🍅' },
  { id: 71, symbol: '🥥' },
  { id: 72, symbol: '🥑' },
  { id: 73, symbol: '🍇' },
  { id: 74, symbol: '🍉' },
  { id: 75, symbol: '🍒' },
  { id: 76, symbol: '🍓' },
  { id: 77, symbol: '🍈' },
  { id: 78, symbol: '🍊' },
  { id: 79, symbol: '🍋' },
  { id: 80, symbol: '🍌' },
  { id: 81, symbol: '🍍' },
  { id: 82, symbol: '🥭' },
  { id: 83, symbol: '🍎' },
  { id: 84, symbol: '🍏' },
  { id: 85, symbol: '🍐' },
  { id: 86, symbol: '🍑' },
  { id: 87, symbol: '🥝' },
  { id: 88, symbol: '🍅' },
  { id: 89, symbol: '🥥' },
  { id: 90, symbol: '🥑' },
  { id: 91, symbol: '🍇' },
  { id: 92, symbol: '🍉' },
  { id: 93, symbol: '🍒' },
  { id: 94, symbol: '🍓' },
  { id: 95, symbol: '🍈' },
  { id: 96, symbol: '🍊' },
  { id: 97, symbol: '🍋' },
  { id: 98, symbol: '🍌' },
  { id: 99, symbol: '🍍' },
  { id: 100, symbol: '🥭' },
  { id: 101, symbol: '🍎' },
  { id: 102, symbol: '🍏' },
  { id: 103, symbol: '🍐' },
  { id: 104, symbol: '🍑' },
  { id: 105, symbol: '🥝' },
  { id: 106, symbol: '🍅' },
  { id: 107, symbol: '🥥' },
  { id: 108, symbol: '🥑' },
  { id: 109, symbol: '🍇' },
  { id: 110, symbol: '🍉' },
  { id: 111, symbol: '🍒' },
  { id: 112, symbol: '🍓' },
  { id: 113, symbol: '🍈' },
  { id: 114, symbol: '🍊' },
  { id: 115, symbol: '🍋' },
  { id: 116, symbol: '🍌' },
  { id: 117, symbol: '🍍' },
  { id: 118, symbol: '🥭' },
  { id: 119, symbol: '🍎' },
  { id: 120, symbol: '🍏' },
  { id: 121, symbol: '🍐' },
  { id: 122, symbol: '🍑' },
  { id: 123, symbol: '🥝' },
  { id: 124, symbol: '🍅' },
  { id: 125, symbol: '🥥' },
  { id: 126, symbol: '🥑' },
  { id: 127, symbol: '🍇' },
  { id: 128, symbol: '🍉' },
  { id: 129, symbol: '🍒' },
  { id: 130, symbol: '🍓' },
  { id: 131, symbol: '🍈' },
  { id: 132, symbol: '🍊' },
  { id: 133, symbol: '🍋' },
  { id: 134, symbol: '🍌' },
  { id: 135, symbol: '🍍' },
  { id: 136, symbol: '🥭' },
  { id: 137, symbol: '🍎' },
  { id: 138, symbol: '🍏' },
  { id: 139, symbol: '🍐' },
  { id: 140, symbol: '🍑' },
  { id: 141, symbol: '🥝' },
  { id: 142, symbol: '🍅' },
  { id: 143, symbol: '🥥' },
  { id: 144, symbol: '🥑' },
  { id: 145, symbol: '🍇' },
  { id: 146, symbol: '🍉' },
  { id: 147, symbol: '🍒' },
  { id: 148, symbol: '🍓' },
  { id: 149, symbol: '🍈' },
  { id: 150, symbol: '🍊' },
  { id: 151, symbol: '🍋' },
  { id: 152, symbol: '🍌' },
  { id: 153, symbol: '🍍' },
  { id: 154, symbol: '🥭' },
  { id: 155, symbol: '🍎' },
  { id: 156, symbol: '🍏' },
  { id: 157, symbol: '🍐' },
  { id: 158, symbol: '🍑' },
  { id: 159, symbol: '🥝' },
  { id: 160, symbol: '🍅' },
  { id: 161, symbol: '🥥' },
  { id: 162, symbol: '🥑' },
  { id: 163, symbol: '🍇' },
  { id: 164, symbol: '🍉' },
  { id: 165, symbol: '🍒' },
  { id: 166, symbol: '🍓' },
  { id: 167, symbol: '🍈' },
  { id: 168, symbol: '🍊' },
  { id: 169, symbol: '🍋' },
  { id: 170, symbol: '🍌' },
  { id: 171, symbol: '🍍' },
  { id: 172, symbol: '🥭' },
  { id: 173, symbol: '🍎' },
  { id: 174, symbol: '🍏' },
  { id: 175, symbol: '🍐' },
  { id: 176, symbol: '🍑' },
  { id: 177, symbol: '🥝' },
  { id: 178, symbol: '🍅' },
  { id: 179, symbol: '🥥' },
  { id: 180, symbol: '🥑' },
  { id: 181, symbol: '🍇' },
  { id: 182, symbol: '🍉' },
  { id: 183, symbol: '🍒' },
  { id: 184, symbol: '🍓' },
  { id: 185, symbol: '🍈' },
  { id: 186, symbol: '🍊' },
  { id: 187, symbol: '🍋' },
  { id: 188, symbol: '🍌' },
  { id: 189, symbol: '🍍' },
  { id: 190, symbol: '🥭' },
  { id: 191, symbol: '🍎' },
  { id: 192, symbol: '🍏' },
  { id: 193, symbol: '🍐' },
  { id: 194, symbol: '🍑' },
  { id: 195, symbol: '🥝' },
  { id: 196, symbol: '🍅' },
  { id: 197, symbol: '🥥' },
  { id: 198, symbol: '🥑' },
  { id: 199, symbol: '🍇' },
  { id: 200, symbol: '🍉' },
  { id: 201, symbol: '🍒' },
  { id: 202, symbol: '🍓' },
  { id: 203, symbol: '🍈' },
  { id: 204, symbol: '🍊' },
  { id: 205, symbol: '🍋' },
  { id: 206, symbol: '🍌' },
  { id: 207, symbol: '🍍' },
  { id: 208, symbol: '🥭' },
  { id: 209, symbol: '🍎' },
  { id: 210, symbol: '🍏' },
  { id: 211, symbol: '🍐' },
  { id: 212, symbol: '🍑' },
  { id: 213, symbol: '🥝' },
  { id: 214, symbol: '🍅' },
  { id: 215, symbol: '🥥' },
  { id: 216, symbol: '🥑' },
  { id: 217, symbol: '🍇' },
  { id: 218, symbol: '🍉' },
  { id: 219, symbol: '🍒' },
  { id: 220, symbol: '🍓' },
  { id: 221, symbol: '🍈' },
  { id: 222, symbol: '🍊' },
  { id: 223, symbol: '🍋' },
  { id: 224, symbol: '🍌' },
  { id: 225, symbol: '🍍' },
  { id: 226, symbol: '🥭' },
  { id: 227, symbol: '🍎' },
  { id: 228, symbol: '🍏' },
  { id: 229, symbol: '🍐' },
  { id: 230, symbol: '🍑' },
  { id: 231, symbol: '🥝' },
  { id: 232, symbol: '🍅' },
  { id: 233, symbol: '🥥' },
  { id: 234, symbol: '🥑' },
  { id: 235, symbol: '🍇' },
  { id: 236, symbol: '🍉' },
  { id: 237, symbol: '🍒' },
  { id: 238, symbol: '🍓' },
  { id: 239, symbol: '🍈' },
  { id: 240, symbol: '🍊' },
  { id: 241, symbol: '🍋' },
  { id: 242, symbol: '🍌' },
  { id: 243, symbol: '🍍' },
  { id: 244, symbol: '🥭' },
  { id: 245, symbol: '🍎' },
  { id: 246, symbol: '🍏' },
  { id: 247, symbol: '🍐' },
  { id: 248, symbol: '🍑' },
  { id: 249, symbol: '🥝' },
  { id: 250, symbol: '🍅' },
  { id: 251, symbol: '🥥' },
  { id: 252, symbol: '🥑' },
  { id: 253, symbol: '🍇' },
  { id: 254, symbol: '🍉' },
  { id: 255, symbol: '🍒' },
  { id: 256, symbol: '🍓' },
  { id: 257, symbol: '🍈' },
  { id: 258, symbol: '🍊' },
  { id: 259, symbol: '🍋' },
  { id: 260, symbol: '🍌' },
  { id: 261, symbol: '🍍' },
  { id: 262, symbol: '🥭' },
  { id: 263, symbol: '🍎' },
  { id: 264, symbol: '🍏' },
  { id: 265, symbol: '🍐' },
  { id: 266, symbol: '🍑' },
  { id: 267, symbol: '🥝' },
  { id: 268, symbol: '🍅' },
  { id: 269, symbol: '🥥' },
  { id: 270, symbol: '🥑' },
  { id: 271, symbol: '🍇' },
  { id: 272, symbol: '🍉' },
  { id: 273, symbol: '🍒' },
  { id: 274, symbol: '🍓' },
  { id: 275, symbol: '🍈' },
  { id: 276, symbol: '🍊' },
  { id: 277, symbol: '🍋' },
  { id: 278, symbol: '🍌' },
  { id: 279, symbol: '🍍' },
  { id: 280, symbol: '🥭' },
  { id: 281, symbol: '🍎' },
  { id: 282, symbol: '🍏' },
  { id: 283, symbol: '🍐' },
  { id: 284, symbol: '🍑' },
  { id: 285, symbol: '🥝' },
  { id: 286, symbol: '🍅' },
  { id: 287, symbol: '🥥' },
  { id: 288, symbol: '🥑' },
  { id: 289, symbol: '🍇' },
  { id: 290, symbol: '🍉' },
  { id: 291, symbol: '🍒' },
  { id: 292, symbol: '🍓' },
  { id: 293, symbol: '🍈' },
  { id: 294, symbol: '🍊' },
  { id: 295, symbol: '🍋' },
  { id: 296, symbol: '🍌' },
  { id: 297, symbol: '🍍' },
  { id: 298, symbol: '🥭' },
  { id: 299, symbol: '🍎' },
  { id: 300, symbol: '🍏' },
  { id: 301, symbol: '🍐' },
  { id: 302, symbol: '🍑' },
  { id: 303, symbol: '🥝' },
  { id: 304, symbol: '🍅' },
  { id: 305, symbol: '🥥' },
  { id: 306, symbol: '🥑' },
  { id: 307, symbol: '🍇' },
  { id: 308, symbol: '🍉' },
  { id: 309, symbol: '🍒' },
  { id: 310, symbol: '🍓' },
  { id: 311, symbol: '🍈' },
  { id: 312, symbol: '🍊' },
  { id: 313, symbol: '🍋' },
  { id: 314, symbol: '🍌' },
  { id: 315, symbol: '🍍' },
  { id: 316, symbol: '🥭' },
  { id: 317, symbol: '🍎' },
  { id: 318, symbol: '🍏' },
  { id: 319, symbol: '🍐' },
  { id: 320, symbol: '🍑' },
  { id: 321, symbol: '🥝' },
  { id: 322, symbol: '🍅' },
  { id: 323, symbol: '🥥' },
  { id: 324, symbol: '🥑' },
  { id: 325, symbol: '🍇' },
  { id: 326, symbol: '🍉' },
  { id: 327, symbol: '🍒' },
  { id: 328, symbol: '🍓' },
  { id: 329, symbol: '🍈' },
  { id: 330, symbol: '🍊' },
  { id: 331, symbol: '🍋' },
  { id: 332, symbol: '🍌' },
  { id: 333, symbol: '🍍' },
  { id: 334, symbol: '🥭' },
  { id: 335, symbol: '🍎' },
  { id: 336, symbol: '🍏' },
  { id: 337, symbol: '🍐' },
  { id: 338, symbol: '🍑' },
  { id: 339, symbol: '🥝' },
  { id: 340, symbol: '🍅' },
  { id: 341, symbol: '🥥' },
  { id: 342, symbol: '🥑' },
  { id: 343, symbol: '🍇' },
  { id: 344, symbol: '🍉' },
  { id: 345, symbol: '🍒' },
  { id: 346, symbol: '🍓' },
  { id: 347, symbol: '🍈' },
  { id: 348, symbol: '🍊' },
  { id: 349, symbol: '🍋' },
  { id: 350, symbol: '🍌' },
  { id: 351, symbol: '🍍' },
  { id: 352, symbol: '🥭' },
  { id: 353, symbol: '🍎' },
  { id: 354, symbol: '🍏' },
  { id: 355, symbol: '🍐' },
  { id: 356, symbol: '🍑' },
  { id: 357, symbol: '🥝' },
  { id: 358, symbol: '🍅' },
  { id: 359, symbol: '🥥' },
  { id: 360, symbol: '🥑' },
  { id: 361, symbol: '🍇' },
  { id: 362, symbol: '🍉' },
  { id: 363, symbol: '🍒' },
  { id: 364, symbol: '🍓' },
  { id: 365, symbol: '🍈' },
  { id: 366, symbol: '🍊' },
  { id: 367, symbol: '🍋' },
  { id: 368, symbol: '🍌' },
  { id: 369, symbol: '🍍' },
  { id: 370, symbol: '🥭' },
  { id: 371, symbol: '🍎' },
  { id: 372, symbol: '🍏' },
  { id: 373, symbol: '🍐' },
  { id: 374, symbol: '🍑' },
  { id: 375, symbol: '🥝' },
  { id: 376, symbol: '🍅' },
  { id: 377, symbol: '🥥' },
  { id: 378, symbol: '🥑' },
  { id: 379, symbol: '🍇' },
  { id: 380, symbol: '🍉' },
  { id: 381, symbol: '🍒' },
  { id: 382, symbol: '🍓' },
  { id: 383, symbol: '🍈' },
  { id: 384, symbol: '🍊' },
  { id: 385, symbol: '🍋' },
  { id: 386, symbol: '🍌' },
  { id: 387, symbol: '🍍' },
  { id: 388, symbol: '🥭' },
  { id: 389, symbol: '🍎' },
  { id: 390, symbol: '🍏' },
  { id: 391, symbol: '🍐' },
  { id: 392, symbol: '🍑' },
  { id: 393, symbol: '🥝' },
  { id: 394, symbol: '🍅' },
  { id: 395, symbol: '🥥' },
  { id: 396, symbol: '🥑' },
  { id: 397, symbol: '🍇' },
  { id: 398, symbol: '🍉' },
  { id: 399, symbol: '🍒' },
  { id: 400, symbol: '🍓' },
  { id: 401, symbol: '🍈' },
  { id: 402, symbol: '🍊' },
  { id: 403, symbol: '🍋' },
  { id: 404, symbol: '🍌' },
  { id: 405, symbol: '🍍' },
  { id: 406, symbol: '🥭' },
  { id: 407, symbol: '🍎' },
  { id: 408, symbol: '🍏' },
  { id: 409, symbol: '🍐' },
  { id: 410, symbol: '🍑' },
  { id: 411, symbol: '🥝' },
  { id: 412, symbol: '🍅' },
  { id: 413, symbol: '🥥' },
  { id: 414, symbol: '🥑' },
  { id: 415, symbol: '🔔' },
]
