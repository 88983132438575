import { useContext, useEffect } from 'react'

import { Context as GameContext } from '../../../../../context/GameContext'
import { Context as PayoutContext } from '../../../../../context/PayoutContext'

const RandomNumbers = () => {
  const {
    state: {
      randomNumberA1,
      randomNumberA2,
      randomNumberA3,
      randomNumberA4,
      randomNumberA5,
      randomNumberB1,
      randomNumberB2,
      randomNumberB3,
      randomNumberB4,
      randomNumberB5,
      randomNumberC1,
      randomNumberC2,
      randomNumberC3,
      randomNumberC4,
      randomNumberC5,
    },
    setRandomNumberA1,
    setRandomNumberA2,
    setRandomNumberA3,
    setRandomNumberA4,
    setRandomNumberA5,
    setRandomNumberB1,
    setRandomNumberB2,
    setRandomNumberB3,
    setRandomNumberB4,
    setRandomNumberB5,
    setRandomNumberC1,
    setRandomNumberC2,
    setRandomNumberC3,
    setRandomNumberC4,
    setRandomNumberC5,
    setAllRandomNumbersDone,
    reelSpinning5,
  } = useContext(GameContext)

  const {
    state: { payoutOverLimitStartReplay },
  } = useContext(PayoutContext)

  useEffect(() => {
    if (
      randomNumberA1 > 0 &&
      randomNumberA2 > 0 &&
      randomNumberA3 > 0 &&
      randomNumberA4 > 0 &&
      randomNumberA5 > 0 &&
      randomNumberB1 > 0 &&
      randomNumberB2 > 0 &&
      randomNumberB3 > 0 &&
      randomNumberB4 > 0 &&
      randomNumberB5 > 0 &&
      randomNumberC1 > 0 &&
      randomNumberC2 > 0 &&
      randomNumberC3 > 0 &&
      randomNumberC4 > 0 &&
      randomNumberC5 > 0
    ) {
      setAllRandomNumbersDone(true)
    }
  }, [
    randomNumberA1,
    randomNumberA2,
    randomNumberA3,
    randomNumberA4,
    randomNumberA5,
    randomNumberB1,
    randomNumberB2,
    randomNumberB3,
    randomNumberB4,
    randomNumberB5,
    randomNumberC1,
    randomNumberC2,
    randomNumberC3,
    randomNumberC4,
    randomNumberC5,
  ])

  useEffect(() => {
    if (payoutOverLimitStartReplay || !reelSpinning5) {
      setRandomNumberA1(0)
      setRandomNumberA2(0)
      setRandomNumberA3(0)
      setRandomNumberA4(0)
      setRandomNumberA5(0)
      setRandomNumberB1(0)
      setRandomNumberB2(0)
      setRandomNumberB3(0)
      setRandomNumberB4(0)
      setRandomNumberB5(0)
      setRandomNumberC1(0)
      setRandomNumberC2(0)
      setRandomNumberC3(0)
      setRandomNumberC4(0)
      setRandomNumberC5(0)
      setAllRandomNumbersDone(false)
    }
  }, [payoutOverLimitStartReplay, reelSpinning5])

  return null
}

export default RandomNumbers
