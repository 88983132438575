import React, { useContext, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import { Context as GameContext } from '../../../context/GameContext'
import { Context as NavContext } from '../../../context/NavContext'

import './faces.css'

const Faces = () => {
  const [spinningSymbol, setSpinningSymbol] = useState()

  const { setVisibleComponent } = useContext(NavContext)

  const {
    state: {
      gameCredits,
      betValue,
      betTotal,
      numberOfLines,
      condensedNumberA1,
      condensedNumberA2,
      condensedNumberA3,
      condensedNumberA4,
      condensedNumberA5,
      condensedNumberB1,
      condensedNumberB2,
      condensedNumberB3,
      condensedNumberB4,
      condensedNumberB5,
      condensedNumberC1,
      condensedNumberC2,
      condensedNumberC3,
      condensedNumberC4,
      condensedNumberC5,
      allRandomNumbersDone,
      symbolLandedA1,
      symbolLandedA2,
      symbolLandedA3,
      symbolLandedA4,
      symbolLandedA5,
      symbolLandedB1,
      symbolLandedB2,
      symbolLandedB3,
      symbolLandedB4,
      symbolLandedB5,
      symbolLandedC1,
      symbolLandedC2,
      symbolLandedC3,
      symbolLandedC4,
      symbolLandedC5,
      symbolLandedD1,
      symbolLandedD2,
      symbolLandedD3,
      symbolLandedD4,
      symbolLandedD5,
      symbolLandedE1,
      symbolLandedE2,
      symbolLandedE3,
      symbolLandedE4,
      symbolLandedE5,
      reelSpinning1,
      reelSpinning2,
      reelSpinning5,
      scatterWinOverlayShow,
      freeSpinPlayOn,
      freeSpinsTotal,
      prize,
      symbolWinClassRowA1,
      symbolWinClassRowA2,
      symbolWinClassRowA3,
      symbolWinClassRowA4,
      symbolWinClassRowA5,
      symbolWinClassRowB1,
      symbolWinClassRowB2,
      symbolWinClassRowB3,
      symbolWinClassRowB4,
      symbolWinClassRowB5,
      symbolWinClassRowC1,
      symbolWinClassRowC2,
      symbolWinClassRowC3,
      symbolWinClassRowC4,
      symbolWinClassRowC5,
      symbolWinClassRowD1,
      symbolWinClassRowD2,
      symbolWinClassRowD3,
      symbolWinClassRowD4,
      symbolWinClassRowD5,
      symbolWinClassRowE1,
      symbolWinClassRowE2,
      symbolWinClassRowE3,
      symbolWinClassRowE4,
      symbolWinClassRowE5,
    },
    setNameOfGame,
    setGameCredits,
    setBetTotal,
    setNumberOfLines,
    setRandomNumberA1,
    setRandomNumberA2,
    setRandomNumberA3,
    setRandomNumberA4,
    setRandomNumberA5,
    setRandomNumberB1,
    setRandomNumberB2,
    setRandomNumberB3,
    setRandomNumberB4,
    setRandomNumberB5,
    setRandomNumberC1,
    setRandomNumberC2,
    setRandomNumberC3,
    setRandomNumberC4,
    setRandomNumberC5,
    setAllRandomNumbersDone,
    setSymbolLandedA1,
    setSymbolLandedA2,
    setSymbolLandedA3,
    setSymbolLandedA4,
    setSymbolLandedA5,
    setSymbolLandedB1,
    setSymbolLandedB2,
    setSymbolLandedB3,
    setSymbolLandedB4,
    setSymbolLandedB5,
    setSymbolLandedC1,
    setSymbolLandedC2,
    setSymbolLandedC3,
    setSymbolLandedC4,
    setSymbolLandedC5,
    setLinesOverlayShow,
    setReelSpinning1,
    setReelSpinning2,
    setReelSpinning5,
    setRowAResult,
    setRowBResult,
    setRowCResult,
    setRowAWinArray,
    setRowBWinArray,
    setRowCWinArray,
    setWinningSymbolRowA,
    setWinningSymbolRowB,
    setWinningSymbolRowC,
    setRowAPrizeArray,
    setRowBPrizeArray,
    setRowCPrizeArray,
    setBetValue,
    setFreeSpinsTotal,
    setPrize,
    setWildCardQuantityRowA,
    setWildCardQuantityRowB,
    setWildCardQuantityRowC,
    setScatterQuantityRowA,
    setScatterQuantityRowB,
    setScatterQuantityRowC,
    setRowAPrize,
    setRowBPrize,
    setRowCPrize,
    setSymbolWinClassRowA1,
    setSymbolWinClassRowA2,
    setSymbolWinClassRowA3,
    setSymbolWinClassRowA4,
    setSymbolWinClassRowA5,
    setSymbolWinClassRowB1,
    setSymbolWinClassRowB2,
    setSymbolWinClassRowB3,
    setSymbolWinClassRowB4,
    setSymbolWinClassRowB5,
    setSymbolWinClassRowC1,
    setSymbolWinClassRowC2,
    setSymbolWinClassRowC3,
    setSymbolWinClassRowC4,
    setSymbolWinClassRowC5,
    checkUserLock,
    setSymbol1WildCardWinA,
    setSymbol2WildCardWinA,
    setSymbol3WildCardWinA,
    setSymbol4WildCardWinA,
    setSymbol5WildCardWinA,
    setSymbol6WildCardWinA,
    setSymbol7WildCardWinA,
    setSymbol8WildCardWinA,
    setSymbol9WildCardWinA,
    setSymbol1WildCardWinB,
    setSymbol2WildCardWinB,
    setSymbol3WildCardWinB,
    setSymbol4WildCardWinB,
    setSymbol5WildCardWinB,
    setSymbol6WildCardWinB,
    setSymbol7WildCardWinB,
    setSymbol8WildCardWinB,
    setSymbol9WildCardWinB,
    setSymbol1WildCardWinC,
    setSymbol2WildCardWinC,
    setSymbol3WildCardWinC,
    setSymbol4WildCardWinC,
    setSymbol5WildCardWinC,
    setSymbol6WildCardWinC,
    setSymbol7WildCardWinC,
    setSymbol8WildCardWinC,
    setSymbol9WildCardWinC,
    resetAllGameState,
  } = useContext(GameContext)

  const renderSymbol = (placeholder, symbolLanded, reelSpinning) => {
    if (!symbolLanded && !reelSpinning) return <>{placeholder}</>
    if (reelSpinning) return spinningSymbol
    return symbolLanded
  }

  const closeGame = () => {
    if (reelSpinning5 || freeSpinPlayOn) return null
    setVisibleComponent('gamesMenu')
  }

  const reel1 = () => {
    return (
      <div className="facesReelsCenterBed">
        <div className="facesReelsCenterContainer">
          <div className="facesReel">
            <div
              className={
                !symbolWinClassRowA1 ? 'reelSymbolBed' : 'reelSymbolBedWinRowA'
              }
            >
              <div
                className={
                  reelSpinning1
                    ? 'reelSymbolContainerSpin'
                    : 'reelSymbolContainerStop'
                }
              >
                <div className="facesReelSymbol">
                  {renderSymbol('🥶', symbolLandedA1, reelSpinning1)}
                </div>
              </div>
            </div>
            <div
              className={
                !symbolWinClassRowB1 ? 'reelSymbolBed' : 'reelSymbolBedWinRowB'
              }
            >
              <div
                className={
                  reelSpinning1
                    ? 'reelSymbolContainerSpin'
                    : 'reelSymbolContainerStop'
                }
              >
                <div className="facesReelSymbol">
                  {renderSymbol('🤑', symbolLandedB1, reelSpinning1)}
                </div>
              </div>
            </div>
            <div
              className={
                !symbolWinClassRowC1 ? 'reelSymbolBed' : 'reelSymbolBedWinRowC'
              }
            >
              <div
                className={
                  reelSpinning1
                    ? 'reelSymbolContainerSpin'
                    : 'reelSymbolContainerStop'
                }
              >
                <div className="facesReelSymbol">
                  {renderSymbol('🤠', symbolLandedC1, reelSpinning1)}
                </div>
              </div>
            </div>
            <div
              className={
                !symbolWinClassRowD1 ? 'reelSymbolBed' : 'reelSymbolBedWinRowD'
              }
            >
              <div
                className={
                  reelSpinning1
                    ? 'reelSymbolContainerSpin'
                    : 'reelSymbolContainerStop'
                }
              >
                <div className="facesReelSymbol">
                  {renderSymbol('🥳', symbolLandedD1, reelSpinning1)}
                </div>
              </div>
            </div>
            <div
              className={
                !symbolWinClassRowE1 ? 'reelSymbolBed' : 'reelSymbolBedWinRowE'
              }
            >
              <div
                className={
                  reelSpinning1
                    ? 'reelSymbolContainerSpin'
                    : 'reelSymbolContainerStop'
                }
              >
                <div className="facesReelSymbol">
                  {renderSymbol('🤪', symbolLandedE1, reelSpinning1)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const reel2 = () => {
    return (
      <div className="facesReelsCenterBed">
        <div className="facesReelsCenterContainer">
          <div className="facesReel">
            <div
              className={
                !symbolWinClassRowA2 ? 'reelSymbolBed' : 'reelSymbolBedWinRowA'
              }
            >
              <div
                className={
                  reelSpinning1
                    ? 'reelSymbolContainerSpin'
                    : 'reelSymbolContainerStop'
                }
              >
                <div className="facesReelSymbol">
                  {renderSymbol('🤪', symbolLandedA2, reelSpinning1)}
                </div>
              </div>
            </div>
            <div
              className={
                !symbolWinClassRowB2 ? 'reelSymbolBed' : 'reelSymbolBedWinRowB'
              }
            >
              <div
                className={
                  reelSpinning1
                    ? 'reelSymbolContainerSpin'
                    : 'reelSymbolContainerStop'
                }
              >
                <div className="facesReelSymbol">
                  {renderSymbol('😁', symbolLandedB2, reelSpinning1)}
                </div>
              </div>
            </div>
            <div
              className={
                !symbolWinClassRowC2 ? 'reelSymbolBed' : 'reelSymbolBedWinRowC'
              }
            >
              <div
                className={
                  reelSpinning1
                    ? 'reelSymbolContainerSpin'
                    : 'reelSymbolContainerStop'
                }
              >
                <div className="facesReelSymbol">
                  {renderSymbol('🤪', symbolLandedC2, reelSpinning1)}
                </div>
              </div>
            </div>
            <div
              className={
                !symbolWinClassRowD2 ? 'reelSymbolBed' : 'reelSymbolBedWinRowD'
              }
            >
              <div
                className={
                  reelSpinning1
                    ? 'reelSymbolContainerSpin'
                    : 'reelSymbolContainerStop'
                }
              >
                <div className="facesReelSymbol">
                  {renderSymbol('🥳', symbolLandedD2, reelSpinning1)}
                </div>
              </div>
            </div>
            <div
              className={
                !symbolWinClassRowE2 ? 'reelSymbolBed' : 'reelSymbolBedWinRowE'
              }
            >
              <div
                className={
                  reelSpinning1
                    ? 'reelSymbolContainerSpin'
                    : 'reelSymbolContainerStop'
                }
              >
                <div className="facesReelSymbol">
                  {renderSymbol('🥶', symbolLandedE2, reelSpinning1)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const reel3 = () => {
    return (
      <div className="facesReelsCenterBed">
        <div className="facesReelsCenterContainer">
          <div className="facesReel">
            <div
              className={
                !symbolWinClassRowA3 ? 'reelSymbolBed' : 'reelSymbolBedWinRowA'
              }
            >
              <div
                className={
                  reelSpinning1
                    ? 'reelSymbolContainerSpin'
                    : 'reelSymbolContainerStop'
                }
              >
                <div className="facesReelSymbol">
                  {renderSymbol('🤣', symbolLandedA3, reelSpinning1)}
                </div>
              </div>
            </div>
            <div
              className={
                !symbolWinClassRowB3 ? 'reelSymbolBed' : 'reelSymbolBedWinRowB'
              }
            >
              <div
                className={
                  reelSpinning1
                    ? 'reelSymbolContainerSpin'
                    : 'reelSymbolContainerStop'
                }
              >
                <div className="facesReelSymbol">
                  {renderSymbol('😏', symbolLandedB3, reelSpinning1)}
                </div>
              </div>
            </div>
            <div
              className={
                !symbolWinClassRowC3 ? 'reelSymbolBed' : 'reelSymbolBedWinRowC'
              }
            >
              <div
                className={
                  reelSpinning1
                    ? 'reelSymbolContainerSpin'
                    : 'reelSymbolContainerStop'
                }
              >
                <div className="facesReelSymbol">
                  {renderSymbol('🥰', symbolLandedC3, reelSpinning1)}
                </div>
              </div>
            </div>
            <div
              className={
                !symbolWinClassRowD3 ? 'reelSymbolBed' : 'reelSymbolBedWinRowD'
              }
            >
              <div
                className={
                  reelSpinning1
                    ? 'reelSymbolContainerSpin'
                    : 'reelSymbolContainerStop'
                }
              >
                <div className="facesReelSymbol">
                  {renderSymbol('😏', symbolLandedD3, reelSpinning1)}
                </div>
              </div>
            </div>
            <div
              className={
                !symbolWinClassRowE3 ? 'reelSymbolBed' : 'reelSymbolBedWinRowE'
              }
            >
              <div
                className={
                  reelSpinning1
                    ? 'reelSymbolContainerSpin'
                    : 'reelSymbolContainerStop'
                }
              >
                <div className="facesReelSymbol">
                  {renderSymbol('💩', symbolLandedE3, reelSpinning1)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const reel4 = () => {
    return (
      <div className="facesReelsCenterBed">
        <div className="facesReelsCenterContainer">
          <div className="facesReel">
            <div
              className={
                !symbolWinClassRowA4 ? 'reelSymbolBed' : 'reelSymbolBedWinRowA'
              }
            >
              <div
                className={
                  reelSpinning1
                    ? 'reelSymbolContainerSpin'
                    : 'reelSymbolContainerStop'
                }
              >
                <div className="facesReelSymbol">
                  {renderSymbol('😁', symbolLandedA4, reelSpinning1)}
                </div>
              </div>
            </div>
            <div
              className={
                !symbolWinClassRowB4 ? 'reelSymbolBed' : 'reelSymbolBedWinRowB'
              }
            >
              <div
                className={
                  reelSpinning1
                    ? 'reelSymbolContainerSpin'
                    : 'reelSymbolContainerStop'
                }
              >
                <div className="facesReelSymbol">
                  {renderSymbol('🥶', symbolLandedB4, reelSpinning1)}
                </div>
              </div>
            </div>
            <div
              className={
                !symbolWinClassRowC4 ? 'reelSymbolBed' : 'reelSymbolBedWinRowC'
              }
            >
              <div
                className={
                  reelSpinning1
                    ? 'reelSymbolContainerSpin'
                    : 'reelSymbolContainerStop'
                }
              >
                <div className="facesReelSymbol">
                  {renderSymbol('🥶', symbolLandedC4, reelSpinning1)}
                </div>
              </div>
            </div>
            <div
              className={
                !symbolWinClassRowD4 ? 'reelSymbolBed' : 'reelSymbolBedWinRowD'
              }
            >
              <div
                className={
                  reelSpinning1
                    ? 'reelSymbolContainerSpin'
                    : 'reelSymbolContainerStop'
                }
              >
                <div className="facesReelSymbol">
                  {renderSymbol('🤠', symbolLandedD4, reelSpinning1)}
                </div>
              </div>
            </div>
            <div
              className={
                !symbolWinClassRowE4 ? 'reelSymbolBed' : 'reelSymbolBedWinRowE'
              }
            >
              <div
                className={
                  reelSpinning1
                    ? 'reelSymbolContainerSpin'
                    : 'reelSymbolContainerStop'
                }
              >
                <div className="facesReelSymbol">
                  {renderSymbol('🤑', symbolLandedE4, reelSpinning1)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const reel5 = () => {
    return (
      <div className="facesReelsCenterBed">
        <div className="facesReelsCenterContainer">
          <div className="facesReel">
            <div
              className={
                !symbolWinClassRowA5 ? 'reelSymbolBed' : 'reelSymbolBedWinRowA'
              }
            >
              <div
                className={
                  reelSpinning1
                    ? 'reelSymbolContainerSpin'
                    : 'reelSymbolContainerStop'
                }
              >
                <div className="facesReelSymbol">
                  {renderSymbol('😘', symbolLandedA5, reelSpinning1)}
                </div>
              </div>
            </div>
            <div
              className={
                !symbolWinClassRowB5 ? 'reelSymbolBed' : 'reelSymbolBedWinRowB'
              }
            >
              <div
                className={
                  reelSpinning1
                    ? 'reelSymbolContainerSpin'
                    : 'reelSymbolContainerStop'
                }
              >
                <div className="facesReelSymbol">
                  {renderSymbol('😏', symbolLandedB5, reelSpinning1)}
                </div>
              </div>
            </div>
            <div
              className={
                !symbolWinClassRowC5 ? 'reelSymbolBed' : 'reelSymbolBedWinRowC'
              }
            >
              <div
                className={
                  reelSpinning1
                    ? 'reelSymbolContainerSpin'
                    : 'reelSymbolContainerStop'
                }
              >
                <div className="facesReelSymbol">
                  {renderSymbol('🤪', symbolLandedC5, reelSpinning1)}
                </div>
              </div>
            </div>
            <div
              className={
                !symbolWinClassRowD5 ? 'reelSymbolBed' : 'reelSymbolBedWinRowD'
              }
            >
              <div
                className={
                  reelSpinning1
                    ? 'reelSymbolContainerSpin'
                    : 'reelSymbolContainerStop'
                }
              >
                <div className="facesReelSymbol">
                  {renderSymbol('😏', symbolLandedD5, reelSpinning1)}
                </div>
              </div>
            </div>
            <div
              className={
                !symbolWinClassRowE5 ? 'reelSymbolBed' : 'reelSymbolBedWinRowE'
              }
            >
              <div
                className={
                  reelSpinning1
                    ? 'reelSymbolContainerSpin'
                    : 'reelSymbolContainerStop'
                }
              >
                <div className="facesReelSymbol">
                  {renderSymbol('💩', symbolLandedE5, reelSpinning1)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderContent = () => {
    return (
      <div className="facesBed">
        <div className="facesTopRow">
          <div className="fruitsExitButtonBed">
            <FontAwesomeIcon
              icon={faTimesCircle}
              className="fruitsExitButton"
              onClick={() => closeGame()}
            />
          </div>
        </div>
        <div className="facesMiddleRow">
          <div className="facesMiddleLeftBed"></div>
          {reel1()}
          {reel2()}
          {reel3()}
          {reel4()}
          {reel5()}
          <div className="facesMiddleRightBed"></div>
        </div>
        <div className="facesBottomRow"></div>
      </div>
    )
  }

  return renderContent()
}

export default Faces
