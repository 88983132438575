import React, { useContext, useEffect } from 'react'

import { Context as GameContext } from '../../../../context/GameContext'
import { Context as NavContext } from '../../../../context/NavContext'
import { Context as UserContext } from '../../../../context/UserContext'

import './networkErrorOverlay.css'

const NetworkErrorOverlay = () => {
  const {
    state: { networkErrorOverlayShow, betTotal, gameCredits },
    setNetworkErrorOverlayShow,
    setGameCredits,
    setFreeSpinsTotal,
  } = useContext(GameContext)

  const { setVisibleComponent } = useContext(NavContext)

  const { fetchUser } = useContext(UserContext)

  useEffect(() => {
    if (networkErrorOverlayShow) {
      const run = setInterval(() => {
        refundBetValue()
      }, 5000)
      return () => {
        clearInterval(run)
      }
    }
  }, [networkErrorOverlayShow])

  const refundBetValue = () => {
    setFreeSpinsTotal(0)
    fetchUser()
    setGameCredits(gameCredits + betTotal)
    setTimeout(() => {
      setNetworkErrorOverlayShow(false)
      setVisibleComponent('gamesMenu')
    }, 1000)
  }

  const renderContent = () => {
    let formattedBetValue = (betTotal / 100).toFixed(2)
    if (!networkErrorOverlayShow) return null
    return (
      <div className="networkErrorOverlayBed">
        <div className="networkErrorOverlayTop"></div>
        <div className="networkErrorOverlayMiddle">
          <div className="networkErrorOverlayMiddleTop"></div>
          <div className="networkErrorOverlayMiddleCenter">
            <div className="networkErrorOverlayMessageBed">
              <div className="networkErrorOverlayMessageContainer">
                <div className="networkErrorOverlayMessageText">
                  NETWORK ERROR CODE: 212 <br />
                  BET REVERSAL... <br />
                  YOU WILL BE REFUNDED: {formattedBetValue}
                </div>
                <div className="networkErrorOverlayRefundButtonBed">
                  <div
                    className="networkErrorOverlayRefundButton"
                    onClick={() => refundBetValue()}
                  >
                    OK
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="networkErrorOverlayMiddleBottom"></div>
        </div>
        <div className="networkErrorOverlayBottom"></div>
      </div>
    )
  }

  return renderContent()
}

export default NetworkErrorOverlay
