import createDataContext from './createDataContext'

// Reducer
const socketReducer = (state, action) => {
  switch (action.type) {
    case 'SET_LOADING':
      return { ...state, socketLoading: action.payload }
    case 'SET_SOCKET_CHANEL':
      return { ...state, socketChanel: action.payload }
    case 'SET_SOCKET_DATA_FROM_CLIENT':
      return { ...state, socketDataFromClient: action.payload }
    case 'SET_SOCKET_DATA_FROM_CASHIER':
      return { ...state, socketDataFromCashier: action.payload }
    case 'SET_SOCKET_DATA_FROM_CREATOR':
      return { ...state, socketDataFromCreator: action.payload }
    case 'SET_LOCK':
      return { ...state, lock: action.payload }
    case 'SET_JACKPOT_RELEASE':
      return { ...state, jackpotRelease: action.payload }
    case 'SET_RELOAD':
      return { ...state, reload: action.payload }
    case 'SET_JACKPOT_TRIGGER_VALUE':
      return { ...state, jackpotTriggerValue: action.payload }
    case 'SET_PROGRESITE_TRIGGER_VALUE':
      return { ...state, progresiveTriggerValue: action.payload }
    default:
      return state
  }
}

// Actions
const setSocketLoading = (dispatch) => (value) => {
  dispatch({ type: 'SET_LOADING', payload: value })
}

const setSocketChanel = (dispatch) => (value) => {
  dispatch({ type: 'SET_SOCKET_CHANEL', payload: value })
}

const setSocketDataFromClient = (dispatch) => (data) => {
  dispatch({ type: 'SET_SOCKET_DATA_FROM_CLIENT', payload: data })
}

const setSocketDataFromCashier = (dispatch) => (data) => {
  dispatch({ type: 'SET_SOCKET_DATA_FROM_CASHIER', payload: data })
}

const setSocketDataFromCreator = (dispatch) => (data) => {
  dispatch({ type: 'SET_SOCKET_DATA_FROM_CREATOR', payload: data })
}

const setLock = (dispatch) => (value) => {
  dispatch({ type: 'SET_LOCK', payload: value })
}

const setJackpotRelease = (dispatch) => (value) => {
  dispatch({ type: 'SET_JACKPOT_RELEASE', payload: value })
}

const setReload = (dispatch) => (value) => {
  dispatch({ type: 'SET_RELOAD', payload: value })
}

const setJackpotTriggerValue = (dispatch) => (value) => {
  dispatch({
    type: 'SET_JACKPOT_TRIGGER_VALUE',
    payload: value,
  })
}

const setProgresiveTriggerValue = (dispatch) => (value) => {
  dispatch({
    type: 'SET_PROGRESITE_TRIGGER_VALUE',
    payload: value,
  })
}

//
export const { Provider, Context } = createDataContext(
  socketReducer,
  {
    setSocketLoading,
    setSocketChanel,
    setSocketDataFromClient,
    setSocketDataFromCashier,
    setSocketDataFromCreator,
    setLock,
    setJackpotRelease,
    setReload,
    setJackpotTriggerValue,
    setProgresiveTriggerValue,
  },
  {
    socketLoading: false,
    socketChanel: null,
    socketDataFromClient: null,
    socketDataFromCashier: null,
    socketDataFromCreator: null,
    lock: false,
    jackpotRelease: false,
    reload: false,
    jackpotTriggerValue: null,
    progresiveTriggerValue: null,
  }
)
