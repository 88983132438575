import createDataContext from './createDataContext'

// Reducer
const ModalContext = (state, action) => {
  switch (action.type) {
    case 'SET_NOTIFICATION_MODAL_VISIBLE':
      return { ...state, notificationModalVisible: action.payload }
    case 'SET_NOTIFICATION_MODAL_CONTENT':
      return { ...state, notificationModalContent: action.payload }
    case 'SET_BURGER_MODAL_VISIBLE':
      return { ...state, burgerMenuModalVisible: action.payload }
    default:
      return state
  }
}

// Actions
const setNotificationModalVisible = (dispatch) => async (value) => {
  dispatch({ type: 'SET_NOTIFICATION_MODAL_VISIBLE', payload: value })
}
const setNotificationModalContent = (dispatch) => async (content) => {
  dispatch({ type: 'SET_NOTIFICATION_MODAL_CONTENT', payload: content })
}

const setBurgerMenuModalVisible = (dispatch) => (value) => {
  dispatch({ type: 'SET_BURGER_MODAL_VISIBLE', payload: value })
}

export const { Context, Provider } = createDataContext(
  ModalContext,
  {
    setNotificationModalVisible,
    setNotificationModalContent,
    setBurgerMenuModalVisible,
  },
  // Initial state
  {
    notificationModalVisible: null,
    notificationModalContent: null,
    burgerMenuModalVisible: false,
  }
)
