import { useContext, useEffect } from 'react'

import { Context as GameContext } from '../../../../context/GameContext'

const AssignWinClass = () => {
  const {
    state: {
      symbolLandedA1,
      symbolLandedA2,
      symbolLandedA3,
      symbolLandedA4,
      symbolLandedA5,
      symbolLandedB1,
      symbolLandedB2,
      symbolLandedB3,
      symbolLandedB4,
      symbolLandedB5,
      symbolLandedC1,
      symbolLandedC2,
      symbolLandedC3,
      symbolLandedC4,
      symbolLandedC5,
      reelSpinning5,
      winningSymbolRowA,
      winningSymbolRowB,
      winningSymbolRowC,
      symbolWinClassRowB2,
    },
    setSymbolWinClassRowA1,
    setSymbolWinClassRowA2,
    setSymbolWinClassRowA3,
    setSymbolWinClassRowA4,
    setSymbolWinClassRowA5,
    setSymbolWinClassRowB1,
    setSymbolWinClassRowB2,
    setSymbolWinClassRowB3,
    setSymbolWinClassRowB4,
    setSymbolWinClassRowB5,
    setSymbolWinClassRowC1,
    setSymbolWinClassRowC2,
    setSymbolWinClassRowC3,
    setSymbolWinClassRowC4,
    setSymbolWinClassRowC5,
  } = useContext(GameContext)

  useEffect(() => {
    if (winningSymbolRowA.length > 0 && !reelSpinning5) {
      assignSymbolBedClassRowA()
    }
  }, [winningSymbolRowA, reelSpinning5])

  useEffect(() => {
    if (winningSymbolRowB.length > 0 && !reelSpinning5) {
      assignSymbolBedClassRowB()
    }
  }, [winningSymbolRowB, reelSpinning5])

  useEffect(() => {
    if (winningSymbolRowC.length > 0 && !reelSpinning5) {
      assignSymbolBedClassRowC()
    }
  }, [winningSymbolRowA, reelSpinning5])

  const assignSymbolBedClassRowA = () => {
    let checkSymbol1 = winningSymbolRowA.some((symbol) => {
      return symbol.symbol === symbolLandedA1
    })
    setSymbolWinClassRowA1(checkSymbol1)
    let checkSymbol2 = winningSymbolRowA.some((symbol) => {
      return symbol.symbol === symbolLandedA2
    })
    setSymbolWinClassRowA2(checkSymbol2)
    let checkSymbol3 = winningSymbolRowA.some((symbol) => {
      return symbol.symbol === symbolLandedA3
    })
    setSymbolWinClassRowA3(checkSymbol3)
    let checkSymbol4 = winningSymbolRowA.some((symbol) => {
      return symbol.symbol === symbolLandedA4
    })
    setSymbolWinClassRowA4(checkSymbol4)
    let checkSymbol5 = winningSymbolRowA.some((symbol) => {
      return symbol.symbol === symbolLandedA5
    })
    setSymbolWinClassRowA5(checkSymbol5)
  }

  const assignSymbolBedClassRowB = () => {
    let checkSymbol1 = winningSymbolRowB.some((symbol) => {
      return symbol.symbol === symbolLandedB1
    })
    setSymbolWinClassRowB1(checkSymbol1)
    let checkSymbol2 = winningSymbolRowB.some((symbol) => {
      return symbol.symbol === symbolLandedB2
    })
    setSymbolWinClassRowB2(checkSymbol2)
    let checkSymbol3 = winningSymbolRowB.some((symbol) => {
      return symbol.symbol === symbolLandedB3
    })
    setSymbolWinClassRowB3(checkSymbol3)
    let checkSymbol4 = winningSymbolRowB.some((symbol) => {
      return symbol.symbol === symbolLandedB4
    })
    setSymbolWinClassRowB4(checkSymbol4)
    let checkSymbol5 = winningSymbolRowB.some((symbol) => {
      return symbol.symbol === symbolLandedB5
    })
    setSymbolWinClassRowB5(checkSymbol5)
  }

  const assignSymbolBedClassRowC = () => {
    let checkSymbol1 = winningSymbolRowC.some((symbol) => {
      return symbol.symbol === symbolLandedC1
    })
    setSymbolWinClassRowC1(checkSymbol1)
    let checkSymbol2 = winningSymbolRowC.some((symbol) => {
      return symbol.symbol === symbolLandedC2
    })
    setSymbolWinClassRowC2(checkSymbol2)
    let checkSymbol3 = winningSymbolRowC.some((symbol) => {
      return symbol.symbol === symbolLandedC3
    })
    setSymbolWinClassRowC3(checkSymbol3)
    let checkSymbol4 = winningSymbolRowC.some((symbol) => {
      return symbol.symbol === symbolLandedC4
    })
    setSymbolWinClassRowC4(checkSymbol4)
    let checkSymbol5 = winningSymbolRowC.some((symbol) => {
      return symbol.symbol === symbolLandedC5
    })
    setSymbolWinClassRowC5(checkSymbol5)
  }

  return null
}

export default AssignWinClass
