import React, { useState, useContext, useEffect } from 'react'

import { Context as GameContext } from '../../../../../context/GameContext'
import gif001 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif001.gif'
import gif002 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif002.gif'
import gif003 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif003.gif'
import gif004 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif004.gif'
import gif005 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif005.gif'
import gif006 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif006.gif'
import gif007 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif007.gif'
import gif008 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif008.gif'
import gif009 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif009.gif'
import gif010 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif010.gif'
import gif011 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif011.gif'
import gif012 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif012.gif'
import gif013 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif013.gif'
import gif014 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif014.gif'
import gif015 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif015.gif'
import gif016 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif016.gif'
import gif017 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif017.gif'
import gif018 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif018.gif'
import gif019 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif019.gif'
import gif020 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif020.gif'
import gif021 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif021.gif'
import gif022 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif022.gif'
import gif023 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif023.gif'
import gif024 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif024.gif'
import gif025 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif025.gif'
import gif026 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif026.gif'
import gif027 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif027.gif'
import gif028 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif028.gif'
import gif029 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif029.gif'

const GifGoodLuckGenerator = () => {
  const [randomNumber, setRandomNumber] = useState(0)

  const {
    state: { reelSpinning1 },
  } = useContext(GameContext)

  useEffect(() => {
    if (reelSpinning1) {
      setRandomNumber(Math.floor(Math.random() * (28 - 1) + 1))
    }
  }, [reelSpinning1])

  const renderGif = () => {
    switch (randomNumber) {
      case 1:
        return <img src={gif001} alt="celebrateGif" className="bannerGif" />
      case 2:
        return <img src={gif002} alt="celebrateGif" className="bannerGif" />
      case 3:
        return <img src={gif003} alt="celebrateGif" className="bannerGif" />
      case 4:
        return <img src={gif004} alt="celebrateGif" className="bannerGif" />
      case 5:
        return <img src={gif005} alt="celebrateGif" className="bannerGif" />
      case 6:
        return <img src={gif006} alt="celebrateGif" className="bannerGif" />
      case 7:
        return <img src={gif007} alt="celebrateGif" className="bannerGif" />
      case 8:
        return <img src={gif008} alt="celebrateGif" className="bannerGif" />
      case 9:
        return <img src={gif009} alt="celebrateGif" className="bannerGif" />
      case 10:
        return <img src={gif010} alt="celebrateGif" className="bannerGif" />
      case 11:
        return <img src={gif011} alt="celebrateGif" className="bannerGif" />
      case 12:
        return <img src={gif012} alt="celebrateGif" className="bannerGif" />
      case 13:
        return <img src={gif013} alt="celebrateGif" className="bannerGif" />
      case 14:
        return <img src={gif014} alt="celebrateGif" className="bannerGif" />
      case 15:
        return <img src={gif015} alt="celebrateGif" className="bannerGif" />
      case 16:
        return <img src={gif016} alt="celebrateGif" className="bannerGif" />
      case 17:
        return <img src={gif017} alt="celebrateGif" className="bannerGif" />
      case 18:
        return <img src={gif018} alt="celebrateGif" className="bannerGif" />
      case 19:
        return <img src={gif019} alt="celebrateGif" className="bannerGif" />
      case 20:
        return <img src={gif020} alt="celebrateGif" className="bannerGif" />
      case 21:
        return <img src={gif021} alt="celebrateGif" className="bannerGif" />
      case 22:
        return <img src={gif022} alt="celebrateGif" className="bannerGif" />
      case 23:
        return <img src={gif023} alt="celebrateGif" className="bannerGif" />
      case 24:
        return <img src={gif024} alt="celebrateGif" className="bannerGif" />
      case 25:
        return <img src={gif025} alt="celebrateGif" className="bannerGif" />
      case 26:
        return <img src={gif026} alt="celebrateGif" className="bannerGif" />
      case 27:
        return <img src={gif027} alt="celebrateGif" className="bannerGif" />
      case 28:
        return <img src={gif028} alt="celebrateGif" className="bannerGif" />
      case 29:
        return <img src={gif029} alt="celebrateGif" className="bannerGif" />

      default:
        break
    }
  }

  return renderGif()
}

export default GifGoodLuckGenerator
