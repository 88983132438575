import { useState, useContext, useEffect } from 'react'

import { Context as PayoutContext } from '../../../../../context/PayoutContext'

const ClientProgresive3 = () => {
  const [clientProgresive3StartValue, setClientProgresive3StartValue] =
    useState(0)

  const [clientProgresive3BalanceCounter, setClientProgresive3BalanceCounter] =
    useState(0)

  const {
    state: {
      clientProgresiveData,
      clientProgresive3,
      clientProgresive3Release,
      clientProgresive3Balance,
      addCreditAverage,
    },
    updateClientProgresive,
    setClientProgresive3,
    setClientProgresive3Release,
    setClientProgresive3Balance,
    setClientProgresiveReleaseDB,
  } = useContext(PayoutContext)

  useEffect(() => {
    if (clientProgresiveData) {
      const { clientProgresive3Release, clientProgresive3 } =
        clientProgresiveData
      setClientProgresive3Release(clientProgresive3Release)
      setClientProgresive3(clientProgresive3)
      let triggerValue = addCreditAverage * 10
      if (triggerValue > 0) {
        if (clientProgresive3 > triggerValue) {
          setClientProgresive3Release(true)
          setClientProgresiveReleaseDB({ clientProgresive3Release: true })
        }
      }
    }
  }, [clientProgresiveData, addCreditAverage])

  useEffect(() => {
    if (clientProgresive3Release && clientProgresive3BalanceCounter === 0) {
      setClientProgresive3StartValue(clientProgresive3)
      setClientProgresive3Balance(clientProgresive3)
      setClientProgresive3Release(true)
      setClientProgresive3BalanceCounter(1)
    }
  }, [
    clientProgresive3Release,
    clientProgresive3,
    clientProgresive3BalanceCounter,
  ])

  useEffect(() => {
    let clientProgresive3BalancePercentage =
      (clientProgresive3Balance / clientProgresive3StartValue) * 100
    if (clientProgresive3BalancePercentage < 20) {
      setClientProgresive3Release(false)
      setClientProgresiveReleaseDB({ clientProgresive3Release: false })
      updateClientProgresive({
        clientProgresive3PayoutEnd: true,
      })
      setClientProgresive3Balance(0)
      setClientProgresive3BalanceCounter(0)
    }
  }, [clientProgresive3Balance, clientProgresive3StartValue])

  return null
}

export default ClientProgresive3
