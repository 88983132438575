import createDataContext from './createDataContext'
import ngrokApi from '../api/ngrokApi'

// Reducer
const BaitContext = (state, action) => {
  switch (action.type) {
    case 'LOADING':
      return { ...state, loading: true }
    case 'FETCH_SITE_BAIT':
      return { ...state, siteBaitData: action.payload }
    case 'UPDATE_SITE_BAIT_BALANCE_REMAINING':
      return { ...state, siteBaitBalanceRemaining: action.payload }
    case 'UPDATE_SITE_BAIT':
      return { ...state, siteBaitData: action.payload }
    case 'SET_SITE_BAIT_RELEASE':
      return { ...state, siteBaitRelease: action.payload }
    case 'SET_SITE_BAIT_BALANCE':
      return { ...state, siteBaitBalance: action.payload }
    case 'SET_SITE_BAIT_BALANCE_COUNT':
      return { ...state, siteBaitBalanceCounter: action.payload }
    case 'SET_SITE_BAIT_RELEASE_BALANCE':
      return { ...state, siteBaitReleaseBalance: action.payload }
    default:
      return state
  }
}

// Actions
const fetchSiteBait = (dispatch) => async (data) => {
  try {
    const response = await ngrokApi.post('/bait/fetch-site-bait', data)
    dispatch({
      type: 'FETCH_SITE_BAIT',
      payload: response.data,
    })
    return
  } catch (error) {
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const updateSiteBaitBalanceRemaining = (dispatch) => async (data) => {
  try {
    const response = await ngrokApi.post(
      '/bait/site-bait-balance-remaining',
      data
    )
    dispatch({
      type: 'UPDATE_SITE_BAIT_BALANCE_REMAINING',
      payload: response.data,
    })
    return
  } catch (error) {
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const updateSiteBait = (dispatch) => async (data) => {
  try {
    const response = await ngrokApi.post('/bait/update-site-bait', data)
    dispatch({ type: 'UPDATE_SITE_BAIT', payload: response.data })
  } catch (error) {
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const setSiteBaitReleaseDB = (dispatch) => async (data) => {
  try {
    await ngrokApi.post('/payout/progresive-release-db', data)
  } catch (error) {
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const setSiteBaitRelease = (dispatch) => (value) => {
  dispatch({ type: 'SET_SITE_BAIT_RELEASE', payload: value })
}

const setSiteBaitBalance = (dispatch) => (value) => {
  dispatch({ type: 'SET_SITE_BAIT_BALANCE', payload: value })
}

const setSiteBaitBalanceCounter = (dispatch) => (value) => {
  dispatch({ type: 'SET_SITE_BAIT_BALANCE_COUNT', payload: value })
}

const setSiteBaitReleaseBalance = (dispatch) => (value) => {
  dispatch({ type: 'SET_SITE_BAIT_RELEASE_BALANCE', payload: value })
}

export const { Context, Provider } = createDataContext(
  BaitContext,
  {
    fetchSiteBait,
    updateSiteBaitBalanceRemaining,
    updateSiteBait,
    setSiteBaitReleaseDB,
    setSiteBaitRelease,
    setSiteBaitBalance,
    setSiteBaitBalanceCounter,
    setSiteBaitReleaseBalance,
  },
  // Initial state
  {
    loading: false,
    siteBaitData: null,
    siteBaitBalanceRemaining: null,
    siteBaitRelease: false,
    siteBaitBalance: 0,
    siteBaitBalanceCounter: 0,
    siteBaitReleaseBalance: false,
  }
)
