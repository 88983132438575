import { useState, useContext, useEffect } from 'react'

import { Context as GameContext } from '../../../../../context/GameContext'
import { Context as PayoutContext } from '../../../../../context/PayoutContext'
import { Context as UserContext } from '../../../../../context/UserContext'
import { Context as SocketContext } from '../../../../../context/SocketContext'
import { Context as NavContext } from '../../../../../context/NavContext'

const Jackpot = () => {
  const [triggerFetchUserCheck, setTriggerFetchUserCheck] = useState(false)

  const {
    state: { jackpotRelease },
    setSocketDataFromClient,
    setJackpotRelease,
    setJackpotTriggerValue,
  } = useContext(SocketContext)

  const {
    state: { jackpotData, jackpotCleared },
    setJackpotOverlayShow,
  } = useContext(GameContext)

  const {
    state: {
      addCreditAverage,
      resetJackpotState,
      jackpotWon,
      pauseJackpotReleaseDB,
    },
    setJackpotReleaseDB,
    setJackpotWon,
    setTriggerCondensePreJackpotNumber,
    setTriggerJackpotOverlay,
    setResetJackpotState,
    setPauseJackpotReleaseDB,
    resetJackpotClaimed,
  } = useContext(PayoutContext)

  const {
    state: { user },
  } = useContext(UserContext)

  const { setVisibleComponent } = useContext(NavContext)

  useEffect(() => {
    if (jackpotData) {
      const { jackpotRelease, jackpot } = jackpotData
      setJackpotRelease(jackpotRelease)
      let triggerValue = addCreditAverage * 100
      let preTriggerValue = triggerValue - 8000
      if (preTriggerValue > 0) {
        if (jackpot > preTriggerValue) {
          setTriggerCondensePreJackpotNumber(true)
        }
      }
      setJackpotTriggerValue(triggerValue)
      if (triggerValue > 0) {
        if (jackpot > triggerValue && !pauseJackpotReleaseDB) {
          setJackpotRelease(true)
          setJackpotReleaseDB({ jackpotRelease: true })
        }
      }
    }
  }, [jackpotData, addCreditAverage, pauseJackpotReleaseDB])

  useEffect(() => {
    if (jackpotRelease) {
      setSocketDataFromClient({
        subject: 'jackpot',
        jackpotRelease: true,
      })
    }
  }, [user, jackpotRelease])

  useEffect(() => {
    if (jackpotCleared) {
      setSocketDataFromClient({
        subject: 'jackpotCleared',
        jackpotCleared: true,
      })
    }
  }, [jackpotCleared])

  useEffect(() => {
    if (resetJackpotState) {
      setTriggerJackpotOverlay(false)
      setTriggerCondensePreJackpotNumber(false)
      setJackpotOverlayShow(false)
      resetJackpotClaimed(false)
      setPauseJackpotReleaseDB(false)
      setTriggerFetchUserCheck(true)
    }
  }, [resetJackpotState])

  useEffect(() => {
    if (triggerFetchUserCheck) {
      if (jackpotWon) {
        setJackpotWon(false)
        setResetJackpotState(false)
        setVisibleComponent('gamesMenu')
      }
    }
  }, [triggerFetchUserCheck, jackpotWon])

  return null
}

export default Jackpot
