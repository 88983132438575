import React, { useState, useContext, useEffect } from 'react'

import { Context as GameContext } from '../../../../../context/GameContext'
import { Context as PayoutContext } from '../../../../../context/PayoutContext'

import Strategy0 from './strategies/Strategy0' // low
import Strategy1 from './strategies/Strategy1' // high
import Strategy2 from './strategies/Strategy2' // medium/heigh
import Strategy3 from './strategies/Strategy3' // medium/low
import Strategy4 from './strategies/Strategy4' // very high

const CondenserSelector = () => {
  const [strategy, setStrategy] = useState(4)

  const {
    state: { spinCount, newGameOpenBalance, gameCredits },
  } = useContext(GameContext)

  const {
    state: { triggerStrategySelector },
    setTriggerStrategySelector,
  } = useContext(PayoutContext)

  // This is a strategy selector that's not in use, but could will in the future.
  useEffect(() => {
    let gameCreditPercentage = (gameCredits / newGameOpenBalance) * 100
    if (triggerStrategySelector) {
      switch (true) {
        case gameCreditPercentage <= 100 && gameCreditPercentage > 69:
          setStrategy(4)
          setTriggerStrategySelector(false)
          break
        case gameCreditPercentage < 70 && gameCreditPercentage > 49:
          setStrategy(4)
          setTriggerStrategySelector(false)
          break
        case gameCreditPercentage < 50 && gameCreditPercentage > 19:
          setStrategy(4)
          setTriggerStrategySelector(false)
          break
        case gameCreditPercentage < 20 && gameCreditPercentage > 0:
          setStrategy(4)
          setTriggerStrategySelector(false)
          break
        default:
          setStrategy(4)
          break
      }
    }
  }, [spinCount, newGameOpenBalance, gameCredits, triggerStrategySelector])

  // const selector = () => {
  //   switch (strategy) {
  //     case 0:
  //       return <Strategy0 />
  //     case 1:
  //       return <Strategy1 />
  //     case 2:
  //       return <Strategy2 />
  //     case 3:
  //       return <Strategy3 />
  //     case 4:
  //       return <Strategy4 />
  //     default:
  //       return <Strategy0 />
  //   }
  // }

  return <Strategy4 />
}

export default CondenserSelector

// Condenser _1 notes:

// 9 -  2,  6,  5,  4,  1
// 8 -  4,  2,  6,  5,  1
// 7 -  5,  4,  2,  6,  5
// 6 -  6,  5,  4,  2, 10
// 5 -  4, 32, 24, 12,  8
// 4 -  8,  4, 32, 24, 12
// 3 - 12,  8,  4, 32, 24
// 2 - 24, 12,  8,  4, 32
// 1 - 32, 24, 12,  8,  4
