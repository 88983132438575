import React, { useContext } from 'react'
import { Context as PayoutContext } from '../../../../../../context/PayoutContext'

import CondenseRandomNumberA3 from '../CondenseRandomNumberA3'
import CondenseRandomNumberB3 from '../CondenseRandomNumberB3'
import CondenseRandomNumberC3 from '../CondenseRandomNumberC3'
import CondenseJackpotNumber from '../CondenseJackpotNumber'

const Strategy4 = () => {
  const {
    state: { condenserCounter, jackpotWon },
  } = useContext(PayoutContext)

  const strategy = () => {
    if (condenserCounter === 0) {
      return (
        <>
          <CondenseRandomNumberA3 />
          {jackpotWon ? <CondenseJackpotNumber /> : <CondenseRandomNumberB3 />}
          <CondenseRandomNumberC3 />
        </>
      )
    }
    if (condenserCounter === 1) {
      return (
        <>
          <CondenseRandomNumberA3 />
          {jackpotWon ? <CondenseJackpotNumber /> : <CondenseRandomNumberB3 />}
          <CondenseRandomNumberC3 />
        </>
      )
    }
    if (condenserCounter === 2) {
      return (
        <>
          <CondenseRandomNumberA3 />
          {jackpotWon ? <CondenseJackpotNumber /> : <CondenseRandomNumberB3 />}
          <CondenseRandomNumberC3 />
        </>
      )
    }
    if (condenserCounter === 3) {
      return (
        <>
          <CondenseRandomNumberA3 />
          {jackpotWon ? <CondenseJackpotNumber /> : <CondenseRandomNumberB3 />}
          <CondenseRandomNumberC3 />
        </>
      )
    }
    if (condenserCounter === 4) {
      return (
        <>
          <CondenseRandomNumberA3 />
          {jackpotWon ? <CondenseJackpotNumber /> : <CondenseRandomNumberB3 />}
          <CondenseRandomNumberC3 />
        </>
      )
    }
  }

  return strategy()
}

export default Strategy4
