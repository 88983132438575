import { useContext, useEffect, useState } from 'react'
import uuid from 'uuid/v4'

import { Context as GameContext } from '../../../../context/GameContext'
import { Context as PayoutContext } from '../../../../context/PayoutContext'

const CalculateRowAWin = () => {
  const [checkForWildCardDone, setCheckForWildCardDone] = useState(false)
  const [winArray, setWinArray] = useState([])
  const [winSymbolArray, setWinSymbolArray] = useState([])
  const [prizeArray, setPrizeArray] = useState([])
  const [appendWildCardDone, setAppendWildCardDone] = useState(false)
  const [assignPrizeDone, setAssignPrizeDone] = useState(false)
  const [prizeArrayDone, setPrizeArrayDone] = useState(false)

  const {
    state: {
      betValue,
      numberOfLines,
      rowAResult,
      reelSpinning5,
      wildCardQuantityRowA,
      scatterQuantityRowA,
      rowAFreeSpins,
      symbol1WildCardWinA,
      symbol2WildCardWinA,
      symbol3WildCardWinA,
      symbol4WildCardWinA,
      symbol5WildCardWinA,
      symbol6WildCardWinA,
      symbol7WildCardWinA,
      symbol8WildCardWinA,
      symbol9WildCardWinA,
    },
    setRowAWinArray,
    setRowAPrizeArray,
    setWildCardQuantityRowA,
    setScatterQuantityRowA,
    setScatterWinOverlayShow,
    setRowAFreeSpins,
    setWinningSymbolRowA,
    setScatterAndWildCardCheckDoneRowA,
    setSymbol1WildCardWinA,
    setSymbol2WildCardWinA,
    setSymbol3WildCardWinA,
    setSymbol4WildCardWinA,
    setSymbol5WildCardWinA,
    setSymbol6WildCardWinA,
    setSymbol7WildCardWinA,
    setSymbol8WildCardWinA,
    setSymbol9WildCardWinA,
    setPrizeWonDoneRowA,
    setNoPrizeDoneRowA,
  } = useContext(GameContext)

  const {
    state: { payoutOverLimitStartReplay },
  } = useContext(PayoutContext)

  useEffect(() => {
    if (rowAResult.length > 0) {
      checkForWildCardAndScatter()
    }
  }, [rowAResult])

  useEffect(() => {
    if (
      numberOfLines > 1 &&
      wildCardQuantityRowA > 0 &&
      scatterQuantityRowA > 0
    ) {
      setScatterAndWildCardCheckDoneRowA(true)
      setTimeout(() => {
        setScatterWinOverlayShow(true)
        setRowAFreeSpins(rowAFreeSpins + 10)
      }, 4800)
    }
  }, [numberOfLines, wildCardQuantityRowA, scatterQuantityRowA])

  useEffect(() => {
    if (wildCardQuantityRowA > 0 && checkForWildCardDone) {
      appendWildCard()
    }
    if (checkForWildCardDone && wildCardQuantityRowA === 0) {
      assignPrize()
    }
  }, [wildCardQuantityRowA, checkForWildCardDone])

  useEffect(() => {
    if (appendWildCardDone || assignPrizeDone) {
      if (winArray.length < 1 || numberOfLines < 2) {
        setNoPrizeDoneRowA(true)
      }
      if (winArray.length > 0) {
        winArray.map((win, index) => {
          if (index === winArray.length - 1) {
            setPrizeArrayDone(true)
            setPrizeWonDoneRowA(true)
          }
          let prize = win.betMutiple * betValue
          setPrizeArray((prizeArray) => [...prizeArray, { prizeValue: prize }])
        })
      }
    }
  }, [appendWildCardDone, assignPrizeDone, winArray, numberOfLines])

  useEffect(() => {
    if (numberOfLines > 1 && (appendWildCardDone || assignPrizeDone)) {
      setWinningSymbolRowA(winSymbolArray)
    }
  }, [numberOfLines, appendWildCardDone, assignPrizeDone])

  useEffect(() => {
    if (prizeArrayDone) {
      setRowAPrizeArray(prizeArray)
      setRowAWinArray(prizeArray)
    }
  }, [prizeArrayDone])

  useEffect(() => {
    if (payoutOverLimitStartReplay || !reelSpinning5) {
      setWinArray([])
      setWinSymbolArray([])
      setPrizeArray([])
      setCheckForWildCardDone(false)
      setAppendWildCardDone(false)
      setAssignPrizeDone(false)
      setPrizeArrayDone(false)
      setPrizeWonDoneRowA(false)
      setNoPrizeDoneRowA(false)
    }
  }, [payoutOverLimitStartReplay, reelSpinning5])

  const checkForWildCardAndScatter = () => {
    rowAResult.map((result, index) => {
      if (index === rowAResult.length - 1) {
        setCheckForWildCardDone(true)
      }
      const { symbol, quantity } = result
      if (symbol === '10') {
        setWildCardQuantityRowA(quantity)
      }
      if (symbol === '11') {
        setScatterQuantityRowA(quantity)
      }
    })
  }

  const appendWildCard = () => {
    if (rowAResult.length > 0) {
      let arraySorted = rowAResult.sort((x, y) => {
        return y.symbol - x.symbol
      })
      let wildCardAssigned
      if (wildCardQuantityRowA > 0) wildCardAssigned = false
      arraySorted.map((result, index) => {
        if (index === arraySorted.length - 1) {
          setAppendWildCardDone(true)
        }
        const { symbol, quantity } = result
        // Case 🍀 total = 5
        if (symbol === '10' && quantity === 5) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5500, key: uuid() },
          ])
          setSymbol1WildCardWinA(true)
          return
        }
        // 1st
        // Case 🍓 win + 🍀 total = 5
        if (
          symbol === '9' &&
          quantity === 4 &&
          wildCardQuantityRowA === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍓', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5000, key: uuid() },
          ])
          setSymbol1WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '9' &&
          quantity === 3 &&
          wildCardQuantityRowA === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍓', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5000, key: uuid() },
          ])
          setSymbol1WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '9' &&
          quantity === 2 &&
          wildCardQuantityRowA === 3 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍓', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5000, key: uuid() },
          ])
          setSymbol1WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '9' &&
          quantity === 1 &&
          wildCardQuantityRowA === 4 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍓', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5000, key: uuid() },
          ])
          setSymbol1WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        // 2nd
        // Case 🍒 win + 🍀 total = 5
        if (
          symbol === '8' &&
          quantity === 4 &&
          wildCardQuantityRowA === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍒', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 750, key: uuid() },
          ])
          setSymbol3WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '8' &&
          quantity === 3 &&
          wildCardQuantityRowA === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍒', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 750, key: uuid() },
          ])
          setSymbol3WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '8' &&
          quantity === 2 &&
          wildCardQuantityRowA === 3 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍒', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 750, key: uuid() },
          ])
          setSymbol3WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '8' &&
          quantity === 1 &&
          wildCardQuantityRowA === 4 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍒', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 750, key: uuid() },
          ])
          setSymbol3WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        // 3rd
        // Case 🍓 win + 🍀 total = 4
        if (
          symbol === '9' &&
          quantity === 3 &&
          wildCardQuantityRowA === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍓', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 1000, key: uuid() },
          ])
          setSymbol1WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '9' &&
          quantity === 2 &&
          wildCardQuantityRowA === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍓', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 1000, key: uuid() },
          ])
          setSymbol1WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '9' &&
          quantity === 1 &&
          wildCardQuantityRowA === 3 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍓', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 1000, key: uuid() },
          ])
          setSymbol1WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        // 4th
        // Case 🍇 win + 🍀 total = 5
        if (
          symbol === '7' &&
          quantity === 4 &&
          wildCardQuantityRowA === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍇', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 750, key: uuid() },
          ])
          setSymbol2WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '7' &&
          quantity === 3 &&
          wildCardQuantityRowA === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍇', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 750, key: uuid() },
          ])
          setSymbol2WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '7' &&
          quantity === 2 &&
          wildCardQuantityRowA === 3 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍇', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 750, key: uuid() },
          ])
          setSymbol2WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '7' &&
          quantity === 1 &&
          wildCardQuantityRowA === 4 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍇', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 750, key: uuid() },
          ])
          setSymbol2WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        // 5th
        // Case 🍌 win + 🍀 total = 5
        if (
          symbol === '6' &&
          quantity === 4 &&
          wildCardQuantityRowA === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍌', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 750, key: uuid() },
          ])
          setSymbol4WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '6' &&
          quantity === 3 &&
          wildCardQuantityRowA === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍌', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 750, key: uuid() },
          ])
          setSymbol4WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '6' &&
          quantity === 2 &&
          wildCardQuantityRowA === 3 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍌', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 750, key: uuid() },
          ])
          setSymbol4WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '6' &&
          quantity === 1 &&
          wildCardQuantityRowA === 4 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍌', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 750, key: uuid() },
          ])
          setSymbol4WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        // 6th
        // Case 🍒 win + 🍀 total = 4
        if (
          symbol === '8' &&
          quantity === 3 &&
          wildCardQuantityRowA === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍒', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 400, key: uuid() },
          ])
          setSymbol3WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '8' &&
          quantity === 2 &&
          wildCardQuantityRowA === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍒', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 400, key: uuid() },
          ])
          setSymbol3WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '8' &&
          quantity === 1 &&
          wildCardQuantityRowA === 3 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍒', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 400, key: uuid() },
          ])
          setSymbol3WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        // 7th
        // Case 🍉 win + 🍀 total = 5
        if (
          symbol === '5' &&
          quantity === 4 &&
          wildCardQuantityRowA === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍉', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol5WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '5' &&
          quantity === 3 &&
          wildCardQuantityRowA === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍉', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol5WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '5' &&
          quantity === 2 &&
          wildCardQuantityRowA === 3 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍉', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol5WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '5' &&
          quantity === 1 &&
          wildCardQuantityRowA === 4 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍉', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol5WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        // 8th
        // Case 🥑 win + 🍀 total = 5
        if (
          symbol === '4' &&
          quantity === 4 &&
          wildCardQuantityRowA === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🥑', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol6WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '4' &&
          quantity === 3 &&
          wildCardQuantityRowA === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🥑', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol6WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '4' &&
          quantity === 2 &&
          wildCardQuantityRowA === 3 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🥑', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol6WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '4' &&
          quantity === 1 &&
          wildCardQuantityRowA === 4 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🥑', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol6WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        // 9th
        // Case 🍓 win + 🍀 total = 3
        if (
          symbol === '9' &&
          quantity === 2 &&
          wildCardQuantityRowA === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍓', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol1WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '9' &&
          quantity === 1 &&
          wildCardQuantityRowA === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍓', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol1WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        // 10th
        // Case 🍇 win + 🍀 total = 4
        if (
          symbol === '7' &&
          quantity === 3 &&
          wildCardQuantityRowA === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍇', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol2WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '7' &&
          quantity === 2 &&
          wildCardQuantityRowA === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍇', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol2WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '7' &&
          quantity === 1 &&
          wildCardQuantityRowA === 3 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍇', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol2WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        // 11th
        // Case 🍌 win + 🍀 total = 4
        if (
          symbol === '6' &&
          quantity === 3 &&
          wildCardQuantityRowA === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍌', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol4WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '6' &&
          quantity === 2 &&
          wildCardQuantityRowA === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍌', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol4WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '6' &&
          quantity === 1 &&
          wildCardQuantityRowA === 3 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍌', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol4WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        // 12th
        // Case 🍊 win + 🍀 total = 5
        if (
          symbol === '3' &&
          quantity === 4 &&
          wildCardQuantityRowA === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍊', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol7WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '3' &&
          quantity === 3 &&
          wildCardQuantityRowA === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍊', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol7WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '3' &&
          quantity === 2 &&
          wildCardQuantityRowA === 3 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍊', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol7WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '3' &&
          quantity === 1 &&
          wildCardQuantityRowA === 4 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍊', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol7WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        // 13th
        // Case 🍋 win + 🍀 total = 5
        if (
          symbol === '2' &&
          quantity === 4 &&
          wildCardQuantityRowA === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍋', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol8WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '2' &&
          quantity === 3 &&
          wildCardQuantityRowA === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍋', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol8WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '2' &&
          quantity === 2 &&
          wildCardQuantityRowA === 3 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍋', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol8WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '2' &&
          quantity === 1 &&
          wildCardQuantityRowA === 4 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍋', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol8WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        // 14th
        // Case 🍈 win + 🍀 total = 5
        if (
          symbol === '1' &&
          quantity === 4 &&
          wildCardQuantityRowA === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍈', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol9WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '1' &&
          quantity === 3 &&
          wildCardQuantityRowA === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍈', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol9WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '1' &&
          quantity === 2 &&
          wildCardQuantityRowA === 3 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍈', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol9WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '1' &&
          quantity === 1 &&
          wildCardQuantityRowA === 4 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍈', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol9WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        // 15th
        // Case 🍒 win + 🍀 total = 3
        if (
          symbol === '8' &&
          quantity === 2 &&
          wildCardQuantityRowA === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍒', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 40, key: uuid() },
          ])
          setSymbol3WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '8' &&
          quantity === 1 &&
          wildCardQuantityRowA === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍒', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 40, key: uuid() },
          ])
          setSymbol3WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        // 16th
        // Case 🍉 win + 🍀 total = 4
        if (
          symbol === '5' &&
          quantity === 3 &&
          wildCardQuantityRowA === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍉', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 40, key: uuid() },
          ])
          setSymbol5WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '5' &&
          quantity === 2 &&
          wildCardQuantityRowA === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍉', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 40, key: uuid() },
          ])
          setSymbol5WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '5' &&
          quantity === 1 &&
          wildCardQuantityRowA === 3 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍉', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 40, key: uuid() },
          ])
          setSymbol5WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        // 17th
        // Case 🥑 win + 🍀 total = 4
        if (
          symbol === '4' &&
          quantity === 3 &&
          wildCardQuantityRowA === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🥑', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 40, key: uuid() },
          ])
          setSymbol6WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '4' &&
          quantity === 2 &&
          wildCardQuantityRowA === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🥑', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 40, key: uuid() },
          ])
          setSymbol6WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '4' &&
          quantity === 1 &&
          wildCardQuantityRowA === 3 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🥑', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 40, key: uuid() },
          ])
          setSymbol6WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        // 18th
        // Case 🍇 win + 🍀 total = 3
        if (
          symbol === '7' &&
          quantity === 2 &&
          wildCardQuantityRowA === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍇', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 30, key: uuid() },
          ])
          setSymbol2WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '7' &&
          quantity === 1 &&
          wildCardQuantityRowA === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍇', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 30, key: uuid() },
          ])
          setSymbol2WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        // 19th
        // Case 🍌 win + 🍀 total = 3
        if (
          symbol === '6' &&
          quantity === 2 &&
          wildCardQuantityRowA === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍌', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 30, key: uuid() },
          ])
          setSymbol4WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '6' &&
          quantity === 1 &&
          wildCardQuantityRowA === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍌', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 30, key: uuid() },
          ])
          setSymbol4WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        // 20th
        // Case 🍊 win + 🍀 total = 4
        if (
          symbol === '3' &&
          quantity === 3 &&
          wildCardQuantityRowA === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍊', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 25, key: uuid() },
          ])
          setSymbol7WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '3' &&
          quantity === 2 &&
          wildCardQuantityRowA === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍊', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 25, key: uuid() },
          ])
          setSymbol7WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '3' &&
          quantity === 1 &&
          wildCardQuantityRowA === 3 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍊', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 25, key: uuid() },
          ])
          setSymbol7WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        // 21st
        // Case 🍋 win + 🍀 total = 4
        if (
          symbol === '2' &&
          quantity === 3 &&
          wildCardQuantityRowA === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍋', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 25, key: uuid() },
          ])
          setSymbol8WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '2' &&
          quantity === 2 &&
          wildCardQuantityRowA === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍋', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 25, key: uuid() },
          ])
          setSymbol8WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '2' &&
          quantity === 1 &&
          wildCardQuantityRowA === 3 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍋', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 25, key: uuid() },
          ])
          setSymbol8WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        // 22nd
        // Case 🍈 win + 🍀 total = 4
        if (
          symbol === '1' &&
          quantity === 3 &&
          wildCardQuantityRowA === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍈', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 25, key: uuid() },
          ])
          setSymbol9WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '1' &&
          quantity === 2 &&
          wildCardQuantityRowA === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍈', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 25, key: uuid() },
          ])
          setSymbol9WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '1' &&
          quantity === 1 &&
          wildCardQuantityRowA === 3 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍈', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 25, key: uuid() },
          ])
          setSymbol9WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        // 23rd
        // Case 🍉 win + 🍀 total = 3
        if (
          symbol === '5' &&
          quantity === 2 &&
          wildCardQuantityRowA === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍉', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5, key: uuid() },
          ])
          setSymbol5WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '5' &&
          quantity === 1 &&
          wildCardQuantityRowA === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍉', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5, key: uuid() },
          ])
          setSymbol5WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        // 24th
        // Case 🥑 win + 🍀 total = 3
        if (
          symbol === '4' &&
          quantity === 2 &&
          wildCardQuantityRowA === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🥑', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5, key: uuid() },
          ])
          setSymbol6WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '4' &&
          quantity === 1 &&
          wildCardQuantityRowA === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🥑', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5, key: uuid() },
          ])
          setSymbol6WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        // 25th
        // Case 🍊 win + 🍀 total = 3
        if (
          symbol === '3' &&
          quantity === 2 &&
          wildCardQuantityRowA === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍊', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5, key: uuid() },
          ])
          setSymbol7WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '3' &&
          quantity === 1 &&
          wildCardQuantityRowA === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍊', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5, key: uuid() },
          ])
          setSymbol7WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        // 26th
        // Case 🍋 win + 🍀 total = 3
        if (
          symbol === '2' &&
          quantity === 2 &&
          wildCardQuantityRowA === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍋', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5, key: uuid() },
          ])
          setSymbol8WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '2' &&
          quantity === 1 &&
          wildCardQuantityRowA === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍋', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5, key: uuid() },
          ])
          setSymbol8WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        // 27th
        // Case 🍈 win + 🍀 total = 3
        if (
          symbol === '1' &&
          quantity === 2 &&
          wildCardQuantityRowA === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍈', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5, key: uuid() },
          ])
          setSymbol9WildCardWinA(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '1' &&
          quantity === 1 &&
          wildCardQuantityRowA === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowA(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍈', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5, key: uuid() },
          ])
          setSymbol9WildCardWinA(true)
          wildCardAssigned = true
          return
        }
      })
    }
  }

  const assignPrize = () => {
    let arraySorted = rowAResult.sort((x, y) => {
      return y.symbol - x.symbol
    })
    arraySorted.map((result, index) => {
      if (index === arraySorted.length - 1) {
        setAssignPrizeDone(true)
      }
      const { symbol, quantity } = result
      if (!symbol1WildCardWinA) {
        if (symbol === '9' && quantity === 3) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍓', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          return
        }
        if (symbol === '9' && quantity === 4) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍓', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 1000, key: uuid() },
          ])
          return
        }
        if (symbol === '9' && quantity === 5) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍓', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5000, key: uuid() },
          ])
          return
        }
      }
      if (!symbol2WildCardWinA) {
        if (symbol === '7' && quantity === 3) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍇', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 30, key: uuid() },
          ])
          return
        }
        if (symbol === '7' && quantity === 4) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍇', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          return
        }
        if (symbol === '7' && quantity === 5) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍇', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 750, key: uuid() },
          ])
          return
        }
      }
      if (!symbol3WildCardWinA) {
        if (symbol === '8' && quantity === 3) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍒', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 40, key: uuid() },
          ])
          return
        }
        if (symbol === '8' && quantity === 4) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍒', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 400, key: uuid() },
          ])
          return
        }
        if (symbol === '8' && quantity === 5) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍒', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 2000, key: uuid() },
          ])
          return
        }
      }
      if (!symbol4WildCardWinA) {
        if (symbol === '6' && quantity === 3) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍌', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 30, key: uuid() },
          ])
          return
        }
        if (symbol === '6' && quantity === 4) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍌', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          return
        }
        if (symbol === '6' && quantity === 5) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍌', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 750, key: uuid() },
          ])
          return
        }
      }
      if (!symbol5WildCardWinA) {
        if (symbol === '5' && quantity === 3) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍉', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5, key: uuid() },
          ])
          return
        }
        if (symbol === '5' && quantity === 4) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍉', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 40, key: uuid() },
          ])
          return
        }
        if (symbol === '5' && quantity === 5) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍉', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          return
        }
      }
      if (!symbol6WildCardWinA) {
        if (symbol === '4' && quantity === 3) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🥑', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5, key: uuid() },
          ])
          return
        }
        if (symbol === '4' && quantity === 4) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🥑', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 40, key: uuid() },
          ])
          return
        }
        if (symbol === '4' && quantity === 5) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🥑', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          return
        }
      }
      if (!symbol7WildCardWinA) {
        if (symbol === '3' && quantity === 3) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍊', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5, key: uuid() },
          ])
          return
        }
        if (symbol === '3' && quantity === 4) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍊', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 25, key: uuid() },
          ])
          return
        }
        if (symbol === '3' && quantity === 5) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍊', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          return
        }
      }
      if (!symbol8WildCardWinA) {
        if (symbol === '2' && quantity === 3) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍋', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5, key: uuid() },
          ])
          return
        }
        if (symbol === '2' && quantity === 4) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍋', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 25, key: uuid() },
          ])
          return
        }
        if (symbol === '2' && quantity === 5) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍋', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          return
        }
      }
      if (!symbol9WildCardWinA) {
        if (symbol === '1' && quantity === 3) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍈', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5, key: uuid() },
          ])
          return
        }
        if (symbol === '1' && quantity === 4) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍈', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 25, key: uuid() },
          ])
          return
        }
        if (symbol === '1' && quantity === 5) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍈', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          return
        }
      }
    })
  }

  return null
}

export default CalculateRowAWin
