import { useState, useContext, useEffect } from 'react'

import { Context as UserContext } from '../../../../../context/UserContext'
import { Context as GameContext } from '../../../../../context/GameContext'
import { Context as PayoutContext } from '../../../../../context/PayoutContext'
import { Context as SocketContext } from '../../../../../context/SocketContext'

const ProgresiveJackpot1 = () => {
  const [progresiveJackpot1StartValue, setProgresiveJackpot1StartValue] =
    useState(0)

  const [
    progresiveJackpot1BalanceCounter,
    setProgresiveJackpot1BalanceCounter,
  ] = useState(0)

  const [progresiveJackpot1, setProgresiveJackpot1] = useState(0)

  const {
    state: { jackpotData, progresiveJackpot1Balance },
    updateJackpot,
    setProgresiveJackpot1ReleaseBalance,
    setProgresiveJackpot1Balance,
  } = useContext(GameContext)

  const {
    state: { user },
  } = useContext(UserContext)

  const {
    state: {
      progresiveJackpot1Release,
      siteClients,
      addCreditAverage,
      progresiveJackpotBalanceRemaining,
    },
    setProgresiveJackpot1Release,
    setProgresiveReleaseDB,
  } = useContext(PayoutContext)

  const { setProgresiveTriggerValue } = useContext(SocketContext)

  useEffect(() => {
    if (progresiveJackpotBalanceRemaining) {
      setProgresiveJackpot1Balance(progresiveJackpotBalanceRemaining)
    }
  }, [progresiveJackpotBalanceRemaining, progresiveJackpot1Balance])

  useEffect(() => {
    if (jackpotData) {
      const { progresiveJackpot1Release, progresiveJackpot1 } = jackpotData
      setProgresiveJackpot1Release(progresiveJackpot1Release)
      setProgresiveJackpot1(progresiveJackpot1)
      let triggerValue = addCreditAverage * 100
      setProgresiveTriggerValue(triggerValue)
      if (triggerValue > 0) {
        if (progresiveJackpot1 > triggerValue) {
          setProgresiveJackpot1Release(true)
          setProgresiveReleaseDB({ progresiveJackpot1Release: true })
        }
      }
    }
  }, [jackpotData, addCreditAverage])

  useEffect(() => {
    if (progresiveJackpot1Release && progresiveJackpot1BalanceCounter === 0) {
      setProgresiveJackpot1StartValue(progresiveJackpot1)
      setProgresiveJackpot1Balance(progresiveJackpot1)
      setProgresiveJackpot1ReleaseBalance(true)
      setProgresiveJackpot1BalanceCounter(1)
    }
  }, [
    progresiveJackpot1Release,
    progresiveJackpot1,
    progresiveJackpot1BalanceCounter,
  ])

  useEffect(() => {
    let progresiveJackpot1BalancePercentage =
      (progresiveJackpot1Balance / progresiveJackpot1StartValue) * 100
    if (progresiveJackpot1BalancePercentage < 20) {
      setProgresiveJackpot1ReleaseBalance(false)
      updateJackpot({
        progresiveJackpot1PayoutEnd: true,
        siteNumber: user.siteNumber,
      })
      setProgresiveJackpot1Balance(0)
      setProgresiveJackpot1BalanceCounter(0)
    }
  }, [progresiveJackpot1Balance, progresiveJackpot1StartValue])

  return null
}

export default ProgresiveJackpot1
