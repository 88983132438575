import { useEffect } from 'react'

import fruits from '../../../../../assets/images/gameButtons/fruits.png'

// Win gifs
import winGif001 from '../../../../../assets/images/gifs/win/winGif001.gif'
import winGif002 from '../../../../../assets/images/gifs/win/winGif002.gif'
import winGif003 from '../../../../../assets/images/gifs/win/winGif003.gif'
import winGif004 from '../../../../../assets/images/gifs/win/winGif004.gif'
import winGif005 from '../../../../../assets/images/gifs/win/winGif005.gif'
import winGif006 from '../../../../../assets/images/gifs/win/winGif006.gif'
import winGif007 from '../../../../../assets/images/gifs/win/winGif007.gif'
import winGif008 from '../../../../../assets/images/gifs/win/winGif008.gif'
import winGif009 from '../../../../../assets/images/gifs/win/winGif009.gif'
import winGif010 from '../../../../../assets/images/gifs/win/winGif010.gif'
import winGif011 from '../../../../../assets/images/gifs/win/winGif011.gif'
import winGif012 from '../../../../../assets/images/gifs/win/winGif012.gif'
import winGif013 from '../../../../../assets/images/gifs/win/winGif013.gif'
import winGif014 from '../../../../../assets/images/gifs/win/winGif014.gif'
import winGif015 from '../../../../../assets/images/gifs/win/winGif015.gif'
import winGif016 from '../../../../../assets/images/gifs/win/winGif016.gif'
import winGif017 from '../../../../../assets/images/gifs/win/winGif017.gif'
import winGif018 from '../../../../../assets/images/gifs/win/winGif018.gif'
import winGif019 from '../../../../../assets/images/gifs/win/winGif019.gif'
import winGif020 from '../../../../../assets/images/gifs/win/winGif020.gif'
import winGif021 from '../../../../../assets/images/gifs/win/winGif021.gif'
import winGif022 from '../../../../../assets/images/gifs/win/winGif022.gif'
import winGif023 from '../../../../../assets/images/gifs/win/winGif023.gif'
import winGif024 from '../../../../../assets/images/gifs/win/winGif024.gif'
import winGif025 from '../../../../../assets/images/gifs/win/winGif025.gif'
import winGif026 from '../../../../../assets/images/gifs/win/winGif026.gif'
import winGif027 from '../../../../../assets/images/gifs/win/winGif027.gif'
import winGif028 from '../../../../../assets/images/gifs/win/winGif028.gif'
import winGif029 from '../../../../../assets/images/gifs/win/winGif029.gif'
import winGif030 from '../../../../../assets/images/gifs/win/winGif030.gif'
import winGif031 from '../../../../../assets/images/gifs/win/winGif031.gif'
import winGif032 from '../../../../../assets/images/gifs/win/winGif032.gif'
import winGif033 from '../../../../../assets/images/gifs/win/winGif033.gif'
import winGif034 from '../../../../../assets/images/gifs/win/winGif034.gif'
import winGif035 from '../../../../../assets/images/gifs/win/winGif035.gif'
import winGif036 from '../../../../../assets/images/gifs/win/winGif036.gif'
import winGif037 from '../../../../../assets/images/gifs/win/winGif037.gif'
import winGif038 from '../../../../../assets/images/gifs/win/winGif038.gif'
import winGif039 from '../../../../../assets/images/gifs/win/winGif039.gif'
import winGif040 from '../../../../../assets/images/gifs/win/winGif040.gif'
import winGif041 from '../../../../../assets/images/gifs/win/winGif041.gif'
import winGif042 from '../../../../../assets/images/gifs/win/winGif042.gif'
import winGif043 from '../../../../../assets/images/gifs/win/winGif043.gif'
import winGif044 from '../../../../../assets/images/gifs/win/winGif044.gif'
import winGif045 from '../../../../../assets/images/gifs/win/winGif045.gif'
import winGif046 from '../../../../../assets/images/gifs/win/winGif046.gif'
import winGif047 from '../../../../../assets/images/gifs/win/winGif047.gif'
import winGif048 from '../../../../../assets/images/gifs/win/winGif048.gif'
import winGif049 from '../../../../../assets/images/gifs/win/winGif049.gif'
import winGif050 from '../../../../../assets/images/gifs/win/winGif050.gif'
import winGif051 from '../../../../../assets/images/gifs/win/winGif051.gif'
import winGif052 from '../../../../../assets/images/gifs/win/winGif052.gif'
import winGif053 from '../../../../../assets/images/gifs/win/winGif053.gif'
import winGif054 from '../../../../../assets/images/gifs/win/winGif054.gif'
import winGif055 from '../../../../../assets/images/gifs/win/winGif055.gif'
import winGif056 from '../../../../../assets/images/gifs/win/winGif056.gif'
import winGif057 from '../../../../../assets/images/gifs/win/winGif057.gif'
import winGif058 from '../../../../../assets/images/gifs/win/winGif058.gif'
import winGif059 from '../../../../../assets/images/gifs/win/winGif059.gif'
import winGif060 from '../../../../../assets/images/gifs/win/winGif060.gif'
import winGif061 from '../../../../../assets/images/gifs/win/winGif061.gif'
import winGif062 from '../../../../../assets/images/gifs/win/winGif062.gif'
import winGif063 from '../../../../../assets/images/gifs/win/winGif063.gif'
import winGif064 from '../../../../../assets/images/gifs/win/winGif064.gif'
import winGif065 from '../../../../../assets/images/gifs/win/winGif065.gif'
import winGif066 from '../../../../../assets/images/gifs/win/winGif066.gif'
import winGif067 from '../../../../../assets/images/gifs/win/winGif067.gif'
import winGif068 from '../../../../../assets/images/gifs/win/winGif068.gif'
import winGif069 from '../../../../../assets/images/gifs/win/winGif069.gif'
import winGif070 from '../../../../../assets/images/gifs/win/winGif070.gif'
import winGif071 from '../../../../../assets/images/gifs/win/winGif071.gif'
import winGif072 from '../../../../../assets/images/gifs/win/winGif072.gif'
import winGif073 from '../../../../../assets/images/gifs/win/winGif073.gif'
import winGif074 from '../../../../../assets/images/gifs/win/winGif074.gif'
import winGif075 from '../../../../../assets/images/gifs/win/winGif075.gif'
import winGif076 from '../../../../../assets/images/gifs/win/winGif076.gif'
import winGif077 from '../../../../../assets/images/gifs/win/winGif077.gif'
import winGif078 from '../../../../../assets/images/gifs/win/winGif078.gif'
import winGif079 from '../../../../../assets/images/gifs/win/winGif079.gif'
import winGif080 from '../../../../../assets/images/gifs/win/winGif080.gif'
import winGif081 from '../../../../../assets/images/gifs/win/winGif081.gif'
import winGif082 from '../../../../../assets/images/gifs/win/winGif082.gif'
import winGif083 from '../../../../../assets/images/gifs/win/winGif083.gif'
import winGif084 from '../../../../../assets/images/gifs/win/winGif084.gif'
import winGif085 from '../../../../../assets/images/gifs/win/winGif085.gif'
import winGif086 from '../../../../../assets/images/gifs/win/winGif086.gif'
// Good luck gifs
import goodLuckGif001 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif001.gif'
import goodLuckGif002 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif002.gif'
import goodLuckGif003 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif003.gif'
import goodLuckGif004 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif004.gif'
import goodLuckGif005 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif005.gif'
import goodLuckGif006 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif006.gif'
import goodLuckGif007 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif007.gif'
import goodLuckGif008 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif008.gif'
import goodLuckGif009 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif009.gif'
import goodLuckGif010 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif010.gif'
import goodLuckGif011 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif011.gif'
import goodLuckGif012 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif012.gif'
import goodLuckGif013 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif013.gif'
import goodLuckGif014 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif014.gif'
import goodLuckGif015 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif015.gif'
import goodLuckGif016 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif016.gif'
import goodLuckGif017 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif017.gif'
import goodLuckGif018 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif018.gif'
import goodLuckGif019 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif019.gif'
import goodLuckGif020 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif020.gif'
import goodLuckGif021 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif021.gif'
import goodLuckGif022 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif022.gif'
import goodLuckGif023 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif023.gif'
import goodLuckGif024 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif024.gif'
import goodLuckGif025 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif025.gif'
import goodLuckGif026 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif026.gif'
import goodLuckGif027 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif027.gif'
import goodLuckGif028 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif028.gif'
import goodLuckGif029 from '../../../../../assets/images/gifs/goodLuck/goodLuckGif029.gif'
// So sad gifs
import soSadGif001 from '../../../../../assets/images/gifs/soSad/soSadGif001.gif'
import soSadGif002 from '../../../../../assets/images/gifs/soSad/soSadGif002.gif'
import soSadGif003 from '../../../../../assets/images/gifs/soSad/soSadGif003.gif'
import soSadGif004 from '../../../../../assets/images/gifs/soSad/soSadGif004.gif'
import soSadGif005 from '../../../../../assets/images/gifs/soSad/soSadGif005.gif'
import soSadGif006 from '../../../../../assets/images/gifs/soSad/soSadGif006.gif'
import soSadGif007 from '../../../../../assets/images/gifs/soSad/soSadGif007.gif'
import soSadGif008 from '../../../../../assets/images/gifs/soSad/soSadGif008.gif'
import soSadGif009 from '../../../../../assets/images/gifs/soSad/soSadGif009.gif'
import soSadGif010 from '../../../../../assets/images/gifs/soSad/soSadGif010.gif'
import soSadGif011 from '../../../../../assets/images/gifs/soSad/soSadGif011.gif'
import soSadGif012 from '../../../../../assets/images/gifs/soSad/soSadGif012.gif'
import soSadGif013 from '../../../../../assets/images/gifs/soSad/soSadGif013.gif'
import soSadGif014 from '../../../../../assets/images/gifs/soSad/soSadGif014.gif'
import soSadGif015 from '../../../../../assets/images/gifs/soSad/soSadGif015.gif'
import soSadGif016 from '../../../../../assets/images/gifs/soSad/soSadGif016.gif'
import soSadGif017 from '../../../../../assets/images/gifs/soSad/soSadGif017.gif'
import soSadGif018 from '../../../../../assets/images/gifs/soSad/soSadGif018.gif'
import soSadGif019 from '../../../../../assets/images/gifs/soSad/soSadGif019.gif'
import soSadGif020 from '../../../../../assets/images/gifs/soSad/soSadGif020.gif'
import soSadGif021 from '../../../../../assets/images/gifs/soSad/soSadGif021.gif'
import soSadGif022 from '../../../../../assets/images/gifs/soSad/soSadGif022.gif'
import soSadGif023 from '../../../../../assets/images/gifs/soSad/soSadGif023.gif'
import soSadGif024 from '../../../../../assets/images/gifs/soSad/soSadGif024.gif'
import soSadGif025 from '../../../../../assets/images/gifs/soSad/soSadGif025.gif'
import soSadGif026 from '../../../../../assets/images/gifs/soSad/soSadGif026.gif'
import soSadGif027 from '../../../../../assets/images/gifs/soSad/soSadGif027.gif'
import soSadGif028 from '../../../../../assets/images/gifs/soSad/soSadGif028.gif'
import soSadGif029 from '../../../../../assets/images/gifs/soSad/soSadGif029.gif'
import soSadGif030 from '../../../../../assets/images/gifs/soSad/soSadGif030.gif'
import soSadGif031 from '../../../../../assets/images/gifs/soSad/soSadGif031.gif'
import soSadGif032 from '../../../../../assets/images/gifs/soSad/soSadGif032.gif'
import soSadGif033 from '../../../../../assets/images/gifs/soSad/soSadGif033.gif'
import soSadGif034 from '../../../../../assets/images/gifs/soSad/soSadGif034.gif'
import soSadGif035 from '../../../../../assets/images/gifs/soSad/soSadGif035.gif'
import soSadGif036 from '../../../../../assets/images/gifs/soSad/soSadGif036.gif'
import soSadGif037 from '../../../../../assets/images/gifs/soSad/soSadGif037.gif'
import soSadGif038 from '../../../../../assets/images/gifs/soSad/soSadGif038.gif'
import soSadGif039 from '../../../../../assets/images/gifs/soSad/soSadGif039.gif'
import soSadGif040 from '../../../../../assets/images/gifs/soSad/soSadGif040.gif'
import soSadGif041 from '../../../../../assets/images/gifs/soSad/soSadGif041.gif'
import soSadGif042 from '../../../../../assets/images/gifs/soSad/soSadGif042.gif'
import soSadGif043 from '../../../../../assets/images/gifs/soSad/soSadGif043.gif'
import soSadGif044 from '../../../../../assets/images/gifs/soSad/soSadGif044.gif'
import soSadGif045 from '../../../../../assets/images/gifs/soSad/soSadGif045.gif'
import soSadGif046 from '../../../../../assets/images/gifs/soSad/soSadGif046.gif'
import soSadGif047 from '../../../../../assets/images/gifs/soSad/soSadGif047.gif'
import soSadGif048 from '../../../../../assets/images/gifs/soSad/soSadGif048.gif'
import soSadGif049 from '../../../../../assets/images/gifs/soSad/soSadGif049.gif'
import soSadGif050 from '../../../../../assets/images/gifs/soSad/soSadGif050.gif'
import soSadGif051 from '../../../../../assets/images/gifs/soSad/soSadGif051.gif'
import soSadGif052 from '../../../../../assets/images/gifs/soSad/soSadGif052.gif'
import soSadGif053 from '../../../../../assets/images/gifs/soSad/soSadGif053.gif'
import soSadGif054 from '../../../../../assets/images/gifs/soSad/soSadGif054.gif'
import soSadGif055 from '../../../../../assets/images/gifs/soSad/soSadGif055.gif'
import soSadGif056 from '../../../../../assets/images/gifs/soSad/soSadGif056.gif'
import soSadGif057 from '../../../../../assets/images/gifs/soSad/soSadGif057.gif'
import soSadGif058 from '../../../../../assets/images/gifs/soSad/soSadGif058.gif'
import soSadGif059 from '../../../../../assets/images/gifs/soSad/soSadGif059.gif'
import soSadGif060 from '../../../../../assets/images/gifs/soSad/soSadGif060.gif'
import soSadGif061 from '../../../../../assets/images/gifs/soSad/soSadGif061.gif'
import soSadGif062 from '../../../../../assets/images/gifs/soSad/soSadGif062.gif'
import soSadGif063 from '../../../../../assets/images/gifs/soSad/soSadGif063.gif'
import soSadGif064 from '../../../../../assets/images/gifs/soSad/soSadGif064.gif'
import soSadGif065 from '../../../../../assets/images/gifs/soSad/soSadGif065.gif'
import soSadGif066 from '../../../../../assets/images/gifs/soSad/soSadGif066.gif'
import soSadGif067 from '../../../../../assets/images/gifs/soSad/soSadGif067.gif'
import soSadGif068 from '../../../../../assets/images/gifs/soSad/soSadGif068.gif'
import soSadGif069 from '../../../../../assets/images/gifs/soSad/soSadGif069.gif'
import soSadGif070 from '../../../../../assets/images/gifs/soSad/soSadGif070.gif'
import soSadGif071 from '../../../../../assets/images/gifs/soSad/soSadGif071.gif'
import soSadGif072 from '../../../../../assets/images/gifs/soSad/soSadGif072.gif'
import soSadGif073 from '../../../../../assets/images/gifs/soSad/soSadGif073.gif'
import soSadGif074 from '../../../../../assets/images/gifs/soSad/soSadGif074.gif'
import soSadGif075 from '../../../../../assets/images/gifs/soSad/soSadGif075.gif'
import soSadGif076 from '../../../../../assets/images/gifs/soSad/soSadGif076.gif'
import soSadGif077 from '../../../../../assets/images/gifs/soSad/soSadGif077.gif'
import soSadGif078 from '../../../../../assets/images/gifs/soSad/soSadGif078.gif'
import soSadGif079 from '../../../../../assets/images/gifs/soSad/soSadGif079.gif'
import soSadGif080 from '../../../../../assets/images/gifs/soSad/soSadGif080.gif'
import soSadGif081 from '../../../../../assets/images/gifs/soSad/soSadGif081.gif'
import soSadGif082 from '../../../../../assets/images/gifs/soSad/soSadGif082.gif'
import soSadGif083 from '../../../../../assets/images/gifs/soSad/soSadGif083.gif'
import soSadGif084 from '../../../../../assets/images/gifs/soSad/soSadGif084.gif'
import soSadGif085 from '../../../../../assets/images/gifs/soSad/soSadGif085.gif'
import soSadGif086 from '../../../../../assets/images/gifs/soSad/soSadGif086.gif'
import soSadGif087 from '../../../../../assets/images/gifs/soSad/soSadGif087.gif'
import soSadGif088 from '../../../../../assets/images/gifs/soSad/soSadGif088.gif'
import soSadGif089 from '../../../../../assets/images/gifs/soSad/soSadGif089.gif'
import soSadGif090 from '../../../../../assets/images/gifs/soSad/soSadGif090.gif'
import soSadGif091 from '../../../../../assets/images/gifs/soSad/soSadGif090.gif'
import soSadGif092 from '../../../../../assets/images/gifs/soSad/soSadGif090.gif'
import soSadGif093 from '../../../../../assets/images/gifs/soSad/soSadGif090.gif'
import soSadGif094 from '../../../../../assets/images/gifs/soSad/soSadGif090.gif'
import soSadGif095 from '../../../../../assets/images/gifs/soSad/soSadGif090.gif'
import soSadGif096 from '../../../../../assets/images/gifs/soSad/soSadGif090.gif'
import soSadGif097 from '../../../../../assets/images/gifs/soSad/soSadGif090.gif'
import soSadGif098 from '../../../../../assets/images/gifs/soSad/soSadGif090.gif'
import soSadGif099 from '../../../../../assets/images/gifs/soSad/soSadGif090.gif'
import soSadGif100 from '../../../../../assets/images/gifs/soSad/soSadGif100.gif'
import soSadGif101 from '../../../../../assets/images/gifs/soSad/soSadGif101.gif'
import soSadGif102 from '../../../../../assets/images/gifs/soSad/soSadGif102.gif'
import soSadGif103 from '../../../../../assets/images/gifs/soSad/soSadGif103.gif'
import soSadGif104 from '../../../../../assets/images/gifs/soSad/soSadGif104.gif'
import soSadGif105 from '../../../../../assets/images/gifs/soSad/soSadGif105.gif'
import soSadGif106 from '../../../../../assets/images/gifs/soSad/soSadGif106.gif'
import soSadGif107 from '../../../../../assets/images/gifs/soSad/soSadGif107.gif'
import soSadGif108 from '../../../../../assets/images/gifs/soSad/soSadGif108.gif'
import soSadGif109 from '../../../../../assets/images/gifs/soSad/soSadGif109.gif'

const GifPreloader = () => {
  const imageUrls = [
    winGif001,
    winGif002,
    winGif003,
    winGif004,
    winGif005,
    winGif006,
    winGif007,
    winGif008,
    winGif009,
    winGif010,
    winGif011,
    winGif012,
    winGif013,
    winGif014,
    winGif015,
    winGif016,
    winGif017,
    winGif018,
    winGif019,
    winGif020,
    winGif021,
    winGif022,
    winGif023,
    winGif024,
    winGif025,
    winGif026,
    winGif027,
    winGif028,
    winGif029,
    winGif030,
    winGif031,
    winGif032,
    winGif033,
    winGif034,
    winGif035,
    winGif036,
    winGif037,
    winGif038,
    winGif039,
    winGif040,
    winGif041,
    winGif042,
    winGif043,
    winGif044,
    winGif045,
    winGif046,
    winGif047,
    winGif048,
    winGif049,
    winGif050,
    winGif051,
    winGif052,
    winGif053,
    winGif054,
    winGif055,
    winGif056,
    winGif057,
    winGif058,
    winGif059,
    winGif060,
    winGif061,
    winGif062,
    winGif063,
    winGif064,
    winGif065,
    winGif066,
    winGif067,
    winGif068,
    winGif069,
    winGif070,
    winGif071,
    winGif072,
    winGif073,
    winGif074,
    winGif075,
    winGif076,
    winGif077,
    winGif078,
    winGif079,
    winGif080,
    winGif081,
    winGif082,
    winGif083,
    winGif084,
    winGif085,
    winGif086,
    goodLuckGif001,
    goodLuckGif002,
    goodLuckGif003,
    goodLuckGif004,
    goodLuckGif005,
    goodLuckGif006,
    goodLuckGif007,
    goodLuckGif008,
    goodLuckGif009,
    goodLuckGif010,
    goodLuckGif011,
    goodLuckGif012,
    goodLuckGif013,
    goodLuckGif014,
    goodLuckGif015,
    goodLuckGif016,
    goodLuckGif017,
    goodLuckGif018,
    goodLuckGif019,
    goodLuckGif020,
    goodLuckGif021,
    goodLuckGif022,
    goodLuckGif023,
    goodLuckGif024,
    goodLuckGif025,
    goodLuckGif026,
    goodLuckGif027,
    goodLuckGif028,
    goodLuckGif029,
    soSadGif001,
    soSadGif002,
    soSadGif003,
    soSadGif004,
    soSadGif005,
    soSadGif006,
    soSadGif007,
    soSadGif008,
    soSadGif009,
    soSadGif010,
    soSadGif011,
    soSadGif012,
    soSadGif013,
    soSadGif014,
    soSadGif015,
    soSadGif016,
    soSadGif017,
    soSadGif018,
    soSadGif019,
    soSadGif020,
    soSadGif021,
    soSadGif022,
    soSadGif023,
    soSadGif024,
    soSadGif025,
    soSadGif026,
    soSadGif027,
    soSadGif028,
    soSadGif029,
    soSadGif030,
    soSadGif031,
    soSadGif032,
    soSadGif033,
    soSadGif034,
    soSadGif035,
    soSadGif036,
    soSadGif037,
    soSadGif038,
    soSadGif039,
    soSadGif040,
    soSadGif041,
    soSadGif042,
    soSadGif043,
    soSadGif044,
    soSadGif045,
    soSadGif046,
    soSadGif047,
    soSadGif048,
    soSadGif049,
    soSadGif050,
    soSadGif051,
    soSadGif052,
    soSadGif053,
    soSadGif054,
    soSadGif055,
    soSadGif056,
    soSadGif057,
    soSadGif058,
    soSadGif059,
    soSadGif060,
    soSadGif061,
    soSadGif062,
    soSadGif063,
    soSadGif064,
    soSadGif065,
    soSadGif066,
    soSadGif067,
    soSadGif068,
    soSadGif069,
    soSadGif070,
    soSadGif071,
    soSadGif072,
    soSadGif073,
    soSadGif074,
    soSadGif075,
    soSadGif076,
    soSadGif077,
    soSadGif078,
    soSadGif079,
    soSadGif080,
    soSadGif081,
    soSadGif082,
    soSadGif083,
    soSadGif084,
    soSadGif085,
    soSadGif086,
    soSadGif087,
    soSadGif088,
    soSadGif089,
    soSadGif090,
    soSadGif091,
    soSadGif092,
    soSadGif093,
    soSadGif094,
    soSadGif095,
    soSadGif096,
    soSadGif097,
    soSadGif098,
    soSadGif099,
    soSadGif100,
    soSadGif101,
    soSadGif102,
    soSadGif103,
    soSadGif104,
    soSadGif105,
    soSadGif106,
    soSadGif107,
    soSadGif108,
    soSadGif109,
  ]

  useEffect(() => {
    const images = []
    imageUrls.forEach((imageUrl) => {
      const img = new Image()
      img.src = imageUrl
      img.onload = () => {
        images.push(img)
        if (images.length === imageUrls.length) {
          console.log('All images preloaded')
        }
      }
      img.onerror = () => {
        console.error('Failed to preload image: ' + imageUrl)
      }
    })
  }, [])

  return null
}

export default GifPreloader
