import React, { useContext } from 'react'

import { Context as GameContext } from '../../../../context/GameContext'

import './linesOverlay.css'

const LinesOverlay = () => {
  const {
    state: { numberOfLines, reelSpinning5, linesOverlayShow },
  } = useContext(GameContext)

  const renderLines = (num, show, hide) => {
    if (reelSpinning5) return null
    if (numberOfLines > num) {
      return show
    } else {
      return hide
    }
  }

  const renderContent = () => {
    if (!linesOverlayShow) return null
    return (
      <div className="linesOverlayBed">
        <div className="linesOverlayTop"></div>
        <div className="linesOverlayMiddle">
          <div className="linesOverlayMiddleTop">
            <div
              className={renderLines(
                1,
                'linesOverlayTopLine',
                'linesOverlayTopLineHidden'
              )}
            ></div>
          </div>
          <div className="linesOverlayMiddleCenter">
            <div
              className={renderLines(
                0,
                'linesOverlayMiddleLine',
                'linesOverlayMiddleLineHidden'
              )}
            ></div>
          </div>
          <div className="linesOverlayMiddleBottom">
            <div
              className={renderLines(
                2,
                'linesOverlayBottomLine',
                'linesOverlayBottomLineHidden'
              )}
            ></div>
          </div>
        </div>
        <div className="linesOverlayBottom"></div>
      </div>
    )
  }

  return renderContent()
}

export default LinesOverlay
