import createDataContext from './createDataContext'
import ngrokApi from '../api/ngrokApi'

// Reducer
const GameContext = (state, action) => {
  switch (action.type) {
    case 'LOADING':
      return { ...state, loading: action.payload }
    case 'FETCH_ALL_GAME_PLAY_DATA':
      return { ...state, gamePlayData: action.payload }
    case 'FETCH_LATEST_GAME_PLAY_DATA':
      return { ...state, latestGamePlayData: action.payload }
    case 'FETCH_JACKPOT_DATA':
      return { ...state, jackpotData: action.payload }
    case 'UPDATE_JACKPOT':
      return { ...state, jackpotData: action.payload }
    case 'JACKPOT_CLEARED':
      return { ...state, jackpotCleared: action.payload }
    case 'SET_PROGRESIVE_JACKPOT_1_BALANCE':
      return { ...state, progresiveJackpot1Balance: action.payload }
    case 'SET_PROGRESIVE_JACKPOT_1_BALANCE_COUNT':
      return { ...state, progresiveJackpot1BalanceCounter: action.payload }
    case 'SET_PROGRESIVE_JACKPOT_1_RELEASE_BALANCE':
      return { ...state, progresiveJackpot1ReleaseBalance: action.payload }
    case 'SET_SPIN_COUNT':
      return { ...state, spinCount: action.payload }
    case 'SET_NEW_GAME_OPEN_BALANCE':
      return { ...state, newGameOpenBalance: action.payload }
    case 'SET_NAME_OF_GAME':
      return { ...state, nameOfGame: action.payload }
    case 'SET_CREDITS':
      return { ...state, gameCredits: action.payload }
    case 'SET_BET_VALUE':
      return { ...state, betValue: action.payload }
    case 'SET_BET_TOTAL':
      return { ...state, betTotal: action.payload }
    case 'SET_NUMBER_OF_LINES':
      return { ...state, numberOfLines: action.payload }
    case 'SET_RANDOM_NUMBER_A1':
      return { ...state, randomNumberA1: action.payload }
    case 'SET_RANDOM_NUMBER_A2':
      return { ...state, randomNumberA2: action.payload }
    case 'SET_RANDOM_NUMBER_A3':
      return { ...state, randomNumberA3: action.payload }
    case 'SET_RANDOM_NUMBER_A4':
      return { ...state, randomNumberA4: action.payload }
    case 'SET_RANDOM_NUMBER_A5':
      return { ...state, randomNumberA5: action.payload }
    case 'SET_RANDOM_NUMBER_B1':
      return { ...state, randomNumberB1: action.payload }
    case 'SET_RANDOM_NUMBER_B2':
      return { ...state, randomNumberB2: action.payload }
    case 'SET_RANDOM_NUMBER_B3':
      return { ...state, randomNumberB3: action.payload }
    case 'SET_RANDOM_NUMBER_B4':
      return { ...state, randomNumberB4: action.payload }
    case 'SET_RANDOM_NUMBER_B5':
      return { ...state, randomNumberB5: action.payload }
    case 'SET_RANDOM_NUMBER_C1':
      return { ...state, randomNumberC1: action.payload }
    case 'SET_RANDOM_NUMBER_C2':
      return { ...state, randomNumberC2: action.payload }
    case 'SET_RANDOM_NUMBER_C3':
      return { ...state, randomNumberC3: action.payload }
    case 'SET_RANDOM_NUMBER_C4':
      return { ...state, randomNumberC4: action.payload }
    case 'SET_RANDOM_NUMBER_C5':
      return { ...state, randomNumberC5: action.payload }
    case 'SET_SYMBOL_LANDED_A1':
      return { ...state, symbolLandedA1: action.payload }
    case 'SET_SYMBOL_LANDED_A2':
      return { ...state, symbolLandedA2: action.payload }
    case 'SET_SYMBOL_LANDED_A3':
      return { ...state, symbolLandedA3: action.payload }
    case 'SET_SYMBOL_LANDED_A4':
      return { ...state, symbolLandedA4: action.payload }
    case 'SET_SYMBOL_LANDED_A5':
      return { ...state, symbolLandedA5: action.payload }
    case 'SET_SYMBOL_LANDED_B1':
      return { ...state, symbolLandedB1: action.payload }
    case 'SET_SYMBOL_LANDED_B2':
      return { ...state, symbolLandedB2: action.payload }
    case 'SET_SYMBOL_LANDED_B3':
      return { ...state, symbolLandedB3: action.payload }
    case 'SET_SYMBOL_LANDED_B4':
      return { ...state, symbolLandedB4: action.payload }
    case 'SET_SYMBOL_LANDED_B5':
      return { ...state, symbolLandedB5: action.payload }
    case 'SET_SYMBOL_LANDED_C1':
      return { ...state, symbolLandedC1: action.payload }
    case 'SET_SYMBOL_LANDED_C2':
      return { ...state, symbolLandedC2: action.payload }
    case 'SET_SYMBOL_LANDED_C3':
      return { ...state, symbolLandedC3: action.payload }
    case 'SET_SYMBOL_LANDED_C4':
      return { ...state, symbolLandedC4: action.payload }
    case 'SET_SYMBOL_LANDED_C5':
      return { ...state, symbolLandedC5: action.payload }
    case 'SET_SYMBOL_LANDED_D1':
      return { ...state, symbolLandedD1: action.payload }
    case 'SET_SYMBOL_LANDED_D2':
      return { ...state, symbolLandedD2: action.payload }
    case 'SET_SYMBOL_LANDED_D3':
      return { ...state, symbolLandedD3: action.payload }
    case 'SET_SYMBOL_LANDED_D4':
      return { ...state, symbolLandedD4: action.payload }
    case 'SET_SYMBOL_LANDED_D5':
      return { ...state, symbolLandedD5: action.payload }
    case 'SET_SYMBOL_LANDED_E1':
      return { ...state, symbolLandedE1: action.payload }
    case 'SET_SYMBOL_LANDED_E2':
      return { ...state, symbolLandedE2: action.payload }
    case 'SET_SYMBOL_LANDED_E3':
      return { ...state, symbolLandedE3: action.payload }
    case 'SET_SYMBOL_LANDED_E4':
      return { ...state, symbolLandedE4: action.payload }
    case 'SET_SYMBOL_LANDED_E5':
      return { ...state, symbolLandedE5: action.payload }
    case 'SET_ALL_RANDOM_NUMBERS_DONE':
      return { ...state, allRandomNumbersDone: action.payload }
    case 'SET_LINES_OVERLAY_SHOW':
      return { ...state, linesOverlayShow: action.payload }
    case 'SET_JACKPOT_OVERLAY_SHOW':
      return { ...state, jackpotOverlayShow: action.payload }
    case 'SET_NETWORK_ERROR_OVERLAY_SHOW':
      return { ...state, networkErrorOverlayShow: action.payload }
    case 'SET_REEL_SPINNING_1':
      return { ...state, reelSpinning1: action.payload }
    case 'SET_REEL_SPINNING_2':
      return { ...state, reelSpinning2: action.payload }
    case 'SET_REEL_SPINNING_4':
      return { ...state, reelSpinning4: action.payload }
    case 'SET_REEL_SPINNING_5':
      return { ...state, reelSpinning5: action.payload }
    case 'SET_ROW_A':
      return { ...state, rowA: action.payload }
    case 'SET_ROW_B':
      return { ...state, rowB: action.payload }
    case 'SET_ROW_C':
      return { ...state, rowC: action.payload }
    case 'SET_ROW_A_RESULT':
      return { ...state, rowAResult: action.payload }
    case 'SET_ROW_B_RESULT':
      return { ...state, rowBResult: action.payload }
    case 'SET_ROW_C_RESULT':
      return { ...state, rowCResult: action.payload }
    case 'SET_ROW_A_WIN_ARRAY':
      return { ...state, rowAWinArray: action.payload }
    case 'SET_ROW_B_WIN_ARRAY':
      return { ...state, rowBWinArray: action.payload }
    case 'SET_ROW_C_WIN_ARRAY':
      return { ...state, rowCWinArray: action.payload }
    case 'SET_ROW_A_PRIZE_ARRAY':
      return { ...state, rowAPrizeArray: action.payload }
    case 'SET_ROW_B_PRIZE_ARRAY':
      return { ...state, rowBPrizeArray: action.payload }
    case 'SET_ROW_C_PRIZE_ARRAY':
      return { ...state, rowCPrizeArray: action.payload }
    case 'SET_WINNING_SYMBOL_ROW_A':
      return { ...state, winningSymbolRowA: action.payload }
    case 'SET_WINNING_SYMBOL_ROW_B':
      return { ...state, winningSymbolRowB: action.payload }
    case 'SET_WINNING_SYMBOL_ROW_C':
      return { ...state, winningSymbolRowC: action.payload }
    case 'SET_ROW_A_PRIZE':
      return { ...state, rowAPrize: action.payload }
    case 'SET_ROW_B_PRIZE':
      return { ...state, rowBPrize: action.payload }
    case 'SET_ROW_C_PRIZE':
      return { ...state, rowCPrize: action.payload }
    case 'SET_PRIZE':
      return { ...state, prize: action.payload }
    case 'SET_WILD_CARD_QUANTITY_ROW_A':
      return { ...state, wildCardQuantityRowA: action.payload }
    case 'SET_WILD_CARD_QUANTITY_ROW_B':
      return { ...state, wildCardQuantityRowB: action.payload }
    case 'SET_WILD_CARD_QUANTITY_ROW_C':
      return { ...state, wildCardQuantityRowC: action.payload }
    case 'SET_SCATTER_QUANTITY_ROW_A':
      return { ...state, scatterQuantityRowA: action.payload }
    case 'SET_SCATTER_QUANTITY_ROW_B':
      return { ...state, scatterQuantityRowB: action.payload }
    case 'SET_SCATTER_QUANTITY_ROW_C':
      return { ...state, scatterQuantityRowC: action.payload }
    case 'SET_SCATTER_AND_WILD_CARD_CHECK_DONE_ROW_A':
      return { ...state, scatterAndWildCardCheckDoneRowA: action.payload }
    case 'SET_SCATTER_AND_WILD_CARD_CHECK_DONE_ROW_B':
      return { ...state, scatterAndWildCardCheckDoneRowB: action.payload }
    case 'SET_SCATTER_AND_WILD_CARD_CHECK_DONE_ROW_C':
      return { ...state, scatterAndWildCardCheckDoneRowC: action.payload }
    case 'SET_SCATTER_WIN_OVERLAY_SHOW':
      return { ...state, scatterWinOverlayShow: action.payload }
    case 'SET_ROW_A_FREE_SPINS':
      return { ...state, rowAFreeSpins: action.payload }
    case 'SET_ROW_B_FREE_SPINS':
      return { ...state, rowBFreeSpins: action.payload }
    case 'SET_ROW_C_FREE_SPINS':
      return { ...state, rowCFreeSpins: action.payload }
    case 'SET_FREE_SPINS_TOTAL':
      return { ...state, freeSpinsTotal: action.payload }
    case 'SET_FREE_SPIN_PLAY_ON':
      return { ...state, freeSpinPlayOn: action.payload }
    case 'SET_SYMBOL_WIN_CLASS_ROW_A1':
      return { ...state, symbolWinClassRowA1: action.payload }
    case 'SET_SYMBOL_WIN_CLASS_ROW_A2':
      return { ...state, symbolWinClassRowA2: action.payload }
    case 'SET_SYMBOL_WIN_CLASS_ROW_A3':
      return { ...state, symbolWinClassRowA3: action.payload }
    case 'SET_SYMBOL_WIN_CLASS_ROW_A4':
      return { ...state, symbolWinClassRowA4: action.payload }
    case 'SET_SYMBOL_WIN_CLASS_ROW_A5':
      return { ...state, symbolWinClassRowA5: action.payload }
    case 'SET_SYMBOL_WIN_CLASS_ROW_B1':
      return { ...state, symbolWinClassRowB1: action.payload }
    case 'SET_SYMBOL_WIN_CLASS_ROW_B2':
      return { ...state, symbolWinClassRowB2: action.payload }
    case 'SET_SYMBOL_WIN_CLASS_ROW_B3':
      return { ...state, symbolWinClassRowB3: action.payload }
    case 'SET_SYMBOL_WIN_CLASS_ROW_B4':
      return { ...state, symbolWinClassRowB4: action.payload }
    case 'SET_SYMBOL_WIN_CLASS_ROW_B5':
      return { ...state, symbolWinClassRowB5: action.payload }
    case 'SET_SYMBOL_WIN_CLASS_ROW_C1':
      return { ...state, symbolWinClassRowC1: action.payload }
    case 'SET_SYMBOL_WIN_CLASS_ROW_C2':
      return { ...state, symbolWinClassRowC2: action.payload }
    case 'SET_SYMBOL_WIN_CLASS_ROW_C3':
      return { ...state, symbolWinClassRowC3: action.payload }
    case 'SET_SYMBOL_WIN_CLASS_ROW_C4':
      return { ...state, symbolWinClassRowC4: action.payload }
    case 'SET_SYMBOL_WIN_CLASS_ROW_C5':
      return { ...state, symbolWinClassRowC5: action.payload }
    case 'SET_SYMBOL_WIN_CLASS_ROW_D1':
      return { ...state, symbolWinClassRowD1: action.payload }
    case 'SET_SYMBOL_WIN_CLASS_ROW_D2':
      return { ...state, symbolWinClassRowD2: action.payload }
    case 'SET_SYMBOL_WIN_CLASS_ROW_D3':
      return { ...state, symbolWinClassRowD3: action.payload }
    case 'SET_SYMBOL_WIN_CLASS_ROW_D4':
      return { ...state, symbolWinClassRowD4: action.payload }
    case 'SET_SYMBOL_WIN_CLASS_ROW_D5':
      return { ...state, symbolWinClassRowD5: action.payload }
    case 'SET_SYMBOL_WIN_CLASS_ROW_E1':
      return { ...state, symbolWinClassRowE1: action.payload }
    case 'SET_SYMBOL_WIN_CLASS_ROW_E2':
      return { ...state, symbolWinClassRowE2: action.payload }
    case 'SET_SYMBOL_WIN_CLASS_ROW_E3':
      return { ...state, symbolWinClassRowE3: action.payload }
    case 'SET_SYMBOL_WIN_CLASS_ROW_E4':
      return { ...state, symbolWinClassRowE4: action.payload }
    case 'SET_SYMBOL_WIN_CLASS_ROW_E5':
      return { ...state, symbolWinClassRowE5: action.payload }
    case 'SET_SYMBOL_1_WILD_CARD_WIN_A':
      return { ...state, symbol1WildCardWinA: action.payload }
    case 'SET_SYMBOL_2_WILD_CARD_WIN_A':
      return { ...state, symbol2WildCardWinA: action.payload }
    case 'SET_SYMBOL_3_WILD_CARD_WIN_A':
      return { ...state, symbol3WildCardWinA: action.payload }
    case 'SET_SYMBOL_4_WILD_CARD_WIN_A':
      return { ...state, symbol4WildCardWinA: action.payload }
    case 'SET_SYMBOL_5_WILD_CARD_WIN_A':
      return { ...state, symbol5WildCardWinA: action.payload }
    case 'SET_SYMBOL_6_WILD_CARD_WIN_A':
      return { ...state, symbol6WildCardWinA: action.payload }
    case 'SET_SYMBOL_7_WILD_CARD_WIN_A':
      return { ...state, symbol7WildCardWinA: action.payload }
    case 'SET_SYMBOL_8_WILD_CARD_WIN_A':
      return { ...state, symbol8WildCardWinA: action.payload }
    case 'SET_SYMBOL_9_WILD_CARD_WIN_A':
      return { ...state, symbol9WildCardWinA: action.payload }
    case 'SET_SYMBOL_1_WILD_CARD_WIN_B':
      return { ...state, symbol1WildCardWinB: action.payload }
    case 'SET_SYMBOL_2_WILD_CARD_WIN_B':
      return { ...state, symbol2WildCardWinB: action.payload }
    case 'SET_SYMBOL_3_WILD_CARD_WIN_B':
      return { ...state, symbol3WildCardWinB: action.payload }
    case 'SET_SYMBOL_4_WILD_CARD_WIN_B':
      return { ...state, symbol4WildCardWinB: action.payload }
    case 'SET_SYMBOL_5_WILD_CARD_WIN_B':
      return { ...state, symbol5WildCardWinB: action.payload }
    case 'SET_SYMBOL_6_WILD_CARD_WIN_B':
      return { ...state, symbol6WildCardWinB: action.payload }
    case 'SET_SYMBOL_7_WILD_CARD_WIN_B':
      return { ...state, symbol7WildCardWinB: action.payload }
    case 'SET_SYMBOL_8_WILD_CARD_WIN_B':
      return { ...state, symbol8WildCardWinB: action.payload }
    case 'SET_SYMBOL_9_WILD_CARD_WIN_B':
      return { ...state, symbol9WildCardWinB: action.payload }
    case 'SET_SYMBOL_1_WILD_CARD_WIN_C':
      return { ...state, symbol1WildCardWinC: action.payload }
    case 'SET_SYMBOL_2_WILD_CARD_WIN_C':
      return { ...state, symbol2WildCardWinC: action.payload }
    case 'SET_SYMBOL_3_WILD_CARD_WIN_C':
      return { ...state, symbol3WildCardWinC: action.payload }
    case 'SET_SYMBOL_4_WILD_CARD_WIN_C':
      return { ...state, symbol4WildCardWinC: action.payload }
    case 'SET_SYMBOL_5_WILD_CARD_WIN_C':
      return { ...state, symbol5WildCardWinC: action.payload }
    case 'SET_SYMBOL_6_WILD_CARD_WIN_C':
      return { ...state, symbol6WildCardWinC: action.payload }
    case 'SET_SYMBOL_7_WILD_CARD_WIN_C':
      return { ...state, symbol7WildCardWinC: action.payload }
    case 'SET_SYMBOL_8_WILD_CARD_WIN_C':
      return { ...state, symbol8WildCardWinC: action.payload }
    case 'SET_SYMBOL_9_WILD_CARD_WIN_C':
      return { ...state, symbol9WildCardWinC: action.payload }
    case 'SET_CONDENSED_NUMBER_A1':
      return { ...state, condensedNumberA1: action.payload }
    case 'SET_CONDENSED_NUMBER_A2':
      return { ...state, condensedNumberA2: action.payload }
    case 'SET_CONDENSED_NUMBER_A3':
      return { ...state, condensedNumberA3: action.payload }
    case 'SET_CONDENSED_NUMBER_A4':
      return { ...state, condensedNumberA4: action.payload }
    case 'SET_CONDENSED_NUMBER_A5':
      return { ...state, condensedNumberA5: action.payload }
    case 'SET_CONDENSED_NUMBER_B1':
      return { ...state, condensedNumberB1: action.payload }
    case 'SET_CONDENSED_NUMBER_B2':
      return { ...state, condensedNumberB2: action.payload }
    case 'SET_CONDENSED_NUMBER_B3':
      return { ...state, condensedNumberB3: action.payload }
    case 'SET_CONDENSED_NUMBER_B4':
      return { ...state, condensedNumberB4: action.payload }
    case 'SET_CONDENSED_NUMBER_B5':
      return { ...state, condensedNumberB5: action.payload }
    case 'SET_CONDENSED_NUMBER_C1':
      return { ...state, condensedNumberC1: action.payload }
    case 'SET_CONDENSED_NUMBER_C2':
      return { ...state, condensedNumberC2: action.payload }
    case 'SET_CONDENSED_NUMBER_C3':
      return { ...state, condensedNumberC3: action.payload }
    case 'SET_CONDENSED_NUMBER_C4':
      return { ...state, condensedNumberC4: action.payload }
    case 'SET_CONDENSED_NUMBER_C5':
      return { ...state, condensedNumberC5: action.payload }
    case 'SET_CONDENSE_RANDOM_NUMBER_A_DONE':
      return { ...state, condenseRandomNumberADone: action.payload }
    case 'SET_CONDENSE_RANDOM_NUMBER_B_DONE':
      return { ...state, condenseRandomNumberBDone: action.payload }
    case 'SET_CONDENSE_RANDOM_NUMBER_C_DONE':
      return { ...state, condenseRandomNumberCDone: action.payload }
    case 'SET_PRIZE_WON_DONE_ROW_A':
      return { ...state, prizeWonDoneRowA: action.payload }
    case 'SET_PRIZE_WON_DONE_ROW_B':
      return { ...state, prizeWonDoneRowB: action.payload }
    case 'SET_PRIZE_WON_DONE_ROW_C':
      return { ...state, prizeWonDoneRowC: action.payload }
    case 'SET_NO_PRIZE_DONE_ROW_A':
      return { ...state, noPrizeDoneRowA: action.payload }
    case 'SET_NO_PRIZE_DONE_ROW_B':
      return { ...state, noPrizeDoneRowB: action.payload }
    case 'SET_NO_PRIZE_DONE_ROW_C':
      return { ...state, noPrizeDoneRowC: action.payload }
    case 'RESET_ALL_GAME_STATE':
      return {
        ...state,
        randomNumberA1: 0,
        randomNumberA2: 0,
        randomNumberA3: 0,
        randomNumberA4: 0,
        randomNumberA5: 0,
        randomNumberB1: 0,
        randomNumberB2: 0,
        randomNumberB3: 0,
        randomNumberB4: 0,
        randomNumberB5: 0,
        randomNumberC1: 0,
        randomNumberC2: 0,
        randomNumberC3: 0,
        randomNumberC4: 0,
        randomNumberC5: 0,
        symbolLandedA1: null,
        symbolLandedA2: null,
        symbolLandedA3: null,
        symbolLandedA4: null,
        symbolLandedA5: null,
        symbolLandedB1: null,
        symbolLandedB2: null,
        symbolLandedB3: null,
        symbolLandedB4: null,
        symbolLandedB5: null,
        symbolLandedC1: null,
        symbolLandedC2: null,
        symbolLandedC3: null,
        symbolLandedC4: null,
        symbolLandedC5: null,
        symbolLandedD1: null,
        symbolLandedD2: null,
        symbolLandedD3: null,
        symbolLandedD4: null,
        symbolLandedD5: null,
        symbolLandedE1: null,
        symbolLandedE2: null,
        symbolLandedE3: null,
        symbolLandedE4: null,
        symbolLandedE5: null,
        allRandomNumbersDone: false,
        reelSpinning00: false,
        reelSpinning0: false,
        reelSpinning1: false,
        reelSpinning2: false,
        reelSpinning4: false,
        reelSpinning5: false,
        rowA: [],
        rowB: [],
        rowC: [],
        rowAResult: [],
        rowBResult: [],
        rowCResult: [],
        rowAWinArray: [],
        rowBWinArray: [],
        rowCWinArray: [],
        rowAPrizeArray: [],
        rowBPrizeArray: [],
        rowCPrizeArray: [],
        winningSymbolRowA: [],
        winningSymbolRowB: [],
        winningSymbolRowC: [],
        rowAPrize: 0,
        rowBPrize: 0,
        rowCPrize: 0,
        prize: 0,
        wildCardQuantityRowA: 0,
        wildCardQuantityRowB: 0,
        wildCardQuantityRowC: 0,
        scatterQuantityRowA: 0,
        scatterQuantityRowB: 0,
        scatterQuantityRowC: 0,
        symbolWinClassRowA1: false,
        symbolWinClassRowA2: false,
        symbolWinClassRowA3: false,
        symbolWinClassRowA4: false,
        symbolWinClassRowA5: false,
        symbolWinClassRowB1: false,
        symbolWinClassRowB2: false,
        symbolWinClassRowB3: false,
        symbolWinClassRowB4: false,
        symbolWinClassRowB5: false,
        symbolWinClassRowC1: false,
        symbolWinClassRowC2: false,
        symbolWinClassRowC3: false,
        symbolWinClassRowC4: false,
        symbolWinClassRowC5: false,
        symbolWinClassRowD1: false,
        symbolWinClassRowD2: false,
        symbolWinClassRowD3: false,
        symbolWinClassRowD4: false,
        symbolWinClassRowD5: false,
        symbolWinClassRowE1: false,
        symbolWinClassRowE2: false,
        symbolWinClassRowE3: false,
        symbolWinClassRowE4: false,
        symbolWinClassRowE5: false,
        symbol1WildCardWinB: false,
        symbol2WildCardWinB: false,
        symbol3WildCardWinB: false,
        symbol4WildCardWinB: false,
        symbol5WildCardWinB: false,
        symbol6WildCardWinB: false,
        symbol7WildCardWinB: false,
        symbol8WildCardWinB: false,
        symbol9WildCardWinB: false,
        condensedNumberA1: 0,
        condensedNumberA2: 0,
        condensedNumberA3: 0,
        condensedNumberA4: 0,
        condensedNumberA5: 0,
        condensedNumberB1: 0,
        condensedNumberB2: 0,
        condensedNumberB3: 0,
        condensedNumberB4: 0,
        condensedNumberB5: 0,
        condensedNumberC1: 0,
        condensedNumberC2: 0,
        condensedNumberC3: 0,
        condensedNumberC4: 0,
        condensedNumberC5: 0,
        condenseRandomNumberADone: false,
        condenseRandomNumberBDone: false,
        condenseRandomNumberCDone: false,
      }
    case 'SET_BANNER_GIF_CONTENT':
      return { ...state, bannerGifContent: action.payload }
    case 'SET_USER_SET_CREDIT_COUNT':
      return { ...state, userSetCreditCount: action.payload }
    case 'SET_TEST_SWITCH':
      return { ...state, testSwitch: action.payload }
    default:
      return state
  }
}

// Actions
const recordGamePlay = (dispatch) => async (data) => {
  try {
    await ngrokApi.post('/play/game-play', data)
    return
  } catch (error) {
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const fetchAllGamePlayData = (dispatch) => async () => {
  try {
    const response = await ngrokApi.get('/play/game-play-data')
    dispatch({ type: 'FETCH_ALL_GAME_PLAY_DATA', payload: response.data })
    return
  } catch (error) {
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const fetchLatestGamePlayData = (dispatch) => async () => {
  try {
    const response = await ngrokApi.get('/play/game-play-data-latest')
    dispatch({ type: 'FETCH_LATEST_GAME_PLAY_DATA', payload: response.data })
    return
  } catch (error) {
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const checkUserLock = (dispatch) => async (id) => {
  try {
    await ngrokApi.post('/uni-coms/check-user-lock', id)
  } catch (error) {
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const fetchJackpotData = (dispatch) => async (siteNumber) => {
  try {
    const response = await ngrokApi.post('/play/fetch-jackpot-data', siteNumber)
    dispatch({ type: 'FETCH_JACKPOT_DATA', payload: response.data })
  } catch (error) {
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const updateJackpot = (dispatch) => async (data) => {
  try {
    const response = await ngrokApi.post('/play/update-jackpot', data)
    dispatch({ type: 'UPDATE_JACKPOT', payload: response.data })
  } catch (error) {
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const clearJackpot = (dispatch) => async (data) => {
  try {
    const response = await ngrokApi.post('/play/clear-jackpot', data)
    dispatch({ type: 'JACKPOT_CLEARED', payload: response.data.jackpotCleared })
  } catch (error) {
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

const setJackpotCleared = (dispatch) => (value) => {
  dispatch({ type: 'SET_JACKPOT_CLEARED', payload: value })
}

const setProgresiveJackpot1Balance = (dispatch) => (value) => {
  dispatch({ type: 'SET_PROGRESIVE_JACKPOT_1_BALANCE', payload: value })
}

const setProgresiveJackpot1BalanceCounter = (dispatch) => (value) => {
  dispatch({ type: 'SET_PROGRESIVE_JACKPOT_1_BALANCE_COUNT', payload: value })
}
const setProgresiveJackpot1ReleaseBalance = (dispatch) => (value) => {
  dispatch({ type: 'SET_PROGRESIVE_JACKPOT_1_RELEASE_BALANCE', payload: value })
}

const setSpinCount = (dispatch) => (value) => {
  dispatch({ type: 'SET_SPIN_COUNT', payload: value })
}

const setNewGameOpenBalance = (dispatch) => (value) => {
  dispatch({ type: 'SET_NEW_GAME_OPEN_BALANCE', payload: value })
}

const setNameOfGame = (dispatch) => (value) => {
  dispatch({ type: 'SET_NAME_OF_GAME', payload: value })
}

const setGameCredits = (dispatch) => (value) => {
  dispatch({ type: 'SET_CREDITS', payload: value })
}

const setBetValue = (dispatch) => (value) => {
  dispatch({ type: 'SET_BET_VALUE', payload: value })
}

const setBetTotal = (dispatch) => (value) => {
  dispatch({ type: 'SET_BET_TOTAL', payload: value })
}

const setNumberOfLines = (dispatch) => (value) => {
  dispatch({ type: 'SET_NUMBER_OF_LINES', payload: value })
}

const setRandomNumberA1 = (dispatch) => (num) => {
  dispatch({ type: 'SET_RANDOM_NUMBER_A1', payload: num })
}
const setRandomNumberA2 = (dispatch) => (num) => {
  dispatch({ type: 'SET_RANDOM_NUMBER_A2', payload: num })
}
const setRandomNumberA3 = (dispatch) => (num) => {
  dispatch({ type: 'SET_RANDOM_NUMBER_A3', payload: num })
}
const setRandomNumberA4 = (dispatch) => (num) => {
  dispatch({ type: 'SET_RANDOM_NUMBER_A4', payload: num })
}
const setRandomNumberA5 = (dispatch) => (num) => {
  dispatch({ type: 'SET_RANDOM_NUMBER_A5', payload: num })
}
const setRandomNumberB1 = (dispatch) => (num) => {
  dispatch({ type: 'SET_RANDOM_NUMBER_B1', payload: num })
}
const setRandomNumberB2 = (dispatch) => (num) => {
  dispatch({ type: 'SET_RANDOM_NUMBER_B2', payload: num })
}
const setRandomNumberB3 = (dispatch) => (num) => {
  dispatch({ type: 'SET_RANDOM_NUMBER_B3', payload: num })
}
const setRandomNumberB4 = (dispatch) => (num) => {
  dispatch({ type: 'SET_RANDOM_NUMBER_B4', payload: num })
}
const setRandomNumberB5 = (dispatch) => (num) => {
  dispatch({ type: 'SET_RANDOM_NUMBER_B5', payload: num })
}
const setRandomNumberC1 = (dispatch) => (num) => {
  dispatch({ type: 'SET_RANDOM_NUMBER_C1', payload: num })
}
const setRandomNumberC2 = (dispatch) => (num) => {
  dispatch({ type: 'SET_RANDOM_NUMBER_C2', payload: num })
}
const setRandomNumberC3 = (dispatch) => (num) => {
  dispatch({ type: 'SET_RANDOM_NUMBER_C3', payload: num })
}
const setRandomNumberC4 = (dispatch) => (num) => {
  dispatch({ type: 'SET_RANDOM_NUMBER_C4', payload: num })
}
const setRandomNumberC5 = (dispatch) => (num) => {
  dispatch({ type: 'SET_RANDOM_NUMBER_C5', payload: num })
}

const setSymbolLandedA1 = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_LANDED_A1', payload: value })
}
const setSymbolLandedA2 = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_LANDED_A2', payload: value })
}
const setSymbolLandedA3 = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_LANDED_A3', payload: value })
}
const setSymbolLandedA4 = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_LANDED_A4', payload: value })
}
const setSymbolLandedA5 = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_LANDED_A5', payload: value })
}

const setSymbolLandedB1 = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_LANDED_B1', payload: value })
}
const setSymbolLandedB2 = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_LANDED_B2', payload: value })
}
const setSymbolLandedB3 = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_LANDED_B3', payload: value })
}
const setSymbolLandedB4 = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_LANDED_B4', payload: value })
}
const setSymbolLandedB5 = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_LANDED_B5', payload: value })
}

const setSymbolLandedC1 = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_LANDED_C1', payload: value })
}
const setSymbolLandedC2 = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_LANDED_C2', payload: value })
}
const setSymbolLandedC3 = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_LANDED_C3', payload: value })
}
const setSymbolLandedC4 = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_LANDED_C4', payload: value })
}
const setSymbolLandedC5 = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_LANDED_C5', payload: value })
}

const setSymbolLandedD1 = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_LANDED_D1', payload: value })
}
const setSymbolLandedD2 = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_LANDED_D2', payload: value })
}
const setSymbolLandedD3 = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_LANDED_D3', payload: value })
}
const setSymbolLandedD4 = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_LANDED_D4', payload: value })
}
const setSymbolLandedD5 = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_LANDED_D5', payload: value })
}

const setSymbolLandedE1 = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_LANDED_E1', payload: value })
}
const setSymbolLandedE2 = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_LANDED_E2', payload: value })
}
const setSymbolLandedE3 = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_LANDED_E3', payload: value })
}
const setSymbolLandedE4 = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_LANDED_E4', payload: value })
}
const setSymbolLandedE5 = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_LANDED_E5', payload: value })
}

const setAllRandomNumbersDone = (dispatch) => (value) => {
  dispatch({ type: 'SET_ALL_RANDOM_NUMBERS_DONE', payload: value })
}

const setLinesOverlayShow = (dispatch) => (value) => {
  dispatch({ type: 'SET_LINES_OVERLAY_SHOW', payload: value })
}

const setJackpotOverlayShow = (dispatch) => (value) => {
  dispatch({ type: 'SET_JACKPOT_OVERLAY_SHOW', payload: value })
}

const setNetworkErrorOverlayShow = (dispatch) => (value) => {
  dispatch({ type: 'SET_NETWORK_ERROR_OVERLAY_SHOW', payload: value })
}

const setReelSpinning1 = (dispatch) => (value) => {
  dispatch({ type: 'SET_REEL_SPINNING_1', payload: value })
}
const setReelSpinning2 = (dispatch) => (value) => {
  dispatch({ type: 'SET_REEL_SPINNING_2', payload: value })
}
const setReelSpinning4 = (dispatch) => (value) => {
  dispatch({ type: 'SET_REEL_SPINNING_4', payload: value })
}
const setReelSpinning5 = (dispatch) => (value) => {
  dispatch({ type: 'SET_REEL_SPINNING_5', payload: value })
}

const setRowA = (dispatch) => (value) => {
  dispatch({ type: 'SET_ROW_A', payload: value })
}
const setRowB = (dispatch) => (value) => {
  dispatch({ type: 'SET_ROW_B', payload: value })
}
const setRowC = (dispatch) => (value) => {
  dispatch({ type: 'SET_ROW_C', payload: value })
}

const setRowAResult = (dispatch) => (value) => {
  dispatch({ type: 'SET_ROW_A_RESULT', payload: value })
}
const setRowBResult = (dispatch) => (value) => {
  dispatch({ type: 'SET_ROW_B_RESULT', payload: value })
}
const setRowCResult = (dispatch) => (value) => {
  dispatch({ type: 'SET_ROW_C_RESULT', payload: value })
}

const setRowAWinArray = (dispatch) => (data) => {
  dispatch({ type: 'SET_ROW_A_WIN_ARRAY', payload: data })
}
const setRowBWinArray = (dispatch) => (data) => {
  dispatch({ type: 'SET_ROW_B_WIN_ARRAY', payload: data })
}
const setRowCWinArray = (dispatch) => (data) => {
  dispatch({ type: 'SET_ROW_C_WIN_ARRAY', payload: data })
}

const setRowAPrizeArray = (dispatch) => (data) => {
  dispatch({ type: 'SET_ROW_A_PRIZE_ARRAY', payload: data })
}
const setRowBPrizeArray = (dispatch) => (data) => {
  dispatch({ type: 'SET_ROW_B_PRIZE_ARRAY', payload: data })
}
const setRowCPrizeArray = (dispatch) => (data) => {
  dispatch({ type: 'SET_ROW_C_PRIZE_ARRAY', payload: data })
}

const setWinningSymbolRowA = (dispatch) => (data) => {
  dispatch({ type: 'SET_WINNING_SYMBOL_ROW_A', payload: data })
}
const setWinningSymbolRowB = (dispatch) => (data) => {
  dispatch({ type: 'SET_WINNING_SYMBOL_ROW_B', payload: data })
}
const setWinningSymbolRowC = (dispatch) => (data) => {
  dispatch({ type: 'SET_WINNING_SYMBOL_ROW_C', payload: data })
}

const setRowAPrize = (dispatch) => (data) => {
  dispatch({ type: 'SET_ROW_A_PRIZE', payload: data })
}
const setRowBPrize = (dispatch) => (data) => {
  dispatch({ type: 'SET_ROW_B_PRIZE', payload: data })
}
const setRowCPrize = (dispatch) => (data) => {
  dispatch({ type: 'SET_ROW_C_PRIZE', payload: data })
}

const setPrize = (dispatch) => (data) => {
  dispatch({ type: 'SET_PRIZE', payload: data })
}

const setWildCardQuantityRowA = (dispatch) => (data) => {
  dispatch({ type: 'SET_WILD_CARD_QUANTITY_ROW_A', payload: data })
}
const setWildCardQuantityRowB = (dispatch) => (data) => {
  dispatch({ type: 'SET_WILD_CARD_QUANTITY_ROW_B', payload: data })
}
const setWildCardQuantityRowC = (dispatch) => (data) => {
  dispatch({ type: 'SET_WILD_CARD_QUANTITY_ROW_C', payload: data })
}

const setScatterQuantityRowA = (dispatch) => (data) => {
  dispatch({ type: 'SET_SCATTER_QUANTITY_ROW_A', payload: data })
}
const setScatterQuantityRowB = (dispatch) => (data) => {
  dispatch({ type: 'SET_SCATTER_QUANTITY_ROW_B', payload: data })
}
const setScatterQuantityRowC = (dispatch) => (data) => {
  dispatch({ type: 'SET_SCATTER_QUANTITY_ROW_C', payload: data })
}

const setScatterWinOverlayShow = (dispatch) => (data) => {
  dispatch({ type: 'SET_SCATTER_WIN_OVERLAY_SHOW', payload: data })
}

const setScatterAndWildCardCheckDoneRowA = (dispatch) => (data) => {
  dispatch({
    type: 'SET_SCATTER_AND_WILD_CARD_CHECK_DONE_ROW_A',
    payload: data,
  })
}
const setScatterAndWildCardCheckDoneRowB = (dispatch) => (data) => {
  dispatch({
    type: 'SET_SCATTER_AND_WILD_CARD_CHECK_DONE_ROW_B',
    payload: data,
  })
}
const setScatterAndWildCardCheckDoneRowC = (dispatch) => (data) => {
  dispatch({
    type: 'SET_SCATTER_AND_WILD_CARD_CHECK_DONE_ROW_C',
    payload: data,
  })
}

const setRowAFreeSpins = (dispatch) => (data) => {
  dispatch({ type: 'SET_ROW_A_FREE_SPINS', payload: data })
}
const setRowBFreeSpins = (dispatch) => (data) => {
  dispatch({ type: 'SET_ROW_B_FREE_SPINS', payload: data })
}
const setRowCFreeSpins = (dispatch) => (data) => {
  dispatch({ type: 'SET_ROW_C_FREE_SPINS', payload: data })
}
const setFreeSpinsTotal = (dispatch) => (data) => {
  dispatch({ type: 'SET_FREE_SPINS_TOTAL', payload: data })
}
const setFreeSpinsPlayOn = (dispatch) => (data) => {
  dispatch({ type: 'SET_FREE_SPIN_PLAY_ON', payload: data })
}

const setSymbolWinClassRowA1 = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_WIN_CLASS_ROW_A1', payload: value })
}
const setSymbolWinClassRowA2 = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_WIN_CLASS_ROW_A2', payload: value })
}
const setSymbolWinClassRowA3 = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_WIN_CLASS_ROW_A3', payload: value })
}
const setSymbolWinClassRowA4 = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_WIN_CLASS_ROW_A4', payload: value })
}
const setSymbolWinClassRowA5 = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_WIN_CLASS_ROW_A5', payload: value })
}

const setSymbolWinClassRowB1 = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_WIN_CLASS_ROW_B1', payload: value })
}
const setSymbolWinClassRowB2 = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_WIN_CLASS_ROW_B2', payload: value })
}
const setSymbolWinClassRowB3 = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_WIN_CLASS_ROW_B3', payload: value })
}
const setSymbolWinClassRowB4 = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_WIN_CLASS_ROW_B4', payload: value })
}
const setSymbolWinClassRowB5 = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_WIN_CLASS_ROW_B5', payload: value })
}

const setSymbolWinClassRowC1 = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_WIN_CLASS_ROW_C1', payload: value })
}
const setSymbolWinClassRowC2 = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_WIN_CLASS_ROW_C2', payload: value })
}
const setSymbolWinClassRowC3 = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_WIN_CLASS_ROW_C3', payload: value })
}
const setSymbolWinClassRowC4 = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_WIN_CLASS_ROW_C4', payload: value })
}
const setSymbolWinClassRowC5 = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_WIN_CLASS_ROW_C5', payload: value })
}

const setSymbolWinClassRowD1 = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_WIN_CLASS_ROW_D1', payload: value })
}
const setSymbolWinClassRowD2 = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_WIN_CLASS_ROW_D2', payload: value })
}
const setSymbolWinClassRowD3 = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_WIN_CLASS_ROW_D3', payload: value })
}
const setSymbolWinClassRowD4 = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_WIN_CLASS_ROW_D4', payload: value })
}
const setSymbolWinClassRowD5 = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_WIN_CLASS_ROW_D5', payload: value })
}

const setSymbolWinClassRowE1 = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_WIN_CLASS_ROW_E1', payload: value })
}
const setSymbolWinClassRowE2 = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_WIN_CLASS_ROW_E2', payload: value })
}
const setSymbolWinClassRowE3 = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_WIN_CLASS_ROW_E3', payload: value })
}
const setSymbolWinClassRowE4 = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_WIN_CLASS_ROW_E4', payload: value })
}
const setSymbolWinClassRowE5 = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_WIN_CLASS_ROW_E5', payload: value })
}

const setSymbol1WildCardWinA = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_1_WILD_CARD_WIN_A', payload: value })
}
const setSymbol2WildCardWinA = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_2_WILD_CARD_WIN_A', payload: value })
}
const setSymbol3WildCardWinA = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_3_WILD_CARD_WIN_A', payload: value })
}
const setSymbol4WildCardWinA = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_4_WILD_CARD_WIN_A', payload: value })
}
const setSymbol5WildCardWinA = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_5_WILD_CARD_WIN_A', payload: value })
}
const setSymbol6WildCardWinA = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_6_WILD_CARD_WIN_A', payload: value })
}
const setSymbol7WildCardWinA = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_7_WILD_CARD_WIN_A', payload: value })
}
const setSymbol8WildCardWinA = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_8_WILD_CARD_WIN_A', payload: value })
}
const setSymbol9WildCardWinA = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_9_WILD_CARD_WIN_A', payload: value })
}

const setSymbol1WildCardWinB = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_1_WILD_CARD_WIN_B', payload: value })
}
const setSymbol2WildCardWinB = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_2_WILD_CARD_WIN_B', payload: value })
}
const setSymbol3WildCardWinB = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_3_WILD_CARD_WIN_B', payload: value })
}
const setSymbol4WildCardWinB = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_4_WILD_CARD_WIN_B', payload: value })
}
const setSymbol5WildCardWinB = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_5_WILD_CARD_WIN_B', payload: value })
}
const setSymbol6WildCardWinB = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_6_WILD_CARD_WIN_B', payload: value })
}
const setSymbol7WildCardWinB = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_7_WILD_CARD_WIN_B', payload: value })
}
const setSymbol8WildCardWinB = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_8_WILD_CARD_WIN_B', payload: value })
}
const setSymbol9WildCardWinB = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_9_WILD_CARD_WIN_B', payload: value })
}

const setSymbol1WildCardWinC = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_1_WILD_CARD_WIN_C', payload: value })
}
const setSymbol2WildCardWinC = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_2_WILD_CARD_WIN_C', payload: value })
}
const setSymbol3WildCardWinC = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_3_WILD_CARD_WIN_C', payload: value })
}
const setSymbol4WildCardWinC = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_4_WILD_CARD_WIN_C', payload: value })
}
const setSymbol5WildCardWinC = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_5_WILD_CARD_WIN_C', payload: value })
}
const setSymbol6WildCardWinC = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_6_WILD_CARD_WIN_C', payload: value })
}
const setSymbol7WildCardWinC = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_7_WILD_CARD_WIN_C', payload: value })
}
const setSymbol8WildCardWinC = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_8_WILD_CARD_WIN_C', payload: value })
}
const setSymbol9WildCardWinC = (dispatch) => (value) => {
  dispatch({ type: 'SET_SYMBOL_9_WILD_CARD_WIN_C', payload: value })
}

const setCondensedNumberA1 = (dispatch) => (value) => {
  dispatch({ type: 'SET_CONDENSED_NUMBER_A1', payload: value })
}
const setCondensedNumberA2 = (dispatch) => (value) => {
  dispatch({ type: 'SET_CONDENSED_NUMBER_A2', payload: value })
}
const setCondensedNumberA3 = (dispatch) => (value) => {
  dispatch({ type: 'SET_CONDENSED_NUMBER_A3', payload: value })
}
const setCondensedNumberA4 = (dispatch) => (value) => {
  dispatch({ type: 'SET_CONDENSED_NUMBER_A4', payload: value })
}
const setCondensedNumberA5 = (dispatch) => (value) => {
  dispatch({ type: 'SET_CONDENSED_NUMBER_A5', payload: value })
}

const setCondensedNumberB1 = (dispatch) => (value) => {
  dispatch({ type: 'SET_CONDENSED_NUMBER_B1', payload: value })
}
const setCondensedNumberB2 = (dispatch) => (value) => {
  dispatch({ type: 'SET_CONDENSED_NUMBER_B2', payload: value })
}
const setCondensedNumberB3 = (dispatch) => (value) => {
  dispatch({ type: 'SET_CONDENSED_NUMBER_B3', payload: value })
}
const setCondensedNumberB4 = (dispatch) => (value) => {
  dispatch({ type: 'SET_CONDENSED_NUMBER_B4', payload: value })
}
const setCondensedNumberB5 = (dispatch) => (value) => {
  dispatch({ type: 'SET_CONDENSED_NUMBER_B5', payload: value })
}

const setCondensedNumberC1 = (dispatch) => (value) => {
  dispatch({ type: 'SET_CONDENSED_NUMBER_C1', payload: value })
}
const setCondensedNumberC2 = (dispatch) => (value) => {
  dispatch({ type: 'SET_CONDENSED_NUMBER_C2', payload: value })
}
const setCondensedNumberC3 = (dispatch) => (value) => {
  dispatch({ type: 'SET_CONDENSED_NUMBER_C3', payload: value })
}
const setCondensedNumberC4 = (dispatch) => (value) => {
  dispatch({ type: 'SET_CONDENSED_NUMBER_C4', payload: value })
}
const setCondensedNumberC5 = (dispatch) => (value) => {
  dispatch({ type: 'SET_CONDENSED_NUMBER_C5', payload: value })
}

const setCondenseRandomNumberADone = (dispatch) => (value) => {
  dispatch({ type: 'SET_CONDENSE_RANDOM_NUMBER_A_DONE', payload: value })
}
const setCondenseRandomNumberBDone = (dispatch) => (value) => {
  dispatch({ type: 'SET_CONDENSE_RANDOM_NUMBER_B_DONE', payload: value })
}
const setCondenseRandomNumberCDone = (dispatch) => (value) => {
  dispatch({ type: 'SET_CONDENSE_RANDOM_NUMBER_C_DONE', payload: value })
}

const setPrizeWonDoneRowA = (dispatch) => (value) => {
  dispatch({ type: 'SET_PRIZE_WON_DONE_ROW_A', payload: value })
}
const setPrizeWonDoneRowB = (dispatch) => (value) => {
  dispatch({ type: 'SET_PRIZE_WON_DONE_ROW_B', payload: value })
}
const setPrizeWonDoneRowC = (dispatch) => (value) => {
  dispatch({ type: 'SET_PRIZE_WON_DONE_ROW_C', payload: value })
}

const setNoPrizeDoneRowA = (dispatch) => (value) => {
  dispatch({ type: 'SET_NO_PRIZE_DONE_ROW_A', payload: value })
}
const setNoPrizeDoneRowB = (dispatch) => (value) => {
  dispatch({ type: 'SET_NO_PRIZE_DONE_ROW_B', payload: value })
}
const setNoPrizeDoneRowC = (dispatch) => (value) => {
  dispatch({ type: 'SET_NO_PRIZE_DONE_ROW_C', payload: value })
}

const resetAllGameState = (dispatch) => () => {
  dispatch({ type: 'RESET_ALL_GAME_STATE', payload: null })
}

const setBannerGifContent = (dispatch) => (value) => {
  dispatch({ type: 'SET_BANNER_GIF_CONTENT', payload: value })
}

const setUserSetCreditCount = (dispatch) => (value) => {
  dispatch({ type: 'SET_USER_SET_CREDIT_COUNT', payload: value })
}

const setTestSwitch = (dispatch) => (value) => {
  dispatch({ type: 'SET_TEST_SWITCH', payload: value })
}

const updateUserFreeSpins = (dispatch) => async (value) => {
  try {
    await ngrokApi.post('/play/update-free-spins', value)
  } catch (error) {
    dispatch({
      type: 'ADD_ERROR',
      payload: error,
    })
  }
}

export const { Context, Provider } = createDataContext(
  GameContext,
  {
    setNameOfGame,
    setGameCredits,
    setProgresiveJackpot1Balance,
    setProgresiveJackpot1BalanceCounter,
    setProgresiveJackpot1ReleaseBalance,
    setSpinCount,
    setNewGameOpenBalance,
    setBetValue,
    setBetTotal,
    setNumberOfLines,
    setRandomNumberA1,
    setRandomNumberA2,
    setRandomNumberA3,
    setRandomNumberA4,
    setRandomNumberA5,
    setRandomNumberB1,
    setRandomNumberB2,
    setRandomNumberB3,
    setRandomNumberB4,
    setRandomNumberB5,
    setRandomNumberC1,
    setRandomNumberC2,
    setRandomNumberC3,
    setRandomNumberC4,
    setRandomNumberC5,
    setSymbolLandedA1,
    setSymbolLandedA2,
    setSymbolLandedA3,
    setSymbolLandedA4,
    setSymbolLandedA5,
    setSymbolLandedB1,
    setSymbolLandedB2,
    setSymbolLandedB3,
    setSymbolLandedB4,
    setSymbolLandedB5,
    setSymbolLandedC1,
    setSymbolLandedC2,
    setSymbolLandedC3,
    setSymbolLandedC4,
    setSymbolLandedC5,
    setSymbolLandedD1,
    setSymbolLandedD2,
    setSymbolLandedD3,
    setSymbolLandedD4,
    setSymbolLandedD5,
    setSymbolLandedE1,
    setSymbolLandedE2,
    setSymbolLandedE3,
    setSymbolLandedE4,
    setSymbolLandedE5,
    setAllRandomNumbersDone,
    setLinesOverlayShow,
    setJackpotOverlayShow,
    setNetworkErrorOverlayShow,
    setReelSpinning1,
    setReelSpinning2,
    setReelSpinning4,
    setReelSpinning5,
    setRowA,
    setRowB,
    setRowC,
    setRowAResult,
    setRowBResult,
    setRowCResult,
    setRowAWinArray,
    setRowBWinArray,
    setRowCWinArray,
    setRowAPrizeArray,
    setRowBPrizeArray,
    setRowCPrizeArray,
    setWinningSymbolRowA,
    setWinningSymbolRowB,
    setWinningSymbolRowC,
    setPrize,
    setWildCardQuantityRowA,
    setWildCardQuantityRowB,
    setWildCardQuantityRowC,
    setScatterQuantityRowA,
    setScatterQuantityRowB,
    setScatterQuantityRowC,
    setScatterAndWildCardCheckDoneRowA,
    setScatterAndWildCardCheckDoneRowB,
    setScatterAndWildCardCheckDoneRowC,
    setScatterWinOverlayShow,
    setRowAFreeSpins,
    setRowBFreeSpins,
    setRowCFreeSpins,
    setFreeSpinsTotal,
    setFreeSpinsPlayOn,
    setRowAPrize,
    setRowBPrize,
    setRowCPrize,
    recordGamePlay,
    fetchAllGamePlayData,
    fetchLatestGamePlayData,
    checkUserLock,
    fetchJackpotData,
    updateJackpot,
    clearJackpot,
    setJackpotCleared,
    setSymbolWinClassRowA1,
    setSymbolWinClassRowA2,
    setSymbolWinClassRowA3,
    setSymbolWinClassRowA4,
    setSymbolWinClassRowA5,
    setSymbolWinClassRowB1,
    setSymbolWinClassRowB2,
    setSymbolWinClassRowB3,
    setSymbolWinClassRowB4,
    setSymbolWinClassRowB5,
    setSymbolWinClassRowC1,
    setSymbolWinClassRowC2,
    setSymbolWinClassRowC3,
    setSymbolWinClassRowC4,
    setSymbolWinClassRowC5,
    setSymbolWinClassRowD1,
    setSymbolWinClassRowD2,
    setSymbolWinClassRowD3,
    setSymbolWinClassRowD4,
    setSymbolWinClassRowD5,
    setSymbolWinClassRowE1,
    setSymbolWinClassRowE2,
    setSymbolWinClassRowE3,
    setSymbolWinClassRowE4,
    setSymbolWinClassRowE5,
    setSymbol1WildCardWinA,
    setSymbol2WildCardWinA,
    setSymbol3WildCardWinA,
    setSymbol4WildCardWinA,
    setSymbol5WildCardWinA,
    setSymbol6WildCardWinA,
    setSymbol7WildCardWinA,
    setSymbol8WildCardWinA,
    setSymbol9WildCardWinA,
    setSymbol1WildCardWinB,
    setSymbol2WildCardWinB,
    setSymbol3WildCardWinB,
    setSymbol4WildCardWinB,
    setSymbol5WildCardWinB,
    setSymbol6WildCardWinB,
    setSymbol7WildCardWinB,
    setSymbol8WildCardWinB,
    setSymbol9WildCardWinB,
    setSymbol1WildCardWinC,
    setSymbol2WildCardWinC,
    setSymbol3WildCardWinC,
    setSymbol4WildCardWinC,
    setSymbol5WildCardWinC,
    setSymbol6WildCardWinC,
    setSymbol7WildCardWinC,
    setSymbol8WildCardWinC,
    setSymbol9WildCardWinC,
    setCondensedNumberA1,
    setCondensedNumberA2,
    setCondensedNumberA3,
    setCondensedNumberA4,
    setCondensedNumberA5,
    setCondensedNumberB1,
    setCondensedNumberB2,
    setCondensedNumberB3,
    setCondensedNumberB4,
    setCondensedNumberB5,
    setCondensedNumberC1,
    setCondensedNumberC2,
    setCondensedNumberC3,
    setCondensedNumberC4,
    setCondensedNumberC5,
    setCondenseRandomNumberADone,
    setCondenseRandomNumberBDone,
    setCondenseRandomNumberCDone,
    setPrizeWonDoneRowA,
    setPrizeWonDoneRowB,
    setPrizeWonDoneRowC,
    setNoPrizeDoneRowA,
    setNoPrizeDoneRowB,
    setNoPrizeDoneRowC,
    resetAllGameState,
    setBannerGifContent,
    setUserSetCreditCount,
    setTestSwitch,
    updateUserFreeSpins,
  },
  // Initial
  {
    loading: false,
    gamePlayData: [],
    latestGamePlayData: [],
    spinCount: 0,
    newGameOpenBalance: 0,
    nameOfGame: null,
    gameCredits: 0,
    betValue: 50,
    betTotal: 0,
    numberOfLines: 1,
    randomNumberA1: 0,
    randomNumberA2: 0,
    randomNumberA3: 0,
    randomNumberA4: 0,
    randomNumberA5: 0,
    randomNumberB1: 0,
    randomNumberB2: 0,
    randomNumberB3: 0,
    randomNumberB4: 0,
    randomNumberB5: 0,
    randomNumberC1: 0,
    randomNumberC2: 0,
    randomNumberC3: 0,
    randomNumberC4: 0,
    randomNumberC5: 0,
    symbolLandedA1: null,
    symbolLandedA2: null,
    symbolLandedA3: null,
    symbolLandedA4: null,
    symbolLandedA5: null,
    symbolLandedB1: null,
    symbolLandedB2: null,
    symbolLandedB3: null,
    symbolLandedB4: null,
    symbolLandedB5: null,
    symbolLandedC1: null,
    symbolLandedC2: null,
    symbolLandedC3: null,
    symbolLandedC4: null,
    symbolLandedC5: null,
    symbolLandedD1: null,
    symbolLandedD2: null,
    symbolLandedD3: null,
    symbolLandedD4: null,
    symbolLandedD5: null,
    symbolLandedE1: null,
    symbolLandedE2: null,
    symbolLandedE3: null,
    symbolLandedE4: null,
    symbolLandedE5: null,
    allRandomNumbersDone: false,
    linesOverlayShow: false,
    jackpotOverlayShow: false,
    networkErrorOverlayShow: false,
    reelSpinning1: false,
    reelSpinning2: false,
    reelSpinning4: false,
    reelSpinning5: false,
    rowA: [],
    rowB: [],
    rowC: [],
    rowAResult: [],
    rowBResult: [],
    rowCResult: [],
    rowAWinArray: [],
    rowBWinArray: [],
    rowCWinArray: [],
    rowAPrizeArray: [],
    rowBPrizeArray: [],
    rowCPrizeArray: [],
    winningSymbolRowA: [],
    winningSymbolRowB: [],
    winningSymbolRowC: [],
    rowAPrize: 0,
    rowBPrize: 0,
    rowCPrize: 0,
    prize: 0,
    wildCardQuantityRowA: 0,
    wildCardQuantityRowB: 0,
    wildCardQuantityRowC: 0,
    scatterQuantityRowA: 0,
    scatterQuantityRowB: 0,
    scatterQuantityRowC: 0,
    scatterWinOverlayShow: false,
    scatterAndWildCardCheckDoneRowA: false,
    rowAFreeSpins: 0,
    rowBFreeSpins: 0,
    rowCFreeSpins: 0,
    freeSpinsTotal: 0,
    freeSpinPlayOn: false,
    symbolWinClassRowA1: false,
    symbolWinClassRowA2: false,
    symbolWinClassRowA3: false,
    symbolWinClassRowA4: false,
    symbolWinClassRowA5: false,
    symbolWinClassRowB1: false,
    symbolWinClassRowB2: false,
    symbolWinClassRowB3: false,
    symbolWinClassRowB4: false,
    symbolWinClassRowB5: false,
    symbolWinClassRowC1: false,
    symbolWinClassRowC2: false,
    symbolWinClassRowC3: false,
    symbolWinClassRowC4: false,
    symbolWinClassRowC5: false,
    symbolWinClassRowD1: false,
    symbolWinClassRowD2: false,
    symbolWinClassRowD3: false,
    symbolWinClassRowD4: false,
    symbolWinClassRowD5: false,
    symbolWinClassRowE1: false,
    symbolWinClassRowE2: false,
    symbolWinClassRowE3: false,
    symbolWinClassRowE4: false,
    symbolWinClassRowE5: false,
    symbol1WildCardWinB: false,
    symbol2WildCardWinB: false,
    symbol3WildCardWinB: false,
    symbol4WildCardWinB: false,
    symbol5WildCardWinB: false,
    symbol6WildCardWinB: false,
    symbol7WildCardWinB: false,
    symbol8WildCardWinB: false,
    symbol9WildCardWinB: false,
    condensedNumberA1: 0,
    condensedNumberA2: 0,
    condensedNumberA3: 0,
    condensedNumberA4: 0,
    condensedNumberA5: 0,
    condensedNumberB1: 0,
    condensedNumberB2: 0,
    condensedNumberB3: 0,
    condensedNumberB4: 0,
    condensedNumberB5: 0,
    condensedNumberC1: 0,
    condensedNumberC2: 0,
    condensedNumberC3: 0,
    condensedNumberC4: 0,
    condensedNumberC5: 0,
    condenseRandomNumberADone: false,
    condenseRandomNumberBDone: false,
    condenseRandomNumberCDone: false,
    jackpotData: null,
    jackpotCleared: false,
    progresiveJackpot: 0,
    progresiveJackpot1Balance: 0,
    progresiveJackpot1BalanceCounter: 0,
    progresiveJackpot1ReleaseBalance: false,
    prizeWonDoneRowA: false,
    prizeWonDoneRowB: false,
    prizeWonDoneRowC: false,
    noPrizeDoneRowA: false,
    noPrizeDoneRowB: false,
    noPrizeDoneRowC: false,
    bannerGifContent: '',
    userSetCreditCount: 0,
    testSwitch: false,
  }
)
