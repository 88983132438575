import { useContext, useEffect, useState } from 'react'
import uuid from 'uuid/v4'

import { Context as GameContext } from '../../../../context/GameContext'
import { Context as PayoutContext } from '../../../../context/PayoutContext'

const CalculateRowCWin = () => {
  const [checkForWildCardDone, setCheckForWildCardDone] = useState(false)
  const [winArray, setWinArray] = useState([])
  const [winSymbolArray, setWinSymbolArray] = useState([])
  const [prizeArray, setPrizeArray] = useState([])
  const [appendWildCardDone, setAppendWildCardDone] = useState(false)
  const [assignPrizeDone, setAssignPrizeDone] = useState(false)
  const [prizeArrayDone, setPrizeArrayDone] = useState(false)

  const {
    state: {
      betValue,
      numberOfLines,
      rowCResult,
      reelSpinning5,
      wildCardQuantityRowC,
      scatterQuantityRowC,
      rowCFreeSpins,
      symbol1WildCardWinC,
      symbol2WildCardWinC,
      symbol3WildCardWinC,
      symbol4WildCardWinC,
      symbol5WildCardWinC,
      symbol6WildCardWinC,
      symbol7WildCardWinC,
      symbol8WildCardWinC,
      symbol9WildCardWinC,
    },
    setRowCWinArray,
    setRowCPrizeArray,
    setWildCardQuantityRowC,
    setScatterQuantityRowC,
    setScatterWinOverlayShow,
    setRowCFreeSpins,
    setWinningSymbolRowC,
    setScatterAndWildCardCheckDoneRowC,
    setSymbol1WildCardWinC,
    setSymbol2WildCardWinC,
    setSymbol3WildCardWinC,
    setSymbol4WildCardWinC,
    setSymbol5WildCardWinC,
    setSymbol6WildCardWinC,
    setSymbol7WildCardWinC,
    setSymbol8WildCardWinC,
    setSymbol9WildCardWinC,
    setPrizeWonDoneRowC,
    setNoPrizeDoneRowC,
  } = useContext(GameContext)

  const {
    state: { payoutOverLimitStartReplay },
  } = useContext(PayoutContext)

  useEffect(() => {
    if (rowCResult.length > 0) {
      checkForWildCardAndScatter()
    }
  }, [rowCResult])

  useEffect(() => {
    if (
      numberOfLines > 2 &&
      wildCardQuantityRowC > 0 &&
      scatterQuantityRowC > 0
    ) {
      setScatterAndWildCardCheckDoneRowC(true)
      setTimeout(() => {
        setScatterWinOverlayShow(true)
        setRowCFreeSpins(rowCFreeSpins + 10)
      }, 4800)
    }
  }, [numberOfLines, wildCardQuantityRowC, scatterQuantityRowC])

  useEffect(() => {
    if (wildCardQuantityRowC > 0 && checkForWildCardDone) {
      appendWildCard()
    }
    if (checkForWildCardDone && wildCardQuantityRowC === 0) {
      assignPrize()
    }
  }, [wildCardQuantityRowC, checkForWildCardDone])

  useEffect(() => {
    if (appendWildCardDone || assignPrizeDone) {
      if (winArray.length < 1 || numberOfLines < 3) {
        setNoPrizeDoneRowC(true)
      }
      if (winArray.length > 0) {
        winArray.map((win, index) => {
          if (index === winArray.length - 1) {
            setPrizeArrayDone(true)
            setPrizeWonDoneRowC(true)
          }
          let prize = win.betMutiple * betValue
          setPrizeArray((prizeArray) => [...prizeArray, { prizeValue: prize }])
        })
      }
    }
  }, [appendWildCardDone, assignPrizeDone, winArray, numberOfLines])

  useEffect(() => {
    if (numberOfLines > 2 && (appendWildCardDone || assignPrizeDone)) {
      setWinningSymbolRowC(winSymbolArray)
    }
  }, [numberOfLines, appendWildCardDone, assignPrizeDone])

  useEffect(() => {
    if (prizeArrayDone) {
      setRowCPrizeArray(prizeArray)
      setRowCWinArray(prizeArray)
    }
  }, [prizeArrayDone])

  useEffect(() => {
    if (payoutOverLimitStartReplay || !reelSpinning5) {
      setWinArray([])
      setWinSymbolArray([])
      setPrizeArray([])
      setCheckForWildCardDone(false)
      setAppendWildCardDone(false)
      setAssignPrizeDone(false)
      setPrizeArrayDone(false)
      setPrizeWonDoneRowC(false)
      setNoPrizeDoneRowC(false)
    }
  }, [payoutOverLimitStartReplay, reelSpinning5])

  const checkForWildCardAndScatter = () => {
    rowCResult.map((result, index) => {
      if (index === rowCResult.length - 1) {
        setCheckForWildCardDone(true)
      }
      const { symbol, quantity } = result
      if (symbol === '10') {
        setWildCardQuantityRowC(quantity)
      }
      if (symbol === '11') {
        setScatterQuantityRowC(quantity)
      }
    })
  }

  const appendWildCard = () => {
    if (rowCResult.length > 0) {
      let arraySorted = rowCResult.sort((x, y) => {
        return y.symbol - x.symbol
      })
      let wildCardAssigned
      if (wildCardQuantityRowC > 0) wildCardAssigned = false
      arraySorted.map((result, index) => {
        if (index === arraySorted.length - 1) {
          setAppendWildCardDone(true)
        }
        const { symbol, quantity } = result
        // Case 🍀 total = 5
        if (symbol === '10' && quantity === 5) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5500, key: uuid() },
          ])
          setSymbol1WildCardWinC(true)
          return
        }
        // 1st
        // Case 🍓 win + 🍀 total = 5
        if (
          symbol === '9' &&
          quantity === 4 &&
          wildCardQuantityRowC === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍓', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5000, key: uuid() },
          ])
          setSymbol1WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '9' &&
          quantity === 3 &&
          wildCardQuantityRowC === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍓', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5000, key: uuid() },
          ])
          setSymbol1WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '9' &&
          quantity === 2 &&
          wildCardQuantityRowC === 3 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍓', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5000, key: uuid() },
          ])
          setSymbol1WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '9' &&
          quantity === 1 &&
          wildCardQuantityRowC === 4 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍓', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5000, key: uuid() },
          ])
          setSymbol1WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        // 2nd
        // Case 🍒 win + 🍀 total = 5
        if (
          symbol === '8' &&
          quantity === 4 &&
          wildCardQuantityRowC === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍒', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 750, key: uuid() },
          ])
          setSymbol3WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '8' &&
          quantity === 3 &&
          wildCardQuantityRowC === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍒', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 750, key: uuid() },
          ])
          setSymbol3WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '8' &&
          quantity === 2 &&
          wildCardQuantityRowC === 3 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍒', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 750, key: uuid() },
          ])
          setSymbol3WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '8' &&
          quantity === 1 &&
          wildCardQuantityRowC === 4 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍒', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 750, key: uuid() },
          ])
          setSymbol3WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        // 3rd
        // Case 🍓 win + 🍀 total = 4
        if (
          symbol === '9' &&
          quantity === 3 &&
          wildCardQuantityRowC === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍓', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 1000, key: uuid() },
          ])
          setSymbol1WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '9' &&
          quantity === 2 &&
          wildCardQuantityRowC === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍓', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 1000, key: uuid() },
          ])
          setSymbol1WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '9' &&
          quantity === 1 &&
          wildCardQuantityRowC === 3 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍓', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 1000, key: uuid() },
          ])
          setSymbol1WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        // 4th
        // Case 🍇 win + 🍀 total = 5
        if (
          symbol === '7' &&
          quantity === 4 &&
          wildCardQuantityRowC === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍇', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 750, key: uuid() },
          ])
          setSymbol2WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '7' &&
          quantity === 3 &&
          wildCardQuantityRowC === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍇', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 750, key: uuid() },
          ])
          setSymbol2WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '7' &&
          quantity === 2 &&
          wildCardQuantityRowC === 3 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍇', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 750, key: uuid() },
          ])
          setSymbol2WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '7' &&
          quantity === 1 &&
          wildCardQuantityRowC === 4 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍇', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 750, key: uuid() },
          ])
          setSymbol2WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        // 5th
        // Case 🍌 win + 🍀 total = 5
        if (
          symbol === '6' &&
          quantity === 4 &&
          wildCardQuantityRowC === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍌', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 750, key: uuid() },
          ])
          setSymbol4WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '6' &&
          quantity === 3 &&
          wildCardQuantityRowC === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍌', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 750, key: uuid() },
          ])
          setSymbol4WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '6' &&
          quantity === 2 &&
          wildCardQuantityRowC === 3 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍌', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 750, key: uuid() },
          ])
          setSymbol4WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '6' &&
          quantity === 1 &&
          wildCardQuantityRowC === 4 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍌', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 750, key: uuid() },
          ])
          setSymbol4WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        // 6th
        // Case 🍒 win + 🍀 total = 4
        if (
          symbol === '8' &&
          quantity === 3 &&
          wildCardQuantityRowC === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍒', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 400, key: uuid() },
          ])
          setSymbol3WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '8' &&
          quantity === 2 &&
          wildCardQuantityRowC === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍒', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 400, key: uuid() },
          ])
          setSymbol3WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '8' &&
          quantity === 1 &&
          wildCardQuantityRowC === 3 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍒', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 400, key: uuid() },
          ])
          setSymbol3WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        // 7th
        // Case 🍉 win + 🍀 total = 5
        if (
          symbol === '5' &&
          quantity === 4 &&
          wildCardQuantityRowC === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍉', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol5WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '5' &&
          quantity === 3 &&
          wildCardQuantityRowC === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍉', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol5WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '5' &&
          quantity === 2 &&
          wildCardQuantityRowC === 3 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍉', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol5WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '5' &&
          quantity === 1 &&
          wildCardQuantityRowC === 4 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍉', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol5WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        // 8th
        // Case 🥑 win + 🍀 total = 5
        if (
          symbol === '4' &&
          quantity === 4 &&
          wildCardQuantityRowC === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🥑', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol6WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '4' &&
          quantity === 3 &&
          wildCardQuantityRowC === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🥑', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol6WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '4' &&
          quantity === 2 &&
          wildCardQuantityRowC === 3 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🥑', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol6WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '4' &&
          quantity === 1 &&
          wildCardQuantityRowC === 4 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🥑', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol6WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        // 9th
        // Case 🍓 win + 🍀 total = 3
        if (
          symbol === '9' &&
          quantity === 2 &&
          wildCardQuantityRowC === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍓', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol1WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '9' &&
          quantity === 1 &&
          wildCardQuantityRowC === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍓', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol1WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        // 10th
        // Case 🍇 win + 🍀 total = 4
        if (
          symbol === '7' &&
          quantity === 3 &&
          wildCardQuantityRowC === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍇', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol2WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '7' &&
          quantity === 2 &&
          wildCardQuantityRowC === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍇', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol2WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '7' &&
          quantity === 1 &&
          wildCardQuantityRowC === 3 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍇', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol2WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        // 11th
        // Case 🍌 win + 🍀 total = 4
        if (
          symbol === '6' &&
          quantity === 3 &&
          wildCardQuantityRowC === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍌', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol4WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '6' &&
          quantity === 2 &&
          wildCardQuantityRowC === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍌', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol4WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '6' &&
          quantity === 1 &&
          wildCardQuantityRowC === 3 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍌', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol4WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        // 12th
        // Case 🍊 win + 🍀 total = 5
        if (
          symbol === '3' &&
          quantity === 4 &&
          wildCardQuantityRowC === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍊', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol7WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '3' &&
          quantity === 3 &&
          wildCardQuantityRowC === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍊', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol7WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '3' &&
          quantity === 2 &&
          wildCardQuantityRowC === 3 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍊', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol7WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '3' &&
          quantity === 1 &&
          wildCardQuantityRowC === 4 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍊', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol7WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        // 13th
        // Case 🍋 win + 🍀 total = 5
        if (
          symbol === '2' &&
          quantity === 4 &&
          wildCardQuantityRowC === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍋', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol8WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '2' &&
          quantity === 3 &&
          wildCardQuantityRowC === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍋', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol8WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '2' &&
          quantity === 2 &&
          wildCardQuantityRowC === 3 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍋', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol8WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '2' &&
          quantity === 1 &&
          wildCardQuantityRowC === 4 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍋', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol8WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        // 14th
        // Case 🍈 win + 🍀 total = 5
        if (
          symbol === '1' &&
          quantity === 4 &&
          wildCardQuantityRowC === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍈', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol9WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '1' &&
          quantity === 3 &&
          wildCardQuantityRowC === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍈', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol9WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '1' &&
          quantity === 2 &&
          wildCardQuantityRowC === 3 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍈', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol9WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '1' &&
          quantity === 1 &&
          wildCardQuantityRowC === 4 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍈', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          setSymbol9WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        // 15th
        // Case 🍒 win + 🍀 total = 3
        if (
          symbol === '8' &&
          quantity === 2 &&
          wildCardQuantityRowC === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍒', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 40, key: uuid() },
          ])
          setSymbol3WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '8' &&
          quantity === 1 &&
          wildCardQuantityRowC === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍒', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 40, key: uuid() },
          ])
          setSymbol3WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        // 16th
        // Case 🍉 win + 🍀 total = 4
        if (
          symbol === '5' &&
          quantity === 3 &&
          wildCardQuantityRowC === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍉', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 40, key: uuid() },
          ])
          setSymbol5WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '5' &&
          quantity === 2 &&
          wildCardQuantityRowC === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍉', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 40, key: uuid() },
          ])
          setSymbol5WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '5' &&
          quantity === 1 &&
          wildCardQuantityRowC === 3 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍉', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 40, key: uuid() },
          ])
          setSymbol5WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        // 17th
        // Case 🥑 win + 🍀 total = 4
        if (
          symbol === '4' &&
          quantity === 3 &&
          wildCardQuantityRowC === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🥑', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 40, key: uuid() },
          ])
          setSymbol6WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '4' &&
          quantity === 2 &&
          wildCardQuantityRowC === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🥑', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 40, key: uuid() },
          ])
          setSymbol6WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '4' &&
          quantity === 1 &&
          wildCardQuantityRowC === 3 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🥑', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 40, key: uuid() },
          ])
          setSymbol6WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        // 18th
        // Case 🍇 win + 🍀 total = 3
        if (
          symbol === '7' &&
          quantity === 2 &&
          wildCardQuantityRowC === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍇', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 30, key: uuid() },
          ])
          setSymbol2WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '7' &&
          quantity === 1 &&
          wildCardQuantityRowC === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍇', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 30, key: uuid() },
          ])
          setSymbol2WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        // 19th
        // Case 🍌 win + 🍀 total = 3
        if (
          symbol === '6' &&
          quantity === 2 &&
          wildCardQuantityRowC === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍌', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 30, key: uuid() },
          ])
          setSymbol4WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '6' &&
          quantity === 1 &&
          wildCardQuantityRowC === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍌', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 30, key: uuid() },
          ])
          setSymbol4WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        // 20th
        // Case 🍊 win + 🍀 total = 4
        if (
          symbol === '3' &&
          quantity === 3 &&
          wildCardQuantityRowC === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍊', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 25, key: uuid() },
          ])
          setSymbol7WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '3' &&
          quantity === 2 &&
          wildCardQuantityRowC === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍊', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 25, key: uuid() },
          ])
          setSymbol7WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '3' &&
          quantity === 1 &&
          wildCardQuantityRowC === 3 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍊', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 25, key: uuid() },
          ])
          setSymbol7WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        // 21st
        // Case 🍋 win + 🍀 total = 4
        if (
          symbol === '2' &&
          quantity === 3 &&
          wildCardQuantityRowC === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍋', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 25, key: uuid() },
          ])
          setSymbol8WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '2' &&
          quantity === 2 &&
          wildCardQuantityRowC === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍋', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 25, key: uuid() },
          ])
          setSymbol8WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '2' &&
          quantity === 1 &&
          wildCardQuantityRowC === 3 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍋', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 25, key: uuid() },
          ])
          setSymbol8WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        // 22nd
        // Case 🍈 win + 🍀 total = 4
        if (
          symbol === '1' &&
          quantity === 3 &&
          wildCardQuantityRowC === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍈', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 25, key: uuid() },
          ])
          setSymbol9WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '1' &&
          quantity === 2 &&
          wildCardQuantityRowC === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍈', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 25, key: uuid() },
          ])
          setSymbol9WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '1' &&
          quantity === 1 &&
          wildCardQuantityRowC === 3 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍈', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 25, key: uuid() },
          ])
          setSymbol9WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        // 23rd
        // Case 🍉 win + 🍀 total = 3
        if (
          symbol === '5' &&
          quantity === 2 &&
          wildCardQuantityRowC === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍉', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5, key: uuid() },
          ])
          setSymbol5WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '5' &&
          quantity === 1 &&
          wildCardQuantityRowC === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍉', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5, key: uuid() },
          ])
          setSymbol5WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        // 24th
        // Case 🥑 win + 🍀 total = 3
        if (
          symbol === '4' &&
          quantity === 2 &&
          wildCardQuantityRowC === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🥑', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5, key: uuid() },
          ])
          setSymbol6WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '4' &&
          quantity === 1 &&
          wildCardQuantityRowC === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🥑', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5, key: uuid() },
          ])
          setSymbol6WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        // 25th
        // Case 🍊 win + 🍀 total = 3
        if (
          symbol === '3' &&
          quantity === 2 &&
          wildCardQuantityRowC === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍊', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5, key: uuid() },
          ])
          setSymbol7WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '3' &&
          quantity === 1 &&
          wildCardQuantityRowC === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍊', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5, key: uuid() },
          ])
          setSymbol7WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        // 26th
        // Case 🍋 win + 🍀 total = 3
        if (
          symbol === '2' &&
          quantity === 2 &&
          wildCardQuantityRowC === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍋', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5, key: uuid() },
          ])
          setSymbol8WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '2' &&
          quantity === 1 &&
          wildCardQuantityRowC === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍋', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5, key: uuid() },
          ])
          setSymbol8WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        // 27th
        // Case 🍈 win + 🍀 total = 3
        if (
          symbol === '1' &&
          quantity === 2 &&
          wildCardQuantityRowC === 1 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍈', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5, key: uuid() },
          ])
          setSymbol9WildCardWinC(true)
          wildCardAssigned = true
          return
        }
        if (
          symbol === '1' &&
          quantity === 1 &&
          wildCardQuantityRowC === 2 &&
          !wildCardAssigned
        ) {
          setWildCardQuantityRowC(0)
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍈', key: uuid() },
            { symbol: '🍀', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5, key: uuid() },
          ])
          setSymbol9WildCardWinC(true)
          wildCardAssigned = true
          return
        }
      })
    }
  }

  const assignPrize = () => {
    let arraySorted = rowCResult.sort((x, y) => {
      return y.symbol - x.symbol
    })
    arraySorted.map((result, index) => {
      if (index === arraySorted.length - 1) {
        setAssignPrizeDone(true)
      }
      const { symbol, quantity } = result
      if (!symbol1WildCardWinC) {
        if (symbol === '9' && quantity === 3) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍓', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          return
        }
        if (symbol === '9' && quantity === 4) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍓', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 1000, key: uuid() },
          ])
          return
        }
        if (symbol === '9' && quantity === 5) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍓', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5000, key: uuid() },
          ])
          return
        }
      }
      if (!symbol2WildCardWinC) {
        if (symbol === '7' && quantity === 3) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍇', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 30, key: uuid() },
          ])
          return
        }
        if (symbol === '7' && quantity === 4) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍇', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          return
        }
        if (symbol === '7' && quantity === 5) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍇', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 750, key: uuid() },
          ])
          return
        }
      }
      if (!symbol3WildCardWinC) {
        if (symbol === '8' && quantity === 3) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍒', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 40, key: uuid() },
          ])
          return
        }
        if (symbol === '8' && quantity === 4) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍒', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 400, key: uuid() },
          ])
          return
        }
        if (symbol === '8' && quantity === 5) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍒', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 2000, key: uuid() },
          ])
          return
        }
      }
      if (!symbol4WildCardWinC) {
        if (symbol === '6' && quantity === 3) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍌', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 30, key: uuid() },
          ])
          return
        }
        if (symbol === '6' && quantity === 4) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍌', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          return
        }
        if (symbol === '6' && quantity === 5) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍌', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 750, key: uuid() },
          ])
          return
        }
      }
      if (!symbol5WildCardWinC) {
        if (symbol === '5' && quantity === 3) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍉', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5, key: uuid() },
          ])
          return
        }
        if (symbol === '5' && quantity === 4) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍉', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 40, key: uuid() },
          ])
          return
        }
        if (symbol === '5' && quantity === 5) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍉', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          return
        }
      }
      if (!symbol6WildCardWinC) {
        if (symbol === '4' && quantity === 3) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🥑', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5, key: uuid() },
          ])
          return
        }
        if (symbol === '4' && quantity === 4) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🥑', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 40, key: uuid() },
          ])
          return
        }
        if (symbol === '4' && quantity === 5) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🥑', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          return
        }
      }
      if (!symbol7WildCardWinC) {
        if (symbol === '3' && quantity === 3) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍊', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5, key: uuid() },
          ])
          return
        }
        if (symbol === '3' && quantity === 4) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍊', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 25, key: uuid() },
          ])
          return
        }
        if (symbol === '3' && quantity === 5) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍊', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          return
        }
      }
      if (!symbol8WildCardWinC) {
        if (symbol === '2' && quantity === 3) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍋', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5, key: uuid() },
          ])
          return
        }
        if (symbol === '2' && quantity === 4) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍋', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 25, key: uuid() },
          ])
          return
        }
        if (symbol === '2' && quantity === 5) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍋', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          return
        }
      }
      if (!symbol9WildCardWinC) {
        if (symbol === '1' && quantity === 3) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍈', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 5, key: uuid() },
          ])
          return
        }
        if (symbol === '1' && quantity === 4) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍈', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 25, key: uuid() },
          ])
          return
        }
        if (symbol === '1' && quantity === 5) {
          setWinSymbolArray((winSymbol) => [
            ...winSymbol,
            { symbol: '🍈', key: uuid() },
          ])
          setWinArray((winArray) => [
            ...winArray,
            { betMutiple: 100, key: uuid() },
          ])
          return
        }
      }
    })
  }

  return null
}

export default CalculateRowCWin
