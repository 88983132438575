// desctiption: not balanced, high paying symbols are most frequent excluding wildcard and scatter
import { useContext, useEffect, useState } from 'react'

import { Context as GameContext } from '../../../../../context/GameContext'
import { Context as PayoutContext } from '../../../../../context/PayoutContext'

const CondenseRandomNumberB3 = () => {
  const [condensedNumber1Done, setCondensedNumber1Done] = useState(false)
  const [condensedNumber2Done, setCondensedNumber2Done] = useState(false)
  const [condensedNumber3Done, setCondensedNumber3Done] = useState(false)
  const [condensedNumber4Done, setCondensedNumber4Done] = useState(false)
  const [condensedNumber5Done, setCondensedNumber5Done] = useState(false)

  const {
    state: {
      randomNumberB1,
      randomNumberB2,
      randomNumberB3,
      randomNumberB4,
      randomNumberB5,
      reelSpinning5,
      freeSpinPlayOn,
    },
    setCondensedNumberB1,
    setCondensedNumberB2,
    setCondensedNumberB3,
    setCondensedNumberB4,
    setCondensedNumberB5,
    setCondenseRandomNumberBDone,
  } = useContext(GameContext)

  const {
    state: {
      payoutOverLimitStartReplay,
      triggerCondensePreJackpotNumber,
      jackpotWon,
    },
  } = useContext(PayoutContext)

  useEffect(() => {
    condensedRandomNumberB1()
    condensedRandomNumberB2()
    condensedRandomNumberB3()
    condensedRandomNumberB4()
    condensedRandomNumberB5()
  }, [
    randomNumberB1,
    randomNumberB2,
    randomNumberB3,
    randomNumberB4,
    randomNumberB5,
  ])

  useEffect(() => {
    if (
      condensedNumber1Done &&
      condensedNumber2Done &&
      condensedNumber3Done &&
      condensedNumber4Done &&
      condensedNumber5Done
    ) {
      setCondenseRandomNumberBDone(true)
    }
  }, [
    condensedNumber1Done,
    condensedNumber2Done,
    condensedNumber3Done,
    condensedNumber4Done,
    condensedNumber5Done,
  ])

  useEffect(() => {
    if (!reelSpinning5 || payoutOverLimitStartReplay) {
      setCondenseRandomNumberBDone(false)
      setCondensedNumber1Done(false)
      setCondensedNumber2Done(false)
      setCondensedNumber3Done(false)
      setCondensedNumber4Done(false)
      setCondensedNumber5Done(false)
    }
  }, [reelSpinning5, payoutOverLimitStartReplay])

  const condensedRandomNumberB1 = () => {
    switch (true) {
      // 4
      case randomNumberB1 > 0 && randomNumberB1 < 5:
        setCondensedNumberB1(1)
        setCondensedNumber1Done(true)
        break
      // 4
      case randomNumberB1 > 4 && randomNumberB1 < 9:
        setCondensedNumberB1(2)
        setCondensedNumber1Done(true)
        break
      // 4
      case randomNumberB1 > 8 && randomNumberB1 < 13:
        setCondensedNumberB1(3)
        setCondensedNumber1Done(true)
        break
      // 14
      case randomNumberB1 > 12 && randomNumberB1 < 27:
        setCondensedNumberB1(4)
        setCondensedNumber1Done(true)
        break
      // 14
      case randomNumberB1 > 26 && randomNumberB1 < 41:
        setCondensedNumberB1(5)
        setCondensedNumber1Done(true)
        break
      // 14
      case randomNumberB1 > 40 && randomNumberB1 < 55:
        setCondensedNumberB1(6)
        setCondensedNumber1Done(true)
        break
      // 14
      case randomNumberB1 > 54 && randomNumberB1 < 69:
        setCondensedNumberB1(7)
        setCondensedNumber1Done(true)
        break
      // 14
      case randomNumberB1 > 68 && randomNumberB1 < 83:
        setCondensedNumberB1(8)
        setCondensedNumber1Done(true)
        break
      // 14
      case randomNumberB1 > 82 && randomNumberB1 < 97:
        setCondensedNumberB1(9)
        setCondensedNumber1Done(true)
        break
      // 2
      case randomNumberB1 > 96 && randomNumberB1 < 99:
        setCondensedNumberB1(
          triggerCondensePreJackpotNumber || jackpotWon || freeSpinPlayOn
            ? 1
            : 10
        )
        setCondensedNumber1Done(true)
        break
      // 1
      case randomNumberB1 > 98 && randomNumberB1 < 100:
        setCondensedNumberB1(
          triggerCondensePreJackpotNumber || jackpotWon || freeSpinPlayOn
            ? 2
            : 11
        )
        setCondensedNumber1Done(true)
        break
      default:
        break
    }
  }

  const condensedRandomNumberB2 = () => {
    switch (true) {
      // 4
      case randomNumberB2 > 0 && randomNumberB2 < 5:
        setCondensedNumberB2(1)
        setCondensedNumber2Done(true)
        break
      // 4
      case randomNumberB2 > 4 && randomNumberB2 < 9:
        setCondensedNumberB2(2)
        setCondensedNumber2Done(true)
        break
      // 4
      case randomNumberB2 > 8 && randomNumberB2 < 13:
        setCondensedNumberB2(3)
        setCondensedNumber2Done(true)
        break
      // 14
      case randomNumberB2 > 12 && randomNumberB2 < 27:
        setCondensedNumberB2(4)
        setCondensedNumber2Done(true)
        break
      // 14
      case randomNumberB2 > 26 && randomNumberB2 < 41:
        setCondensedNumberB2(5)
        setCondensedNumber2Done(true)
        break
      // 14
      case randomNumberB2 > 40 && randomNumberB2 < 55:
        setCondensedNumberB2(6)
        setCondensedNumber2Done(true)
        break
      // 14
      case randomNumberB2 > 54 && randomNumberB2 < 69:
        setCondensedNumberB2(7)
        setCondensedNumber2Done(true)
        break
      // 14
      case randomNumberB2 > 68 && randomNumberB2 < 83:
        setCondensedNumberB2(8)
        setCondensedNumber2Done(true)
        break
      // 14
      case randomNumberB2 > 82 && randomNumberB2 < 97:
        setCondensedNumberB2(9)
        setCondensedNumber2Done(true)
        break
      // 2
      case randomNumberB2 > 96 && randomNumberB2 < 99:
        setCondensedNumberB2(
          triggerCondensePreJackpotNumber || jackpotWon || freeSpinPlayOn
            ? 1
            : 10
        )
        setCondensedNumber2Done(true)
        break
      // 1
      case randomNumberB2 > 98 && randomNumberB2 < 100:
        setCondensedNumberB2(
          triggerCondensePreJackpotNumber || jackpotWon || freeSpinPlayOn
            ? 2
            : 11
        )
        setCondensedNumber2Done(true)
        break
      default:
        break
    }
  }

  const condensedRandomNumberB3 = () => {
    switch (true) {
      // 4
      case randomNumberB3 > 0 && randomNumberB3 < 5:
        setCondensedNumberB3(1)
        setCondensedNumber3Done(true)
        break
      // 4
      case randomNumberB3 > 4 && randomNumberB3 < 9:
        setCondensedNumberB3(2)
        setCondensedNumber3Done(true)
        break
      // 4
      case randomNumberB3 > 8 && randomNumberB3 < 13:
        setCondensedNumberB3(3)
        setCondensedNumber3Done(true)
        break
      // 14
      case randomNumberB3 > 12 && randomNumberB3 < 27:
        setCondensedNumberB3(4)
        setCondensedNumber3Done(true)
        break
      // 14
      case randomNumberB3 > 26 && randomNumberB3 < 41:
        setCondensedNumberB3(5)
        setCondensedNumber3Done(true)
        break
      // 14
      case randomNumberB3 > 40 && randomNumberB3 < 55:
        setCondensedNumberB3(6)
        setCondensedNumber3Done(true)
        break
      // 14
      case randomNumberB3 > 54 && randomNumberB3 < 69:
        setCondensedNumberB3(7)
        setCondensedNumber3Done(true)
        break
      // 14
      case randomNumberB3 > 68 && randomNumberB3 < 83:
        setCondensedNumberB3(8)
        setCondensedNumber3Done(true)
        break
      // 14
      case randomNumberB3 > 82 && randomNumberB3 < 97:
        setCondensedNumberB3(9)
        setCondensedNumber3Done(true)
        break
      // 2
      case randomNumberB3 > 96 && randomNumberB3 < 99:
        setCondensedNumberB3(
          triggerCondensePreJackpotNumber || jackpotWon || freeSpinPlayOn
            ? 1
            : 10
        )
        setCondensedNumber3Done(true)
        break
      // 1
      case randomNumberB3 > 98 && randomNumberB3 < 100:
        setCondensedNumberB3(
          triggerCondensePreJackpotNumber || jackpotWon || freeSpinPlayOn
            ? 2
            : 11
        )
        setCondensedNumber3Done(true)
        break
      default:
        break
    }
  }

  const condensedRandomNumberB4 = () => {
    switch (true) {
      // 4
      case randomNumberB4 > 0 && randomNumberB4 < 5:
        setCondensedNumberB4(1)
        setCondensedNumber4Done(true)
        break
      // 4
      case randomNumberB4 > 4 && randomNumberB4 < 9:
        setCondensedNumberB4(2)
        setCondensedNumber4Done(true)
        break
      // 4
      case randomNumberB4 > 8 && randomNumberB4 < 13:
        setCondensedNumberB4(3)
        setCondensedNumber4Done(true)
        break
      // 14
      case randomNumberB4 > 12 && randomNumberB4 < 27:
        setCondensedNumberB4(4)
        setCondensedNumber4Done(true)
        break
      // 14
      case randomNumberB4 > 26 && randomNumberB4 < 41:
        setCondensedNumberB4(5)
        setCondensedNumber4Done(true)
        break
      // 14
      case randomNumberB4 > 40 && randomNumberB4 < 55:
        setCondensedNumberB4(6)
        setCondensedNumber4Done(true)
        break
      // 14
      case randomNumberB4 > 54 && randomNumberB4 < 69:
        setCondensedNumberB4(7)
        setCondensedNumber4Done(true)
        break
      // 14
      case randomNumberB4 > 68 && randomNumberB4 < 83:
        setCondensedNumberB4(8)
        setCondensedNumber4Done(true)
        break
      // 14
      case randomNumberB4 > 82 && randomNumberB4 < 97:
        setCondensedNumberB4(9)
        setCondensedNumber4Done(true)
        break
      // 2
      case randomNumberB4 > 96 && randomNumberB4 < 99:
        setCondensedNumberB4(
          triggerCondensePreJackpotNumber || jackpotWon || freeSpinPlayOn
            ? 1
            : 10
        )
        setCondensedNumber4Done(true)
        break
      // 1
      case randomNumberB4 > 98 && randomNumberB4 < 100:
        setCondensedNumberB4(
          triggerCondensePreJackpotNumber || jackpotWon || freeSpinPlayOn
            ? 2
            : 11
        )
        setCondensedNumber4Done(true)
        break
      default:
        break
    }
  }

  const condensedRandomNumberB5 = () => {
    switch (true) {
      // 4
      case randomNumberB5 > 0 && randomNumberB5 < 5:
        setCondensedNumberB5(1)
        setCondensedNumber5Done(true)
        break
      // 4
      case randomNumberB5 > 4 && randomNumberB5 < 9:
        setCondensedNumberB5(2)
        setCondensedNumber5Done(true)
        break
      // 4
      case randomNumberB5 > 8 && randomNumberB5 < 13:
        setCondensedNumberB5(3)
        setCondensedNumber5Done(true)
        break
      // 14
      case randomNumberB5 > 12 && randomNumberB5 < 27:
        setCondensedNumberB5(4)
        setCondensedNumber5Done(true)
        break
      // 14
      case randomNumberB5 > 26 && randomNumberB5 < 41:
        setCondensedNumberB5(5)
        setCondensedNumber5Done(true)
        break
      // 14
      case randomNumberB5 > 40 && randomNumberB5 < 55:
        setCondensedNumberB5(6)
        setCondensedNumber5Done(true)
        break
      // 14
      case randomNumberB5 > 54 && randomNumberB5 < 69:
        setCondensedNumberB5(7)
        setCondensedNumber5Done(true)
        break
      // 14
      case randomNumberB5 > 68 && randomNumberB5 < 83:
        setCondensedNumberB5(8)
        setCondensedNumber5Done(true)
        break
      // 14
      case randomNumberB5 > 82 && randomNumberB5 < 97:
        setCondensedNumberB5(9)
        setCondensedNumber5Done(true)
        break
      // 2
      case randomNumberB5 > 96 && randomNumberB5 < 99:
        setCondensedNumberB5(
          triggerCondensePreJackpotNumber || jackpotWon || freeSpinPlayOn
            ? 1
            : 10
        )
        setCondensedNumber5Done(true)
        break
      // 1
      case randomNumberB5 > 98 && randomNumberB5 < 100:
        setCondensedNumberB5(
          triggerCondensePreJackpotNumber || jackpotWon || freeSpinPlayOn
            ? 2
            : 11
        )
        setCondensedNumber5Done(true)
        break
      default:
        break
    }
  }

  return null
}

export default CondenseRandomNumberB3
